import { dto } from "shared"

export const IUserAttributeClientId = Symbol.for('IUserAttributeClient');

export interface IUserAttributeClient {
    getUserAttributes(data: dto.GetUserAttributesRequest): Promise<dto.PagingResponse<dto.UserAttribute>>
    getUserAttribute(userattributeId: string): Promise<dto.UserAttribute>
    createUserAttribute(data: dto.AddUserAttributeRequest): Promise<dto.UserAttribute>
    updateUserAttribute(userattributeId: string, data: dto.UpdateUserAttributeRequest): Promise<void>
    deleteUserAttribute(userattributeId: string): Promise<void>
    uploadUserAttributesUsingExcel(companyId: string, data: dto.UploadUserAttributesByExcelFileRequest): Promise<dto.UploadUserAttributesByExcelFileResponse>
}