import { SharedDataHelper, dto } from "shared"
import { CookieHelper } from "./cookie-helper"

export class CompanyHelper {
	static verifyAndGetDefaultCompanyId(companies: dto.Company[]): string | null {
		const defaultCompanyId = CookieHelper.getCookie("defaultCompanyId")
		if (SharedDataHelper.stringNotNullTrimEmpty(defaultCompanyId)) {
			const valid = companies.find(x => x.id === defaultCompanyId)
			if (valid) {
				return defaultCompanyId
			}
		}
		return null
	}

	static getDefaultCompany(companies: dto.Company[]): string | null {
		const companyId = CompanyHelper.verifyAndGetDefaultCompanyId(companies)
		if (SharedDataHelper.stringNotNullTrimEmpty(companyId)) {
			return companyId
		}

		return SharedDataHelper.firstOrDefault(companies)?.id ?? null
	}
}