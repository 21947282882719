import { useCallback } from "react";
import { useMemo } from "react";
import { dto } from "shared";
import { useHistory, useLocation } from "react-router-dom";
import { IUserRecoverPasswordClientId, IUserRecoverPasswordClient, Config } from "shared-client"
import { validate, ValidationError } from "class-validator";
import { setFieldError, submitWrapper } from "../../../../common/form/form";
import { FormikErrors, FormikHelpers } from "formik";
import useClient from "../../../hook/useClient";
import { useEffect } from "react";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";

const PathBasic = "/recover-password"
const PathInitial = PathBasic + "/initial";
const PathChallengeToken = PathBasic + "/challenge-token";
const PathEnterPassword = PathBasic + "/enter-new-password";
const PathCompleted = PathBasic + "/completed";

interface Model {
  email: string,
  token: string,
  password: string,
  confirmPassword: string,
  showPassword: boolean,
}

export type UserRecoverPasswordModel = Model

const _createRecoverRequest = (model: Model) => {
  const request = new dto.UserRecoverPasswordRecoverRequest()
  request.email = model.email;
  request.clientId = Config.OAuthClientId;
  return request
}

export const useUserRecoveredPassword = () => {
  const history = useHistory();
  const { pathname } = useLocation()
  const { setGlobalMessage } = useGlobalMessageContext()
  const client = useClient<IUserRecoverPasswordClient>(IUserRecoverPasswordClientId)

  useEffect(() => {
    if (pathname !== PathInitial) {
      history.replace(PathInitial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues: Model = useMemo(() => {
    return {
      email: "",
      token: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
    }
  }, []);

  const createRecoverRequest = useCallback(_createRecoverRequest, [])

  const createChallengeTokenRequest = ((model: Model) => {
    let request = new dto.UserRecoverPasswordChallengeTokenRequest();
    request.email = model.email;
    request.token = model.token
    request.clientId = Config.OAuthClientId;
    return request
  })

  const createCompleteRequest = ((model: Model) => {
    let request = new dto.UserRecoverPasswordCompleteRequest();
    request.email = model.email;
    request.token = model.token;
    request.password = model.password;
    request.confirmPassword = model.confirmPassword;
    request.clientId = Config.OAuthClientId;
    return request
  })

  const onInitialValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
    const errorModel: FormikErrors<Model> = {}
    let validateErrors: ValidationError[] = []
    switch (pathname.toLowerCase()) {
      case PathInitial:
        const requestRecover = createRecoverRequest(model)
        validateErrors = await validate(requestRecover);
        setFieldError<Model>(validateErrors, "email", errorModel)
        break
      case PathChallengeToken:
        const requestChallengeToken = createChallengeTokenRequest(model)
        validateErrors = await validate(requestChallengeToken);
        setFieldError<Model>(validateErrors, "token", errorModel)
        break
      case PathEnterPassword:
        const requestComplete = createCompleteRequest(model)
        validateErrors = await validate(requestComplete);
        setFieldError<Model>(validateErrors, "token", errorModel)
        break
      default:
        break

    }

    return errorModel
  }, [createRecoverRequest, pathname])

  const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
    let success = false
    const _pathname = pathname.toLowerCase()

    if (_pathname === PathInitial) {
      await submitWrapper<Model>(async () => {
        const requestRecover = createRecoverRequest(model)
        let statusResponse = await client.recover(requestRecover)
        success = statusResponse?.success ?? false
        if (success) {
          history.push(PathChallengeToken)
        } else {
          setGlobalMessage({ type: "error", message: statusResponse?.message ?? "Error recover user password" })
        }
      }, { setFieldError, setGlobalMessage, fieldNames: ["email"] })
    }

    if (_pathname === PathChallengeToken) {
      await submitWrapper<Model>(async () => {
        const requestChallengeToken = createChallengeTokenRequest(model)
        const statusResponse = await client.challengeToken(requestChallengeToken)
        success = statusResponse?.success ?? false
        if (success) {
          history.push(PathEnterPassword)
        } else {
          setGlobalMessage({ type: "error", message: statusResponse?.message ?? "Error challenge token" })
        }
      }, { setFieldError, setGlobalMessage, fieldNames: ["token"] })
    }

    if (_pathname === PathEnterPassword) {
      await submitWrapper<Model>(async () => {
        const requestComplete = createCompleteRequest(model)
        const statusResponse = await client.completeResetPassword(requestComplete)
        success = statusResponse?.success ?? false
        if (success) {
          history.push(PathCompleted)
        } else {
          setGlobalMessage({ type: "error", message: statusResponse?.message ?? "Error set password" })
        }
      }, { setFieldError, setGlobalMessage, fieldNames: ["password", "confirmPassword"] })
    }
  }, [client, setGlobalMessage, history, createRecoverRequest, pathname])

  return {
    paths: {
      PathInitial,
      PathChallengeToken,
      PathEnterPassword,
      PathCompleted
    },
    initialValues,
    validate: onInitialValidate,
    onSubmit,
  }
}

export const useUserRecoverPasswordChallengeToken = () => {
  const { setGlobalMessage } = useGlobalMessageContext()
  const { values: model } = useTypedFormikContext<Model>()
  const client = useClient<IUserRecoverPasswordClient>(IUserRecoverPasswordClientId)

  const resendToken = useCallback(async () => {
    const requestRecover = _createRecoverRequest(model)
    let statusResponse = await client.recover(requestRecover)
    let success = statusResponse?.success ?? false
    if (success) {
      setGlobalMessage({ type: "success", message: "Token is resent" })
    } else {
      setGlobalMessage({ type: "error", message: statusResponse?.message ?? "General error2" })
    }
  }, [setGlobalMessage, model, client])

  return {
    resendToken
  }
}

export const useUserRecoverPasswordEnterPassword = () => {

  const { setFieldValue, model } = useTypedFormikContext<Model>()

  const onToggleShowPassword = useCallback(() => {
    setFieldValue("showPassword", !model.showPassword, false)
  }, [setFieldValue, model.showPassword])

  return {
    onToggleShowPassword
  }
}

export const useUserRecoverPasswordCompleted = () => {
  const history = useHistory()
  const { setGlobalMessage } = useGlobalMessageContext()

  const onCloseRequested = useCallback(() => {
    setGlobalMessage(null)
    history.replace("/signin")
  }, [history, setGlobalMessage])

  return {
    onCloseRequested
  }
}

