import "./queued-job.scss"
import { Form, Formik } from "formik"
import React from "react"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import { QueuedJobModel, useQueuedJob } from "./useQueuedJob"
import { useLocalization } from "../../../hook/use-localization"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { TextArea } from "../../../_common/fields/text-area/TextArea"


export const QueuedJob: React.FC = () => {
    const { t } = useLocalization()
    const { modelInitialValues, onSubmit, onValidate, loading, } = useQueuedJob()

    return (
        <Shell pageTitle={t("QueuedJob")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent />
                        </Form>
                    )}
                </Formik>
            </>
            }
        </Shell>
    )
}



const FormContent: React.FC = () => {
    const { model } = useTypedFormikContext<QueuedJobModel>()

    const { t } = useLocalization()

    const jobRecordItems = model.data.map((record) =>
        <div className="text-input__container" key={record.fieldName}>
            <div>{t(record.title)}</div>
            <input className="text-input__input form-control" disabled={true} value={record.value} />
        </div>
    );
    return (
        <div className="indent-container">
            <TextInput name="companyName" disabled={true} label={t("Company")} />
            <TextInput name="queueName" disabled={true} label={t("Type")} />
            <TextInput name="status" disabled={true} label={t("Status")} />
            <TextInput name="date" disabled={true} label={t("Date")} />
            {model.errorDetails != null && <TextArea name="errorDetails" className="queuedJobErrorDetails" translateValue={true} disabled={true} label={t("Error")} />}
 
            <div className="alert alert-light zone-container indent-container" role="alert">
                <div>{t("Data")}:</div>
                {jobRecordItems}
            </div>

        </div>)
}
