import React, { useCallback } from "react";
import { SharedDataHelper, dto } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./user-attributes.scss";
import { useUserAttributes } from "./useUserAttributes";
import { UserAttributeHelper } from "../../../_common/helpers/user-attribute-helper";
import { useLocalization } from "../../../hook/use-localization";
import { useSharedAttribute } from "../../../hook/use-shared-attribute";

export const UserAttributes: React.FC = () => {
    const { t } = useLocalization()
    const isMobile = useIsMobile();
    const {
        userAttributes,
        onUserAttributeSelected,
        loading,
        pagination,
        onAddUserAttribute,
        unregisteredUser,
        onViewUser,
        userAttributeNames
    } = useUserAttributes();

    const { transformSharedAttrValue } = useSharedAttribute()


    const rowClick = (id: string) => {
        if (isMobile) {
            onUserAttributeSelected(id);
        }
    };

    const getAttributeName = useCallback((attribute: dto.UserAttribute) => {
        if (dto.UserAttribute.isSharedAttribute(attribute.name)) {
            return t(attribute.name)
        }
        return attribute.name

    }, [t])

    const getAttributeValue = useCallback((attribute: dto.UserAttribute) => {
        if (dto.UserAttribute.isSharedAttribute(attribute.name)) {
            return transformSharedAttrValue(attribute.name, attribute.value)
        }
        const attrName = userAttributeNames?.find(x => x.name === attribute.name)
        if (attrName?.attributeType === dto.UserAttributeNameType.date) {
            if (SharedDataHelper.matchISO8601(attribute.value)) {
                return SharedDataHelper.getFormattedDate(SharedDataHelper.parseISO8601(attribute.value), "dd.MM.yyyy HH:mm")
            }
        }
        if (attrName?.attributeType === dto.UserAttributeNameType.exists) {
            return ""
        }
        return attribute.value

    }, [transformSharedAttrValue, userAttributeNames])

    const getAttributeDescription = useCallback((attribute: dto.UserAttribute) => {
        if (dto.UserAttribute.isSharedAttribute(attribute.name)) {
            return ""
        }
        return attribute.description

    }, [])

    const listItems = userAttributes.map((userAttribute) => (
        <div
            className="user-attributes__item data-row-item"
            key={userAttribute.id}
            onClick={() => rowClick(userAttribute.id)}
        >
            <div className="user-attributes__item-name-value-description">
                <div className="user-attributes__item-name">
                    <button
                        onClick={() => {
                            onUserAttributeSelected(userAttribute.id);
                        }}
                    >
                        {getAttributeName(userAttribute)}
                    </button>
                </div>
                <div className="user-attributes__item-value">
                    {getAttributeValue(userAttribute)}
                </div>
                <div className="user-attributes__item-description">
                    {getAttributeDescription(userAttribute)}
                </div>
            </div>
            <div className="user-attributes__item-dates">
                {t("Created")}  {SharedDataHelper.getFormattedDate(userAttribute.updatedDate ?? userAttribute.createdDate, "dd.MM.yyyy HH:mm")}
            </div>
        </div>
    ));
    return (
        <Shell
            pageTitle={<div className="user-attributes-title">{t("Contact attributes")} <button
                onClick={onViewUser}
            >
                {unregisteredUser?.firstName}  {unregisteredUser?.lastName}
            </button></div>}
            mobileHeaderContent={
                <button
                    className="mobile-add-new"
                    onClick={onAddUserAttribute}
                >
                    {t("Add")}
                </button>

            }
            desktopHeaderContent={
                <Button onClick={onAddUserAttribute}>
                    {t("AddAttribute")}
                </Button>

            }
        >
            <Loading loading={loading} />
            <div className="indent-container users-header-filter">
                <Pagination {...pagination} />
            </div>
            {listItems}
            {userAttributes.length === 0 && <NoEntities />}
        </Shell>
    );
};
