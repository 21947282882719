import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { dto } from "shared";
import { ICompanyClient, ICompanyClientId, IUserInvitationClient, IUserInvitationClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import useClient from "../../../hook/useClient";
import { validate } from "class-validator";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { reactDelay } from "../../../_common/helpers/common";


interface Model {
	companyId: string,
	userId: string,
	companies: dto.Company[]
}

export type AddUserInvitationModel = Model

const createModel = (companies: dto.Company[], companyId: string, userId: string): Model => {

	return {
		companyId: companyId,
		userId: userId,
		companies: companies
	}
}

const createAddRequest = (model: Model): dto.AddUserInvitationRequest => {
	const request = new dto.AddUserInvitationRequest()
	request.companyId = model.companyId
	request.userId = model.userId
	if (request.userId === "-") {
		request.userId = ""
	}
	return request
}

export const useAddUserInvitation = () => {

	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [editMode, setEditMode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const invitationClient = useClient<IUserInvitationClient>(IUserInvitationClientId)

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				const companies = await companyClient.getCurrentCompanies()
				const selectedCompanyId = companies.length > 0 ? companies[0].id : null
				const model = createModel(companies, selectedCompanyId ?? "", "-")
				setModelInitialValues(model)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [setLoading, setGlobalMessage, setModelInitialValues, companyClient])


	useEffect(() => {
		loadInitialModel()
	}, [])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const errorModel: FormikErrors<Model> = {}
		const request = createAddRequest(model)
		let validateErrors = await validate(request);
		setFieldErrors<Model>(validateErrors, ["companyId", "userId"], errorModel)
		return errorModel
	}, [])


	const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			const request = createAddRequest(model)
			const response = await invitationClient.addUserInvitation(request)
			history.replace("/user-invitations/" + response.id)
			reactDelay(() => setGlobalMessage({ message: "Invitation has been sent to user.", type: "success" }))
		}, { setFieldError, setGlobalMessage, fieldNames: ["companyId", "userId"] })
	}, [invitationClient, setGlobalMessage, history])



	return {
		modelInitialValues,
		onSubmit,
		onValidate,
		editMode,
		setEditMode,
		loading,
	}
}

export const useAddUserInvitationForm = () => {
	const { model } = useTypedFormikContext<Model>()

	const addInvitationEnabled = useMemo(() => {
		return model.companies.length > 0
	}, [model.companies])

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(model.companies)
	}, [model.companies])

	return {
		addInvitationEnabled,
		companyItems
	}
}


