import React, { useMemo } from "react"
import { dto } from "shared"
import "./message-type-dropdown.scss"
import { Dropdown } from "../../common/dropdown/DropDown"
import { useLocalization } from "../../../hook/use-localization"



interface Props {
    selectedMessageType: dto.MessageType,
    onSelect: (messageType: dto.MessageType) => void,
    disabled?: boolean,
}

export const MessageTypesDropdown: React.FC<Props> = ({ selectedMessageType, onSelect, disabled }) => {
    const { t } = useLocalization()
    const items = useMemo(() => {
        return [
            { text: t("MessageType_Promo"), value: dto.MessageType.promo },
            { text: t("MessageType_Notification"), value: dto.MessageType.notification }]
    }, [t])

    return (
        <Dropdown className="message-types-dropdown" items={items} label={t("MessageType")} onSelect={onSelect as any} selectedValue={selectedMessageType} disabled={disabled} />
    )
}