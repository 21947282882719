import { IsArray, IsBoolean, IsDate, IsDefined, IsEnum, IsOptional, MaxLength, MinLength, ValidateNested } from "class-validator";
import { UserGender } from "..";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";
import { ContainsValidTimezone } from "../../../validators/contains-valid-timezone";

export class ConsentItem {
    @ContainsValidString()
    id: string

    @IsBoolean()
    enabled: boolean
}

export class UserActionUpdateUnregisteredUserRequest {

    @ContainsValidTimezone()
    timezone: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    firstName: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    lastName: string

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsDate()
    dateOfBirth: Date | undefined

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsEnum(UserGender)
    gender: UserGender | undefined

    @IsDefined()
    @IsArray()
    @ValidateNested({ each: true })
    consentItems: ConsentItem[]
}