import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { ICompanyClient, ICompanyClientId, INotificationTaskBatchClient, INotificationTaskBatchClientId, INotificationTemplateClient, INotificationTemplateClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { useAppContext } from "../../../hook/use-app-context";
import { DropdownTitleSubtitleItem } from "../../../_common/components/dropdown-title-subtitle/dropdown-title-subtitle";
import { notification } from "../../../../common/notification/notification";
import { NotificationSpecificFactory } from "../../../../common/notification/notification-specific-factory";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useLocalization } from "../../../hook/use-localization";
import { reactDelay } from "../../../_common/helpers/common";

declare type ActionType = "estimate" | "create_only_for_me" | "create_notifications"

interface Model {
    notificationTaskBatchId: string | null,
    companyId: string | null,
    templateId: string | null,
    name: string | null,
    taskDate: Date | null,
    expirationDate: Date | null,
    numberOfCompletedTasks: string,
    numberOfFailedTasks: string,
    numberOfPendingTasks: string,
    readonly: boolean,
    createdDate: Date | null,
    updatedDate: Date | null,
    notificationTaskType: dto.NotificationTaskType,
    companies: dto.Company[],
    templates: dto.NotificationTemplate[],
    /* separated with \n */
    addresses: string,
    actionType: ActionType,
    addressesValidationMessage: string
}

export type NotificationBatchModel = Model

const createModel = (
    batchInfo: dto.NotificationTaskBatchInfo | null,
    companies: dto.Company[],
    selectedCompanyId: string | null,
    selectedNotificationTaskType: dto.NotificationTaskType,
    readonly: boolean): Model => {

    const numberOfCompletedTasks = batchInfo != null ? `${batchInfo.numberOfTasksCompleted}/${batchInfo.numberOfTasksTotal}` : ""
    const numberOfFailedTasks = batchInfo != null ? `${batchInfo.numberOfTasksFailed}/${batchInfo.numberOfTasksTotal}` : ""
    const numberOfPendingTasks = batchInfo != null ? `${batchInfo.numberOfTasksFailed}/${batchInfo.numberOfTasksTotal}` : ""

    let taskDate = batchInfo?.batch?.taskDate
    if (batchInfo?.batch?.id == null) {
        taskDate = SharedDataHelper.addDays(new Date(), 1);
        taskDate.setHours(9, 0)
    }

    let expirationDate = batchInfo?.batch?.expirationDate
    if (batchInfo?.batch?.id == null) {
        expirationDate = SharedDataHelper.addDays(new Date(), 3);
        expirationDate.setHours(18, 0)
    }

    return {
        readonly,
        notificationTaskBatchId: batchInfo?.batch?.id ?? null,
        notificationTaskType: selectedNotificationTaskType,
        numberOfCompletedTasks,
        numberOfFailedTasks,
        numberOfPendingTasks,
        name: batchInfo?.batch?.name ?? "",
        taskDate: taskDate ?? null,
        expirationDate: expirationDate ?? null,
        createdDate: batchInfo?.batch?.createdDate ?? null,
        updatedDate: batchInfo?.batch?.updatedDate ?? null,
        companyId: selectedCompanyId,
        companies: companies,
        templateId: "",
        templates: [],
        actionType: "estimate",
        addresses: "",
        addressesValidationMessage: ""
    }
}

const createGetNotificationTemplatesRequest = (options: { companyId: string, notificationTaskType: dto.NotificationTaskType }): dto.GetNotificationTemplatesRequest => {
    const { companyId, notificationTaskType } = options
    const request = new dto.GetNotificationTemplatesRequest()
    request.companyId = companyId
    request.paging = dto.PagingRequest.createMaxPaging()
    request.sortBy = []
    request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
    request.templateType = notification.taskTypeToTemplateType(notificationTaskType)
    return request
}

const createAddNotificationTaskBatchRequest = (model: Model): dto.AddNotificationTaskBatchRequest => {
    const request = new dto.AddNotificationTaskBatchRequest()
    request.companyId = model.companyId!
    request.notificationTaskType = model.notificationTaskType!
    request.generateTasks = model.actionType === "create_notifications"
    request.generateImmediatelyTaskOnlyForMe = model.actionType === "create_only_for_me"
    request.name = request.generateImmediatelyTaskOnlyForMe ? `Only for me: ${model.name}` : model.name!
    request.templateId = model.templateId!
    request.taskDate = model.taskDate!
    request.expirationDate = model.expirationDate!
    return request
}

export const useNotificationTaskBatch = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const { t } = useLocalization()
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const { userRoles } = useAppContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [batchInfo, setBatchInfo] = useState<dto.NotificationTaskBatchInfo | null>()
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId);
    const notificationTemplateClient = useClient<INotificationTemplateClient>(INotificationTemplateClientId)

    const notificationTaskBatchClient = useClient<INotificationTaskBatchClient>(INotificationTaskBatchClientId)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                let notificationTaskType = notification.defaultNotificationTaskType
                let readonly = id != null
                let companyId: string | null = null
                let companies: dto.Company[] = []
                let notificationTaskBatchInfo: dto.NotificationTaskBatchInfo | null = null
                if (id != null) {
                    notificationTaskBatchInfo = await notificationTaskBatchClient.getNotificationTaskBatchInfo(id)
                    companyId = notificationTaskBatchInfo.batch.companyId
                    companies = [notificationTaskBatchInfo.batch.company]
                    setBatchInfo(notificationTaskBatchInfo)
                    notificationTaskType = notification.templateTypeToTaskType(notificationTaskBatchInfo.batch.template.templateType)
                } else {
                    companies = await companyClient.getCurrentCompanies()
                    companyId = CompanyHelper.getDefaultCompany(companies)
                    setBatchInfo(null)

                    if ((window as any).TEMP_BULK_MESSAGE_TEMPLATE_INFO != null) {
                        const { companyId: tempSelectedCompanyId, templateId: tempTemplateId } = (window as any).TEMP_BULK_MESSAGE_TEMPLATE_INFO;
                        (window as any).TEMP_BULK_MESSAGE_TEMPLATE_INFO = null
                        //should always be the case for Company user
                        if (companies.find(x => x.id === tempSelectedCompanyId) != null) {
                            companyId = tempSelectedCompanyId
                            const serverTemplate = await notificationTemplateClient.getNotificationTemplate(tempTemplateId)
                            notificationTaskType = notification.templateTypeToTaskType(serverTemplate.templateType)
                        }
                    }
                }
                const model = createModel(notificationTaskBatchInfo, companies, companyId, notificationTaskType, readonly)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, companyClient, notificationTaskBatchClient, notificationTemplateClient])

    useEffect(() => {
        loadInitialModel()
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const request = createAddNotificationTaskBatchRequest(model)
        let validateErrors: ValidationError[] = await validate(request);
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["notificationTaskType", "name", "taskDate", "companyId", "templateId", "addresses"], errorModel)
        return errorModel
    }, [])


    const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            const request = createAddNotificationTaskBatchRequest(model)
            const batchResponse = await notificationTaskBatchClient.addNotificationTaskBatch(request)
            if (id == null && model.actionType === "create_only_for_me") {
                setGlobalMessage({ message: t(`MessageSent`), type: "success" })
            }
            else if (id == null && model.actionType === "create_notifications") {
                history.replace("/notification-task-batches/" + batchResponse.id);
                reactDelay(() => (setGlobalMessage({ message: t("BulkMessagesCreated"), type: "success" })))
            } else {
                setGlobalMessage({ message: t('ExpectedNumOfMessages').replaceAll("##Num##", batchResponse.expectedNumberOfCreated?.toString()), type: "info" })
            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["notificationTaskType", "name", "taskDate", "companyId", "templateId", "addresses"] })
    }, [setGlobalMessage, notificationTaskBatchClient, history, id, t])

    const isAddingNewNotificationTaskBatch = useMemo(() => {
        return id == null
    }, [id])

    const cancelNotificationTaskBatchEnabled = useMemo(() => {
        return (userRoles.isAdmin || userRoles.isCompanyAdmin) && batchInfo != null && batchInfo.numberOfTasksPending! > 0 && batchInfo.numberOfTasksPending! > 0
    }, [batchInfo, userRoles.isAdmin, userRoles.isCompanyAdmin])

    const onCancelNotificationTaskBatch = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await notificationTaskBatchClient.cancelNotificationTaskBatch(id)
                loadInitialModel()
                setGlobalMessage({ message: t("BulkMessagesCanceled"), type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, notificationTaskBatchClient, setGlobalMessage, loadInitialModel, t])

    const deleteNotificationTaskBatchEnabled = useMemo(() => {
        return userRoles.isAdmin && batchInfo != null
    }, [batchInfo, userRoles.isAdmin])


    const onDeleteNotificationTaskBatch = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await notificationTaskBatchClient.deleteNotificationTaskBatch(id)
                history.replace("/notification-task-batches")
                setGlobalMessage({ message: t("BulkMessagesDeleted"), type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, notificationTaskBatchClient, history, setGlobalMessage, t])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onCancelNotificationTaskBatch,
        onDeleteNotificationTaskBatch,
        cancelNotificationTaskBatchEnabled,
        deleteNotificationTaskBatchEnabled,
        isAddingNewNotificationTaskBatch
    }
}

export const useNotificationTaskBatchForm = () => {
    let { id } = useParams<{ id: string }>();
    const { t } = useLocalization();
    const { model, setFieldValue } = useTypedFormikContext<Model>()
    const notificationTemplateClient = useClient<INotificationTemplateClient>(INotificationTemplateClientId)

    useEffect(() => {
        setFieldValue("templates", [])
        if (model.companyId != null) {
            (async () => {
                const request = createGetNotificationTemplatesRequest({ companyId: model.companyId!, notificationTaskType: model.notificationTaskType })
                const pagedResponse = await notificationTemplateClient.getNotificationTemplates(request)
                const templates = pagedResponse.entities.filter(x => x.eventTypeId == null)
                setFieldValue("templates", templates, true)
                setFieldValue("templateId", templates.length > 0 ? templates[0].id : null, true)
            })()
        }
    }, [model.companyId, model.notificationTaskType])

    const notificationTemplateItems = useMemo((): DropdownTitleSubtitleItem[] => {
        return model.templates.map(template => {
            return {
                title: template.name,
                subtitle: template.templateType,
                value: template.id,
                revertTitleSubTitle: true
            }
        })
    }, [model.templates])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies)
    }, [model.companies])



    const actionItems = useMemo((): DropdownTitleSubtitleItem[] => {
        return [{
            title: t(`NotificationTaskBatch.Estimate.Title`),
            subtitle: t(`NotificationTaskBatch.Estimate.Description`),
            value: "estimate"
        }, {
            title: t(`NotificationTaskBatch.GenerateOnlyForMe.Title`),
            subtitle: t(`NotificationTaskBatch.GenerateOnlyForMe.Description`),
            value: "create_only_for_me"
        }, {
            title: t(`NotificationTaskBatch.CreateBatch.Title`),
            subtitle: t(`NotificationTaskBatch.CreateBatch.Description`),
            value: "create_notifications"
        }]
    }, [t])

    const actionTypeSubtitle = useMemo((): string => {
        if (SharedDataHelper.stringNotNullTrimEmpty(model.actionType)) {
            return actionItems.find(x => x.value === model.actionType)?.subtitle ?? ""
        }
        return ""
    }, [actionItems, model.actionType])

    const addressesTitle = useMemo((): string => {
        const notificationSpecific = NotificationSpecificFactory.createTaskSpecific(model.notificationTaskType)
        return t(notificationSpecific.getAddressesInputTitle()) + " (" + t("SeparateBySpecialCharacterN") + ") "
    }, [model.notificationTaskType, t])

    const onAddressChanged = useCallback((inputAddresses: string) => {
        let addresses = (inputAddresses ?? "").split(/[\n]/)
        addresses = addresses.filter(x => SharedDataHelper.stringNotNullTrimEmpty(x))
        if (addresses.length === 0) {
            setFieldValue("addressesValidationMessage", "")
        } else {
            const notificationSpecific = NotificationSpecificFactory.createTaskSpecific(model.notificationTaskType)
            const result = notificationSpecific.validateAddresses(addresses)
            setFieldValue("addressesValidationMessage", `${result.success}/${result.total} of valid.`)
        }
    }, [setFieldValue, model.notificationTaskType])

    useEffect(() => {
        if (model.taskDate != null && id == null) {
            setFieldValue("expirationDate", SharedDataHelper.addDays(model.taskDate, 2))
        }
    }, [model.taskDate, id])

    return {
        notificationTemplateItems,
        notificationTaskTypes: notification.notificationTaskTypes,
        companyItems,
        actionItems,
        actionTypeSubtitle,
        addressesTitle,
        onAddressChanged
    }
}
