import * as React from 'react'
import { useAsync } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useConstant } from './use-constant';

const useDebouncedCallback = (debouncedCallback: (text: string | null) => void) => {

    // Handle the input text state
    const [inputText, setInputText] = React.useState('');
    const [hadValue, setHadValue] = React.useState(false)

    // Debounce the original search async function
    const debouncedSearchFunction = useConstant(() =>
        AwesomeDebouncePromise(debouncedCallback, 500)
    );

    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    useAsync(
        async () => {
            if (!hadValue && inputText.length === 0) {
                return [];
            } else {
                return debouncedSearchFunction(inputText);
            }
        },
        [debouncedSearchFunction, inputText]
    );

    const _setInputText = React.useCallback((val: string) => {
        setInputText(val);
        if (val !== "") {
            setHadValue(true)
        }
    }, [])

    // Return everything needed for the hook consumer
    return {
        inputText,
        setInputText: _setInputText
    };
};

export default useDebouncedCallback;

