import React, { ReactChild } from "react"
import { Dropdown, DropdownItem } from "../../common/dropdown/DropDown"
import "./dropdown-title-subtitle"

export interface DropdownTitleSubtitleItem {
	title: string,
	subtitle: string,
	value: string | null,
	revertTitleSubTitle?: boolean
}



interface Props {
	label: string
	items: DropdownTitleSubtitleItem[],
	selectedValue: string | null,
	onSelect: (value: string | null) => void
	disabled?: boolean

}

export const DropdownTitleSubtitle: React.FC<Props> = ({ items, selectedValue, label, onSelect, disabled }) => {

	const dropdownItems: DropdownItem[] = items.map(c => {
		const textNode: ReactChild = <div className="dropdown-title-subtitle__item">
			{c.revertTitleSubTitle !== true && <div className="dropdown-title-subtitle__item-title">{c.title}</div>}
			<div className="company-dropdown__item-subtitle">{c.subtitle}</div>
			{c.revertTitleSubTitle === true && <div className="dropdown-title-subtitle__item-title">{c.title}</div>}
		</div>
		return {
			textNode,
			value: c.value as any,
			text: c.title
		}
	})

	return (
		<Dropdown label={label} className="dropdown-title-subtitle" items={dropdownItems} onSelect={onSelect} selectedValue={selectedValue} disabled={disabled} ></Dropdown>
	)
}