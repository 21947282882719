
import { CustomUserAttributeNamePrefix } from "../api/dto";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsValidCustomAttributeNameConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {

        if (text != null && typeof text === "string") {
            if (text != text.trim()) {
                return false
            }

            if (!text.startsWith(CustomUserAttributeNamePrefix)) {
                return false
            }
            // must at least have something after CustomUserAttributeNamePrefix
            if (text == CustomUserAttributeNamePrefix) {
                return false
            }
            return true;
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        return `${CustomUserAttributeNamePrefix}PrefixRequired`
    }
}

export function IsValidCustomAttributeName(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsValidCustomAttributeNameConstraint
        });
    };
}