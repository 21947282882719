import { dto } from "shared"

export const INotificationTemplateClientId = Symbol.for('INotificationTemplateClient');

export interface INotificationTemplateClient {
    getNotificationTemplates(data: dto.GetNotificationTemplatesRequest): Promise<dto.PagingResponse<dto.NotificationTemplate>>
    getNotificationTemplate(notificationtemplateId: string): Promise<dto.NotificationTemplate>
    addNotificationTemplate(data: dto.AddNotificationTemplateRequest): Promise<dto.NotificationTemplate>
    updateNotificationTemplate(notificationtemplateId: string, data: dto.UpdateNotificationTemplateRequest): Promise<void>
    deleteNotificationTemplate(notificationtemplateId: string): Promise<void>
    getPredefinedTemplates(): Promise<dto.PredefinedNotificationTemplate[]>
}