import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { IsDefined, IsOptional, ValidateNested } from "class-validator";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";

export class GetUserConsentHistoriesRequest {

    @IsOptional()
    @ContainsValidString()
    companyId: string | null;

    @IsOptional()
    @ContainsValidString()
    firstName: string | null;

    @IsOptional()
    @ContainsValidString()
    lastName: string | null;

    @IsOptional()
    @ContainsValidString()
    mobilePhoneNumber: string | null;

    @IsOptional()
    @ContainsValidString()
    email: string | null;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]
}


