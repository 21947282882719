import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserAttributeNameClient } from "./i-user-attribute-name-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserAttributeNameClient extends BaseClient implements IUserAttributeNameClient {

    async getUserAttributeNames(data: dto.GetUserAttributeNamesRequest): Promise<dto.PagingResponse<dto.UserAttributeName>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userAttributeName.base + apiPaths.userAttributeName.userAttributeNames), params: data });
            return response.data as dto.PagingResponse<dto.UserAttributeName>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUserAttributeName(userattributenameId: string): Promise<dto.UserAttributeName> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userAttributeName.base + apiPaths.userAttributeName.userAttributeName.replace(":userattributenameid", userattributenameId)) });
            return response.data as dto.UserAttributeName;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async createUserAttributeName(data: dto.AddUserAttributeNameRequest): Promise<dto.UserAttributeName> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.userAttributeName.base + apiPaths.userAttributeName.userAttributeNames), data: data });
            return response.data as dto.UserAttributeName;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateUserAttributeName(userattributenameId: string, data: dto.UpdateUserAttributeNameRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.userAttributeName.base + apiPaths.userAttributeName.userAttributeName.replace(":userattributenameid", userattributenameId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUserAttributeName(userattributenameId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.userAttributeName.base + apiPaths.userAttributeName.userAttributeName.replace(":userattributenameid", userattributenameId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}