import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { repository, IAuthClient, IAuthClientId, IAppTypeClient, IAppTypeClientId, ICompanyClient, ICompanyClientId } from "shared-client"
import { useAppContext } from "../../../hook/use-app-context"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import useIsMobile from "../../../hook/use-is-mobile"
import useClient from "../../../hook/useClient"
import { UserHelper } from "../../helpers/user-helper"
import useIsTablet from "../../../hook/use-is-tablet"
import { useLocalization } from "../../../hook/use-localization"

export interface ShellMenuItem {
    id: string,
    imagePath: string,
    text: string,
    allowed: boolean,
    navigationPath?: string
    onClick?: () => void
}

export const useShell = () => {
    const history = useHistory()
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();
    const { t } = useLocalization()
    const { userRoles, user, clearUser } = useAppContext()
    const { globalMessage, setGlobalMessage } = useGlobalMessageContext()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [appType, setAppType] = useState("")
    const authClient = useClient<IAuthClient>(IAuthClientId)
    const appTypeClient = useClient<IAppTypeClient>(IAppTypeClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [userCompanyName, setUserCompanyName] = useState<string | null>(null)


    const tokenRepository = useClient<repository.ITokenRepository>(repository.ITokenRepositoryId)

    useEffect(() => {
        (async () => {
            const appTypeResponse = await appTypeClient.appType()
            setAppType(appTypeResponse.type)
        })()
    }, [appTypeClient])

    useEffect(() => {
        (async () => {
            if (userRoles.isCompanyAdmin || userRoles.isCompanyUser) {
                const companies = await companyClient.getCurrentCompanies();
                // should be only one company
                const companyName = companies.map(x => x.name).join(", ")
                setUserCompanyName(companyName)
            } else {
                setUserCompanyName(null)
            }
        })()
    }, [companyClient, userRoles.isCompanyAdmin, userRoles.isCompanyUser])

    const logout = useCallback(() => {
        (async () => {

            clearUser()
            setGlobalMessage(null)
            try {
                await authClient.logout()
            } finally {
                await tokenRepository.deleteAccessToken()
                await tokenRepository.deleteRefreshToken()
            }
            history.replace("/")
        })()
    }, [])

    const openMobileMenu = useCallback(() => {
        setMobileMenuOpen(true)
    }, [])

    const closeMobileMenu = useCallback(() => {
        setMobileMenuOpen(false)
    }, [])

    const menuItems = useMemo(() => {
        let menuItems: ShellMenuItem[] = []
        menuItems.push({
            id: "companies",
            text: t("Companies"),
            imagePath: "/companies.png",
            navigationPath: "/companies",
            allowed: userRoles.isAdmin
        })
        menuItems.push({
            id: "users",
            text: t("Users"),
            imagePath: "/users.png",
            navigationPath: "/users",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })
        menuItems.push({
            id: "unregistered-users",
            text: t("Contacts"),
            imagePath: "/users.png",
            navigationPath: "/unregistered-users",
            allowed: userRoles.isAdmin || userRoles.isCompanyUser
        })


        menuItems.push({
            id: "notification-templates",
            text: t("Templates"),
            imagePath: "/templates.png",
            navigationPath: "/notification-templates",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "notification-tasks",
            text: t("Notifications"),
            imagePath: "/notification.png",
            navigationPath: "/notification-tasks",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "notification-task-batches",
            text: t("BulkMessages"),
            imagePath: "/notifications.png",
            navigationPath: "/notification-task-batches",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "events",
            text: t("Events"),
            imagePath: "/calendar.png",
            navigationPath: "/events",
            allowed: userRoles.isAdmin || userRoles.isCompanyUser
        })

        menuItems.push({
            id: "event-types",
            text: t("Event Types"),
            imagePath: "/event-types.png",
            navigationPath: "/event-types",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "user-attribute-names",
            text: t("Contact attributes"),
            imagePath: "/attributes.png",
            navigationPath: "/user-attribute-names",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "analytics",
            text: t("Analytics"),
            imagePath: "/analytics.svg",
            navigationPath: "/analytics",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "user-invitations",
            text: t("Invite users"),
            imagePath: "/invitation.svg",
            navigationPath: "/user-invitations",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin
        })

        menuItems.push({
            id: "queued-jobs",
            text: t("QueuedJobs"),
            imagePath: "/queued-jobs.png",
            navigationPath: "/queued-jobs",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin || userRoles.isCompanyUser
        })

        menuItems.push({
            id: "user-consent-histories",
            text: t("UserConsentHistory"),
            imagePath: "/consent-history.png",
            navigationPath: "/user-consent-history",
            allowed: userRoles.isAdmin || userRoles.isCompanyAdmin || userRoles.isCompanyUser
        })

        menuItems.push({
            id: "client-credentials",
            text: t("ClientCredentials"),
            imagePath: "/applications.png",
            navigationPath: "/client-credentials",
            allowed: userRoles.isAdmin
        })

        menuItems.push({
            id: "account",
            text: t("Account"),
            imagePath: "/account.png",
            navigationPath: "/account",
            allowed: true
        })

        menuItems.push({
            id: "logout",
            text: t("Logout"),
            imagePath: "/logout.png",
            allowed: true,
            onClick: logout
        })

        return menuItems.filter(x => x.allowed)
    }, [logout, userRoles, t])


    const roleDescription = useMemo(() => {
        return UserHelper.getRoleDescription(user)
    }, [user])

    return {
        openMobileMenu,
        closeMobileMenu,
        mobileMenuOpen,
        menuItems,
        isTablet,
        isMobile,
        user,
        globalMessage,
        setGlobalMessage,
        roleDescription,
        appType,
        userCompanyName
    }
}