import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { IsDefined, ValidateNested } from "class-validator";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";

export class GetUserAttributeNamesRequest {

    @ContainsValidString()
    companyId: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["name", "createdDate"] })
    sortBy: SortItem[]
}


