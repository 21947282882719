import { Form, Formik } from "formik"
import React from "react"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { Button } from "../../../_common/common/button/Button"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./notification-task.scss"
import { NotificationTaskModel, useNotificationTask, useNotificationTaskForm } from "./useNotificationTask"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { dto } from "shared"
import { SmsNotificationTaskDetails } from "./sms/sms-notification-task-details"
import { EmailNotificationTaskDetails } from "./email/email-notification-task-details"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { useLocalization } from "../../../hook/use-localization"
import { MessageTypesDropdown } from "../../../_common/components/message-type-dropdown/message-type-dropdown"
import { MessageTypeDropdownField } from "../../../_common/fields/message-type-dropdown/message-type-dropdown-field"
import { TemplateMarkups } from "../../../_common/components/template-markups/template-markups"
import { NotificationTaskStatusIcon } from "../../../_common/components/notification-status/notification-task-status-icon"
import { TextArea } from "../../../_common/fields/text-area/TextArea"


export const NotificationTask: React.FC = () => {
    const { t } = useLocalization();
    const { model, onSubmit, onValidate, loading, onDeleteNotificationTask, deleteEnabled } = useNotificationTask()

    return (
        <Shell pageTitle={t("Notification")}>
            <Loading loading={loading} />
            {
                model != null && <>
                    <Formik initialValues={model} onSubmit={onSubmit}
                        validateOnBlur={true}
                        validate={onValidate}>
                        {() => (
                            <Form>
                                <FormContent />
                            </Form>
                        )}
                    </Formik>

                    {model.readonly && deleteEnabled && <DangerZone>
                        <div className="delete-title">{t("DeleteNotification")}</div>
                        <Button onClick={onDeleteNotificationTask}>{t("Delete")}</Button>
                        <div className="delete-message">{t("DeleteNotification")}</div>
                    </DangerZone>}


                </>
            }
        </Shell >
    )
}

const FormContent: React.FC = () => {
    const { t } = useLocalization()
    const { model } = useTypedFormikContext<NotificationTaskModel>()
    const { companyItems, whenToExecuteItems, taskTypes, saveEnabled } = useNotificationTaskForm()

    return (
        <div className="indent-container">
            {model.readonly && <TextInput name="notificationTaskId" disabled={true} label={t("Id")} />}
            <DropDownField name="notificationTaskType" disabled={model.readonly} label={t("Type")} items={taskTypes} />
            <CompanyDropDownField disabled={model.readonly} name="selectedCompanyId" label={t("Company")} items={companyItems} />
            <MessageTypeDropdownField disabled={model.readonly} name="messageType" />
            {model.readonly && <TextInput name="statusTranslated" translateValue={true} disabled={true} label={<div className="notification-task-status-container">{t("Status")}{model.status != null && <NotificationTaskStatusIcon status={model!.status} />}</div>} />}
            {model.readonly && <TextArea name="statusDescription" disabled={true} label={t("StatusDescription")} />}
            {!model.readonly && <DropDownField name="sendNow" disabled={model.readonly} label={t("WhenToSend")} items={whenToExecuteItems} />}
            {!model.readonly && !model.sendNow && <DatePickerField name="taskDate" disabled={model.readonly} label={t("NotificationDate")} showTimeInput />}
            {model.notificationTaskType === dto.NotificationTaskType.sendSms && <SmsNotificationTaskDetails />}
            {model.notificationTaskType === dto.NotificationTaskType.sendEmail && <EmailNotificationTaskDetails />}
            {!model.readonly &&
                <div className="action-buttons-row">
                    {saveEnabled && !model.readonly && <ButtonSubmit>{model.sendNow ? t("Send") : t("Save")}</ButtonSubmit>}
                </div>
            }
            {model.readonly &&
                <>
                    <DatePickerField name="taskDate" disabled={true} label={t("NotificationDate")} showTimeInput />
                    {model.batchId != null &&
                        <>
                            <TextInput name="batchId" disabled={true} label={t("BatchId")} />
                            <TextInput name="batchName" disabled={true} label={t("BatchName")} />
                        </>
                    }
                    {model.userId != null &&
                        <>
                            <TextInput name="userId" disabled={true} label={t("UserId")} />
                            <TextInput name="userName" disabled={true} label={t("UserName")} />
                        </>
                    }
                    {model.unregisteredUserId != null &&
                        <>
                            <TextInput name="unregisteredUserId" disabled={true} label={t("ContactId")} />
                            <TextInput name="unregisteredUserName" disabled={true} label={t("ContactName")} />
                        </>
                    }
                    {model.eventId != null &&
                        <>
                            <TextInput name="eventId" disabled={true} label={t("EventId")} />
                            <TextInput name="eventDescription" disabled={true} label={t("EventDescription")} />
                            <TextInput name="eventTypeId" disabled={true} label={t("EventTypeId")} />
                            <TextInput name="eventTypeName" disabled={true} label={t("EventTypeName")} />
                        </>
                    }
                    {(model.numberOfFailedTry ?? 0) > 0 && <TextInput name="numberOfFailedTry" disabled={true} label={t("NumberOfFailedTry")} />}
                    {model.wasCleanUp && <TextInput name="wasCleanUp" disabled={true} label={t("WasCleanup")} />}
                    <DatePickerField name="createdDate" disabled={true} label={t("CreatedDate")} />
                    {model.updatedDate != null && <DatePickerField name="updatedDate" disabled={true} label={t("UpdatedDate")} />}

                </>
            }
        </div>
    )
}
