import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { DropdownTitleSubtitle, DropdownTitleSubtitleItem } from "../../components/dropdown-title-subtitle/dropdown-title-subtitle";



interface Props {
	name: string,
	label: string,
	className?: string,
	disabled?: boolean,
	items: DropdownTitleSubtitleItem[],
}
export const DropDownTitleSubtitleField: React.FC<Props> = (props) => {
	const { name, className, disabled, items, ...dropdownProps } = props
	const [field, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null



	const onSelect = useCallback((value: string | null) => {
		setFieldValue(name, value, true)
	}, [setFieldValue, name])
	return (
		<div className="dropdown-field__container">
			<DropdownTitleSubtitle {...dropdownProps} onSelect={onSelect} items={items} selectedValue={selectedValue} disabled={disabled} />
			{meta.touched && meta.error ? (
				<div className="error-text">{meta.error}</div>
			) : null}
		</div>
	);
}