import { SortType } from "./sort-type";
import { IsNotEmpty } from "class-validator";
import { IsSortType } from "../../../../validators/is-sort-type"

export class SortItem {
    //can be also comma separated like firstname,lastname
    @IsNotEmpty()
    name: string

    @IsSortType()
    type: SortType

    constructor(options?: { name: string, type?: SortType }) {
        this.name = options?.name
        this.type = options?.type ?? "asc"
    }
}