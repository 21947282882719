import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { ICompanyClient, ICompanyClientId, IEventTypeClient, IEventTypeClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useLocalization } from "../../../hook/use-localization";
import { reactDelay } from "../../../_common/helpers/common";


// only status is allowed to be changed
interface Model {
	eventTypeId: string | null,
	companyId: string | null,
	name: string,
	description: string,
	sendNotificationsBefore: string | null,
	readonly: boolean,
	companies: dto.Company[],
	originalModel: Omit<Model, "originalModel"> | null
}

export type UserModel = Model

const timeDifferenceToString = (timeDifference: dto.BusinessConfiguration.TimeDifference[] | null | undefined): string => {
	return timeDifference?.map(item => `${item.value}-${item.unit}`).join("\n") ?? ""
}

const stringToTimeDifference = (str: string | null): { valid: boolean, errorMessage?: string, timeDifferences?: dto.BusinessConfiguration.TimeDifference[] } => {
	if (SharedDataHelper.stringIsNullTrimEmpty(str)) {
		return { timeDifferences: [], valid: true }
	}
	const array = str!.split("\n")
	const timeDifferences: dto.BusinessConfiguration.TimeDifference[] = []
	for (const part of array) {
		if (SharedDataHelper.stringNotNullTrimEmpty(part)) {
            const regex = /^(-?\d+)-(hour|minute|day)$/;
            const match = part.match(regex);
            if(!match){
                return { valid: false, errorMessage: "Must be in format value-unit" }
            }

 			 
			const val = match[1]
			const unit = match[2]
			if (!SharedDataHelper.isStringInteger(val)) {
				return { valid: false, errorMessage: "Value is not integer" }
			}

			if (!(unit === "day" || unit === "hour" || unit === "minute")) {
				return { valid: false, errorMessage: "Unit can be day, hour or minute" }
			}
			timeDifferences.push(new dto.BusinessConfiguration.TimeDifference({
				value: parseInt(val),
				unit: unit as any
			}))
		}
	}
	return { valid: true, timeDifferences }
}

const createModel = (
	eventType: dto.EventType | null,
	companies: dto.Company[],
	selectedCompanyId: string | null,
	readonly: boolean,
	originalModel?: Model | null): Model => {
	return {
		readonly,
		eventTypeId: eventType?.id ?? null,
		companyId: selectedCompanyId,
		name: eventType?.name ?? "",
		description: eventType?.description ?? "",
		sendNotificationsBefore: timeDifferenceToString(eventType?.sendNotificationsBefore),
		companies: companies,
		originalModel: originalModel ?? null
	}
}

const createUpdateEventTypeRequest = (model: Model): dto.UpdateEventTypeRequest => {
	const request = new dto.UpdateEventTypeRequest()
	request.name = model.name
	request.description = model.description
	const { valid, timeDifferences } = stringToTimeDifference(model.sendNotificationsBefore)
	if (!valid) {
		throw new Error("Should be valid at this point")
	}
	request.sendNotificationsBefore = timeDifferences
	return request
}

const createAddEventTypeRequest = (model: Model): dto.AddEventTypeRequest => {
	const request = new dto.AddEventTypeRequest()
	request.name = model.name
	request.description = model.description
	const { valid, timeDifferences } = stringToTimeDifference(model.sendNotificationsBefore)
	if (!valid) {
		throw new Error("Should be valid at this point")
	}
	request.sendNotificationsBefore = timeDifferences ?? null
	request.companyId = model.companyId!
	return request
}

export const useEventType = () => {
	//one of id or companyid is defined
	let { id } = useParams<{ id: string }>();
	const { t } = useLocalization();
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [editMode, setEditMode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const eventTypeClient = useClient<IEventTypeClient>(IEventTypeClientId)

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				let companies = await companyClient.getCurrentCompanies()
				let readonly = id != null
				let eventType: dto.EventType | null = null
				let companyId: string | null = null
				if (id != null) {
					eventType = await eventTypeClient.getEventType(id)
					companyId = eventType.companyId
					companies = companies.filter(x => x.id === companyId)
				} else {
					companyId = CompanyHelper.getDefaultCompany(companies)
				}

				const originalModel = createModel(eventType, companies, companyId, readonly)
				const model = createModel(eventType, companies, companyId, readonly, originalModel)
				setModelInitialValues(model)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, setLoading, setGlobalMessage, setModelInitialValues, eventTypeClient, companyClient])


	useEffect(() => {
		loadInitialModel()
	}, [id])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const { valid, errorMessage } = stringToTimeDifference(model.sendNotificationsBefore)
		if (!valid) {
			const errorModel: FormikErrors<Model> = {};
			(errorModel as any)["sendNotificationsBefore"] = errorMessage
			return errorModel
		}

		let validateErrors: ValidationError[] = []
		if (id != null) {
			const request = createAddEventTypeRequest(model)
			validateErrors = await validate(request);
		} else {
			const request = createAddEventTypeRequest(model)
			validateErrors = await validate(request);
		}
		const errorModel: FormikErrors<Model> = {}
		setFieldErrors<Model>(validateErrors, ["name", "description", "sendNotificationsBefore"], errorModel)
		return errorModel
	}, [id])


	const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			if (id != null) {
				const request = createUpdateEventTypeRequest(model)
				await eventTypeClient.updateEventType(id, request)
				loadInitialModel()
				reactDelay(() => setGlobalMessage({ message: t("EventType.Updated"), type: "success" }))
			} else {
				const request = createAddEventTypeRequest(model)
				const createdUser = await eventTypeClient.addEventType(request)
				history.replace("/event-types/" + createdUser.id)
				reactDelay(() => setGlobalMessage({ message: t("EventType.Added"), type: "success" }))
			}
		}, { setFieldError, setGlobalMessage, fieldNames: ["name", "description", "sendNotificationsBefore"] })
	}, [id, eventTypeClient, loadInitialModel, setGlobalMessage, history, t])

	const onDeleteEventType = useCallback(() => {
		(async () => {
			try {
				setGlobalMessage(null)
				setLoading(true)
				await eventTypeClient.deleteEventType(id)
				history.replace("/event-types")
				reactDelay(() => setGlobalMessage({ message: t("EventType.Deleted"), type: "success" }))
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, eventTypeClient, history, setGlobalMessage, t])

	const isAddingNewEventType = useMemo(() => {
		return id == null
	}, [id])




	return {
		modelInitialValues,
		onSubmit,
		onValidate,
		editMode,
		setEditMode,
		loading,
		onDeleteEventType,
		isAddingNewEventType,
	}
}

export const useEventTypeForm = () => {
	const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(model.companies)
	}, [model.companies])

	const companyDropDownEnabled = useMemo(() => {
		return !model.readonly && model.eventTypeId == null
	}, [model.readonly, model.eventTypeId])

	const editForm = useCallback(() => {
		setFieldValue("readonly", false, false)
	}, [setFieldValue])

	const cancelEdit = useCallback(() => {
		cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
	}, [setFormValues, setErrors, model])

	const saveEventTypeEnabled = useMemo(() => {
		return SharedDataHelper.stringNotNullTrimEmpty(model.companyId)
	}, [model.companyId])

	return {
		editForm,
		cancelEditForm: cancelEdit,
		saveEventTypeEnabled,
		companyItems,
		companyDropDownEnabled,
	}
}
