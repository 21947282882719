import { IsArray, IsOptional, ValidateNested } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { Type } from "class-transformer";
import { BusinessConfiguration } from "../business-configuration/business-configuration";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";

export class UpdateEventTypeRequest {

    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    name: string | undefined;

    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    description: string | undefined;

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => BusinessConfiguration.TimeDifference)
    sendNotificationsBefore: BusinessConfiguration.TimeDifference[] | null | undefined
}