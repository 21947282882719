import { Company } from "../company/company"

export class ClientCredential {
    id: string
    name: string
    clientSecret: string
    role: string
    updatedDate: Date
    createdDate: Date
    company: Company
    companyId: string
}