import { dto } from "shared"
import { NotificationSpecificFactory } from "./notification-specific-factory"

const getNotificationTaskType = (notificationTask: dto.NotificationTask): string => {
	if (notificationTask.notificationTaskType === dto.NotificationTaskType.sendSms) {
		return "SMS"
	}

	if (notificationTask.notificationTaskType === dto.NotificationTaskType.sendEmail) {
		return "Email"
	}
	return "Not supported"
}

const templateTypeToTaskType = (templateType: dto.NotificationTemplateType): dto.NotificationTaskType => {
	if (templateType === dto.NotificationTemplateType.sms) {
		return dto.NotificationTaskType.sendSms
	}
	if (templateType === dto.NotificationTemplateType.email) {
		return dto.NotificationTaskType.sendEmail
	}

	throw new Error("templateTypeToTaskType not supported type:" + templateType)
}

const taskTypeToTemplateType = (taskType: dto.NotificationTaskType): dto.NotificationTemplateType => {
	if (taskType === dto.NotificationTaskType.sendSms) {
		return dto.NotificationTemplateType.sms
	}
	if (taskType === dto.NotificationTaskType.sendEmail) {
		return dto.NotificationTemplateType.email
	}

	throw new Error("taskTypeToTemplateType not supported type:" + taskType)
}

const getUserName = (notificationTask: dto.NotificationTask) => {
	let username = `${notificationTask.user?.firstName ?? ""} ${notificationTask.user?.lastName ?? ""}`
	username += `${notificationTask.unregisteredUser?.firstName ?? ""} ${notificationTask.unregisteredUser?.lastName ?? ""}`
	return username.trim()
}

const getAddress = (notificationTask: dto.NotificationTask) => {
	const specific = NotificationSpecificFactory.createTaskSpecific(notificationTask.notificationTaskType)
	return specific.getAddress(notificationTask)
}

const getStatusDescription = (task: dto.NotificationTask) => {
	switch (task.status) {
		case "pending":
			return "Pending";
		case "completed":
			return "Completed";
		case "error":
			return "Error";
		case "validationFailed":
			return "Validation failed";
		case "apiGatewayAccepted":
			return "Provider accepted"
		case "apiGatewaySent":
			return "Provider sent to user"
		case "apiGatewayUnknown":
			return "Provider unknown status"
		case "apiGatewayFailed":
			return "Provider error"
	}
}

const notificationTaskTypes = [
	{ text: "Sms", value: dto.NotificationTaskType.sendSms },
	{ text: "Email", value: dto.NotificationTaskType.sendEmail }
]

const notificationTemplateTypes = [
	{ text: "Sms", value: dto.NotificationTemplateType.sms },
	{ text: "Email", value: dto.NotificationTemplateType.email }
]

const defaultNotificationTaskType = dto.NotificationTaskType.sendSms
const defaultNotificationTemplateType = taskTypeToTemplateType(defaultNotificationTaskType)



export const notification = {
	getNotificationTaskType,
	getUserName,
	getAddress,
	getStatusDescription,
	templateTypeToTaskType,
	taskTypeToTemplateType,
	notificationTaskTypes,
	defaultNotificationTaskType,
	defaultNotificationTemplateType,
	notificationTemplateTypes,
}
