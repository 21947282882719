import React from "react"
import "./pagination.scss"


export interface PaginationProps {
	pageIndex: number,
	totalNumberOfPages: number
	onPageRequest: (pageIndex: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ pageIndex, totalNumberOfPages, onPageRequest }) => {
	return (
		<>
			<ul className="pagination pagination__container">
				<li className="page-item"><button disabled={pageIndex === 0} className="page-link" onClick={() => { onPageRequest(0) }}>{"<<"}</button></li>
				<li className="page-item"><button disabled={pageIndex === 0} className="page-link" onClick={() => { onPageRequest(pageIndex - 1) }}>{"<"}</button></li>
				<li className="page-item page-item-current-page">{pageIndex + 1}</li>
				<li className="page-item"><button className="page-link" disabled={(pageIndex + 1) >= totalNumberOfPages} onClick={() => { onPageRequest(pageIndex + 1) }}>{">"}</button></li>
				<li className="page-item"><button className="page-link" disabled={(pageIndex + 1) >= totalNumberOfPages} onClick={() => { onPageRequest(totalNumberOfPages - 1) }}>{">>"}</button></li>
			</ul>
		</>

	)
}