import { dto } from "shared";
import { ClientValidationErrorItem } from "./client-validation-error-item";


export enum ClientErrorStatusCode {
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    internalServerError = 500,
    unknown = -1
}

export class ClientError extends Error {
    readonly statusCode: ClientErrorStatusCode
    validationDetails: ClientValidationErrorItem[] = []
    dataKey?: string;
    private readonly __clientErrorIdentifier = "__clientErrorIdentifier";
    //not for use, just to have info when http status code is not mapped
    ____httpStatusCode: number;


    constructor(options: { statusCode: ClientErrorStatusCode, message?: string }) {
        super()
        this.statusCode = options.statusCode
        this.message = options.message
    }

    // Left to avoid build issues
    // remove this method later
    static createGeneralError(message: string): ClientError {
        return null;
    }

    static createFromErrorResponse(statusCode: number, errorResponse?: dto.ErrorResponse): ClientError {
        const clientStatusCode = ClientError.mapToClientErrorStatusCode(statusCode)
        let clientError = new ClientError({
            statusCode: clientStatusCode,
            message: errorResponse?.serverError == true ? errorResponse?.message : null
        });
        clientError.____httpStatusCode = statusCode
        clientError.dataKey = errorResponse?.dataKey;
        if (clientStatusCode == ClientErrorStatusCode.badRequest &&
            errorResponse?.type == dto.ErrorResponse.VALIDATION) {
            for (var valError of errorResponse?.validationErrors ?? []) {
                const clientErrorItems = ClientValidationErrorItem.createFromValidationError(valError);
                for (const clientErrorItem of clientErrorItems) {
                    clientError.validationDetails.push(clientErrorItem);
                }
            }
            clientError.validationDetails = ClientValidationErrorItem.sortClientErrorItem(clientError.validationDetails);
        }

        return clientError;
    }

    //fix for typescript 'error instanceof ClientError' that is always false
    static isClientError(error: any): boolean {
        if (error.__clientErrorIdentifier) {
            return true;
        }
        return false;
    }

    private static mapToClientErrorStatusCode(statusCode: number): ClientErrorStatusCode {
        if (statusCode == 400) {
            return ClientErrorStatusCode.badRequest
        }

        if (statusCode == 401) {
            return ClientErrorStatusCode.unauthorized
        }

        if (statusCode == 403) {
            return ClientErrorStatusCode.forbidden
        }

        if (statusCode == 404) {
            return ClientErrorStatusCode.notFound
        }

        if (statusCode == 500) {
            return ClientErrorStatusCode.internalServerError
        }

        return ClientErrorStatusCode.unknown
    }
}




