import { IsEmail, IsOptional, IsString, ValidateIf } from "class-validator";
import { BaseMessageSettingsConfiguration } from "../base-message-settings-configuration";

export class EmailSettingsConfiguration extends BaseMessageSettingsConfiguration {

    @IsOptional()
    @ValidateIf(o => o.promoFromEmail !== '')
    @IsEmail()
    promoFromEmail: string

    @IsOptional()
    @IsString()
    promoFromName: string

    @IsOptional()
    @ValidateIf(o => o.notificationFromEmail !== '')
    @IsEmail()
    notificationFromEmail: string

    @IsOptional()
    @IsString()
    notificationFromName: string


    constructor(options: {
        notificationEnabled: boolean,
        notificationFromEmail: string
        notificationFromName: string,
        notificationApiKey: string,
        notificationApiSecret: string,
        promoEnabled: boolean,
        promoFromEmail: string
        promoFromName: string,
        promoApiKey: string,
        promoApiSecret: string,
    }) {
        super({
            notificationEnabled: options?.notificationEnabled,
            notificationApiKey: options?.notificationApiKey,
            notificationApiSecret: options?.notificationApiSecret,
            promoEnabled: options?.promoEnabled,
            promoApiKey: options?.promoApiKey,
            promoApiSecret: options?.promoApiSecret
        });
        if (options == null) {
            return
        }
        this.promoFromEmail = options.promoFromEmail
        this.promoFromName = options.promoFromName
        this.notificationFromEmail = options.notificationFromEmail
        this.notificationFromName = options.notificationFromName
    }
}
