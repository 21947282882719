import { Company } from "../company/company";
import { BusinessConfiguration } from "./business-configuration";

export class BusinessCompanyConfiguration {

    company: Company;

    configuration: BusinessConfiguration.Configuration

    constructor(options: { company: Company, configuration: BusinessConfiguration.Configuration }) {
        this.company = options.company
        this.configuration = options.configuration
    }
}



