import React, { useRef } from "react"
import { dto } from "shared"
import { useUserRole } from "../../../hook/useUserRole"
import { Button } from "../../../_common/common/button/Button"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import Shell from "../../../_common/layout/shell/shell"
import "./user-roles.scss"
import { useUserRoles } from "./useUserRoles"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const UserRoles: React.FC = () => {
	const { t } = useLocalization();
	const { userRoles, companyItems, selectedCompanyId, onCompanySelected, loading, isAllowedCompanyAdminToAdd, addCompanyAdminRole, deleteUserRole, userNameSuffix } = useUserRoles()
	const { descriptionFromRoleName } = useUserRole()
	const confirmationAddAsCompanyAdminRef = useRef<ConfirmationHandle>(null);
	const confirmationDeleteRef = useRef<ConfirmationHandle>(null);


	const listItems = (userRoles ?? []).map((userRole) =>
		<div key={userRole.id} className="user-role__item data-row-item">
			<div className="user-role__item-name">{t(descriptionFromRoleName(userRole.roleName))}</div>
			<Button className="btn-sm margin-left-auto" onClick={() => {
				confirmationDeleteRef.current?.show(userRole.id)
			}
			}>{t("Delete")}</Button>
		</div>
	);
	return (
		<Shell pageTitle={`${t('CompanyUserRoles')} ${userNameSuffix}`}>
			<Loading loading={loading} />
			<div className="indent-container user-roles-header-filter">
				<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
			</div>
			<div className="list-content">
				{listItems}
				{(userRoles != null && userRoles.length === 0) && <NoEntities message="There are no company roles for user. You can invite user to become company user." className="indent-container" />}
			</div>
			<div className="user-roles__add-missing-container">
				{isAllowedCompanyAdminToAdd() && <Button onClick={() => confirmationAddAsCompanyAdminRef.current?.show()}>{t('AddCompanyAdminRole')}</Button>}
				<Confirmation level="require-number-confirmation" body={t("Confirmation.Save")} ref={confirmationAddAsCompanyAdminRef} onOkCallback={() => addCompanyAdminRole()} />

			</div>
			<Confirmation level="require-number-confirmation" body={t("Confirmation.Delete")} ref={confirmationDeleteRef} onOkCallback={(ctx) => deleteUserRole(ctx!)} />
		</Shell >
	)
}