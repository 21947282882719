import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { IUserActionsClient, IUserActionsClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { ConsentItem } from "../../../_common/model/consent-item";



// only status is allowed to be changed
interface Model {
	userId: string | null,
	companyName: string,
	firstName: string,
	lastName: string,
	email: string,
	mobilePhoneNumber: string,
	dateOfBirth: Date | null,
	gender: dto.UserGender | null,
	availableConsentsToChange: ConsentItem[],
	finishedUpdate: boolean
}

export type UserModel = Model

const createModel = (response: dto.UserActionGetUnregisteredUser): Model => {
	const user = response.user
	const consentItems = response.availableConsentsToChange.map(x => ({ enabled: false, consent: x }));
	return {
		userId: user!.id,
		companyName: response.companyName,
		availableConsentsToChange: consentItems,
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		mobilePhoneNumber: SharedDataHelper.stringNotNullEmpty(user.mobilePhoneNumber) ?
			SharedDataHelper.formatMobilePhoneNumber(user!.mobilePhoneNumber) : "",
		gender: user.gender,
		dateOfBirth: user.dateOfBirth,
		finishedUpdate: false
	}
}

const createUpdateUserRequest = (model: Model): dto.UserActionUpdateUnregisteredUserRequest => {
	const allEnabledConsentItems = model.availableConsentsToChange;
	const requestConsentItems: dto.ConsentItem[] = allEnabledConsentItems.map(x => ({ enabled: x.enabled, id: x.consent.id }))
	const request = new dto.UserActionUpdateUnregisteredUserRequest()
	request.firstName = model.firstName
	request.lastName = model.lastName
	request.gender = model.gender ?? undefined
	request.dateOfBirth = model.dateOfBirth ?? undefined
	request.consentItems = requestConsentItems
	request.timezone = SharedDataHelper.getTimeZone()
	return request
}

export const useUnregisteredUserCreation = () => {
    debugger
	let { actionid: actionId, unregistereduserid: unregisteredUserId } = useParams<{ actionid: string, unregistereduserid: string }>();
	const { t } = useLocalization()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const [errorOccurred, setErrorOccurred] = useState(false)
	const [finishedUpdate, setFinishedUpdate] = useState(false)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const userActionsClient = useClient<IUserActionsClient>(IUserActionsClientId)

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				const userResponse = await userActionsClient.getUnregisterUserDetails(actionId, unregisteredUserId)
				const model = createModel(userResponse)
				setModelInitialValues(model)
			} catch (err) {
				setErrorOccurred(true)
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [actionId, unregisteredUserId, setLoading, setGlobalMessage, setModelInitialValues, userActionsClient])


	useEffect(() => {
		loadInitialModel()
	}, [actionId, unregisteredUserId])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		let validateErrors: ValidationError[] = []
		const request = createUpdateUserRequest(model)
		validateErrors = await validate(request);
		const errorModel: FormikErrors<Model> = {}
		setFieldErrors<Model>(validateErrors, ["firstName", "lastName", "dateOfBirth", "gender"], errorModel)
		return errorModel
	}, [])


	const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			const data = createUpdateUserRequest(model)
			await userActionsClient.updateUnregisterUserDetails(actionId, unregisteredUserId, data);
			setFinishedUpdate(true)
			setGlobalMessage({ message: "", type: "success" })
		}, { setFieldError, setGlobalMessage, fieldNames: ["firstName", "lastName", "email", "mobilePhoneNumber", "dateOfBirth", "gender"] })
	}, [actionId, unregisteredUserId, userActionsClient, setGlobalMessage])

	const pageTitle = useMemo(() => {
		if (loading) {
			return t("Loading");
		}
		if (errorOccurred) {
			return t("InvalidData");
		}
		return finishedUpdate ? t("ThankYou") : t("UpdateYourDetailsAndConsents")
	}, [t, finishedUpdate, errorOccurred, loading])

	return {
		pageTitle,
		modelInitialValues,
		finishedUpdate,
		onSubmit,
		onValidate,
		loading,
	}
}

export const useUnregisteredUserCreationForm = () => {
	const { t } = useLocalization();
	const { model } = useTypedFormikContext<UserModel>()


	const genders = useMemo(() => {
		return [
			{ text: t("Male"), value: dto.UserGender.male },
			{ text: t("Female"), value: dto.UserGender.female }]
	}, [t])

	const saveEnabled = model.availableConsentsToChange.length > 0 &&
		model.availableConsentsToChange.find(x => !x.enabled) == null

	return {
		genders,
		saveEnabled
	}
}
