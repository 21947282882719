import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import srTranslation from './sr/translation.json';

const resources = {
  en: { translation: enTranslation },
  sr: { translation: srTranslation },
};

const getLanguageCookie = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith("Language-DS"))
    ?.split('=')[1];
  return cookieValue || null;
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguageCookie() ?? "sr",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;