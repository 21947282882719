import { IsEmail, IsOptional } from "class-validator";
import { AddNotificationTaskDetails } from "../add-notification-task-details";
import { ContainsValidString } from "../../../../../validators/contains-valid-string"
import { ContainsEmailUnsubscribeMarkup } from "../../../../../validators/contains-email-unsubscribe-markup";


export class EmailAddNotificationTaskDetails extends AddNotificationTaskDetails {
    @IsOptional()
    @IsEmail()
    email: string | null


    @ContainsValidString()
    body: string

    @ContainsValidString()
    subject: string

    __type: "EmailAddNotificationTaskDetails"


    constructor(options: { email: string | null, subject: string, body: string }) {
        super();
        if (options == null) {
            return
        }
        this.email = options.email
        this.body = options.body
        this.subject = options.subject
    }
}