import { dto } from "shared";
import React from "react";
import { useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { UserAttributeValue } from "../../components/user-attribute-value/user-attribute-value";



interface Props {
	name: string,
	label: string,
	disabled?: boolean,
	onChange?: (name: string, value: string | null) => void,
	userAttributeName: dto.UserAttributeName
}
export const UserAttributeValueField: React.FC<Props> = (props) => {
	const { name, disabled, label, onChange, userAttributeName } = props
	const [, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null

	const onValueChange = useCallback((value: string | null) => {
		setFieldValue(name, value, true)
		if (onChange != null) {
			onChange(name, value)
		}
	}, [setFieldValue, name, onChange])
	return (
		<div className="dropdown-field__container">
			<UserAttributeValue label={label} userAttributeName={userAttributeName} value={selectedValue} onChange={onValueChange} disabled={disabled} />
			{meta.touched && meta.error ? (
				<div className="error-text">{meta.error}</div>
			) : null}
		</div>
	);
}