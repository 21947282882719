import { IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";
import { IsNotSharedUserAttribute, IsNotSharedUserAttributeConstraint } from "../../../validators/is-not-shared-user-attribute";


export class UpdateUserAttributeRequest {
    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(200)
    @IsNotSharedUserAttribute()
    name?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(1)
    @MaxLength(1000)
    value?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsString()
    @MaxLength(100000)
    description?: string | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString()
    externalId?: string | null

}