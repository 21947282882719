import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./user.scss"
import { UserModel, useUser, useUserForm } from "./useUser"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const User: React.FC = () => {
	const { t } = useLocalization()
	const { modelInitialValues, onSubmit, onValidate, loading, onDeleteUser, enabledDeleteUser, onEditUserRoles, hasCompanyRole } = useUser()
	const confirmationDeleteRef = useRef<ConfirmationHandle>(null);

	return (
		<Shell pageTitle={t("User")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>
				{hasCompanyRole && <div className="alert zone-container  alert-secondary indent-container" role="alert">
					<div className="zone-title">{t("CompanyRoles")}</div>
					<Button onClick={onEditUserRoles}>{t("Edit")}</Button>
					<div className="zone-message">{t("EditUserRolesDesc")} </div>
				</div>}
				{enabledDeleteUser && <DangerZone>
					<div className="delete-title">{t("DeleteUser")} </div>
					<Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
					<div className="delete-message">{t("DeleteUserDesc")} </div>
					<Confirmation level="require-number-confirmation" body={t("Confirmation.DeleteUser")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteUser()} />
				</DangerZone>}
			</>
			}
		</Shell>
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization()
	const { model } = useTypedFormikContext<UserModel>()
	const { editForm, cancelEditForm, statuses, genders } = useUserForm()
	return (
		<div className="indent-container">
			<TextInput name="firstName" disabled={true} label={t("FirstName")} />
			<TextInput name="lastName" disabled={true} label={t("LastName")} />
			<TextInput name="email" disabled={true} label={t("Email")} />
			<TextInput name="mobilePhoneNumber" disabled={true} label={t("MobilePhone")} />
			<DatePickerField name="dateOfBirth" disabled={true} label={t("DateOfBirth")} />
			<DropDownField name="gender" disabled={true} label="Gender" items={genders} />
			<DropDownField name="status" disabled={model.readonly} label={t("Status")} items={statuses} />
			<TextInput name="roleDescription" disabled={true} label={t("CompanyRole")} />
			<TextInput name="companyName" disabled={true} label={t("Company")} />
			<div className="action-buttons-row">
				{model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
				{!model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
				{!model.readonly && <ButtonSubmit>{t("Save")}</ButtonSubmit>}
			</div>
		</div>)
}
