import { useField, useFormikContext } from "formik";
import React from "react";
import { useCallback } from "react";
import { MessageTypesDropdown } from "../../components/message-type-dropdown/message-type-dropdown";


interface Props {
    name: string,
    disabled?: boolean,
    onChange?: (name: string, value: string | null) => void,
    className?: string
}
export const MessageTypeDropdownField: React.FC<Props> = (props) => {
    const { name, disabled, onChange, className } = props
    const [, meta] = useField(name)
    const { values, setFieldValue } = useFormikContext()
    const selectedValue = values != null ? (values as any)[name] : null

    const onSelect = useCallback((userAttributeName: string | null) => {
        setFieldValue(name, userAttributeName, true)
        if (onChange != null) {
            onChange(name, userAttributeName)
        }
    }, [setFieldValue, name, onChange])
    return (
        <div className={"dropdown-field__container " + className ?? ""}>
            <MessageTypesDropdown selectedMessageType={selectedValue} onSelect={onSelect} disabled={disabled} />
            {meta.touched && meta.error ? (
                <div className="error-text">{meta.error}</div>
            ) : null}
        </div>
    );
}