import React, { CSSProperties } from "react";
import { Form, FormControlProps } from 'react-bootstrap';
import useDebouncedCallback from "../../hook/use-debounced-callback";

type DebouncedCallbackTextFieldProps = FormControlProps & {
    placeholder?: string,
    style?: CSSProperties,
    debouncedCallback: (text: string | null) => void
}
const DebouncedCallbackTextField: React.FC<DebouncedCallbackTextFieldProps> = (props) => {
    const useDebouncedCallback_ = () => useDebouncedCallback((text: string | null) => props.debouncedCallback(text))
    const { inputText, setInputText } = useDebouncedCallback_();

    return (
        <Form.Control type={"text"} placeholder={props.placeholder} style={props.style}
            value={inputText}
            onChange={e => setInputText(e.target.value)} />
    );
}

export default DebouncedCallbackTextField
