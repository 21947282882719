import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { WSListener } from "shared-client"
import { SharedDataHelper, dto, ws as wsDto } from "shared"
import { ICompanyClient, ICompanyClientId, INotificationTaskBatchClient, INotificationTaskBatchClientId, INotificationTaskClient, INotificationTaskClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { notification } from "../../../../common/notification/notification"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { DropdownItem } from "../../../_common/common/dropdown/DropDown"
import { useWebSocketContext } from "../../../hook/use-websocket"
import { wsClient } from "../../../context/websocket/web-socket-provider"
import { CompanyHelper } from "../../../_common/helpers/company-helper"
import { useAppContext } from "../../../hook/use-app-context"

const pageSize = 20

export const useNotificationTasks = () => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const { setGlobalMessage } = useGlobalMessageContext()
	const { isConnected } = useWebSocketContext()
	const { userRoles } = useAppContext()
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
	const notificationTaskClient = useClient<INotificationTaskClient>(INotificationTaskClientId)
	const notificationTaskBatchClient = useClient<INotificationTaskBatchClient>(INotificationTaskBatchClientId)
	const [notificationTasks, setNotificationTasks] = useState<dto.NotificationTask[]>([])
	const [notificationTaskBatches, setNotificationTaskBatches] = useState<dto.NotificationTaskBatch[]>([])
	const { pageIndex, setPageIndex, pagination, setTotalNumberOfPages } = usePagination()
	const [selectedNotificationTaskBatchId, setSelectedNotificationTaskBatchId] = useState<string | null>(null)

	useEffect(() => {
		const listenerId = "notification-tasks-listener"
		if (isConnected ?? false) {
			const wsListener: WSListener = {
				id: listenerId,
				type: "notification-status-update",
				callback: (webSocketResponse: wsDto.WebSocketResponse) => {
					const taskUpdatedResponse = webSocketResponse as wsDto.UpdatedNotificationTaskWebSocketResponse
					const resultArray: dto.NotificationTask[] = []
					let hasChanges = false
					for (const notificationTask of notificationTasks) {
						const updatedTask = taskUpdatedResponse.tasks.find(x => x.id === notificationTask.id)
						if (updatedTask != null) {
							const newNotificationTask = { ...notificationTask }
							newNotificationTask.status = updatedTask.status
							newNotificationTask.statusDescription = updatedTask.statusDescription
							resultArray.push(newNotificationTask)
							hasChanges = true
						} else {
							resultArray.push(notificationTask)
						}
					}
					if (hasChanges) {
						setNotificationTasks(resultArray)
					}
				}
			}

			wsClient.addListener(wsListener)
		}
		return () => {
			wsClient.removeListener(listenerId)
		}

	}, [notificationTasks, isConnected])

	const onNotificationTaskSelected = useCallback((notificationTaskId: string) => {
		history.push("/notification-tasks/" + notificationTaskId)
	}, [history])




	useEffect(() => {
		(async () => {
			try {
				if (selectedCompanyId == null) {
					setNotificationTasks([])
					return
				}
				setLoading(true)
				setNotificationTasks([])
				const request = new dto.GetNotificationTasksRequest()
				request.companyId = selectedCompanyId
				request.paging = new dto.PagingRequest({ pageIndex, pageSize })
				request.sortBy = []
				request.batchId = selectedNotificationTaskBatchId ?? undefined
				request.sortBy.push(new dto.SortItem({ name: "taskDate", type: "desc" }))
				const pagedResponse = (await notificationTaskClient.getNotificationTasks(request))
				setNotificationTasks(pagedResponse.entities)
				setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [pageIndex, selectedCompanyId, selectedNotificationTaskBatchId])

	useEffect(() => {
		setPageIndex(0)
		setNotificationTaskBatches([])
		setSelectedNotificationTaskBatchId(null)
		if (selectedCompanyId != null) {
			(async () => {
				const request = new dto.GetNotificationTaskBachInfosRequest()
				request.companyId = selectedCompanyId
				request.paging = dto.PagingRequest.createMaxPaging()
				request.sortBy = []
				request.returnStatistics = false
				request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
				const pagedResponse = await notificationTaskBatchClient.getNotificationTaskBatchInfos(request);
				setNotificationTaskBatches(pagedResponse.entities.map(x => x.batch))
			})();
		}
	}, [selectedCompanyId])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)
				const defaultCompanyId = CompanyHelper.getDefaultCompany(companies)
				setSelectedCompanyId(defaultCompanyId)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies)
	}, [companies])

	const onAddNotificationTasks = useCallback(() => {
		history.push("/notification-tasks/add/notification-task/")
	}, [history])

	const addNewNotificationTaskEnabled = useMemo(() => {
		return companies.length > 0
	}, [companies])



	const batchItems = useMemo((): DropdownItem[] => {
		let array: DropdownItem[] = [{ text: "-", value: "" }]
		array = array.concat(notificationTaskBatches.map(x => { return { text: x.name, value: x.id } }))
		return array
	}, [notificationTaskBatches])

	const getBatchName = useCallback((batchId: string): string | null => {
		if (!SharedDataHelper.stringNotNullTrimEmpty(batchId)) {
			return ""
		}

		return notificationTaskBatches.find(x => x.id === batchId)?.name ?? null
	}, [notificationTaskBatches])

	const onBatchSelected = useCallback((batchId: string | null) => {
		setPageIndex(0)
		setSelectedNotificationTaskBatchId(batchId)
	}, [setPageIndex])

	return {
		notificationTasks,
		batchItems,
		getNotificationTaskType: notification.getNotificationTaskType,
		getUserName: notification.getUserName,
		getNotificationTaskAddress: notification.getAddress,
		onNotificationTaskSelected,
		loading,
		companyItems,
		selectedCompanyId,
		selectedBatchId: selectedNotificationTaskBatchId ?? "",
		onCompanySelected: setSelectedCompanyId,
		onBatchSelected: onBatchSelected,
		pagination,
		onAddNotificationTasks,
		addNewNotificationTaskEnabled,
		addNewNotificationTaskVisible: userRoles.isAdmin,
		getBatchName
	}
}