import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { SharedDataHelper, dto } from "shared";
import { Config, IConsentClient, IConsentClientId, IUserRegistrationClient, IUserRegistrationClientId } from "shared-client"
import { validate } from "class-validator";
import { setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { FormikErrors, FormikHelpers } from "formik";
import useClient from "../../../hook/useClient";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";

const PathBasic = "registration"
const PathInitial = `/${PathBasic}/initial`;
const PathVerifyEmail = `/${PathBasic}/challenge-email-token`;
const PathVerifyMobilePhone = `/${PathBasic}/challenge-sms-token`;
const PathCompleted = `/${PathBasic}/completed`;


interface Model {
    email: string,
    mobilePhoneNumber: string,
    firstName: string,
    lastName: string
    dateOfBirth: Date | null,
    gender: dto.UserGender | null
    password: string,
    confirmPassword: string,
    showPassword: boolean,
    smsToken: string,
    emailToken: string,
    sendSmsPromoMessages: boolean,
    sendEmailPromoMessages: boolean,
    giveDataProcessing: boolean,
    smsConsent: dto.Consent,
    emailConsent: dto.Consent,
    dataProcessingConsent: dto.Consent,

}

export type UserRegistrationModel = Model

const _createRegisterRequest = (model: Model) => {
    const dtoRequest = new dto.UserRegistrationRegisterRequest()
    dtoRequest.clientId = Config.OAuthClientId
    dtoRequest.email = model.email
    dtoRequest.mobilePhoneNumber = model.mobilePhoneNumber
    dtoRequest.firstName = model.firstName
    dtoRequest.lastName = model.lastName
    dtoRequest.dateOfBirth = model.dateOfBirth as any
    dtoRequest.gender = model.gender as any
    dtoRequest.password = model.password
    dtoRequest.confirmPassword = model.confirmPassword
    dtoRequest.timezone = SharedDataHelper.getTimeZone()

    const consentIds: string[] = []

    if (model.giveDataProcessing) {
        consentIds.push(model.dataProcessingConsent!.id)
    }

    if (model.sendSmsPromoMessages) {
        consentIds.push(model.smsConsent!.id)
    }

    if (model.sendEmailPromoMessages) {
        consentIds.push(model.emailConsent!.id)
    }


    dtoRequest.consentIds = consentIds
    return dtoRequest
}

const _createVerifyEmailRequest = (model: Model) => {
    const dtoRequest = new dto.UserRegistrationVerifyEmailRequest()
    dtoRequest.clientId = Config.OAuthClientId
    dtoRequest.email = model.email
    dtoRequest.emailToken = model.emailToken
    return dtoRequest
}

export const useUserRegistration = () => {
    const history = useHistory();
    const { pathname } = useLocation()
    const { setGlobalMessage } = useGlobalMessageContext()
    const client = useClient<IUserRegistrationClient>(IUserRegistrationClientId)
    const consentClient = useClient<IConsentClient>(IConsentClientId);
    const [consents, setConsents] = useState<dto.Consent[]>([])

    useEffect(() => {
        if (pathname !== PathInitial) {
            history.replace(PathInitial)
            return
        }
        (async () => {
            const consents = await consentClient.getConsents({
                sendSmsPromo: true,
                sendEmailPromo: true,
                dataProcessing: true
            });
            setConsents(consents)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initialValues: Model = useMemo(() => {
        const smsConsent = consents.find(x => x.name === dto.ConsentName.sendSmsPromoMessages)!;
        const emailConsent = consents.find(x => x.name === dto.ConsentName.sendEmailPromoMessages)!;
        const dataProcessingConsent = consents.find(x => x.name === dto.ConsentName.dataProcessingConsent)!;
        return {
            email: "",
            mobilePhoneNumber: "",
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            gender: null,
            password: "",
            confirmPassword: "",
            showPassword: false,
            smsToken: "",
            emailToken: "",
            sentEmailMessageInfo: "",
            sentSmsTokenMessageInfo: "",
            sendEmailPromoMessages: false,
            sendSmsPromoMessages: false,
            giveDataProcessing: false,
            smsConsent,
            emailConsent,
            dataProcessingConsent,
        }
    }, [consents]);

    const createRegisterRequest = useCallback(_createRegisterRequest, [])

    const createVerifyEmailRequest = useCallback(_createVerifyEmailRequest, [])

    const createRegisterCompleteRequest = useCallback((model: Model) => {
        const dtoRequest = new dto.UserRegistrationCompleteRequest()
        dtoRequest.clientId = Config.OAuthClientId
        dtoRequest.email = model.email
        dtoRequest.smsToken = model.smsToken
        dtoRequest.emailToken = model.emailToken
        dtoRequest.mobilePhoneNumber = model.mobilePhoneNumber
        return dtoRequest
    }, [])


    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const errorModel: FormikErrors<Model> = {}
        switch (pathname.toLowerCase()) {
            case PathInitial:
                const registerRequest = createRegisterRequest(model)
                let validateErrorsInitial = await validate(registerRequest);
                setFieldErrors<Model>(validateErrorsInitial, ["email", "mobilePhoneNumber", "firstName", "lastName", "password", "confirmPassword", "dateOfBirth", "gender"], errorModel)
                break;
            case PathVerifyEmail:
                const verifyEmailRequest = createVerifyEmailRequest(model)
                const verifyEmailValidation = await validate(verifyEmailRequest);
                setFieldErrors<Model>(verifyEmailValidation, ["emailToken"], errorModel);
                break;
            case PathVerifyMobilePhone:
                const tokenRequest = createRegisterCompleteRequest(model)
                let validateErrorsToken = await validate(tokenRequest);
                setFieldErrors<Model>(validateErrorsToken, ["smsToken", "emailToken"], errorModel)
                break
        }
        return errorModel
    }, [createRegisterRequest, createRegisterCompleteRequest, pathname, createVerifyEmailRequest])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        const _pathname = pathname.toLowerCase()
        if (_pathname === PathInitial) {
            await submitWrapper<Model>(async () => {
                const registerRequest = createRegisterRequest(model)
                const response = await client.register(registerRequest)
                const success = response?.success ?? false
                if (success) {
                    setFieldValue("smsToken", "", false)
                    setFieldValue("emailToken", "", false)
                    history.push(PathVerifyEmail)
                } else {
                    setGlobalMessage({ type: "error", message: response?.message ?? "Error register." })
                }
            }, { setFieldError, setGlobalMessage, fieldNames: ["email", "password"] })
        }

        if (pathname === PathVerifyEmail) {
            await submitWrapper<Model>(async () => {
                const verifyEmailRequest = createVerifyEmailRequest(model)
                const response = await client.verifyEmail(verifyEmailRequest)
                const success = response?.success ?? false
                if (success) {
                    history.push(PathVerifyMobilePhone)
                } else {
                    setGlobalMessage({ type: "error", message: response?.message ?? "Error verify email." })
                }
            }, { setFieldError, setGlobalMessage, fieldNames: ["emailToken"] })
        }

        if (pathname === PathVerifyMobilePhone) {
            await submitWrapper<Model>(async () => {
                const registerCompleteRequest = createRegisterCompleteRequest(model)
                const response = await client.complete(registerCompleteRequest)
                const success = response?.success ?? false
                if (success) {
                    history.push(PathCompleted)
                } else {
                    setGlobalMessage({ type: "error", message: response?.message ?? "Error complete registration." })
                }
            }, { setFieldError, setGlobalMessage, fieldNames: ["smsToken"] })
        }
    }, [createRegisterRequest, createRegisterCompleteRequest, client, setGlobalMessage, history, pathname, createVerifyEmailRequest])


    return {
        paths: {
            PathInitial,
            PathVerifyEmail,
            PathVerifyMobilePhone,
            PathCompleted
        },
        initialValues,
        onSubmit,
        validate: onValidate,
    }
}

export const useUserRegistrationInitial = () => {
    const { t } = useLocalization();
    const { setFieldValue, values: model } = useTypedFormikContext<Model>()

    const toggleShowPassword = useCallback(() => {
        setFieldValue("showPassword", !model.showPassword)
    }, [setFieldValue, model.showPassword])

    const genders = useMemo(() => {
        return [{ text: t("Male"), value: dto.UserGender.male },
        { text: t("Female"), value: dto.UserGender.female }]
    }, [t])

    return {
        toggleShowPassword,
        genders
    }
}

export const useUserVerifyEmail = () => {
    const { t } = useLocalization();
    const { setGlobalMessage } = useGlobalMessageContext()
    const client = useClient<IUserRegistrationClient>(IUserRegistrationClientId)
    const { values: model } = useTypedFormikContext<Model>()

    const resendToken = useCallback(async () => {
        const registerRequest = _createRegisterRequest(model)
        let statusResponse = await client.register(registerRequest)
        let success = statusResponse?.success ?? false
        if (success) {
            setGlobalMessage({ type: "success", message: t("TokenResentToEmail") })
        } else {
            setGlobalMessage({ type: "error", message: statusResponse?.message ?? "Resent general error" })
        }
    }, [setGlobalMessage, model, client, t])

    return {
        resendToken
    }
}

export const useUserVerifyMobilePhone = () => {
    const { t } = useLocalization();
    const { setGlobalMessage } = useGlobalMessageContext()
    const client = useClient<IUserRegistrationClient>(IUserRegistrationClientId)
    const { values: model } = useTypedFormikContext<Model>()

    const resendToken = useCallback(async () => {
        const registerRequest = _createVerifyEmailRequest(model)
        let statusResponse = await client.verifyEmail(registerRequest)
        let success = statusResponse?.success ?? false
        if (success) {
            setGlobalMessage({ type: "success", message: t("TokenResentToMobilePhone") })
        } else {
            setGlobalMessage({ type: "error", message: statusResponse?.message ?? "Resent general error" })
        }
    }, [setGlobalMessage, model, client, t])

    return {
        resendToken
    }
}

export const useRegistrationCompleted = () => {
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()

    const onCloseRequested = useCallback(() => {
        setGlobalMessage(null)
        history.replace("/signin")
    }, [history, setGlobalMessage])


    return {
        onCloseRequested
    }
}





