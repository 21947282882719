import { RoleId } from "./role";
import { IsEnum } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";

export class AddUserRoleRequest {

    @ContainsValidString()
    companyId: string

    @ContainsValidString()
    userId: string

    @IsEnum(RoleId)
    roleId: number;
}