import { SortItem } from "../../common/query";
import { PagingRequest } from "../../common/paging";
import { IsDefined, ValidateNested } from "class-validator";
import { AreValidSortItems } from "../../../../validators/are-valid-sort-items";
import { ContainsValidString } from "../../../../validators/contains-valid-string";

export class GetUserInvitationsRequest {

    @ContainsValidString()
    companyId: string

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]
}