import { IsArray, IsDefined, IsEnum, IsInt, Min, ValidateNested } from "class-validator"
import { Type } from 'class-transformer';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { NotificationTaskType } from "../notification";
import { IsPositiveIntOrZero } from "../../../validators/is-positive-integer-or-zero";
import { EmailSettingsConfiguration } from "./email/email-settings-configuration";
import { SmsSettingsConfiguration } from "./sms/sms-settings-configuration";
import { ConsentSourceName } from "../consent";

export namespace BusinessConfiguration {

    export enum TimeUnit {
        day = "day",
        hour = "hour",
        minute = "minute"
    }

    export class TimeDifference {
        @IsInt()
        value: number

        @IsDefined()
        @IsEnum(TimeUnit)
        unit: TimeUnit


        constructor(options: { value: number, unit: TimeUnit }) {
            if (options == null) {
                return
            }
            if (this.value == 0) {
                throw new Error("TimeDifference error: Value can not be null.")
            }
            this.value = options.value
            this.unit = options.unit
        }
    }


    export class EventSettings {

        @ContainsValidString()
        eventTypeId: string

        @IsDefined()
        @IsArray()
        @ValidateNested({ each: true })
        @Type(() => TimeDifference)
        sendNotificationsBefore: TimeDifference[]


        constructor(options: { eventTypeId: string, sendNotificationsBefore: TimeDifference[] }) {
            if (options == null) {
                return
            }
            this.eventTypeId = options.eventTypeId
            this.sendNotificationsBefore = options.sendNotificationsBefore
        }
    }

    export enum NotificationTaskLimitTimeUnit {
        day = "day",
        month = "month"
    }

    export enum NotificationTaskLimitType {
        // applied in case of task created as part of Bulk messages(BatchId exists)
        bulk = "bulk",
        // applied in case of BatchId is NULL
        //  - ask-consent-id(contact registration or contact update)
        //  - events
        //  - date-of-births
        event = "event"
    }

    export class NotificationTaskLimitSettings {

        @IsDefined()
        @IsEnum(NotificationTaskLimitType)
        type: NotificationTaskLimitType

        @IsDefined()
        @IsEnum(NotificationTaskType)
        notificationTaskType: NotificationTaskType

        @IsDefined()
        @IsEnum(NotificationTaskLimitTimeUnit)
        time: NotificationTaskLimitTimeUnit

        @IsPositiveIntOrZero()
        maximumNumberToSend: number


        constructor(options: { type: NotificationTaskLimitType, notificationTaskType: NotificationTaskType, time: NotificationTaskLimitTimeUnit, maximumNumberToSend: number }) {
            if (options == null) {
                return
            }
            this.type = options.type
            this.time = options.time
            this.maximumNumberToSend = options.maximumNumberToSend
            this.notificationTaskType = options.notificationTaskType
        }
    }



    export class Configuration {

        @IsDefined()
        @IsArray()
        @ValidateNested({ each: true })
        @Type(() => EventSettings)
        events: EventSettings[]

        @IsDefined()
        @IsArray()
        @ValidateNested({ each: true })
        @Type(() => NotificationTaskLimitSettings)
        notificationTaskLimitSettings: NotificationTaskLimitSettings[]

        @IsDefined()
        @ValidateNested()
        @Type(() => EmailSettingsConfiguration)
        email: EmailSettingsConfiguration

        @IsDefined()
        @ValidateNested()
        @Type(() => SmsSettingsConfiguration)
        sms: SmsSettingsConfiguration

        @IsEnum(ConsentSourceName)
        consentSource: ConsentSourceName

    }


}



