import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { ArrayMinSize, IsArray, IsDefined, IsEnum, ValidateNested } from "class-validator";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { QueuedJobStatus } from "./queued-job-status";
import { QueuedJobQueueName } from "./queued-job-queue-name";

export class GetQueuedJobsRequest {

    @IsEnum(QueuedJobQueueName)
    queueName: QueuedJobQueueName

    @ContainsValidString()
    companyId: string;

    @IsArray()
    @ArrayMinSize(1)
    @IsEnum(QueuedJobStatus, { each: true })
    statuses: QueuedJobStatus[]

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]
}


