
var qs = require('qs');


interface QuerySerializerOptions {
    encode: boolean
}

export class QuerySerializer {

    static serialize(object: any, options: QuerySerializerOptions): string | null {
        if (object) {
            //const str = qs.stringify(object, { encode: options.encode });
            //const obj = qs.parse(str, { encode: options.encode })
            return "q-encoded=" + encodeURIComponent(JSON.stringify(object))
        }
        return null;
    }
}