import { dto, apiPaths } from "shared";
import { injectable } from "inversify"; 
import { IOAuthRepository } from "./i-oauth-repository"
import { BaseNetworkRepository } from "../../base/base-network-repository"


@injectable()
export class OAuthRepository extends BaseNetworkRepository implements IOAuthRepository {
    async getTokenByPasswordGrant(oauthPasswordGrant: dto.OAuthPasswordGrant): Promise<dto.OauthTokensResponse> {
        const response = await this.publicAxios({ method: 'post', url: apiPaths.Auth.token, data: oauthPasswordGrant });
        return response.data as dto.OauthTokensResponse;
    } 

    async getTokenByRefreshToken(oauthRefreshToken:dto.OAuthRefreshToken): Promise<dto.OauthTokensResponse>{
        const response = await this.publicAxios({ method: 'post', url: apiPaths.Auth.token, data: oauthRefreshToken });
        return response.data as dto.OauthTokensResponse;
    }

    async logout():Promise<dto.StatusResponse>{
        const response = await this.protectedAxios({ method: 'post', url: apiPaths.Auth.logout});
        return response.data as dto.StatusResponse;
    }

}