import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { IConsentClient, IConsentClientId} from "shared-client";
import { handleError, submitWrapper } from "../../../common/form/form";
import useClient from "../../hook/useClient";
import { useGlobalMessageContext } from "../../hook/use-global-message-context";



// only status is allowed to be changed
interface Model {
	finishedUpdate: boolean
}

export type OptOutModel = Model

const createModel = (): Model => {
	return {
		finishedUpdate: false
	}
};



export const useOptOut = () => {
	let { id } = useParams<{ id: string }>();
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const [finishedUpdate, setFinishedUpdate] = useState(false)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const consentClient = useClient<IConsentClient>(IConsentClientId)

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(false)
				const model = createModel()
				setModelInitialValues(model)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [setGlobalMessage])


	useEffect(() => {
		loadInitialModel()
	}, [id])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		return {}
	}, [])


	const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			await consentClient.deleteUserConsentByShortId(id);
			setFinishedUpdate(true)
			setGlobalMessage({ message: "", type: "success" })
		}, { setFieldError, setGlobalMessage, fieldNames: [] })
	}, [id, consentClient, setGlobalMessage])



	return {
		modelInitialValues,
		finishedUpdate,
		onSubmit,
		onValidate,
		loading,
	}
}

export const useOptOutForm = () => {
	return {
	}
}
