export class DataHelper {

  static isDate(dateString: string | null): boolean {
    if (dateString == null || dateString === "") {
      return false
    }
    return !isNaN(Date.parse(dateString));
  }

  static generateRandomNumber(): number {
    return Math.floor(Math.random() * 10);
  }

  static generateRandomStringBasedOnNumbers(length: number) {
    let result = ""
    for (let i = 0; i < length; i++) {
      result += `${DataHelper.generateRandomNumber()}`
    }
    return result
  }
}