import { dto, SharedDataHelper } from "shared";

export class UserHelper {

	static isAdmin(user: dto.User | null) {
		return UserHelper.hasAdminRole(user?.roles)
	}

	static hasAdminRole(roles: dto.UserRole[] | null | undefined) {
		return roles?.find(x => x.roleId === dto.RoleId.admin) != null
	}

	static isCompanyAdmin(user: dto.User | null, companyId?: string) {
		return UserHelper.hasCompanyAdminRole(user?.roles, companyId)
	}

	static hasCompanyAdminRole(roles: dto.UserRole[] | null | undefined, companyId?: string) {
		return roles?.find(x => x.roleId === dto.RoleId.companyAdmin && (companyId === undefined || x.companyId === companyId)) != null
	}

	static isCompanyUser(user: dto.User | null, companyId?: string) {
		return UserHelper.hasCompanyUserRole(user?.roles, companyId)
	}

	static hasCompanyUserRole(roles: dto.UserRole[] | null | undefined, companyId?: string) {
		return roles?.find(x => (x.roleId === dto.RoleId.companyAdmin || x.roleId === dto.RoleId.companyUser) && (companyId === undefined || x.companyId === companyId)) != null
	}

	static getCompanyRoles(user: dto.User | null) {
		return user?.roles?.filter(x => x.roleId === dto.RoleId.companyAdmin || x.roleId === dto.RoleId.companyUser)
	}

	static hasCompanyRoles(user: dto.User | null) {
		if (user?.roles != null) {
			return SharedDataHelper.firstOrDefault(UserHelper.getCompanyRoles(user!)) != null
		}
		return false
	}

	static getRoleDescription(user: dto.User | null): string {
		if (user?.roles != null) {
			const isAdmin = UserHelper.isAdmin(user)
			if (isAdmin) {
				return "Admin"
			}

			const companyRoles = UserHelper.getCompanyRoles(user)
			if (companyRoles?.find(x => x.roleId === dto.RoleId.companyAdmin) != null) {
				return "Company Admin"
			}

			if (companyRoles?.find(x => x.roleId === dto.RoleId.companyUser) != null) {
				return "Company User"
			}
		}
		return ""
	}
}