import { Match } from "../../../../validators/match"
import { IsArray, IsDate, IsEmail, IsEnum, IsOptional, MaxLength, MinLength } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { IsMobilePhoneNumber } from "../../../../validators/is-mobile-phone-number";
import { UserGender } from "../user/user-gender";
import { ContainsValidTimezone } from "../../../../validators/contains-valid-timezone";

export class UserRegistrationRegisterRequest {

    @IsEmail()
    email: string;

    @IsMobilePhoneNumber()
    mobilePhoneNumber: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    firstName: string;

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    lastName: string;

    @ContainsValidTimezone()
    timezone: string

    @IsDate()
    dateOfBirth: Date

    @IsEnum(UserGender)
    gender: UserGender

    @MinLength(4)
    @ContainsValidString()
    password: string;

    @ContainsValidString()
    @Match('password', { message: 'Password and confirm password are not equal.' })
    confirmPassword: string;

    @ContainsValidString()
    clientId: string;

    @IsArray()
    @ContainsValidString({ each: true })
    consentIds: string[];
}