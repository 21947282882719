import { useField, useFormikContext } from "formik";
import React, { ReactChild } from "react";
import { useCallback } from "react";
import { Dropdown } from "../../common/dropdown/DropDown";
import { CompanyDropdownItem } from "../../components/company-dropdown/company-dropdown";

interface DropdownItem {
	text: string,
	value: string
}

interface Props {
	name: string,
	label: string,
	className?: string,
	disabled?: boolean,
	items: CompanyDropdownItem[],
	onChange?: (name: string, value: string | null) => void
}
export const CompanyDropDownField: React.FC<Props> = (props) => {
	const { name, className, disabled, items, onChange, ...dropdownProps } = props
	const [, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null

	const dropdownItems: DropdownItem[] = items.map(c => {
		const textNode: ReactChild = <div className="company-dropdown__item"><div className="company-dropdown__item-name">{c.name}</div><div className="company-dropdown__item-address">{c.address}</div></div>
		return {
			textNode,
			value: c.companyId as any,
			text: c.name
		}
	})

	const onSelect = useCallback((value: string | null) => {
		document.cookie = `defaultCompanyId=${value}; expires=Sun, 1 Jan 2090 00:00:00 UTC; path=/`
		setFieldValue(name, value, true)
		if (onChange != null) {
			onChange(name, value)
		}
	}, [setFieldValue, name, onChange])
	return (
		<div className="dropdown-field__container">
			<Dropdown {...dropdownProps} onSelect={onSelect} items={dropdownItems} selectedValue={selectedValue} disabled={disabled} />
			{meta.touched && meta.error ? (
				<div className="error-text">{meta.error}</div>
			) : null}
		</div>
	);
}