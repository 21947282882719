import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Shell from "../../_common/layout/shell/shell";


const NotFound = () => (
  <Shell pageTitle={"Not found"} activePageLink={"unknown"} hideNavigationLinks={true} error={null}>
    <ContentHolder>
      <h3>404 - Page not found!</h3>
      <GoHomeLink to="/">
        Go Home
      </GoHomeLink>
    </ContentHolder>
  </Shell>
);

const ContentHolder = styled.div`
  padding:3rem;
`

const GoHomeLink = styled(Link)`
font-size:1.5rem; 
`;

export default NotFound;