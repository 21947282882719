export const ITokenRepositoryId = Symbol.for('ITokenRepository');

export interface ITokenRepository {

    get refreshTokenSupported(): boolean

    getAccessToken(): Promise<string | null>;
    deleteAccessToken(): Promise<void>;
    saveAccessToken(accessToken: string): Promise<void>;

    getRefreshToken(): Promise<string | null>;
    deleteRefreshToken(): Promise<void>;
    saveRefreshToken(refreshToken: string): Promise<void>
}