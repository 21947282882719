import React, { ReactNode } from "react";
import { IGlobalMessage } from "./global-message";
import { GlobalMessageCtx } from "./global-message-context";
 
type GlobalMessageContextProviderProps = { children?: ReactNode }


const GlobalMessageContextProvider: React.FC<GlobalMessageContextProviderProps> = (props) => {
	const [globalMessage, setGlobalMessage] = React.useState<IGlobalMessage>(null as any);
	return (
		<GlobalMessageCtx.Provider value={{ globalMessage, setGlobalMessage }}>
			{props.children}
		</GlobalMessageCtx.Provider>
	)
}

export default GlobalMessageContextProvider;


