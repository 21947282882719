import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import {
    ICompanyClient,
    ICompanyClientId,
    IEventTypeClient,
    IEventTypeClientId,
    INotificationTemplateClient,
    INotificationTemplateClientId,
    IUserAttributeNameClient,
    IUserAttributeNameClientId
} from "shared-client";
import {
    handleError,
    setFieldErrors,
    submitWrapper,
} from "../../../../common/form/form";
import {
    cancelEditForm,
    useTypedFormikContext,
} from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import {
    CompanyDropdownItem,
    createCompanyDropDownItems,
} from "../../../_common/components/company-dropdown/company-dropdown";
import { notification } from "../../../../common/notification/notification";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { DropdownItem } from "../../../_common/fields/dropdown/dropdown-field";
import { useLocalization } from "../../../hook/use-localization";
import { reactDelay } from "../../../_common/helpers/common";
import { enDefaultDesign } from "./email/design";
import { company } from "shared/src/api/path/company-path";


// only status is allowed to be changed
interface Model {
    notificationTemplateId: string | null;
    templateType: dto.NotificationTemplateType;
    companyId: string | null;
    eventTypeId: string;
    name: string;
    details: dto.NotificationTemplateDetails | null;
    query: dto.UserAttributeQueryCondition | null;
    readonly: boolean;
    createdDate: Date | null;
    updatedDate: Date | null;
    companies: dto.Company[];
    useQuery: boolean;
    messageType: dto.MessageType,
    originalModel: Omit<Model, "originalModel"> | null;
}

export type NotificationTemplateModel = Model;

const _isBirthDayTemplate = (name: string): boolean => {
    return name?.toLowerCase()?.startsWith(dto.BirthdayNotificationTemplate.toLowerCase()) ?? false
}

const createModel = (
    notificationTemplate: dto.NotificationTemplate | null,
    query: dto.UserAttributeQueryCondition | null,
    templateType: dto.NotificationTemplateType,
    companies: dto.Company[],
    selectedCompanyId: string | null,
    selectedEventTypeId: string | null,
    useQuery: boolean,
    readonly: boolean,
    t: (key: string) => string,
    language: string,
    originalModel?: Model | null
): Model => {


    const model = {
        readonly,
        notificationTemplateId: notificationTemplate?.id ?? null,
        templateType: templateType,
        companyId: selectedCompanyId,
        eventTypeId: selectedEventTypeId ?? "",
        name: notificationTemplate?.name ?? "",
        details:
            notificationTemplate?.details != null
                ? JSON.parse(JSON.stringify(notificationTemplate?.details))
                : null,
        query: query ?? null,
        createdDate: notificationTemplate?.createdDate ?? null,
        updatedDate: notificationTemplate?.updatedDate ?? null,
        companies: companies,
        useQuery: useQuery,
        originalModel: originalModel ?? null,
        messageType: notificationTemplate?.messageType ?? dto.MessageType.promo
    };

    ensureDefaultTemplateDetails(model, t, language)
    return model
};

export const replaceCompanyDetailsFromTemplate = (company: dto.Company, design: string) => {
    const companyName = company?.name ?? ""
    const companyAddress = company?.address ?? ""
    return design.replaceAll("##company_name##", companyName).replaceAll("##company_address##", companyAddress)

}

const ensureDefaultTemplateDetails = (model: Model, t: (key: string) => string, language: string) => {
    const company = model.companies?.find(x => x.id === model.companyId)
    if (model?.notificationTemplateId == null) {
        if (model.templateType === dto.NotificationTemplateType.sms && model?.details?.templateType !== "sms") {
            const defaultMessage = replaceCompanyDetailsFromTemplate(company!, t('DefaultTemplate.Sms'))
            model.details = new dto.SmsNotificationTemplateDetails({ messageTemplate: defaultMessage })
        }
        if (model.templateType === dto.NotificationTemplateType.email && model?.details?.templateType !== "email") {
            //const designObj = language === "sr" ? enDefaultDesign : enDefaultDesign
            const details = replaceCompanyDetailsFromTemplate(company!, JSON.stringify(enDefaultDesign))
            model.details = new dto.EmailNotificationTemplateDetails({ subject: "", bodyTemplate: "", design: details })
        }
    }
}

const createUpdateNotificationTemplateRequest = (
    model: Model
): dto.UpdateNotificationTemplateRequest => {
    const eventTypeId = SharedDataHelper.stringNotNullTrimEmpty(model.eventTypeId) ? model.eventTypeId : null;
    const request = new dto.UpdateNotificationTemplateRequest();
    request.name = model.name;
    request.details = model.details ?? undefined;
    request.eventTypeId = eventTypeId
    if (eventTypeId == null && !_isBirthDayTemplate(model.name) && model.useQuery) {
        request.query = model.query;
    } else {
        request.query = null
    }
    return request;
};

const createAddNotificationTemplateRequest = (
    model: Model
): dto.AddNotificationTemplateRequest => {
    const eventTypeId = SharedDataHelper.stringNotNullTrimEmpty(model.eventTypeId) ? model.eventTypeId : null
    const request = new dto.AddNotificationTemplateRequest();
    request.isUserRelated = false;
    request.isUnregisteredUserRelated = true;
    request.companyId = model.companyId!;
    request.name = model.name;
    request.messageType = model.messageType
    request.details = model.details!;
    request.templateType = model.templateType;
    request.query = eventTypeId == null && !_isBirthDayTemplate(model.name) && model.useQuery ? model.query : null;
    request.eventTypeId = eventTypeId;
    request.details = model.details!
    return request;
};

export const useNotificationTemplate = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { t, language } = useLocalization()
    const { setGlobalMessage } = useGlobalMessageContext();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(
        null
    );

    const companyClient = useClient<ICompanyClient>(ICompanyClientId);
    const notificationTemplateClient = useClient<INotificationTemplateClient>(INotificationTemplateClientId);

    const loadInitialModel = useCallback((initQuery?: dto.UserAttributeQueryCondition | null | undefined) => {
        (async () => {
            try {
                setLoading(true);
                setGlobalMessage(null);
                setModelInitialValues(null);
                let companies = await companyClient.getCurrentCompanies();
                let readonly = id != null;
                let notificationTemplate: dto.NotificationTemplate | null = null;
                let companyId: string | null = null;
                let templateType = notification.defaultNotificationTemplateType;
                let eventTypeId: string | null = null;
                let useQuery = false
                let queryCondition: dto.UserAttributeQueryCondition | null = null
                if (id != null) {
                    notificationTemplate =
                        await notificationTemplateClient.getNotificationTemplate(id);
                    companyId = notificationTemplate.companyId;
                    companies = companies.filter((x) => x.id === companyId);
                    templateType = notificationTemplate.templateType;
                    eventTypeId = notificationTemplate.eventTypeId
                    useQuery = notificationTemplate.query != null ?? false;
                    queryCondition = notificationTemplate.query
                } else {
                    companyId = CompanyHelper.getDefaultCompany(companies);
                    useQuery = initQuery != null
                    queryCondition = initQuery ?? null
                }

                const originalModel = createModel(
                    notificationTemplate,
                    queryCondition,
                    templateType,
                    companies,
                    companyId,
                    eventTypeId,
                    useQuery,
                    readonly,
                    t,
                    language,
                );
                const model = createModel(
                    notificationTemplate,
                    queryCondition,
                    templateType,
                    companies,
                    companyId,
                    eventTypeId,
                    useQuery,
                    readonly,
                    t,
                    language,
                    originalModel
                );

                setModelInitialValues(model);
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage });
            } finally {
                setLoading(false);
            }
        })();
    }, [
        id,
        setLoading,
        setGlobalMessage,
        setModelInitialValues,
        notificationTemplateClient,
        companyClient,
        t, language
    ]);

    useEffect(() => {
        const initQuery: dto.UserAttributeQueryCondition | null | undefined = (window as any).TEMP_USER_ATTRIBUTE_QUERY;
        (window as any).TEMP_USER_ATTRIBUTE_QUERY = undefined
        loadInitialModel(initQuery);
    }, [id]);

    const onValidate = useCallback(
        async (model: Model): Promise<FormikErrors<Model>> => {
            let validateErrors: ValidationError[] = [];
            if (id != null) {
                const request = createUpdateNotificationTemplateRequest(model);
                validateErrors = await validate(request);
            } else {
                const request = createAddNotificationTemplateRequest(model);
                validateErrors = await validate(request);
            }

            const errorModel: FormikErrors<Model> = {};
            setFieldErrors<Model>(
                validateErrors,
                ["companyId", "name", "query"],
                errorModel
            );
            return errorModel;
        },
        [id]
    );



    const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(
            async () => {
                if (id != null) {
                    const request = createUpdateNotificationTemplateRequest(model);
                    await notificationTemplateClient.updateNotificationTemplate(
                        id,
                        request
                    );
                    loadInitialModel();
                    setGlobalMessage({
                        message: t("Saved"),
                        type: "success",
                    });
                } else {
                    const request = createAddNotificationTemplateRequest(model);
                    const createdTemplate = await notificationTemplateClient.addNotificationTemplate(request);
                    history.replace("/notification-templates/" + createdTemplate.id);
                    reactDelay(() => setGlobalMessage({
                        message: t("NotificationTemplate.Created"),
                        type: "success",
                    }));
                }
            },
            {
                setFieldError,
                setGlobalMessage,
                fieldNames: ["companyId", "name", "query"],
            }
        );
    },
        [
            id,
            notificationTemplateClient,
            loadInitialModel,
            setGlobalMessage,
            history,
            t
        ]
    );

    const onDeleteTemplate = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null);
                setLoading(true);
                await notificationTemplateClient.deleteNotificationTemplate(id);
                history.replace("/notification-templates");
                setGlobalMessage({
                    message: t("NotificationTemplateDeleted"),
                    type: "success",
                });
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage });
            } finally {
                setLoading(false);
            }
        })();
    }, [id, notificationTemplateClient, history, setGlobalMessage, t]);

    const isAddingNewTemplate = useMemo(() => {
        return id == null;
    }, [id]);

    const addGroupMessageFromTemplate = useCallback(() => {
        (window as any).TEMP_BULK_MESSAGE_TEMPLATE_INFO = { companyId: modelInitialValues?.companyId, templateId: id };
        history.replace("/notification-task-batches/add/notification-task-batch");
    }, [id, history, modelInitialValues?.companyId])

    const isAddGroupMessageFromTemplateVisible = useMemo(() => {
        if (modelInitialValues == null) {
            return false
        }
        return !isAddingNewTemplate && SharedDataHelper.stringIsNullTrimEmpty(modelInitialValues.eventTypeId) && !_isBirthDayTemplate(modelInitialValues.name)
    }, [isAddingNewTemplate, modelInitialValues])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteTemplate,
        isAddingNewTemplate,
        addGroupMessageFromTemplate,
        isAddGroupMessageFromTemplateVisible
    };
};

export const useNotificationTemplateForm = () => {
    const { t, language } = useLocalization();
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>();

    const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId);
    const [userAttributeNames, setUserAttributeNames] = useState<dto.UserAttributeName[]>([])

    const eventTypeClient = useClient<IEventTypeClient>(IEventTypeClientId);
    const [eventTypes, setEventTypes] = useState<dto.EventType[]>();


    const onCompanyChanged = useCallback(async (companyId: string | null) => {
        if (companyId == null) {
            setUserAttributeNames([]);
            return
        }
        const attrRequest = new dto.GetUserAttributeNamesRequest();
        attrRequest.companyId = companyId;
        attrRequest.paging = dto.PagingRequest.createMaxPaging();
        attrRequest.sortBy = [];
        attrRequest.sortBy.push(
            new dto.SortItem({ name: "createdDate", type: "desc" })
        );
        const pagedAttrResponse = await userAttributeNameClient.getUserAttributeNames(attrRequest);
        setUserAttributeNames(pagedAttrResponse.entities);

        const eventTypesRequest = new dto.GetEventTypesRequest();
        eventTypesRequest.companyId = companyId;
        eventTypesRequest.paging = dto.PagingRequest.createMaxPaging();
        eventTypesRequest.sortBy = [];
        eventTypesRequest.sortBy.push(
            new dto.SortItem({ name: "createdDate", type: "desc" })
        );

        const pagedEventTypesResponse = await eventTypeClient.getEventTypes(eventTypesRequest);
        setEventTypes(pagedEventTypesResponse.entities)

    }, [userAttributeNameClient, eventTypeClient])

    useEffect(() => {
        (async () => {
            await onCompanyChanged(model.companyId)
        })();
    }, [model.companyId, onCompanyChanged])

    useEffect(() => {
        if (model.useQuery && model.query == null) {
            let query: dto.UserAttributeQueryCondition | null = model.originalModel?.query ?? null
            if (query == null) {
                query = new dto.UserAttributeQueryCondition()
                query.name = dto.UserAttribute.sharedAttributes.dateOfBirth
                query.type = dto.UserAttributeQueryType.lowerOrEqual
                query.value = "2000-01-01"
            }
            setFieldValue("query", query)

        }
    }, [model.useQuery, setFieldValue, model.originalModel?.query, model.query])

    useEffect(() => {
        if (model) {
            ensureDefaultTemplateDetails(model, t, language)
        }
    }, [model, model.templateType, t, language])


    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies);
    }, [model.companies]);

    const selectedCompanyName = useMemo((): string => {
        if (SharedDataHelper.stringIsNullTrimEmpty(model.companyId)) {
            return ""
        }

        return (companyItems ?? []).find(x => x.companyId === model.companyId)?.name ?? ""
    }, [companyItems, model.companyId])

    const companyDropDownEnabled = useMemo(() => {
        return !model.readonly && model.notificationTemplateId == null;
    }, [model.readonly, model.notificationTemplateId]);

    const eventTypesItems = useMemo((): DropdownItem[] => {
        const array: DropdownItem[] = (eventTypes ?? []).map(x => ({
            text: x.name,
            value: x.id,
        }))
        array.unshift({
            text: "-",
            value: ""
        })
        return array
    }, [eventTypes])

    const useQueryItems = useMemo((): DropdownItem[] => {
        return [{
            text: t("Yes"),
            value: true
        },
        {
            text: t("No"),
            value: false
        }]
    }, [t])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false);
    }, [setFieldValue]);

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues });
    }, [setFormValues, setErrors, model]);

    const saveTemplateEnabled = useMemo(() => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.companyId);
    }, [model.companyId]);

    const isBirthDayTemplate = useMemo((): boolean => {
        return _isBirthDayTemplate(model.name)
    }, [model.name])

    const useEventsMarkups = useMemo((): boolean => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.eventTypeId)
    }, [model.eventTypeId])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        saveTemplateEnabled,
        companyDropDownEnabled,
        companyItems,
        templateTypes: notification.notificationTemplateTypes,
        userAttributeNames,
        useQueryItems,
        eventTypesItems,
        selectedCompanyName,
        isBirthDayTemplate,
        useEventsMarkups
    };
};
