

const apiLogPaths = {
  base: "/api-logs",
  apiLogs: "",
  apiLog: "/:apilogid"
}

export { apiLogPaths as apiLog }

