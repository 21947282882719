import { IsArray, IsEmail, IsOptional } from "class-validator";
import { IsMobilePhoneNumber } from "../../../../../validators/is-mobile-phone-number"
import { AddNotificationTaskBatchDetails } from "../add-notification-task-batch-details";
import { LambdaExpression } from "../../../../../validators/lambda-expression";

export class EmailAddNotificationTaskBatchDetails implements AddNotificationTaskBatchDetails {

    @IsOptional()
    @IsArray()
    @IsEmail({}, { each: true })
    emails: string[] | null


    constructor(options: { emails: string[] | null }) {
        if (options == null) {
            return
        }

        this.emails = options.emails
    }
}