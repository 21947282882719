export class PagingResponse<Entity> {
    paging: PagingResponseMetaData;
    entities: Entity[]


    constructor(options?: { paging: PagingResponseMetaData, entities: Entity[] }) {
        if (options == null) {
            return
        }
        this.paging = options.paging
        this.entities = options.entities
    }
}

export class PagingResponseMetaData {
    pageIndex: number;
    pageSize: number;
    totalNumberOfItems: number;
    totalNumberOfPages: number;


    constructor(options?: { pageIndex: number, pageSize: number, totalNumberOfItems: number }) {
        if (options == null) {
            return
        }

        this.pageIndex = options.pageIndex
        this.pageSize = options.pageSize
        this.totalNumberOfItems = options.totalNumberOfItems
        this.totalNumberOfPages = options.totalNumberOfItems % options.pageSize == 0 ? options.totalNumberOfItems / options.pageSize : ((Math.floor(options.totalNumberOfItems / options.pageSize)) + 1)

    }
}