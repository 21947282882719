import { IsNotEmpty, IsString } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";


export class OAuthRefreshToken {

    @ContainsValidString()
    refreshToken: string;

    @ContainsValidString()
    clientId: string;

    @ContainsValidString()
    grantType: string;
}