import React from "react"
import { SharedDataHelper } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { Button } from "../../../_common/common/button/Button"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import { useNotificationTaskBatches } from "./useNotificationTaskBatches"
import "./notification-task-batches.scss"
import { useLocalization } from "../../../hook/use-localization"


export const NotificationTaskBatches: React.FC = () => {
	const { t } = useLocalization()
	const isMobile = useIsMobile()
	const { notificationTaskBatchInfos, companyItems, selectedCompanyId, onNotificationTaskBatchSelected, onCompanySelected, loading, pagination, onAddNotificationTaskBatch, addNewNotificationTaskBatchEnabled } = useNotificationTaskBatches()

	const rowClick = (id: string) => {
		if (isMobile) {
			onNotificationTaskBatchSelected(id)
		}
	}

	const listItems = notificationTaskBatchInfos.map((notificationTaskBatchInfo) =>
		<div className="notification-task-batch__item data-row-item" key={notificationTaskBatchInfo.batch.id} onClick={() => rowClick(notificationTaskBatchInfo.batch.id)}>
			<div className="notification-task-batch__item-name-comp-name">
				<div className="notification-task-batch__item-name"><button className="list-item-link-button" onClick={() => { onNotificationTaskBatchSelected(notificationTaskBatchInfo.batch.id) }}>{notificationTaskBatchInfo.batch.name}</button></div>
				<div className="notification-task-batch__item-company-name">{notificationTaskBatchInfo.batch.company.name}</div>
			</div>
			<div className="notification-task-batch__item-created-completed-failed">
				<div className="notification-task-batch__item-task-date">{isMobile ? (t("NotificationDate") + ": ") : ""} {SharedDataHelper.getFormattedDate(notificationTaskBatchInfo.batch.createdDate, "dd.MM.yyyy")}</div>
				<div className="notification-task-batch__item-completed">{isMobile ? (t("Completed") + ": ") : ""}{notificationTaskBatchInfo.numberOfTasksCompleted}/{notificationTaskBatchInfo.numberOfTasksTotal}</div>
				<div className="notification-task-batch__item-failed">{isMobile ? (t("Failed") + ": ") : ""}{notificationTaskBatchInfo.numberOfTasksFailed}/{notificationTaskBatchInfo.numberOfTasksTotal}</div>
			</div>
		</div>
	);

	const listHeader = <>
		<div className="notification-task-batch__item header-row-item" key={"notification-task-batch-list"}>
			<div className="notification-task-batch__item-name-comp-name">
				<div className="notification-task-batch__item-name">{t("Name")}</div>
				<div className="notification-task-batch__item-company-name">{t("Company")}</div>
			</div>
			<div className="notification-task-batch__item-task-date-completed-failed">
				<div className="notification-task-batch__item-task-date">{t("NotificationDate")}</div>
				<div className="notification-task-batch__item-completed">{t("Completed")}</div>
				<div className="notification-task-batch__item-failed">{t("Failed")}</div>
			</div>
		</div>
	</>

	return (
		<Shell pageTitle={t("BulkMessages")}
			mobileHeaderContent={<button disabled={!addNewNotificationTaskBatchEnabled} className="mobile-add-new" onClick={onAddNotificationTaskBatch}>Add</button>}
			desktopHeaderContent={<Button disabled={!addNewNotificationTaskBatchEnabled} onClick={onAddNotificationTaskBatch}>{t("AddBulkMessage")} </Button>}>

			<Loading loading={loading} />
			<div className="indent-container notification-task-batches-header-filter">
				<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
				<Pagination {...pagination} />

			</div>
			{listHeader}
			{listItems}
			{notificationTaskBatchInfos.length === 0 && <NoEntities />}
		</Shell >
	)
}