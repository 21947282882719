import React from "react"
import { Form, Formik } from "formik"
import "./unregistered-user-creation.scss"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import { UserModel, useUnregisteredUserCreation, useUnregisteredUserCreationForm } from "./useUnregisteredUserCreation"
import { useLocalization } from "../../../hook/use-localization"
import AuthShell from "../../../_common/layout/auth/auth-shell"
import { ConsentField } from "../../../_common/fields/consent/consent-field"


export const UnregisteredUserCreation: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, finishedUpdate, pageTitle } = useUnregisteredUserCreation()

    return (
        <AuthShell title={pageTitle}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            {!finishedUpdate && <FormContent />}
                            {finishedUpdate &&
                                <div className="contact-creation-details-updated">
                                    <div className="success-info">{t("YourDetailsSuccessfullySaved")}</div>
                                    <div className="additional-msg">{t("YourCanCloseThisWindow").replace("{ companyName }", modelInitialValues.companyName)}</div>
                                </div>
                            }
                        </Form>
                    )}
                </Formik>
            </>
            }
        </AuthShell>
    )
}


const FormContent: React.FC = () => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<UserModel>()
    const { genders, saveEnabled } = useUnregisteredUserCreationForm()

    const consentItems = model.availableConsentsToChange.map((consentItem, index) => {
        const name = `availableConsentsToChange.${index}.enabled`;
        return <div key={consentItem.consent.id} >
            <ConsentField className="contact-consent-field" name={name} consent={consentItem.consent} />
        </div >
    });

    return (
        <div className="indent-container unregistered-user-creation-form-content">
            <TextInput name="companyName" disabled={true} label={t("Company")} />
            <TextInput name="firstName" label={t("FirstName")} />
            <TextInput name="lastName" label={t("LastName")} />
            <DatePickerField name="dateOfBirth" label={t("DateOfBirth")} />
            <DropDownField name="gender" label={t("Gender")} items={genders} />
            <TextInput name="email" disabled={true} label={t("Email")} />
            <TextInput name="mobilePhoneNumber" disabled={true} label={t("MobilePhone")} />
            <div className="consents-container">
                {consentItems}
            </div>
            {!saveEnabled && <div className="consents-validation">
                {t("InOrderToSaveMustAcceptConsents")}
            </div>}
            <div className="action-buttons-row">
                <ButtonSubmit disabled={!saveEnabled} className="margin-left-auto">{t("Save")}</ButtonSubmit>
            </div>
            <div className="revoke-consents-contact-company">
                {t("RevokeConsentsContactCompany").replaceAll("{ companyName }", model.companyName)}
            </div>
        </div>)
}
