import { dto } from "shared"

export const IUserClientId = Symbol.for('IUserClient');

export interface IUserClient {
    getCurrentUser(): Promise<dto.User>
    getUsers(data: dto.GetUsersRequest): Promise<dto.PagingResponse<dto.User>>
    getUser(userId: string): Promise<dto.User>
    getUserByEmail(email: string): Promise<dto.User>
    updateUser(userId: string, data: dto.UpdateUserRequest): Promise<void>
    deleteUser(userId: string): Promise<void>
    changeUserPassword(userId: string, data: dto.ChangeUserPasswordRequest): Promise<void>
    changeUserStatus(userId: string, data: dto.ChangeUserStatusRequest): Promise<void>
}