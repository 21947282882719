import { SortItem } from "../../common/query/sort-item";
import { PagingRequest } from "../../common/paging/paging-request";
import { NotificationTaskType } from "../notification-task-type";
import { AreValidSortItems } from '../../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { ArrayMinSize, IsArray, IsDate, IsDefined, IsEnum, IsOptional, ValidateNested } from "class-validator";
import { NotificationTaskStatus } from "../notification-task-status";

export class GetNotificationTasksRequest {
    @IsOptional()
    @IsArray()
    @ArrayMinSize(1)
    @IsEnum(NotificationTaskStatus, { each: true })
    statuses?: NotificationTaskStatus[];

    @IsOptional()
    @IsDate()
    taskDateTo?: Date

    @IsOptional()
    @IsDate()
    taskDateFrom?: Date

    @IsOptional()
    @IsEnum(NotificationTaskType)
    notificationTaskType?: NotificationTaskType;

    @IsOptional()
    @ContainsValidString()
    companyId: string;

    @IsOptional()
    @ContainsValidString()
    batchId?: string;

    @IsOptional()
    @ContainsValidString()
    templateId?: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @IsDefined()
    @AreValidSortItems({ allowed: ["taskDate"] })
    sortBy: SortItem[]
}