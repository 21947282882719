import * as React from 'react'
import { SharedDataHelper, dto } from 'shared'
import { useLocalization } from './use-localization'


export function useSharedAttribute() {
    const { t } = useLocalization()
    const transformSharedAttrValue = React.useCallback((name: string, value: string) => {


        if (!dto.UserAttribute.isSharedAttribute(name)) {
            return value
        }

        if (dto.UserAttribute.isDateOfBirthSharedAttribute(name)) {
            if (SharedDataHelper.matchISO8601(value)) {
                return SharedDataHelper.getFormattedDate(SharedDataHelper.parseISO8601(value), "dd.MM.yyyy")
            }
        }

        if (dto.UserAttribute.isGenderSharedAttribute(name)) {
            return t(value)
        }

        return value

    }, [t])

    return {
        transformSharedAttrValue
    }


}


