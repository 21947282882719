

const userAttributeNamePaths = {
    base: "/user-attribute-names",
    userAttributeNames: "",
    userAttributeName: "/:userattributenameid"
}

export { userAttributeNamePaths as userAttributeName }

