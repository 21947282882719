import React, { useCallback, useEffect, useState } from "react";
import { dto } from "shared";
import { useField, useFormikContext } from "formik";
import "./user-query-field.scss"
import { UserQuery } from "../../components/user-query/user-query";
import { transformAndValidate } from "class-transformer-validator";


interface Props {
  label: string,
  name: string
  userAttributeNames: dto.UserAttributeName[]
  query: dto.UserAttributeQueryCondition,
  disabled?: boolean,
}
export const UserQueryField: React.FC<Props> = (props => {
  const { label, name, userAttributeNames, query, disabled } = props
  const [, meta] = useField(name);
  const { setFieldValue, setFieldError } = useFormikContext();

  const validate = useCallback((query: dto.UserAttributeQueryCondition) => {
    (async () => {
      try {
        await transformAndValidate(dto.UserAttributeQueryCondition, query);
        setFieldError(name, undefined)
      }
      catch (err) {
        setFieldError(name, "Query is not valid")
      }
    })();
  }, [])


  const onChildUpdated = (childQuery: dto.UserAttributeQueryCondition, childIndex: number) => {
    setFieldValue(name, childQuery, true);
    validate(childQuery);
  }

  return (
    <div className="user-query-field__container">
      <UserQuery disabled={disabled} onChanged={onChildUpdated} indexInParent={0} id={name} userAttributeNames={userAttributeNames} query={query} label={label} />
      {meta.error ? (
        <div className="error-text">{meta.error}</div>
      ) : null}
    </div>
  );
})