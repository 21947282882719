import { Formik } from "formik"
import "./events-upload-excel.scss"
import { Tab, Tabs } from 'react-bootstrap';
import React, { useRef, useState } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Loading } from "../../../_common/components/loading/loading"
import Shell from "../../../_common/layout/shell/shell"
import { useEventsUploadExcelForm, EventsUploadExcelModel, useEventsUploadExcel } from "./useEventsUploadExcel"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { useLocalization } from "../../../hook/use-localization"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { Table } from "../../../_common/components/table/table"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"


export const EventsUploadExcel: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, formKey } = useEventsUploadExcel()
    const { setGlobalMessage } = useGlobalMessageContext()

    const [file, setFile] = useState(null);
    const formRef = useRef(null);

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event: any) => {

        event.preventDefault();
        try {
            if (!file) {
                setGlobalMessage({ message: t('PleaseSelectExcelFile'), type: "error" })
                return;
            }

            const formData = new FormData();
            formData.append('excelFile', file);
            setTimeout(() => {
                onSubmit(formData)
            }, 100);

        } catch (error) {
            console.error('Error:', error);
            setGlobalMessage({ message: t('Error'), type: "error" })
        }

        if (formRef?.current) {
            (formRef.current as any).reset();
        }
        setFile(null);
    };
    return (
        <Shell pageTitle={t("UploadExcelEvents")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>

                <Formik key={formKey} initialValues={modelInitialValues} onSubmit={() => { }}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <FormContent loading={loading} />
                    )}
                </Formik>
                <form className="events-upload-excel-form" ref={formRef} id="upload-form" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <input type="file" id="excelFile" name="excelFile" accept=".xlsx, .xls" onChange={handleFileChange} />
                    <div className="action-buttons-row">
                        <ButtonSubmit className="btn-upload-excel">{t("UploadExcel")}</ButtonSubmit>
                    </div>
                </form>
            </>
            }
        </Shell>
    )
}



const FormContent: React.FC<{ loading: boolean }> = ({ loading }) => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<EventsUploadExcelModel>()
    const { companyItems } = useEventsUploadExcelForm()


    const validUserAttributeRecords = (model.validEventsRecords ?? []).map((record: any) => {
        const obj = record.data
        obj["__jobId"] = record.jobId
        return obj
    });

    const inValidUserAttributeRecords = (model.invalidEventsRecords ?? []).map((record: any) => {
        const obj = record.data
        obj["__errorMessage"] = record.errorMessage
        return obj
    });

    const headers = [t("EventType"), t("Date"), t("Description"), t("ContactExternalId")]
    const keys = ["EventType", "Date", "Description", "ContactExternalId"]
    const validHeaders = headers.concat([t("JobId")])
    const validKeys = keys.concat(["__jobId"])
    const invalidHeaders = headers.concat([t("Error")])
    const invalidKeys = keys.concat(["__errorMessage"])
    const defaultActiveKey = validUserAttributeRecords.length > 0 ? "valid-upload-records" : "invalid-upload-records"
    return (
        <div className="indent-container events-upload-excel-form-content">
            <CompanyDropDownField name="companyId" label={t("Company")} items={companyItems} />
            {!loading && (validUserAttributeRecords.length > 0 || inValidUserAttributeRecords.length > 0) &&
                <div className="stats-container">
                    <div>{t("NumberOfValidRecords")}: {validUserAttributeRecords.length}</div>
                    <div className={inValidUserAttributeRecords.length > 0 ? 'invalid-number-of-records-exists' : ''}>{t("NumberOfInvalidRecords")}: {inValidUserAttributeRecords.length}</div>

                    <Tabs
                        id="valid-upload-records"
                        defaultActiveKey={defaultActiveKey}
                        className="mb-3 tabs-upload">
                        {validUserAttributeRecords.length > 0 && <Tab eventKey="valid-upload-records" title={t("ValidRecords")}>
                            <Table data={validUserAttributeRecords} keys={validKeys} headers={validHeaders} dateFormat="dd.MM.yyyy HH:mm" messageWhenNoRecords={t("NoValidRecords")} />
                        </Tab>}
                        {inValidUserAttributeRecords.length > 0 && <Tab eventKey="invalid-upload-records" title={t("InValidRecords")}>
                            <Table data={inValidUserAttributeRecords} keys={invalidKeys} headers={invalidHeaders} dateFormat="dd.MM.yyyy HH:mm" messageWhenNoRecords={t("NoInValidRecords")} tableClassName="upload-eventss-by-excel-error" />
                        </Tab>}
                    </Tabs>
                </div>
            }

        </div>)
}
