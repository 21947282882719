import { dto } from "shared";
import { IUserAttributeNameClient, IUserAttributeNameClientId } from "shared-client";
import useClient from "../../../hook/useClient";
import { useEffect, useMemo, useState } from "react";
import { TypeaheadDropdownItem } from "../../common/typeahead/typeahead";

export const useUserAttributeNameDropDown = (companyId: string, allowSharedAttributes: boolean) => {
  const companyClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId);
  const [userAttributeNames, setUserAttributeNames] = useState<dto.UserAttributeName[]>([])

  useEffect(() => {
    (async () => {
      const pagedResponse = await companyClient.getUserAttributeNames({
        paging: dto.PagingRequest.createMaxPaging(),
        sortBy: [new dto.SortItem({ name: "name" })],
        companyId
      })
      let entities = pagedResponse.entities;
      if (!allowSharedAttributes) {
        entities = entities.filter(x => !dto.UserAttribute.isSharedAttribute(x.name))
      }
      setUserAttributeNames(entities)
    })()
  }, [companyId, companyClient, allowSharedAttributes])


  const userAttributeNameItems = useMemo((): TypeaheadDropdownItem[] => {
    return userAttributeNames.map(x => {
      return {
        text: x.name,
        description: x.description,
        value: x.name
      }
    })
  }, [userAttributeNames])

  return {
    userAttributeNameItems
  }

}
