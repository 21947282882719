import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { ICompanyClient, ICompanyClientId, IConsentClient, IConsentClientId, IUnregisteredUserClient, IUnregisteredUserClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useLocalization } from "../../../hook/use-localization";
import { reactDelay } from "../../../_common/helpers/common";

interface ConsentItem {
    enabled: boolean,
    consent: dto.Consent
}

// only status is allowed to be changed
interface Model {
    userId: string | null,
    givenUserConsentNames: string[]
    companyId: string | null,
    firstName: string,
    lastName: string,
    email: string,
    emailVerified: boolean,
    externalId: string,
    mobilePhoneNumber: string,
    mobilePhoneNumberVerified: boolean,
    dateOfBirth: Date | null,
    gender: dto.UserGender | null,
    readonly: boolean,
    companies: dto.Company[],
    consentItems: ConsentItem[],
    originalModel: Omit<Model, "originalModel"> | null
}

export type UserModel = Model

const createModel = (
    user: dto.UnregisteredUser | null,
    companies: dto.Company[],
    selectedCompanyId: string | null,
    consentItems: ConsentItem[],
    readonly: boolean,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        userId: user?.id ?? null,
        givenUserConsentNames: user?.givenConsents ?? [],
        consentItems: consentItems,
        companyId: selectedCompanyId,
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        email: user?.email ?? "",
        emailVerified: user?.emailVerified ?? false,
        externalId: user?.externalId ?? "",
        mobilePhoneNumber: SharedDataHelper.stringNotNullEmpty(user?.mobilePhoneNumber) ?
            SharedDataHelper.formatMobilePhoneNumber(user!.mobilePhoneNumber) : "",
        mobilePhoneNumberVerified: user?.mobilePhoneNumberVerified ?? false,
        gender: user?.gender ?? null,
        dateOfBirth: user?.dateOfBirth ?? null,
        companies: companies,
        originalModel: originalModel ?? null
    }
}

const createAddUserRequest = (model: Model): dto.AddUnregisteredUserRequest => {
    const request = new dto.AddUnregisteredUserRequest()
    request.firstName = model.firstName
    request.lastName = model.lastName
    request.email = SharedDataHelper.stringNotNullEmpty(model.email) ? model.email : null
    request.mobilePhoneNumber = SharedDataHelper.stringNotNullEmpty(model.mobilePhoneNumber) ? model.mobilePhoneNumber.replaceAll(" ", "") : null
    request.gender = SharedDataHelper.stringNotNullEmpty(model.gender) ? model.gender : null
    request.dateOfBirth = model.dateOfBirth
    request.timezone = SharedDataHelper.getTimeZone()
    request.createdByCompanyId = model.companyId!
    request.externalId = SharedDataHelper.stringNotNullEmpty(model.externalId) ? model.externalId : null
    request.askConsentIds = getAskConsentIds(model)
    return request
}

const createUpdateUserRequest = (model: Model): dto.UpdateUnregisteredUserRequest => {
    const request = new dto.UpdateUnregisteredUserRequest()
    request.firstName = model.firstName
    request.lastName = model.lastName
    request.email = getEmailFromModel(model)
    request.mobilePhoneNumber = getMobilePhoneNumberFromModel(model)
    request.gender = SharedDataHelper.stringNotNullEmpty(model.gender) ? model.gender : null
    request.dateOfBirth = model.dateOfBirth
    request.timezone = SharedDataHelper.getTimeZone()
    request.externalId = SharedDataHelper.stringNotNullEmpty(model.externalId) ? model.externalId : null
    request.askConsentIds = getAskConsentIds(model)
    request.deleteConsentIds = getDeleteConsentIds(model)
    return request
}

const getEmailFromModel = (model: Omit<Model, "originalModel"> | null): string | null => {
    return SharedDataHelper.stringNotNullEmpty(model?.email) ? model!.email.trim() : null
}

const getMobilePhoneNumberFromModel = (model: Omit<Model, "originalModel"> | null): string | null => {
    return SharedDataHelper.stringNotNullEmpty(model?.mobilePhoneNumber) ? SharedDataHelper.replaceAllWhiteSpaces(model!.mobilePhoneNumber) : null
}



const getAskConsentIds = (model: Model): string[] => {
    const askConsentIds = new Set<string>();
    const newConsentItems = model.consentItems.filter(x => x.enabled)
    for (const newConsentItem of newConsentItems) {
        const exists = model.givenUserConsentNames.find(x => x === newConsentItem.consent.name) != null
        if (!exists) {
            askConsentIds.add(newConsentItem.consent.id)
        }
    }
    if (model.originalModel != null) {

        if (SharedDataHelper.isValidMobilePhoneNumber(SharedDataHelper.replaceAllWhiteSpaces(model.originalModel.mobilePhoneNumber)) &&
            SharedDataHelper.isValidMobilePhoneNumber(SharedDataHelper.replaceAllWhiteSpaces(model.mobilePhoneNumber)) &&
            SharedDataHelper.replaceAllWhiteSpaces(model.mobilePhoneNumber) !== SharedDataHelper.replaceAllWhiteSpaces(model.originalModel.mobilePhoneNumber)) {
            const mobileConsentId = model.consentItems.find(x => x.consent.name === dto.ConsentName.sendSmsPromoMessages);
            askConsentIds.add(mobileConsentId!.consent.id)
        }

        if (SharedDataHelper.isValidEmail(model.originalModel.email) &&
            SharedDataHelper.isValidEmail(model.email) &&
            model.email.trim() !== model.originalModel.email.trim()) {
            const emailConsentId = model.consentItems.find(x => x.consent.name === dto.ConsentName.sendEmailPromoMessages);
            askConsentIds.add(emailConsentId!.consent.id)
        }
    }
    return Array.from(askConsentIds)
}


const getDeleteConsentIds = (model: Model): string[] => {
    const deleteConsentItems = model.consentItems.filter(x => !x.enabled)
    const deleteConsentIds: string[] = [];
    for (const deleteConsentItem of deleteConsentItems) {
        const exists = model.givenUserConsentNames.find(x => x === deleteConsentItem.consent.name) != null;
        if (exists) {
            deleteConsentIds.push(deleteConsentItem.consent.id)
        }
    }
    return deleteConsentIds
}



export const useUnregisteredUser = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const history = useHistory()
    const { t } = useLocalization();
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const consentClient = useClient<IConsentClient>(IConsentClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                let companies = await companyClient.getCurrentCompanies()
                let readonly = id != null
                let user: dto.UnregisteredUser | null = null
                let companyId: string | null = null
                const consentItems: ConsentItem[] = []
                const allConsents = await consentClient.getConsents({ dataProcessing: true, sendEmailPromo: true, sendSmsPromo: true });
                let givenConsents: dto.ConsentName[] = []

                if (id != null) {
                    user = await unregisteredUserClient.getUser(id)
                    companyId = user.createdByCompanyId
                    companies = companies.filter(x => x.id === companyId)
                    givenConsents = user.givenConsents ?? []
                } else {
                    companyId = CompanyHelper.getDefaultCompany(companies)
                }

                for (const consent of allConsents) {
                    let enabled = givenConsents.find(x => x === consent.name) != null;
                    if (id == null) {
                        enabled = true
                    }
                    consentItems.push({
                        enabled: enabled,
                        consent: consent
                    })
                }

                const originalModel = createModel(user, companies, companyId, consentItems, readonly)
                const model = createModel(user, companies, companyId, consentItems, readonly, originalModel)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, consentClient, unregisteredUserClient, companyClient])


    useEffect(() => {
        loadInitialModel()
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        let validateErrors: ValidationError[] = []
        if (id != null) {
            const request = createUpdateUserRequest(model)
            validateErrors = await validate(request);
        } else {
            const request = createAddUserRequest(model)
            validateErrors = await validate(request);
        }
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["firstName", "lastName", "email", "mobilePhoneNumber", "dateOfBirth", "gender"], errorModel)
        return errorModel
    }, [id])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            if (id != null) {
                const request = createUpdateUserRequest(model)
                await unregisteredUserClient.updateUser(id, request)
                loadInitialModel()
                setGlobalMessage({ message: t("Contact.Updated"), type: "success" })
            } else {
                const request = createAddUserRequest(model)
                const createdUser = await unregisteredUserClient.createUser(request)
                history.replace("/unregistered-users/" + createdUser.id)
                reactDelay(() => setGlobalMessage({ message: t("Contact.Created"), type: "success" }))
            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["firstName", "lastName", "email", "mobilePhoneNumber", "dateOfBirth", "gender"] })
    }, [id, unregisteredUserClient, loadInitialModel, setGlobalMessage, history, t])

    const onDeleteUser = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await unregisteredUserClient.deleteUser(id)
                history.replace("/unregistered-users")
                reactDelay(() => setGlobalMessage({ message: t("Contact deleted"), type: "success" }))
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, unregisteredUserClient, history, setGlobalMessage, t])

    const isAddingNewUser = useMemo(() => {
        return id == null
    }, [id])

    const onAddNewUser = useCallback(() => {
        history.push(`/unregistered-users/add/user`)
    }, [history])

    const deleteDataProcessingConsent = useCallback(async () => {
        try {
            setGlobalMessage(null)
            setLoading(true)
            await unregisteredUserClient.deleteDataProcessingConsentForUnregisteredUser(id)
            loadInitialModel()
            setGlobalMessage({ message: t("DataProcessingConsentDeleted"), type: "success" })
        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        } finally {
            setLoading(false)
        }
    }, [unregisteredUserClient, id, t, setGlobalMessage, loadInitialModel])


    const onUserAttributesSelected = useCallback(() => {
        history.push(`/unregistered-users/${id}/user-attributes`)
    }, [history,  id])

    const onUserEventsSelected = useCallback(() => {
        history.push(`/unregistered-users/${id}/events`)
    }, [history, id])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteUser,
        isAddingNewUser,
        onAddNewUser,
        deleteDataProcessingConsent,
        onUserAttributesSelected,
        onUserEventsSelected
    }
}

export const useUnregisteredUserForm = () => {
    const history = useHistory()
    const { t } = useLocalization()
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies)
    }, [model.companies])

    const companyDropDownEnabled = useMemo(() => {
        return !model.readonly && model.userId == null
    }, [model.readonly, model.userId])

    const genders = useMemo(() => {
        return [
            { text: t("Male"), value: dto.UserGender.male },
            { text: t("Female"), value: dto.UserGender.female }]
    }, [])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    const saveUserEnabled = useMemo(() => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.companyId)
    }, [model.companyId])

    const onUserAttributesSelected = useCallback(() => {
        history.push(`/unregistered-users/${model.userId}/user-attributes`)
    }, [history, model.userId])

    const onUserEventsSelected = useCallback(() => {
        history.push(`/unregistered-users/${model.userId}/events`)
    }, [history, model.userId])

    const didGiveDataProcessingConsent = model.consentItems.find(x => x.consent.name === dto.ConsentName.dataProcessingConsent && x.enabled) != null

    return {
        editForm,
        cancelEditForm: cancelEdit,
        genders,
        saveUserEnabled,
        companyItems,
        companyDropDownEnabled,
        onUserAttributesSelected,
        onUserEventsSelected,
        didGiveDataProcessingConsent
    }
}
