import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, IEventTypeClient, IEventTypeClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"

const pageSize = 20

export const useEventTypes = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const eventTypeClient = useClient<IEventTypeClient>(IEventTypeClientId)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [eventTypes, setEventTypes] = useState<dto.EventType[]>([])
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)


	const onPageRequest = (pageIndex: number) => {
		loadForm({ pageIndex, selectedCompanyId })
		setPageIndex(pageIndex)
	}

	const { pageIndex, pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

	const onEventTypeSelected = useCallback((eventTypeId: string) => {
		history.push("/event-types/" + eventTypeId)
	}, [history])



	const loadForm = useCallback(async (options: { pageIndex: number, selectedCompanyId: string | null }) => {
		try {
			const { pageIndex, selectedCompanyId } = options
			if (selectedCompanyId == null) {
				setEventTypes([])
				return
			}
			setLoading(true)
			setEventTypes([])
			const request = new dto.GetEventTypesRequest()
			request.companyId = selectedCompanyId
			request.paging = new dto.PagingRequest({ pageIndex, pageSize })
			request.sortBy = []
			request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
			const pagedResponse = (await eventTypeClient.getEventTypes(request))
			setEventTypes(pagedResponse.entities)
			setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

		} catch (err) {
			handleError(err, { setGlobalMessage: setGlobalMessage })
		} finally {
			setLoading(false)
		}
	}, [setGlobalMessage, setTotalNumberOfPages, eventTypeClient])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)
				setSelectedCompanyId(companies.length > 0 ? companies[0].id : null)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	useEffect(() => {
		(async () => {
			await loadForm({ pageIndex, selectedCompanyId })
		})()
	}, [selectedCompanyId, pageIndex]);

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies)
	}, [companies])

	const onAddEventType = useCallback(() => {
		history.push("/event-types/add/event-type/")
	}, [history])

	const addNewEventTypeEnabled = useMemo(() => {
		return companies.length > 0
	}, [companies])

	return {
		eventTypes,
		onEventTypeSelected,
		loading,
		companyItems,
		selectedCompanyId,
		onCompanySelected: setSelectedCompanyId,
		pagination,
		onAddEventType,
		addNewEventTypeEnabled
	}
}