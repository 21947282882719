import { SharedDataHelper, dto } from "shared";

export class UserAttributeHelper {
    static getDisplayValue(userAttribute: dto.UserAttribute, userAttributeNames: dto.UserAttributeName[]) {
        if (userAttribute == null) {
            return ""
        }

        if (userAttributeNames == null || userAttributeNames.length === 0) {
            return userAttribute.value
        }

        const attrName = userAttributeNames.find(x => x.name === userAttribute.name);

        if (attrName == null) {
            return userAttribute.value
        }

        switch (attrName.attributeType) {
            case dto.UserAttributeNameType.date:
                try {
                    return SharedDataHelper.getFormattedDate(
                        SharedDataHelper.parseISO8601(userAttribute.value), "dd.MM.yyyy HH:mm");
                }
                catch { }
                break;
            case dto.UserAttributeNameType.exists:
                return ""

        }

        return userAttribute.value
    }

    static getDisplayDescription(userAttribute: dto.UserAttribute, userAttributeNames: dto.UserAttributeName[]) {
        if (userAttribute == null) {
            return ""
        }

        if (userAttributeNames == null || userAttributeNames.length === 0) {
            return userAttribute.description
        }

        const attrName = userAttributeNames.find(x => x.name === userAttribute.name);

        if (attrName == null) {
            return userAttribute.description
        }

        switch (attrName.attributeType) {
            case dto.UserAttributeNameType.date:
                try {
                    return SharedDataHelper.getFormattedDate(
                        SharedDataHelper.parseISO8601(userAttribute.description), "dd.MM.yyyy HH:mm");
                }
                catch { }
                break;
        }

        return userAttribute.description
    }


    static isValidValue(value: string, userAttributeName: dto.UserAttributeName): boolean {
        if (value == null || value.trim() === "") {
            return false
        }

        switch (userAttributeName.attributeType) {
            case dto.UserAttributeNameType.exists:
                return value === "true"
            case dto.UserAttributeNameType.date:
                try {
                    SharedDataHelper.parseISO8601(value)
                    return true
                } catch {
                    return false
                }
            case dto.UserAttributeNameType.text:
                return true
            case dto.UserAttributeNameType.number:
                return SharedDataHelper.isStringValidNumber(value)
            case dto.UserAttributeNameType.select:
                const selectValues = userAttributeName.selectValues ?? []
                return selectValues.find(x => x === value) != null;
            default:
                throw new Error("Invalid attributeType:" + userAttributeName.attributeType)
        }
    }

    static getDefaultValue(userAttributeName: dto.UserAttributeName): string {
        switch (userAttributeName.attributeType) {
            case dto.UserAttributeNameType.exists:
                return "true"
            case dto.UserAttributeNameType.date:
                return new Date().toISOString();
            case dto.UserAttributeNameType.text:
                return ""
            case dto.UserAttributeNameType.number:
                return "0"
            case dto.UserAttributeNameType.select:
                return (userAttributeName.selectValues?.length ?? 0) > 0 ? userAttributeName.selectValues![0] : "";
            default:
                throw new Error("Invalid attributeType:" + userAttributeName.attributeType)
        }
    }
}