

const eventPaths = {
    base: "/events",
    events: "",
    event: "/:eventid",
    uploadExcelFile: "/:companyid/upload-excel-file",
}

export { eventPaths as event }

