import { SharedDataHelper, ws as wsDto, createPromiseWithTimeout } from "shared"

let WebSocket = require('isomorphic-ws');

if (typeof window !== "undefined") {
	WebSocket = window.WebSocket
}

export class WSListener {
	id: string
	type: wsDto.WebSocketResponseType
	callback: (response: wsDto.WebSocketResponse) => void
	constructor(id: string, type: wsDto.WebSocketResponseType, callback: (response: wsDto.WebSocketResponse) => void) {
		this.id = id
		this.type = type
		this.callback = callback
	}
}

export class WSClient {
	private client: any
	private listeners: WSListener[] = []
	private websocket: any
	private _isConnected = false

	static readonly ConnectionFailedErrorMessage = "ConnectionFailedErrorMessage"
	static readonly SendRequestTimeoutErrorMessage = "SendRequestTimeoutErrorMessage"

	private pendingResponsePromises: { promiseResolve: (value: unknown) => void, requestId: string }[] = []

	constructor() {
		this.listeners = []
	}

	addListener(listener: WSListener) {
		this.removeListener(listener.id)
		this.listeners.push(listener)
	}

	removeListener(id: string) {
		this.listeners = this.listeners.filter(x => x.id != id)
	}

	get isConnected(): boolean {
		return this._isConnected
	}

	async connect(url: string): Promise<void> {
		this._isConnected = false
		this.pendingResponsePromises = []

		const self = this
		const promise = new Promise<void>((resolve, reject) => {

			self.websocket = new WebSocket(url);
			self.websocket.onopen = () => {
				console.log();
				console.log('connected');
				self._isConnected = true
				resolve()
			};

			self.websocket.onclose = () => {
				self._isConnected = false
				self.pendingResponsePromises = []
				reject()
			};

			self.websocket.onerror = () => {
				self._isConnected = false
				self.pendingResponsePromises = []
				reject(new Error(WSClient.ConnectionFailedErrorMessage))
			};

			self.websocket.onmessage = (data) => {
				const message = data.data
				const response: wsDto.WebSocketResponse = JSON.parse(message);
				if (response.contextId != null) {
					const pendingResponsePromise = self.findPendingPromise(response.contextId)
					pendingResponsePromise?.promiseResolve(response);
				}
				for (const listener of self.listeners) {
					if (response.type != null && listener.type == response.type) {
						listener.callback(response)
					}
				}
			};
		});

		await createPromiseWithTimeout(promise, 3000, { timeoutMessage: WSClient.SendRequestTimeoutErrorMessage })

	}

	close() {
		this.websocket?.close()
		this.websocket = null
		this._isConnected = false
	}

	async sendRequest<T>(req: Omit<wsDto.WebSocketRequest, "requestId">, options?: { timeout?: number }): Promise<T> {
		if (!this._isConnected) {
			throw new Error("WS Client is not connected")
		}
		const wsRequest = req as any as wsDto.WebSocketRequest
		if (wsRequest.requestId == null) {
			wsRequest.requestId = SharedDataHelper.randomString(20)
		}

		let promiseResolve: (value: wsDto.WebSocketAuthResponse) => void
		const promise = new Promise<wsDto.WebSocketAuthResponse>((resolve, reject) => {
			promiseResolve = resolve
		});

		this.pendingResponsePromises.push({ promiseResolve, requestId: wsRequest.requestId })
		this.websocket.send(JSON.stringify(wsRequest));

		try {
			const authResponse: wsDto.WebSocketAuthResponse = await createPromiseWithTimeout(promise, options?.timeout ?? 3000, { timeoutMessage: WSClient.SendRequestTimeoutErrorMessage })
			return authResponse as T
		} finally {
			this.removePendingPromise(wsRequest.requestId)
		}
	}

	private removePendingPromise(contextId: string) {
		if (contextId != null) {
			this.pendingResponsePromises = this.pendingResponsePromises.filter(x => x.requestId != contextId)
		}
	}

	private findPendingPromise(contextId: string) {
		if (contextId != null) {
			return this.pendingResponsePromises.find(x => x.requestId == contextId)
		}
		return null
	}
}