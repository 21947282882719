import { FormikErrors } from "formik";
import { useMemo } from "react";
import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { IUserClient, IUserClientId, IUserInvitationClient, IUserInvitationClientId } from "shared-client";
import { handleError } from "../../../../common/form/form";
import { useAppContext } from "../../../hook/use-app-context";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import useClient from "../../../hook/useClient";
import { useLocalization } from "../../../hook/use-localization";



// only status is allowed to be changed
interface Model {
	firstName: string,
	lastName: string,
	email: string,
	status: string;
	companyName: string,
}

export type UserReceivedInvitationModel = Model

const createModel = (userInvitation: dto.UserInvitation, status: string): Model => {
	return {
		firstName: userInvitation.user.firstName,
		lastName: userInvitation.user.lastName,
		email: userInvitation.user.email,
		status: status,
		companyName: userInvitation.company.name,
	}
}

export const useUserReceivedInvitation = () => {
	const { t } = useLocalization();
	let { id } = useParams<{ id: string }>();
	const history = useHistory()
	const { setUser } = useAppContext()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [editMode, setEditMode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [userInvitation, setUserInvitation] = useState<dto.UserInvitation | null>(null)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const userClient = useClient<IUserClient>(IUserClientId)
	const client = useClient<IUserInvitationClient>(IUserInvitationClientId)


	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				const userInvitation = await client.getUserInvitation(id)
				const status = t("UserStatus." + SharedDataHelper.ensureFirstLetterUppercase(userInvitation.statusName))
				const model = createModel(userInvitation, status)
				setModelInitialValues(model)
				setUserInvitation(userInvitation)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const errorModel: FormikErrors<Model> = {}
		return errorModel
	}, [])

	const onAcceptUserInvitation = useCallback(() => {
		(async () => {
			try {
				setGlobalMessage(null)
				setLoading(true)
				await client.acceptUserInvitation(id)
				const user = await userClient.getCurrentUser()
				setUser(user)
				history.replace("/user-received-invitations")
				setGlobalMessage({ message: t("InvitationAccepted"), type: "success" })
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, client, history, setGlobalMessage, userClient, setUser, t])

	const onRejectUserInvitation = useCallback(() => {
		(async () => {
			try {
				setGlobalMessage(null)
				setLoading(true)
				await client.rejectUserInvitation(id)
				history.replace("/user-received-invitations")
				setGlobalMessage({ message: t("InvitationRejected"), type: "success" })
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, client, history, setGlobalMessage])

	const isPending = useMemo(() => {
		return userInvitation?.statusName === dto.UserInvitationStatusName.pending
	}, [userInvitation])


	return {
		modelInitialValues,
		onValidate,
		editMode,
		setEditMode,
		loading,
		onAcceptUserInvitation,
		onRejectUserInvitation,
		isPending
	}
}


