import { useField, useFormikContext } from "formik";
import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import "./email-template.scss";
import { TemplateMarkups } from "../../components/template-markups/template-markups";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { dto } from "shared";
import { useHistory } from "react-router-dom";
import { Button } from "../../common/button/Button";
import { useLocalization } from "../../../hook/use-localization";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    name: string;
    designName: string;
    isReadonly: boolean;
    showMarkups: boolean;
    userAttributeNames: dto.UserAttributeName[],
    useEventMarkups: boolean,
    onAskPredefinedTemplates: () => void
    design: string | null

}
export const EmailTemplate: React.FC<Props> = (props) => {
    const { t } = useLocalization();
    const { label, name, isReadonly, designName, showMarkups, userAttributeNames, useEventMarkups, onAskPredefinedTemplates, design } = props;
    const [, meta] = useField(name);
    const [fieldDesign] = useField(designName);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [isDesignLoaded, setIsDesignLoaded] = useState(false);

    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (isEditorReady && design !== null) {
            const unlayer = emailEditorRef.current?.editor;
            const designObj = JSON.parse(design);
            unlayer?.loadDesign(designObj);
        }
    }, [design, isEditorReady])

    const emailEditorRef = useRef<EditorRef>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            console.log(html)
            setFieldValue(designName, design != null ? JSON.stringify(design) : null);
            setFieldValue(name, html);
        });
    };

    const onReady: EmailEditorProps["onReady"] = (unlayer) => {
        setIsEditorReady(true);
    };

    useEffect(() => {
        if (isEditorReady) {
            setTimeout(() => {
                const unlayer = emailEditorRef.current?.editor;
                if (fieldDesign.value != null) {
                    const designObj = JSON.parse(fieldDesign.value)
                    unlayer?.loadDesign(designObj);
                }
                setIsDesignLoaded(true);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditorReady]);

    useEffect(() => {
        if (!isDesignLoaded) {
            return;
        }
        const interval = setInterval(() => {
            exportHtml();
        }, 3000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesignLoaded]);

    return (
        <div className="email-template__container">
            <div>{label}</div>
            {!isReadonly && showMarkups && <TemplateMarkups useEventMarkups={useEventMarkups} userAttributeNames={userAttributeNames} context="email" />}
            <div className="editor">
                {!isReadonly && <Button className="predefined-templates-btn" onClick={onAskPredefinedTemplates} >{t("PredefinedTemplates")}</Button>}

                <EmailEditor
                    ref={emailEditorRef}
                    options={{ safeHtml: true }}
                    onReady={onReady}
                />
                {isReadonly && <div className="editor-readonlyOverlay">&nbsp;</div>}
            </div>
            {meta.touched && meta.error ? (
                <div className="error-text">{meta.error}</div>
            ) : null}

        </div>
    );
};
