import { useEffect, useState } from "react"
import { dto } from "shared"
import { INotificationTemplateClient, INotificationTemplateClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import useClient from "../../../hook/useClient"



export const usePredefinedNotificationTemplates = () => {
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const notificationTemplateClient = useClient<INotificationTemplateClient>(INotificationTemplateClientId)
    const [predefinedTemplates, setPredefinedTemplates] = useState<dto.PredefinedNotificationTemplate[]>([])



    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setPredefinedTemplates([])
                const predefinedTemplates = await notificationTemplateClient.getPredefinedTemplates()
                setPredefinedTemplates(predefinedTemplates)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    return {
        loading,
        predefinedTemplates,
    }
}