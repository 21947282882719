import { IsArray, IsOptional, ValidateNested } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { BusinessConfiguration } from "../business-configuration/business-configuration";
import { Type } from "class-transformer";

export class AddEventTypeRequest {

    @ContainsValidString()
    companyId: string;

    @ContainsValidString()
    name: string;

    @ContainsValidString()
    description: string;

    @IsOptional()
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => BusinessConfiguration.TimeDifference)
    sendNotificationsBefore: BusinessConfiguration.TimeDifference[] | null
}