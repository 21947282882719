import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserRepository } from "./i-user-repository";
import { BaseNetworkRepository } from "../../base/base-network-repository"

@injectable()
export class UserRepository extends BaseNetworkRepository implements IUserRepository {

    async getCurrentUser(): Promise<dto.User> {
        const response = await this.protectedAxios({ method: 'get', url: apiPaths.user.user.currentUser });
        return response.data as dto.User;
    }

    async getUser(userId: string): Promise<dto.User> {
        const response = await this.protectedAxios({ method: 'get', url: `${apiPaths.user.user.users}/${userId}` });
        return response.data as dto.User;
    }

    async getUsers(request: dto.GetUsersRequest): Promise<dto.PagingResponse<dto.User>> {
        const response = await this.protectedAxios({ method: 'get', url: apiPaths.user.user.users, params: request });
        return response.data as dto.PagingResponse<dto.User>;
    }

    async updateUser(userId: string, updateUserRequest: dto.UpdateUserRequest): Promise<void> {
        await this.protectedAxios({ method: 'patch', url: `${apiPaths.user.user.users}/${userId}`, data: updateUserRequest });
    }

    async changePassword(userId: string, request:dto.ChangeUserPasswordRequest): Promise<void>{
        await this.protectedAxios({ method: 'patch', url: apiPaths.user.user.changePassword.replace(":userid",userId), data: request });
    }

    async changeStatus(userId: string, request:dto.ChangeUserStatusRequest): Promise<void>{
        await this.protectedAxios({ method: 'patch', url: apiPaths.user.user.changeStatus.replace(":userid",userId), data: request });
    }
}