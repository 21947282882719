import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IEventClient } from "./i-event-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class EventClient extends BaseClient implements IEventClient {

    async getEvents(data: dto.GetEventsRequest): Promise<dto.PagingResponse<dto.Event>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.event.base + apiPaths.event.events), params: data });
            return response.data as dto.PagingResponse<dto.Event>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getEvent(eventId: string): Promise<dto.Event> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.event.base + apiPaths.event.event.replace(":eventid", eventId)) });
            return response.data as dto.Event;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addEvent(data: dto.AddEventRequest): Promise<dto.Event> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.event.base + apiPaths.event.events), data: data });
            return response.data as dto.Event;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateEvent(eventId: string, data: dto.UpdateEventRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.event.base + apiPaths.event.event.replace(":eventid", eventId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteEvent(eventId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.event.base + apiPaths.event.event.replace(":eventid", eventId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async uploadEventsUsingExcel(companyId: string, data: dto.UploadEventsByExcelFileRequest): Promise<dto.UploadEventsByExcelFileResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.event.base + apiPaths.event.uploadExcelFile.replace(":companyid", companyId)), data: data });
            return response.data as dto.UploadEventsByExcelFileResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}