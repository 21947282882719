import { ArrayMinSize, IsEnum, IsOptional, Matches, ValidateNested } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { LambdaExpression } from "../../../validators/lambda-expression";



export enum UserAttributeQueryTypeCompare {
  equal = "equal",
  greater = "greater",
  greaterOrEqual = "greaterOrEqual",
  lower = "lower",
  lowerOrEqual = "lowerOrEqual",
  contains = "contains"
}

export enum UserAttributeQueryTypeComposite {
  and = "and",
  or = "or"
}

export enum UserAttributeQueryType {
  equal = "equal",
  greater = "greater",
  greaterOrEqual = "greaterOrEqual",
  lower = "lower",
  lowerOrEqual = "lowerOrEqual",
  and = "and",
  or = "or",
  contains = "contains"
}

export class UserAttributeQueryCondition {

  @IsOptional()
  @ContainsValidString()
  @Matches(/^[^"'_]+$/, {
    message: 'Name Can not contain ", \', _ characters',
  })
  name?: string

  @IsOptional()
  @ContainsValidString()
  @Matches(/^[^"'_]+$/, {
    message: 'Value Can not contain ", \', _ characters',
  })
  value?: string

  @IsOptional()
  @ContainsValidString()
  @Matches(/^[^"'_]+$/, {
    message: 'Description Can not contain ", \', _ characters',
  })
  description?: string

  @IsEnum(UserAttributeQueryType)
  type: UserAttributeQueryType

  @IsOptional()
  @ArrayMinSize(2)
  @ValidateNested({ each: true })
  conditions?: UserAttributeQueryCondition[]

  @LambdaExpression({
    expression: (obj: UserAttributeQueryCondition) => {
      if (obj.type == UserAttributeQueryType.and || obj.type == UserAttributeQueryType.or) {
        return obj.conditions != null && obj.conditions.length > 1 &&
          obj.name === undefined && obj.value === undefined && obj.description === undefined
      } else {
        const valid = obj.conditions === undefined && obj.name != null &&
          (
            (obj.value != null && obj.description === undefined) ||
            (obj.description != null && obj.value === undefined)
          )
        return valid
      }
    }
  })
  __metaValidation?: true

}




