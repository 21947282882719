
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsCompanyStatusConstraint implements ValidatorConstraintInterface {

    private validCompanyStatuses = [1, 2];

    validate(text: any, args: ValidationArguments) {
        if (text != null && typeof text === "number") {
            return this.validCompanyStatuses.find(x => x == text) != null;
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        const name = "Value"
        return `${name} is not valid company status. Should be: ${this.validCompanyStatuses}`;
    }
}

export function IsCompanyStatus(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsCompanyStatusConstraint
        });
    };
}