import { FormikErrors } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { dto } from "shared";
import { ICompanyClient, ICompanyClientId, IEventClient, IEventClientId } from "shared-client";
import { handleError, submitWrapper } from "../../../../common/form/form";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useLocalization } from "../../../hook/use-localization";



// only status is allowed to be changed
interface Model {
    companyId: string | null,
    readonly: boolean,
    formData: FormData | null,
    validEventsRecords: { jobId: string, data: any }[] | null
    invalidEventsRecords: { errorMessage: string, data: any }[] | null,
    companies: dto.Company[],
    originalModel: Omit<Model, "originalModel"> | null
}

export type EventsUploadExcelModel = Model

const createModel = (
    companies: dto.Company[],
    selectedCompanyId: string | null,
    readonly: boolean,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        formData: null,
        companyId: selectedCompanyId,
        validEventsRecords: null,
        invalidEventsRecords: null,
        companies: companies,
        originalModel: originalModel ?? null
    }
}


export const useEventsUploadExcel = () => {

    const history = useHistory()
    const { t } = useLocalization();
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const eventClient = useClient<IEventClient>(IEventClientId)
    const [formKey, setFormKey] = useState(0);

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                let companies = await companyClient.getCurrentCompanies()
                let readonly = false
                let companyId: string | null = CompanyHelper.getDefaultCompany(companies)

                const originalModel = createModel(companies, companyId, readonly)
                const model = createModel(companies, companyId, readonly, originalModel)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage, history })
            } finally {
                setLoading(false)
            }
        })()
    }, [setLoading, setGlobalMessage, setModelInitialValues, companyClient, history])


    useEffect(() => {
        loadInitialModel()
    }, [])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        return {}
    }, [])


    const onSubmit = useCallback(async (formData: FormData) => {
        await submitWrapper<Model>(async () => {
            const request = formData as any
            const response = await eventClient.uploadEventsUsingExcel(modelInitialValues!.companyId!, request)
            const modelCopy = { ...modelInitialValues! }
            modelCopy.invalidEventsRecords = response.invalidRecords
            modelCopy.validEventsRecords = response.validRecords
            setModelInitialValues(modelCopy)
            setFormKey(formKey + 1)
            setGlobalMessage({ message: t("Excel.Uploaded"), type: "success" })
        }, { setFieldError: () => { }, setGlobalMessage, fieldNames: [] })
    }, [setGlobalMessage, t, eventClient, modelInitialValues, formKey])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        formKey
    }
}

export const useEventsUploadExcelForm = () => {
    const { model } = useTypedFormikContext<Model>()

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies)
    }, [model.companies])

    return {
        companyItems
    }
}
