import { IsOptional } from "class-validator";
import { AddNotificationTaskDetails } from "../add-notification-task-details";
import { ContainsValidString } from "../../../../../validators/contains-valid-string"
import { IsMobilePhoneNumber } from "../../../../../validators/is-mobile-phone-number"


export class SmsAddNotificationTaskDetails extends AddNotificationTaskDetails {
    @IsOptional()
    @IsMobilePhoneNumber()
    mobilePhoneNumber: string | null


    @ContainsValidString()
    messageToSend: string

    __type: "SmsAddNotificationTaskDetails"


    constructor(options: { mobilePhoneNumber: string | null, messageToSend: string }) {
        super();
        if (options == null) {
            return
        }
        this.mobilePhoneNumber = options.mobilePhoneNumber
        this.messageToSend = options.messageToSend
    }
}