import React from "react"
import "./danger-zone.scss"

interface Props {

}

export const DangerZone: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const { children } = props
	return (
		<div className="alert alert-danger zone-container indent-container" role="alert">
			{children}
		</div>
	)
}