import React, { useEffect } from "react";
import { dto, notificationTemplateMarkups } from "shared";
import "./template-markups.scss";
import { copyElementInnerText } from "../../../../common/form/copy";
import { useLocalization } from "../../../hook/use-localization";

interface Props {
    context: "sms" | "email"
    userAttributeNames: dto.UserAttributeName[],
    useEventMarkups: boolean,
    useOnlyUnsubscribeMarkups?: boolean
}

export const TemplateMarkups: React.FC<Props> = ({ context, userAttributeNames, useEventMarkups, useOnlyUnsubscribeMarkups }) => {
    const { t } = useLocalization();

    const filteredAttributes = (userAttributeNames ?? []).filter(x => !(["gender", "dateOfBirth"].includes(x.name))).map(x => `##${x.name}##`)
    const eventMarkups = useEventMarkups ? notificationTemplateMarkups.allEventMarkups : []
    const items = (useOnlyUnsubscribeMarkups ? [] : notificationTemplateMarkups.allNameMarkups).concat(eventMarkups).concat(filteredAttributes).map((x) => {
        const contentId = "div_" + x.replaceAll("#", "");
        return (
            <li key={x}>
                <div id={contentId}>{x}</div>
                <img
                    onClick={() => copyElementInnerText(contentId)}
                    src="/copy.svg"
                    alt="Copy"
                    title="Copy"
                ></img>
            </li>
        );
    });

    const unsubscribeItems = Object.values(context === "email" ? dto.EmailUnsubscribeMarkup : dto.SmsUnsubscribeMarkup).map((x) => {
        const contentId = "div_unsubscribe_" + x.replaceAll("#", "");
        return (
            <li key={x}>
                <div id={contentId}>##{x}##</div>
                <img
                    onClick={() => copyElementInnerText(contentId)}
                    src="/copy.svg"
                    alt="Copy"
                    title="Copy"
                ></img>
            </li>
        );
    });


    return (
        <div className="templates-markups__container">
            {useOnlyUnsubscribeMarkups == null && <div>{t("AvailableMarkups")} </div>}
            <ul>{items}</ul>
            <div>{t("Unsubscribe.AvailableMarkups")} </div>
            <ul>{unsubscribeItems}</ul>
        </div>
    );
};
