import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { IUserClient, IUserClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate } from "class-validator";
import { roleDescriptions, useUserRole } from "../../../hook/useUserRole";
import { UserHelper } from "../../../_common/helpers/user-helper";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";


// only status is allowed to be changed
interface Model {
	userId: string,
	firstName: string,
	lastName: string,
	email: string,
	mobilePhoneNumber: string,
	dateOfBirth: Date,
	gender: dto.UserGender,
	status: dto.UserStatus;
	readonly: boolean,
	roleDescription: string,
	companyName: string,
	originalModel: Omit<Model, "originalModel"> | null
}

export type UserModel = Model



const createModel = (user: dto.User, t: (text: string) => string, originalModel?: Model | null): Model => {
	const companyNames = user.roles.filter(x => x.company?.name != null).map(r => r.company.name)
	const companyName = Array.from(new Set(companyNames)).toString()
	const companyRoles = UserHelper.getCompanyRoles(user)!
	return {
		readonly: true,
		userId: user.id,
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		mobilePhoneNumber: SharedDataHelper.formatMobilePhoneNumber(user.mobilePhoneNumber),
		gender: user.gender,
		dateOfBirth: user.dateOfBirth,
		status: user.status,
		companyName: companyName,
		roleDescription: roleDescriptions(companyRoles, t),
		originalModel: originalModel ?? null
	}
}

const createUpdateStatusRequest = (model: Model): dto.ChangeUserStatusRequest => {
	const request = new dto.ChangeUserStatusRequest()
	request.status = model.status
	return request
}

export const useUser = () => {
	let { id } = useParams<{ id: string }>();
	const history = useHistory()
	const { t } = useLocalization()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [editMode, setEditMode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [user, setUser] = useState<dto.User | null>(null)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const client = useClient<IUserClient>(IUserClientId)
	const { isAdmin } = useUserRole()

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				const user = await client.getUser(id)
				const originalModel = createModel(user, t)
				const model = createModel(user, t, originalModel)
				setModelInitialValues(model)
				setUser(user)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, setLoading, setGlobalMessage, setModelInitialValues, client, t])


	useEffect(() => {
		loadInitialModel()
	}, [])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const errorModel: FormikErrors<Model> = {}
		const request = createUpdateStatusRequest(model)
		let validateErrors = await validate(request);
		setFieldErrors<Model>(validateErrors, ["status"], errorModel)
		return errorModel
	}, [])


	const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			const request = createUpdateStatusRequest(model)
			await client.changeUserStatus(id, request)
			loadInitialModel()
		}, { setFieldError, setGlobalMessage, fieldNames: ["status"] })
	}, [id, client, loadInitialModel, setGlobalMessage])

	const onDeleteUser = useCallback(() => {
		(async () => {
			try {
				setGlobalMessage(null)
				setLoading(true)
				await client.deleteUser(id)
				history.replace("/users")
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [id, client, history, setGlobalMessage])

	const enabledDeleteUser = useMemo((): boolean => {
		if (user != null) {
			return !isAdmin(user)
		}
		return false
	}, [user, isAdmin])

	const onEditUserRoles = useCallback(() => {
		history.push(`/users/${id}/user-roles`)
	}, [history, id])

	const hasCompanyRole = useMemo(() => {
		return UserHelper.hasCompanyRoles(user)
	}, [user])

	return {
		modelInitialValues,
		onSubmit,
		onValidate,
		editMode,
		setEditMode,
		loading,
		onDeleteUser,
		enabledDeleteUser,
		onEditUserRoles,
		hasCompanyRole
	}
}

export const useUserForm = () => {
	const { t } = useLocalization()
	const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

	const statuses = useMemo(() => {
		return [
			{ text: t("Active"), value: dto.UserStatus.active },
			{ text: t("Blocked"), value: dto.UserStatus.blocked }]
	}, [])

	const genders = useMemo(() => {
		return [
			{ text: t("Male"), value: dto.UserGender.male },
			{ text: t("Female"), value: dto.UserGender.female }]
	}, [t])

	const editForm = useCallback(() => {
		setFieldValue("readonly", false, false)
	}, [setFieldValue])

	const cancelEdit = useCallback(() => {
		cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
	}, [setFormValues, setErrors, model])

	return {
		editForm,
		cancelEditForm: cancelEdit,
		statuses,
		genders
	}
}
