import { useEffect } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { dto } from "shared";
import { IAuthClientId, IAuthClient, Config, repository, IUserClientId, IUserClient } from "shared-client"
import { validate } from "class-validator";
import { setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { FormikErrors, FormikHelpers } from "formik";
import useClient from "../../../hook/useClient";
import { useAppContext } from "../../../hook/use-app-context";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";

const PathBasic = "signin"
const PathInitial = `/${PathBasic}/initial`;
const PathChallengeToken = `/${PathBasic}/challenge-token`;

interface Model {
  email: string,
  password: string,
  showPassword: boolean,
  smsToken: string,
  sentSmsTokenMessageInfo: string
}

export type LoginModel = Model

const _createInitLoginRequest = (model: Model) => {
  const dtoRequest = new dto.InitLoginRequest()
  dtoRequest.grantType = "password"
  dtoRequest.clientId = Config.OAuthClientId
  dtoRequest.email = model.email
  dtoRequest.password = model.password
  return dtoRequest
}


export const useLogin = () => {
  const { t } = useLocalization();
  const history = useHistory();
  const { pathname } = useLocation()
  const { setUser } = useAppContext()
  const { setGlobalMessage } = useGlobalMessageContext()
  const authClient = useClient<IAuthClient>(IAuthClientId)
  const userClient = useClient<IUserClient>(IUserClientId)
  const tokenRepository = useClient<repository.ITokenRepository>(repository.ITokenRepositoryId)

  useEffect(() => {
    if (pathname !== PathInitial) {
      history.replace(PathInitial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues: Model = useMemo(() => {
    return {
      email: "",
      password: "",
      showPassword: false,
      smsToken: "",
      sentSmsTokenMessageInfo: ""
    }
  }, []);



  const createInitLoginRequest = useCallback(_createInitLoginRequest, [])

  const createTokenRequest = useCallback((model: Model) => {
    const dtoRequest = new dto.OAuthPasswordGrant()
    dtoRequest.grantType = "password"
    dtoRequest.clientId = Config.OAuthClientId
    dtoRequest.email = model.email
    dtoRequest.password = model.password
    dtoRequest.smsToken = model.smsToken
    return dtoRequest
  }, [])


  const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
    const errorModel: FormikErrors<Model> = {}
    switch (pathname.toLowerCase()) {
      case PathInitial:
        const initialRequest = createInitLoginRequest(model)
        let validateErrorsInitial = await validate(initialRequest);
        setFieldErrors<Model>(validateErrorsInitial, ["email", "password"], errorModel)
        break
      case PathChallengeToken:
        const tokenRequest = createTokenRequest(model)
        let validateErrorsToken = await validate(tokenRequest);
        setFieldErrors<Model>(validateErrorsToken, ["smsToken"], errorModel)
        break
    }
    return errorModel
  }, [createInitLoginRequest, createTokenRequest, pathname])


  const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
    const _pathname = pathname.toLowerCase()
    if (_pathname === PathInitial) {
      await submitWrapper<Model>(async () => {
        const initLoginRequest = createInitLoginRequest(model)
        const response = await authClient.initLogin(initLoginRequest)
        const success = response?.success ?? false
        if (success) {
          setFieldValue("smsToken", "", false)
          const msgKey = "SMS message with token is sent to your phone number"
          if (response.message?.startsWith(msgKey)) {
            const phone = response.message.replace(msgKey, "").trim()
            setFieldValue("sentSmsTokenMessageInfo", `${t(msgKey)}${phone}`, false)
          }
          else {
            setFieldValue("sentSmsTokenMessageInfo", response.message, false)
          }
          history.push(PathChallengeToken)
        } else {
          setGlobalMessage({ type: "error", message: response?.message ?? "Error login" })
        }
      }, { setFieldError, setGlobalMessage, fieldNames: ["email", "password"] })
    }

    if (pathname === PathChallengeToken) {
      await submitWrapper<Model>(async () => {
        const tokenRequest = createTokenRequest(model)
        const response = await authClient.token(tokenRequest)
        tokenRepository.saveAccessToken(response.accessToken!)
        tokenRepository.saveRefreshToken(response.refreshToken!)
        const user = await userClient.getCurrentUser()
        setGlobalMessage(null)
        setUser(user)
        history.replace("/")
      }, { setFieldError, setGlobalMessage, fieldNames: ["smsToken"] })
    }
  }, [authClient, userClient, tokenRepository, createInitLoginRequest, createTokenRequest, setGlobalMessage, history, pathname, setUser])



  return {
    paths: {
      PathInitial,
      PathChallengeToken
    },
    initialValues,
    onSubmit,
    validate: onValidate,
  }
}

export const useLoginInitial = () => {
  const history = useHistory()
  const { setGlobalMessage } = useGlobalMessageContext()
  const { setFieldValue, values: model } = useTypedFormikContext<Model>()

  const onRegisterUserRequested = useCallback(() => {
    setGlobalMessage(null)
    history.push("/registration/initial")
  }, [history, setGlobalMessage])

  const onRecoverPasswordRequested = useCallback(() => {
    setGlobalMessage(null)
    history.push("/recover-password")
  }, [history, setGlobalMessage])



  const toggleShowPassword = useCallback(() => {
    setFieldValue("showPassword", !model.showPassword)
  }, [setFieldValue, model.showPassword])

  return {
    toggleShowPassword,
    onRecoverPasswordRequested,
    onRegisterUserRequested
  }
}

export const useUserRecoverPasswordChallengeToken = () => {
  const { setGlobalMessage } = useGlobalMessageContext()
  const authClient = useClient<IAuthClient>(IAuthClientId)
  const { values: model } = useTypedFormikContext<Model>()

  const resendToken = useCallback(async () => {
    const initLoginRequest = _createInitLoginRequest(model)
    let statusResponse = await authClient.initLogin(initLoginRequest)
    let success = statusResponse?.success ?? false
    if (success) {
      setGlobalMessage({ type: "success", message: "Sms token is resent" })
    } else {
      setGlobalMessage({ type: "error", message: statusResponse?.message ?? "General error" })
    }
  }, [setGlobalMessage, model, authClient])

  return {
    resendToken
  }
}




