import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { Button } from "../../../_common/common/button/Button"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import { useUserInvitation } from "./useUserInvitation"
import "./user-invitation.scss"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const UserInvitation: React.FC = () => {
	const { t } = useLocalization();
	const { modelInitialValues, onValidate, loading, onDeleteUserInvitation, deleteEnabled } = useUserInvitation()
	const confirmationDeleteRef = useRef<ConfirmationHandle>(null);
	return (
		<Shell pageTitle={t("UserInvitation")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={() => { }}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>

				{deleteEnabled && <DangerZone>
					<div className="delete-title">{t("DeleteInvitation")}</div>
					<Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
					<div className="delete-message">{t("DeleteInvitationDescription")}</div>
					<Confirmation level="require-number-confirmation" body={t("Confirmation.Delete")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteUserInvitation()} />
				</DangerZone>}
			</>
			}
		</Shell>
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization();
	return (
		<div className="indent-container">
			<TextInput name="firstName" disabled={true} label={t("FirstName")} />
			<TextInput name="lastName" disabled={true} label={t("LastName")} />
			<TextInput name="email" disabled={true} label={t("Email")} />
			<TextInput name="companyName" disabled={true} label={t("Company")} />
			<TextInput name="status" disabled={true} label={t("Status")} />
			<div className="action-buttons-row">
			</div>
		</div>)
}
