import { useMemo } from "react";
import { repository } from "shared-client"
import { browserContainer } from "../../setup/inversify-config";

function useTokenRepository(): repository.ITokenRepository {
	const tokenRepository = useMemo((): repository.ITokenRepository => {
		return browserContainer.get(repository.ITokenRepositoryId)
	}, [])
	return tokenRepository
}

export default useTokenRepository;


