import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SharedDataHelper } from 'shared';

function parseValidationMessageMessage(regex: RegExp, message: string): {
    key: string;
} | null {
    let match = message.match(regex);
    if (match) {
        const [, keyValue] = match;
        return {
            key: keyValue,
        };
    }

    return null;
}

function parseMinMaxValidationMessage(message: string): {
    key: string;
    num: number;
    transKey: string
} | null {
    let regex = /(.+) must be longer than or equal to (\d+) characters/
    let match = message.match(regex);
    let transKey: string | null = null
    if (match) {
        transKey = "MinLengthValidationMessage"
    }
    if (!match) {
        regex = /(.+) must be shorter than or equal to (\d+) characters/
        match = message.match(regex);
        if (match) {
            transKey = "MaxLengthValidationMessage"
        }
    }

    if (match) {
        const [, keyValue, numValueStr] = match;
        const numValue = parseInt(numValueStr, 10);

        if (!isNaN(numValue)) {
            return {
                key: keyValue,
                num: numValue,
                transKey: transKey!
            };
        }
    }

    return null;
}

export function useLocalization() {

    const { t, i18n } = useTranslation();


    const translate = useCallback((_key: string | null, options?: string | {}) => {
        if (SharedDataHelper.stringIsNullTrimEmpty(_key)) {
            return ""
        }
        let key = _key!

        if (key.startsWith("MessageError->")) {
            const msgError = key.slice("MessageError->".length);
            const parts = msgError.split("->")
            if (parts.length === 2) {
                return `${t(parts[0])} ${parts[1]}`
            }
        }

        if (key.endsWith(".")) {
            key = key.slice(0, -1);
        }

        const minMaxObj = parseMinMaxValidationMessage(key)
        if (minMaxObj != null) {
            const name = t(minMaxObj.key)
            const msg = t(minMaxObj.transKey, { key: name, num: minMaxObj.num });
            return msg
        }


        const regObjList: { regex: RegExp, key: string }[] = [{
            regex: /(.+) must be a Date instance/,
            key: "DateValidationMessage"
        }, {
            regex: /(.+) must be a valid enum value/,
            key: "SelectValueInvalidDataMessage"
        }];

        for (const regObj of regObjList) {
            let valObj = parseValidationMessageMessage(regObj.regex, key);
            if (valObj != null) {
                const name = t(valObj.key)
                const msg = t(regObj.key, { key: name });
                return msg
            }
        }

        return t(key)
    }, [t])

    const setLanguage = useCallback((lang: string) => {

        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 10);
        document.cookie = `${"Language-DS"}=${lang};expires=${expires.toUTCString()};path=/`;
        i18n.changeLanguage(lang);
    }, [i18n]);

    return {
        t: translate,
        setLanguage,
        language: i18n.language
    }
}