import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, INotificationTaskBatchClient, INotificationTaskBatchClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { CompanyHelper } from "../../../_common/helpers/company-helper"

const pageSize = 10

export const useNotificationTaskBatches = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const notificationTaskBatchClient = useClient<INotificationTaskBatchClient>(INotificationTaskBatchClientId)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [notificationTaskBatchInfos, setNotificationTaskBatchInfos] = useState<dto.NotificationTaskBatchInfo[]>([])
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const { pageIndex, setPageIndex, pagination, setTotalNumberOfPages } = usePagination()
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)

	const onNotificationTaskBatchSelected = useCallback((notificationTaskBatchId: string) => {
		history.push("/notification-task-batches/" + notificationTaskBatchId)
	}, [history])


	useEffect(() => {
		(async () => {
			try {
				if (selectedCompanyId == null) {
					setNotificationTaskBatchInfos([])
					return
				}
				setLoading(true)
				setNotificationTaskBatchInfos([])
				const request = new dto.GetNotificationTaskBachInfosRequest()
				request.companyId = selectedCompanyId
				request.paging = new dto.PagingRequest({ pageIndex, pageSize })
				request.sortBy = []
				request.returnStatistics = true
				request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
				const pagedResponse = (await notificationTaskBatchClient.getNotificationTaskBatchInfos(request))
				setNotificationTaskBatchInfos(pagedResponse.entities)
				setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [pageIndex, selectedCompanyId])

	useEffect(() => {
		setPageIndex(0)
	}, [selectedCompanyId])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)
				const defaultCompanyId = CompanyHelper.getDefaultCompany(companies)
				setSelectedCompanyId(defaultCompanyId)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies)
	}, [companies])

	const onAddNotificationTaskBatch = useCallback(() => {
		history.push("/notification-task-batches/add/notification-task-batch/")
	}, [history])

	const addNewNotificationTaskBatchEnabled = useMemo(() => {
		return companies.length > 0
	}, [companies])

	return {
		notificationTaskBatchInfos,
		onNotificationTaskBatchSelected,
		loading,
		companyItems,
		selectedCompanyId,
		onCompanySelected: setSelectedCompanyId,
		pagination,
		onAddNotificationTaskBatch,
		addNewNotificationTaskBatchEnabled
	}
}