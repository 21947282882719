import { useMemo } from "react";
import { useContext } from "react";
import { dto } from "shared";
import { AppCtx } from "../context/app/app-context";

export const useAppContext = () => {
	const { app, setApp } = useContext(AppCtx);




	const setUser = (user: dto.User | null) => {
		setApp({ ...app, user: user });
	}

	const clearUser = () => {
		setApp({ user: null })
	}

	const isAdmin = useMemo(() => {
		const roles = app?.user?.roles ?? [];
		return roles.find(x => x.roleName === dto.RoleName.admin) != null;
	}, [app?.user?.roles])

	const isUserWithoutCompany = useMemo(() => {
		const roles = app?.user?.roles ?? [];
		return roles.find(x => x.companyId != null) == null;
	}, [app?.user?.roles])

	const isCompanyUser = useMemo(() => {
		const roles = app?.user?.roles ?? [];
		return roles.find(x => x.companyId != null) != null;
	}, [app?.user?.roles])

	const isCompanyAdmin = useMemo(() => {
		const roles = app?.user?.roles ?? [];
		return roles.find(x => x.companyId != null && x.roleName === dto.RoleName.companyAdmin) != null;
	}, [app?.user?.roles])

	const userRoles = useMemo(() => {
		return {
			isAdmin,
			isUserWithoutCompany,
			isCompanyUser,
			isCompanyAdmin
		}
	}, [isAdmin, isUserWithoutCompany, isCompanyUser, isCompanyAdmin])

	return {
		user: app.user,
		userRoles,
		setUser,
		clearUser
	}
}