import React from "react"
import { SharedDataHelper } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import "./received-user-invitations.scss"
import { useUserReceivedInvitations } from "./useReceivedUserInvitations"
import { useLocalization } from "../../../hook/use-localization"


export const UserReceivedInvitations: React.FC = () => {
	const { t } = useLocalization();
	const isMobile = useIsMobile()
	const { userInvitations, onUserInvitationSelected, loading, pagination } = useUserReceivedInvitations()

	const rowClick = (userid: string) => {
		if (isMobile) {
			onUserInvitationSelected(userid)
		}
	}

	const listItems = userInvitations.map((userInvitation) =>
		<div className="user-received-invitations__item data-row-item" key={userInvitation.id} onClick={() => rowClick(userInvitation.id)}>
			<div className="user-received-invitations__item-name-email">
				<div className="user-received-invitations__item-name"><button onClick={() => { onUserInvitationSelected(userInvitation.id) }}>{userInvitation.user.firstName} {userInvitation.user.lastName}</button></div>
				<div className="user-received-invitations__item-email">{userInvitation.user.email}</div>
			</div>
			<div className="user-received-invitations__item-status-company">
				<div className="user-received-invitations__item-status">{t("UserStatus." + SharedDataHelper.ensureFirstLetterUppercase(userInvitation.statusName))}</div>
				<div className="user-received-invitations__item-company">{userInvitation.company.name}</div>
			</div>
		</div>
	);
	return (
		<Shell pageTitle={t("ReceivedInvitations")}>
			<Loading loading={loading} />
			<div className="indent-container user-invitations-header-filter">
				<Pagination {...pagination} />
			</div>
			{listItems}
			{userInvitations.length === 0 && <NoEntities />}
		</Shell >
	)
}