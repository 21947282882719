
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface OnlyOneDefinedOptions {
    properties: (obj: any) => string[]
}

@ValidatorConstraint({ async: true })
export class OnlyOneDefinedConstraint implements ValidatorConstraintInterface {

    async validate(obj: any, args: ValidationArguments) {
        const holder = args.object
        const onlyOneOption: OnlyOneDefinedOptions = args.constraints[0]
        const propertiesCallback: (obj: any) => string[] = onlyOneOption.properties
        const properties = propertiesCallback(holder)
        let countDefined = 0
        for (const propObj of properties) {
            const subProps = (propObj as string).split(".")
            let propVal: any = null
            for (let i = 0; i < subProps.length; i++) {
                let subProp = subProps[i]
                propVal = (i == 0 ? holder : propVal)[subProp]
                if (propVal == null) {
                    break
                }
            }

            if (propVal != null) {
                countDefined++
            }
        }
        return countDefined == 1;
    }

    defaultMessage(args: ValidationArguments) {
        return `Invalid properties. Only one must be defined`;
    }
}

export function OnlyOneDefined(options: OnlyOneDefinedOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [options as any],
            validator: OnlyOneDefinedConstraint
        });
    };
}