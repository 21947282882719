import { User } from "../../user";
import { Event } from "../../event/event";
import { Company } from "../../company/company";
import { NotificationTaskType } from "../notification-task-type";
import { UnregisteredUser } from "../../unregistered-user/unregistered-user";
import { NotificationTaskStatus } from "../notification-task-status";
import { NotificationTaskBatch } from "../notification-task-batch/notification-task-batch";
import { NotificationTaskDetails } from "./notification-task-details";
import { MessageType } from "../../message-type/_index";

export class NotificationTask {

    id: string;

    messageType: MessageType

    details: NotificationTaskDetails

    userId: string;
    user: User;

    unregisteredUserId: string;
    unregisteredUser: UnregisteredUser;

    companyId: string;
    company: Company;

    eventId: string;
    event: Event

    batchId: string
    batch: NotificationTaskBatch

    templateId: string

    notificationTaskType: NotificationTaskType;

    numberOfFailedTry: number

    status: NotificationTaskStatus;

    statusDescription: string

    apiGatewayIdentifier: string

    taskDate: Date;

    expirationDate: Date;

    wasCleanUp: boolean

    numberOfCredits: number

    createdDate: Date;
    updatedDate: Date;
}




