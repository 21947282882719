import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IEventClient, IEventClientId, IUnregisteredUserClient, IUnregisteredUserClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"

const pageSize = 20

export const useEvents = () => {
  const history = useHistory()
  let { unregistereduserid: unregisteredUserId } = useParams<{ unregistereduserid: string | undefined }>();
  const { setGlobalMessage } = useGlobalMessageContext()
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState<dto.Event[]>([])
  const eventClient = useClient<IEventClient>(IEventClientId)
  const companyClient = useClient<ICompanyClient>(ICompanyClientId)
  const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)
  const [companies, setCompanies] = useState<dto.Company[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
  const [unregisteredUser, setUnregisteredUser] = useState<dto.UnregisteredUser | null>(null)
  const today = SharedDataHelper.getDateWithoutTime(new Date());
  const nextDay = SharedDataHelper.addMinutes(SharedDataHelper.addDays(today, 1), -1)
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(nextDay)

  // Load companies
  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        let companies = await companyClient.getCurrentCompanies()
        if (companies.length === 0) {
          history.replace("/bad-request");
          return
        }
        let companyId = companies.length > 0 ? companies[0].id : null
        if (unregisteredUserId != null) {
          const unregisteredUser = await unregisteredUserClient.getUser(unregisteredUserId)
          if (unregisteredUser == null) {
            history.replace("/not-found");
            return
          }
          const company = companies.find(x => x.id === unregisteredUser.createdByCompanyId)
          if (company == null) {
            history.replace("/bad-request");
            return
          }
          companies = [company]
          companyId = company.id
          setUnregisteredUser(unregisteredUser)
        }
        setCompanies(companies)
        setSelectedCompanyId(companyId)
      } catch (err) {
        handleError(err, { setGlobalMessage: setGlobalMessage })
      } finally {
        setLoading(false)
      }
    })()
  }, [])


  const onPageRequest = (pageIndex: number) => {
    loadEvents({ pageIndex, selectedCompanyId, unregisteredUserId: unregisteredUser?.id, dateFrom, dateTo })
    setPageIndex(pageIndex)
  }

  const onDateFromChanged = (dateFrom: Date | null) => {
    setDateFrom(dateFrom!)
    loadEvents({ pageIndex, selectedCompanyId, unregisteredUserId: unregisteredUser?.id, dateFrom, dateTo })
  }

  const onDateToChanged = (dateTo: Date | null) => {
    setDateTo(dateTo!)
    loadEvents({ pageIndex, selectedCompanyId, unregisteredUserId: unregisteredUser?.id, dateFrom, dateTo })
  }

  const { pageIndex, pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

  const onEventSelected = useCallback((eventId: string, unregisteredUserId: string) => {
    history.push(`/unregistered-users/${unregisteredUserId}/events/${eventId}`)
  }, [history])

  const loadEvents = useCallback(async (options: {
    pageIndex: number,
    selectedCompanyId: string | null,
    unregisteredUserId: string | undefined,
    dateFrom: Date | null,
    dateTo: Date | null
  }) => {
    try {
      const { pageIndex, selectedCompanyId, unregisteredUserId, dateFrom, dateTo } = options
      setEvents([])
      if (selectedCompanyId == null) {

        return
      }
      setLoading(true)
      const request = new dto.GetEventsRequest()
      request.dateFrom = dateFrom ?? undefined
      request.dateTo = dateTo ?? undefined
      request.companyId = selectedCompanyId
      request.unregisteredUserId = unregisteredUserId
      request.paging = new dto.PagingRequest({ pageIndex, pageSize })
      request.sortBy = []
      request.sortBy.push(new dto.SortItem({ name: "date", type: "desc" }))
      const pagedResponse = await eventClient.getEvents(request)
      setEvents(pagedResponse.entities)
      setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

    } catch (err) {
      handleError(err, { setGlobalMessage: setGlobalMessage })
    } finally {
      setLoading(false)
    }
  }, [setGlobalMessage, setTotalNumberOfPages, eventClient])



  useEffect(() => {
    (async () => {
      if (selectedCompanyId == null) {
        setEvents([]);
        return
      }
      await loadEvents({ pageIndex, selectedCompanyId, unregisteredUserId, dateFrom, dateTo })
    })()
  }, [selectedCompanyId]);

  const companyItems = useMemo((): CompanyDropdownItem[] => {
    return createCompanyDropDownItems(companies)
  }, [companies])

  const onAddUnregisteredUserEvent = useCallback(() => {
    history.push(`/unregistered-users/${unregisteredUser?.id}/events/add/event`)
  }, [history, unregisteredUser?.id])

  const addNewEventVisible = useMemo(() => {
    return companies.length > 0 && unregisteredUser?.id != null
  }, [companies, unregisteredUser?.id])


  const userContextInfo = useMemo(() => {
    if (unregisteredUser != null) {
      return " - " + SharedDataHelper.firstLetterUppercase(unregisteredUser.firstName) + " " + SharedDataHelper.firstLetterUppercase(unregisteredUser.lastName)
    }
    return ""

  }, [unregisteredUser])

  return {
    events,
    onEventSelected,
    loading,
    companyItems,
    selectedCompanyId,
    onCompanySelected: setSelectedCompanyId,
    pagination,
    onAddUnregisteredUserEvent,
    addNewEventVisible,
    userContextInfo,
    dateFrom,
    dateTo,
    onDateFromChanged,
    onDateToChanged
  }
}