import { IsDate, IsEnum, IsOptional, MaxLength, MinLength, ValidateIf } from "class-validator";
import { UserGender } from "../user/user-gender";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { ValidateIfNotStrictUndefined } from "../../../../validators/validate-if-not-strict-undefined";


export class UpdateUserRequest {
    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    firstName?: string | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    lastName?: string | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsEnum(UserGender)
    gender?: UserGender | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsDate()
    dateOfBirth?: Date | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString({ each: true })
    consentIds?: string[] | null

}