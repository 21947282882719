

const userActionPaths = {
  base: "/user-actions",
  action: "/:actionid",
  unregisteredUserDetails: "/:actionid/unregistered-users/reg/:userid"
}

export { userActionPaths as userAction }

