import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserRecoverPasswordClient } from "./i-user-recover-password-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserRecoverPasswordClient extends BaseClient implements IUserRecoverPasswordClient {

    async recover(data: dto.UserRecoverPasswordRecoverRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.recoverPassword.base + apiPaths.user.recoverPassword.recover), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async challengeToken(data: dto.UserRecoverPasswordChallengeTokenRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.recoverPassword.base + apiPaths.user.recoverPassword.challengeToken), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async completeResetPassword(data: dto.UserRecoverPasswordCompleteRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.recoverPassword.base + apiPaths.user.recoverPassword.complete), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}