

const userConsentHistoryPaths = {
    base: "/user-consent-history",
    userConsentHistories: "",
    userConsentHistory: "/:userconsenthistoryid",
}

export { userConsentHistoryPaths as userConsentHistory }

