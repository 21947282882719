import React, { useCallback, useRef } from "react";
import AuthShell from "../../../_common/layout/auth/auth-shell"
import { Form, Formik } from "formik";
import { TextInput } from "../../../_common/fields/text-input/TextInput";
import { CheckBoxField } from "../../../_common/fields/checkbox/check-box-field";
import { LinkButtonSubmit } from "../../../_common/common/link-button/LinkButtonSubmit";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { LoginModel, useLogin, useLoginInitial, useUserRecoverPasswordChallengeToken } from "./useLogin";
import { Route, Switch } from "react-router-dom";
import { LinkButton } from "../../../_common/common/link-button/LinkButton";
import "./login.scss"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit";
import { useLocalization } from "../../../hook/use-localization";


const Login: React.FC = () => {
    const { t } = useLocalization();
    const { initialValues, onSubmit, validate, paths } = useLogin()

    return (
        <>
            <AuthShell title={t("SignIn")}>
                <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={validate}>
                    {() => (
                        <Form>
                            <div className="form-container">
                                <Switch>
                                    <Route path={paths.PathInitial} component={Initial} />
                                    <Route path={paths.PathChallengeToken} component={ChallengeToken} />
                                </Switch>
                            </div>
                        </Form>
                    )}
                </Formik>
            </AuthShell>
        </>
    );
}

const Initial: React.FC = () => {
    const loginButtonRef = useRef(null);
    const { t } = useLocalization();
    const { values: model, isSubmitting, submitForm } = useTypedFormikContext<LoginModel>()
    const { toggleShowPassword, onRecoverPasswordRequested, onRegisterUserRequested } = useLoginInitial()

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
            submitForm()
        }
    };

    return (
        <div className="login__initial_container" onKeyDown={handleKeyDown}>
            <TextInput name="email" label={t("Email")} />
            <TextInput type={model.showPassword ? "text" : "password"} name="password" label={t("Password")} />
            <div className="login__show-password-forgot-password">
                <CheckBoxField onChange={toggleShowPassword} className="login__show-password" name="showPassword" label={t("ShowPassword")} />
                <LinkButtonSubmit className="login__recover-password" onClick={onRecoverPasswordRequested}>{t("ForgotPassword")}?</LinkButtonSubmit>
            </div>
            <ButtonSubmit className="margin-left-auto" disabled={isSubmitting}>
                {t("Login")}
            </ButtonSubmit>
            <div className="login__register-container">
                {t("NewToDigitalSoftware")}<LinkButtonSubmit onClick={onRegisterUserRequested}>{t("JoinNow")}</LinkButtonSubmit>
            </div>
        </div>
    )
}

const ChallengeToken: React.FC = () => {
    const { t } = useLocalization();
    const { resendToken } = useUserRecoverPasswordChallengeToken()
    const { isSubmitting, model } = useTypedFormikContext<LoginModel>()

    return (
        <>
            <p className="font-weight-normal">{model.sentSmsTokenMessageInfo}</p>
            <TextInput name="smsToken" label={t("Token")} />

            <div className="login-challenge-token__resend-container">
                {t("Didn'tReceiveCode")}<LinkButton disabled={isSubmitting} onClick={resendToken}>{t("Resend")} </LinkButton>
            </div>
            <ButtonSubmit className="margin-left-auto" disabled={isSubmitting}>
                {t("Next")}
            </ButtonSubmit>
        </>
    );
}



export default Login
