import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { IUserInvitationClient, IUserInvitationClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useAppContext } from "../../../hook/use-app-context"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"

const pageSize = 20

export const useUserReceivedInvitations = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const userInvitationClient = useClient<IUserInvitationClient>(IUserInvitationClientId)
	const [userInvitations, setUserInvitations] = useState<dto.UserInvitation[]>([])
	const { pageIndex, pagination, setTotalNumberOfPages } = usePagination()

	const onUserInvitationSelected = useCallback((userInvitationId: string) => {
		history.push("/user-received-invitations/" + userInvitationId)
	}, [history])


	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				setUserInvitations([])
				const request = new dto.GetSelfUserInvitationsRequest()
				request.paging = new dto.PagingRequest({ pageIndex, pageSize })
				request.sortBy = []
				request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
				const pagedResponse = (await userInvitationClient.getSelfUserInvitations(request))
				setUserInvitations(pagedResponse.entities)
				setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [pageIndex])

	return {
		userInvitations,
		onUserInvitationSelected,
		loading,
		pagination,
	}
}