import React from "react";
import { combineClassNames } from "../../helpers/user-interface-helper";
import "./link-button.scss"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}
export const LinkButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const { children, className, ...buttonProps } = props
	return (
		<button type="button" className={combineClassNames("btn btn-link link-button", className)}  {...buttonProps}>
			{children}
		</button>
	);
}