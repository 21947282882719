

const userAttributePaths = {
    base: "/user-attributes",
    userAttributes: "",
    userAttribute: "/:userattributeid",
    uploadExcelFile: "/:companyid/upload-excel-file",
}

export { userAttributePaths as userAttribute }

