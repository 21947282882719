import { dto } from "shared"

export const ICompanyClientId = Symbol.for('ICompanyClient');

export interface ICompanyClient {
    getCurrentCompanies(): Promise<dto.Company[]>
    getCompanies(data: dto.GetCompaniesRequest): Promise<dto.PagingResponse<dto.Company>>
    getCompany(companyId: string): Promise<dto.Company>
    addCompany(data: dto.AddCompanyRequest): Promise<dto.Company>
    updateCompany(companyId: string, data: dto.UpdateCompanyRequest): Promise<void>
    deleteCompany(companyId: string): Promise<void>
}