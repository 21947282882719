import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ArrayMinSize, IsArray, IsEnum, IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { IsNotSharedUserAttribute } from "../../../validators/is-not-shared-user-attribute";
import { LambdaExpression } from "../../../validators/lambda-expression";
import { UserAttributeNameType } from "./user-attribute-name";
import { IsValidCustomAttributeName } from "../../../validators/is-valid-custom-attribute-name";

export class AddUserAttributeNameRequest {
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(200)
    @IsNotSharedUserAttribute()
    @IsValidCustomAttributeName()
    name: string

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    description: string | null

    @IsEnum(UserAttributeNameType)
    @LambdaExpression({
        expression: (obj: AddUserAttributeNameRequest) => {
            return (obj.attributeType == UserAttributeNameType.select && (obj.selectValues?.length ?? 0) >= 2)
                || (obj.attributeType != UserAttributeNameType.select && obj.selectValues == null)
        }
    }, { message: "If type is select, select values must exists. If type is not select, select values must be null" })
    attributeType: UserAttributeNameType

    @IsOptional()
    @IsArray()
    @ArrayMinSize(2)
    @ContainsValidString({ each: true })
    selectValues: string[] | null

    @ContainsValidString()
    companyId: string;
}