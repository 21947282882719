import { StatusResponse } from "../common/status-response";

export class SetUserAttributeByExternalIdResponse {
    status: StatusResponse
    externalId: string
    unregisteredUserExternalId: string
    userAttributeIdentifier: string
    addedNewUserAttribute: boolean

    constructor(options: {
        status: StatusResponse,
        externalId: string,
        unregisteredUserExternalId: string,
        userAttributeIdentifier: string,
        addedNewUserAttribute: boolean
    }) {
        this.status = options.status;
        this.externalId = options.externalId
        this.unregisteredUserExternalId = options.unregisteredUserExternalId
        this.addedNewUserAttribute = options.addedNewUserAttribute
    }
}