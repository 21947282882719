import { User } from "../user";
import { UnregisteredUser } from "../unregistered-user/unregistered-user";
import { EventType } from "../event-type/event-type";

export class Event {
    id: string;
    date: Date;
    description: string
    eventTypeId: string;
    eventTypeName: string;
    companyId: string;
    createdDate: Date;
    updatedDate: Date;
    users: User[]
    unregisteredUsers: UnregisteredUser[]
}





