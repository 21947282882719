import "reflect-metadata";
import { Container } from 'inversify';
import { registerDepedencies, repository, container, registerClientDependencies } from 'shared-client';
import { TokenRepository } from "../data/repository/oauth/token-repository";



const registerBrowserDepedencies = (container: Container) => {
    //Repositories
    container.bind<repository.ITokenRepository>(repository.ITokenRepositoryId).to(TokenRepository).inTransientScope();
}

registerBrowserDepedencies(container);
registerDepedencies(container);
registerClientDependencies(container)

export { container as browserContainer };