

class AuthPaths {
    static base = "/oauth"
    static initLogin: string = "/init-login"
    static token: string = "/token"
    static logout: string = "/logout"
    static logoutByUserIdentifier: string = "/user/:userid/logout"
}

export { AuthPaths as Auth }

