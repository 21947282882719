import { NotificationTaskBatch } from "./notification-task-batch";

// statistics can be undefined or null of request if made with returnStatistics:false
export class NotificationTaskBatchInfo {
    batch: NotificationTaskBatch
    numberOfTasksPending: number | null
    numberOfTasksApiGatewayAccepted: number | null
    numberOfTasksApiGatewaySent: number | null
    numberOfTasksCompleted: number | null;
    numberOfTasksTotal: number | null;
    numberOfTasksFailed: number | null
}