
import { DataHelper } from "../helper/data-helper";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsPositiveIntOrZeroConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        if (text != null) {
            if (typeof (text) === "number") {
                return DataHelper.isNumberInteger(text) && text >= 0;
            }
            if (typeof (text) === "string") {
                return DataHelper.isStringPositiveIntegerOrZero(text);
            }
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        return DataHelper.firstLetterUppercase(`${args.property} must be positive integer or zero.`);
    }
}

export function IsPositiveIntOrZero(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsPositiveIntOrZeroConstraint
        });
    };
}