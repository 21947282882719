import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserInvitationClient } from "./i-user-invitation-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserInvitationClient extends BaseClient implements IUserInvitationClient {

    async getUserInvitations(data: dto.GetUserInvitationsRequest): Promise<dto.PagingResponse<dto.UserInvitation>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.invitations), params: data });
            return response.data as dto.PagingResponse<dto.UserInvitation>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getSelfUserInvitations(data: dto.GetSelfUserInvitationsRequest): Promise<dto.PagingResponse<dto.UserInvitation>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.selfInvitations), params: data });
            return response.data as dto.PagingResponse<dto.UserInvitation>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUserInvitation(userinvitationId: string): Promise<dto.UserInvitation> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.invitation.replace(":userinvitationid", userinvitationId)) });
            return response.data as dto.UserInvitation;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addUserInvitation(data: dto.AddUserInvitationRequest): Promise<dto.UserInvitation> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.invitations), data: data });
            return response.data as dto.UserInvitation;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async acceptUserInvitation(userinvitationId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.accept.replace(":userinvitationid", userinvitationId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async rejectUserInvitation(userinvitationId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.reject.replace(":userinvitationid", userinvitationId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUserInvitation(userinvitationId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.user.invitation.base + apiPaths.user.invitation.invitation.replace(":userinvitationid", userinvitationId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}