import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import "./user-query.scss"
import { SharedDataHelper, dto } from "shared"
import { Dropdown, DropdownItem } from "../../common/dropdown/DropDown"
import Typeahead, { TypeaheadDropdownItem } from "../../common/typeahead/typeahead"
import { transformAndValidate } from "class-transformer-validator";
import { createDefaultCompositeQuery, createDefaultSimpleQuery } from "../../../../common/user-attribute-query/user-attribute-query"
import { DataUtil } from "../../../../common/data/data-util"
import { UserAttributeHelper } from "../../helpers/user-attribute-helper"
import { UserAttributeValue } from "../user-attribute-value/user-attribute-value"
import { plainToClass } from 'class-transformer';
import { useLocalization } from "../../../hook/use-localization"


declare type CompareWithType = "value" | "description"
declare type QueryEntryType = "simple" | "and" | "or"

export interface Props {
	id: string,
	label?: string,
	query: dto.UserAttributeQueryCondition,
	userAttributeNames: dto.UserAttributeName[],
	indexInParent: number
	onChanged: (query: dto.UserAttributeQueryCondition, indexInParent: number) => void;
	disabled?: boolean,
	headerCustomContent?: ReactNode
}

export const UserQuery: React.FC<Props> = ({ query: originalQuery, userAttributeNames, label, id, indexInParent, onChanged, disabled, headerCustomContent }) => {
	const { t } = useLocalization();
	const [query, setQuery] = useState<dto.UserAttributeQueryCondition>();
	const [isValid, setIsValid] = useState(true);
	const [compareWith, setCompareWidth] = useState<CompareWithType>("value")
	const [queryEntryType, setQueryEntryType] = useState<QueryEntryType>();
	const [userAttributeName, setUserAttributeName] = useState<dto.UserAttributeName | null>(null)

	const getUserAttributeName = useCallback((name: string): dto.UserAttributeName | null => {
		return userAttributeNames.find(x => x.name === name) ?? null;
	}, [userAttributeNames])

	const validate = useCallback((query: dto.UserAttributeQueryCondition) => {
		(async () => {
			try {
				await transformAndValidate(dto.UserAttributeQueryCondition, query);
				setIsValid(true)
			}
			catch {
				setIsValid(false)
			}
		})();
	}, [])

	useEffect(() => {
		setQuery(originalQuery)
		if (originalQuery != null) {
			if (originalQuery.conditions == null) {
				setCompareWidth(originalQuery.value != null ? "value" : "description")
			}
			validate(originalQuery)
		} else {
			setIsValid(false)
		}
	}, [originalQuery])

	useEffect(() => {
		if (originalQuery?.name != null) {
			const userAttributeName = getUserAttributeName(originalQuery?.name);
			setUserAttributeName(userAttributeName)
		}
	}, [originalQuery, userAttributeNames, getUserAttributeName])

	const onQueryUpdated = useCallback((_newQuery: dto.UserAttributeQueryCondition) => {
		const newQuery = plainToClass(dto.UserAttributeQueryCondition, _newQuery);
		validate(newQuery);
		onChanged(newQuery!, indexInParent)
	}, [indexInParent, onChanged, validate]);

	const onChildUpdated = useCallback((childQuery: dto.UserAttributeQueryCondition, childIndex: number) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		newQuery.conditions![childIndex] = childQuery;
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}, [onQueryUpdated, query]);

	const onTypeChanged = useCallback((type: dto.UserAttributeQueryType) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		newQuery.type = type ?? undefined
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}, [onQueryUpdated, query]);

	useEffect(() => {
		if (query?.type == null) {
			return
		}

		if (query.type === dto.UserAttributeQueryType.and) {
			setQueryEntryType("and")
		} else if (query.type === dto.UserAttributeQueryType.or) {
			setQueryEntryType("or")
		} else {
			setQueryEntryType("simple")
		}
	}, [query])

	useEffect(() => {
		if (query == null) {
			return
		}
		if (queryEntryType == null) {
			return
		}
		onQueryEntryTypeChanged(queryEntryType)
	}, [queryEntryType])

	const getSetValueOrDescription = (compareWith: CompareWithType): { setValue: boolean, setDescription: boolean } => {

		if (compareWith === "value") {
			return {
				setValue: true,
				setDescription: false
			}
		}

		return {
			setValue: false,
			setDescription: true
		}
	}

	const onQueryEntryTypeChanged = (queryEntryType: QueryEntryType) => {
		if (query == null) {
			return
		}

		if (queryEntryType == null) {
			return
		}

		const setValueOrDesc = getSetValueOrDescription(compareWith);
		if (queryEntryType === "simple") {
			if (query.type === dto.UserAttributeQueryType.and || query.type === dto.UserAttributeQueryType.or) {
				const newQuery = createDefaultSimpleQuery(setValueOrDesc)
				setQuery(newQuery)
				onQueryUpdated(newQuery)
			}
		} else {
			if (!(query.type === dto.UserAttributeQueryType.and || query.type === dto.UserAttributeQueryType.or)) {
				if (queryEntryType === "and") {
					const newQuery = createDefaultCompositeQuery({
						type: dto.UserAttributeQueryType.and,
						conditions: query.conditions,
						setValue: setValueOrDesc.setValue,
						setDescription: setValueOrDesc.setDescription
					})
					setQuery(newQuery)
					onQueryUpdated(newQuery)
				}
				if (queryEntryType === "or") {
					const newQuery = createDefaultCompositeQuery({
						type: dto.UserAttributeQueryType.or,
						conditions: query.conditions,
						setValue: setValueOrDesc.setValue,
						setDescription: setValueOrDesc.setDescription
					})
					setQuery(newQuery)
					onQueryUpdated(newQuery)
				}
			} else if (query.type !== queryEntryType) {
				const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
				newQuery.type = queryEntryType === "and" ? dto.UserAttributeQueryType.and : dto.UserAttributeQueryType.or
				setQuery(newQuery)
				onQueryUpdated(newQuery)
			}
		}
	}

	const compareWidthTypes = useMemo((): DropdownItem[] => {
		return [
			{
				text: t("value"),
				value: "value"
			},
			{
				text: t("description"),
				value: "description"
			}
		]
	}, [t])

	const queryEntryTypes = useMemo((): { value: QueryEntryType, text: string }[] => {
		const data: { value: QueryEntryType, text: string }[] = [
			{
				text: t("Simple"),
				value: "simple"
			},
			{
				text: t("And"),
				value: "and"
			},
			{
				text: t("Or"),
				value: "or"
			}
		]
		return data
	}, [t]);

	const compareTypes = useMemo((): DropdownItem[] => {
		return [
			{
				text: t(dto.UserAttributeQueryTypeCompare.equal),
				value: dto.UserAttributeQueryTypeCompare.equal,
			},
			{
				text: t(dto.UserAttributeQueryTypeCompare.greater),
				value: dto.UserAttributeQueryTypeCompare.greater,
			},
			{
				text: t(dto.UserAttributeQueryTypeCompare.greaterOrEqual),
				value: dto.UserAttributeQueryTypeCompare.greaterOrEqual,
			},
			{
				text: t(dto.UserAttributeQueryTypeCompare.lower),
				value: dto.UserAttributeQueryTypeCompare.lower,
			},
			{
				text: t(dto.UserAttributeQueryTypeCompare.lowerOrEqual),
				value: dto.UserAttributeQueryTypeCompare.lowerOrEqual,
			},
			{
				text: t(dto.UserAttributeQueryTypeCompare.contains),
				value: dto.UserAttributeQueryTypeCompare.contains,
			}
		]
	}, [t])

	const onCompareWidthChanged = (compareWidth: CompareWithType) => {
		setCompareWidth(compareWidth)
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		if (compareWidth === "description") {
			newQuery.description = newQuery.value
			delete newQuery.value

		}

		if (compareWidth === "value") {
			newQuery.value = newQuery.description
			delete newQuery.description
		}

		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}

	const namesItems = useMemo((): TypeaheadDropdownItem[] => {
		return userAttributeNames.map(x => {
			return {
				text: t(SharedDataHelper.firstLetterUppercase(x.name)),
				value: x.name,
				description: x.description ?? undefined
			}
		})
	}, [userAttributeNames, t])



	const onNameChanged = useCallback((name: string | null) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		newQuery.name = name ?? undefined
		if (newQuery.name != null) {
			const userAttributeName = getUserAttributeName(newQuery.name)
			if (userAttributeName != null) {
				if (userAttributeName.attributeType === dto.UserAttributeNameType.exists) {
					newQuery.value = "true";
					newQuery.description = undefined;
					setCompareWidth("value")
				} else {
					if (newQuery.value != null && !UserAttributeHelper.isValidValue(newQuery.value, userAttributeName)) {
						newQuery.value = UserAttributeHelper.getDefaultValue(userAttributeName)
					}
					if (newQuery.description != null && !UserAttributeHelper.isValidValue(newQuery.description, userAttributeName)) {
						newQuery.description = UserAttributeHelper.getDefaultValue(userAttributeName)
					}
				}

				if (userAttributeName.attributeType === dto.UserAttributeNameType.select) {
					newQuery.type = dto.UserAttributeQueryType.contains
				}
			} else {
				newQuery.value = ""
			}
		}

		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}, [getUserAttributeName, onQueryUpdated, query])

	const onValueChanged = (value: string | null) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		newQuery.value = value ?? undefined
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}

	const onDescriptionChanged = (description: string | null) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query))
		newQuery.description = description ?? undefined
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}

	const addChild = () => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query));
		newQuery.conditions?.push(createDefaultSimpleQuery(getSetValueOrDescription(compareWith)))
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}

	const onDeleteChild = (index: number) => {
		const newQuery: dto.UserAttributeQueryCondition = JSON.parse(JSON.stringify(query));
		newQuery.conditions!.splice(index, 1);
		setQuery(newQuery)
		onQueryUpdated(newQuery)
	}

	const childConditionItems = (query?.conditions ?? []).map((condition, index) => {
		const childId = id + "_child_condition_" + index.toString()
		return <div key={childId} className="user_query__child">
			<UserQuery disabled={disabled} indexInParent={index} onChanged={onChildUpdated} id={childId} query={condition} userAttributeNames={userAttributeNames} />
			{!disabled && query!.conditions!.length > 2 && <img src="/delete.svg" className="user_query__child-delete" alt="" onClick={() => onDeleteChild(index)} />}
			{index < (query!.conditions!.length - 1) && <div className="user_query__children-connector">{t(DataUtil.firstLetterUppercase(query?.type) ?? null)}</div>}
		</div>
	}
	);



	return (
		<div className={"user-query__container " + (isValid ? "valid" : "invalid")}>
			{label != null && <div className="header">{label}
				{headerCustomContent && <div className="custom-content">{headerCustomContent}</div>}
			</div>}
			{query != null && <div className="user-query__content">
				<Dropdown disabled={disabled} label={t("Type")} className="user-query__query-entry-type" selectedValue={queryEntryType} onSelect={e => setQueryEntryType(e as QueryEntryType)} items={queryEntryTypes}></Dropdown>
				<div className="user-query__inner-content">
					{queryEntryType === "simple" &&
						<>
							<Typeahead disabled={disabled} id={id + "_name_"} className="user-query__name" label={t("Name")} selectedValue={query.name!} items={namesItems} onSelect={onNameChanged} placeholder={t("SelectName")} />
							{userAttributeName?.attributeType !== dto.UserAttributeNameType.exists && <Dropdown disabled={disabled} label={t("Operator")} className="user-query__type" selectedValue={query.type} onSelect={val => onTypeChanged(val as any)} items={compareTypes}></Dropdown>}
							{userAttributeName?.attributeType !== dto.UserAttributeNameType.exists && <Dropdown disabled={disabled} label={t("CompareWith")} className="user-query__compare-with" selectedValue={compareWith} onSelect={val => onCompareWidthChanged(val as any)} items={compareWidthTypes}></Dropdown>}

							{compareWith === "value" && <div className="input_value_container">
								{userAttributeName && <UserAttributeValue label={t("Value")} onChange={onValueChanged} disabled={disabled} value={query.value ?? null} userAttributeName={userAttributeName} />}
							</div>}

							{compareWith === "description" && <div className="input_value_container">
								{userAttributeName && <UserAttributeValue label={t("Description")} onChange={onDescriptionChanged} disabled={disabled} value={query.description ?? null} userAttributeName={userAttributeName} />}
							</div>}
						</>
					}

					{queryEntryType !== "simple" &&
						<div className="user_query__children_container">
							{!disabled && <img src="/add.svg" className="user_query__child_add" alt="" onClick={addChild} />}
							{childConditionItems}
						</div>
					}
				</div>
			</div>
			}

		</div>)
}