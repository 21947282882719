import React from "react";
import "./date-picker.scss"
import DatePickerLib from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { combineClassNames } from "../../helpers/user-interface-helper";


interface Props {
	date: Date | null,
	onChange: (date: Date | null) => void,
	disabled?: boolean
	showTimeInput?: boolean
	className?: string
}
export const DatePicker: React.FC<Props> = (props) => {
	const { date, onChange, disabled, showTimeInput, className } = props

	const disableObj: any = {}
	if (disabled) {
		disableObj["disabled"] = true
	}
	const dateFormat = showTimeInput ? `dd.MM.yyyy HH:mm` : `dd.MM.yyyy`

	return (
		<div className={combineClassNames("date-picker__container", className)}>
			<DatePickerLib {...disableObj} showYearDropdown selected={date} dateFormat={dateFormat} onChange={onChange} showTimeInput={showTimeInput} timeFormat="HH:mm" />
		</div>)
}