import "reflect-metadata";
import { injectable } from "inversify";
import { Config } from "../../config/config"
import { container } from "../../setup/inversify-config"
import { dto, SharedDataHelper, QuerySerializer } from "shared"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { ITokenRepository, ITokenRepositoryId } from "../../repository";
import { ClientError, ClientErrorStatusCode } from "../../entity/error/index";
import { IAuthClient, IAuthClientId } from "../client";

@injectable()
export class BaseClient {
    private baseUrl: string;
    static baseNetworkUrl: string;
    static logRequestResponse = true
    tokenRepository: ITokenRepository
    on401IgnoreRefreshToken: boolean = false

    constructor() {
        const bNetworkUrl = BaseClient.baseNetworkUrl;
        const cApiUrl = Config.baseApiUrl
        this.baseUrl = bNetworkUrl ?? cApiUrl
        //console.log("Base client: base-url:" + this.baseUrl)
    }

    protected get publicAxios(): AxiosInstance {
        let axiosApiInstance = axios.create({
            baseURL: this.baseUrl
        });

        axiosApiInstance.interceptors.request.use(request => {
            if (BaseClient.logRequestResponse) {
                console.log('Starting Request', request)
            }
            return request
        })

        axiosApiInstance.interceptors.response.use(
            res => {
                if (BaseClient.logRequestResponse) {
                    console.log(res.data)
                }
                this.processResponse(res.data);
                return res;
            },
            err => {
                if (BaseClient.logRequestResponse) {
                    console.error(JSON.stringify(err))
                }
                throw this.createClientError(err);
            }
        );
        return axiosApiInstance;
    }

    protected get protectedAxios(): AxiosInstance {
        let axiosApiInstance = axios.create({
            baseURL: this.baseUrl
        });
        axiosApiInstance.interceptors.request.use(
            async config => {
                // Transform axios request
                // Currently transform only GET request when config.params is Object
                // It converts params object into query string, so server can deserialize object
                // After converting set config.params to NULL.
                this.transformRequest(config);
                if (BaseClient.logRequestResponse) {
                    console.log('Axios Starting Request', config)
                }
                const tokenRepository = this.getTokenRepository()
                const accessToken = await tokenRepository.getAccessToken();
                if (config.data?.constructor?.name == "FormData" && config.data.getHeaders != null) {
                    const formHeaders = config.data.getHeaders();
                    config.headers = {
                        'Authorization': `Bearer ${accessToken}`,
                        ...formHeaders
                    }

                } else {
                    config.headers = {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
                return config;
            },
            error => {
                Promise.reject(this.createClientError(error))
            });

        const obj_ = this;

        axiosApiInstance.interceptors.response.use((response) => {
            if (BaseClient.logRequestResponse) {
                console.log('Axios response 2:', response.data)
            }
            this.processResponse(response.data);
            return response;
        },
            async function (error) {
                const originalRequest = error.config;
                const refreshTokenSupported = obj_.getTokenRepository().refreshTokenSupported;
                if (error.response?.status === 401 && refreshTokenSupported && !originalRequest._retry && obj_.on401IgnoreRefreshToken !== true) {
                    originalRequest._retry = true;
                    let accessToken: string = null;
                    try {
                        accessToken = await obj_.refreshAccessToken();
                    } catch (err) {
                        return Promise.reject(obj_.createClientError(error));
                    }
                    if (SharedDataHelper.stringIsNullTrimEmpty(accessToken)) {
                        return Promise.reject(obj_.createClientError(error));
                    }
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                    return axiosApiInstance(originalRequest);
                }
                return Promise.reject(obj_.createClientError(error));
            });

        return axiosApiInstance;
    }

    // Transform axios request
    // Currently transform only GET request when config.params is Object
    // It converts params object into query string, so server can deserialize object
    // After converting set config.params to NULL.
    private transformRequest(config: AxiosRequestConfig) {
        if (config.method.toLocaleLowerCase() == "get" && typeof (config.params) === "object" && config.params != null && config.url != null) {
            this.removePropertiesWithNullValue(config.params)
            const serializedQuery = QuerySerializer.serialize(config.params, { encode: false });
            const questionIndex = config.url!.indexOf("?");
            if (questionIndex < 0) {
                config.url += ("?" + serializedQuery);
            } else if (questionIndex == (config.url!.length - 1)) {
                config.url += serializedQuery
            } else {
                config.url += ("&" + serializedQuery);
            }
            config.params = null;
        }

    }

    private removePropertiesWithNullValue(obj: any) {
        if (obj == null) {
            return
        }

        Object.keys(obj).forEach(key => {

            const val = obj[key]
            if (val == null || val == undefined) {
                delete obj[key];
                return
            }

            if (typeof val === 'object') {
                this.removePropertiesWithNullValue(val)
            }
        })
    }

    private async refreshAccessToken(): Promise<string> {
        const tokenRepository = this.getTokenRepository()
        const refreshToken = await tokenRepository.getRefreshToken();
        if (SharedDataHelper.stringIsNullTrimEmpty(refreshToken)) {
            return null;
        }

        const iAuthClient = container.get<IAuthClient>(IAuthClientId)

        const tokenHolder = await iAuthClient.token({
            clientId: Config.OAuthClientId,
            refreshToken: refreshToken,
            grantType: "refreshToken"
        });
        if (SharedDataHelper.stringIsNullTrimEmpty(tokenHolder?.accessToken)) {
            throw new ClientError({
                statusCode: ClientErrorStatusCode.unknown,
                message: "RefreshAccessToken method: access token is null."
            })
        }
        if (SharedDataHelper.stringIsNullTrimEmpty(tokenHolder?.refreshToken)) {
            throw new ClientError({
                statusCode: ClientErrorStatusCode.unknown,
                message: "RefreshAccessToken method: refresh token is null."
            })
        }
        await tokenRepository.saveAccessToken(tokenHolder.accessToken);
        await tokenRepository.saveRefreshToken(tokenHolder.refreshToken);
        return tokenHolder.accessToken;
    }

    // convert response before delivery
    // This function convert date values like  2010-06-15T05:04:00 to date object
    private processResponse(data_: any) {
        if (data_ != null && typeof data_ === "object") {
            const data = data_ as object;
            if (Array.isArray(data)) {

                for (const obj__ of data) {
                    this.processResponse(obj__);
                }
                return
            }
            for (const key of Object.keys(data)) {
                const value = data[key];
                if (value != null) {
                    if (typeof value === "object") {
                        this.processResponse(value);
                    }
                    else if (Array.isArray(value)) {
                        for (const arrObj of value) {
                            this.processResponse(arrObj);
                        }
                    }
                    else if (key.toLocaleLowerCase().indexOf("date") > -1 && SharedDataHelper.matchISO8601(value)) {
                        data[key] = SharedDataHelper.parseISO8601(value);
                    }
                }
            }
        }
    }

    private createClientError(error: any): ClientError {
        if (error instanceof ClientError) {
            return error;
        }
        let clientError = new ClientError({
            statusCode: ClientErrorStatusCode.unknown,
            message: "Axios error:" + error.message
        });
        if (error.isAxiosError == true) {
            if (error.errno == 'ECONNREFUSED') {
                console.log("Can not connect with server - Url is:" + this.baseUrl)
                clientError.message = 'ECONNREFUSED. Server might not running or there is issue with internet connection';
            }
            const errorResponse = error.response?.data as dto.ErrorResponse;
            //console.error(errorResponse)
            clientError = ClientError.createFromErrorResponse(error.response?.status, errorResponse);
        }
        return clientError;
    }

    private getTokenRepository(): ITokenRepository {
        if (this.tokenRepository != null) {
            return this.tokenRepository
        }
        return container.get<ITokenRepository>(ITokenRepositoryId)
    }
}