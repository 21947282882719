import { SortItem } from "../../common/query";
import { PagingRequest } from "../../common/paging";
import { IsBoolean, IsBooleanString, IsDate, IsDefined, IsOptional, ValidateNested } from "class-validator";
import { AreValidSortItems } from "../../../../validators/are-valid-sort-items";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { Transform } from "class-transformer";
import { BooleanTransform } from "../../../transform";

export class GetNotificationTaskBachInfosRequest {

    @IsOptional()
    @IsDate()
    taskDateFrom: Date

    @IsOptional()
    @IsDate()
    taskDateTo: Date

    @IsOptional()
    @IsDate()
    createdDateFrom: Date

    @IsOptional()
    @IsDate()
    createdDateTo: Date

    @ContainsValidString()
    companyId: string

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @IsDefined()
    @AreValidSortItems({ allowed: ["createdDate", "taskDate"] })
    sortBy: SortItem[]

    @IsBoolean()
    @Transform(BooleanTransform)
    returnStatistics: boolean
}