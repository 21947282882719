
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface OnlyOneExpressionTrueOptions {
    expressions: ((obj: any) => boolean)[]
}

@ValidatorConstraint({ async: true })
export class OnlyOneExpressionTrueConstraint implements ValidatorConstraintInterface {

    async validate(obj: any, args: ValidationArguments) {
        const holder = args.object
        const onlyOneOption: OnlyOneExpressionTrueOptions = args.constraints[0] ?? []
        let count = 0;
        for (const expression of onlyOneOption.expressions) {
            const val = expression(holder)
            if (val) {
                count++
            }
        }
        return count == 1;
    }

    defaultMessage(args: ValidationArguments) {
        return `Invalid expressions. Only one must be true`;
    }
}

export function OnlyOneExpressionTrue(options: OnlyOneExpressionTrueOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [options as any],
            validator: OnlyOneExpressionTrueConstraint
        });
    };
}