import { EmailAddNotificationTaskBatchDetails } from "../../notification-task-batch/_index";
import { EmailAddNotificationTaskDetails } from "../../notification-task/_index";
import { EmailNotificationTemplateDetails } from "../../notification-template/email";
import { INotificationSpecific } from "../i-notification-specific";

export class EmailNotificationSpecific implements INotificationSpecific {


	get addNotificationTaskDetails(): { addressProperty: string; messageToSendProperty: string; constructor: Function; } {
		return {
			addressProperty: "email",
			messageToSendProperty: "body",
			constructor: EmailAddNotificationTaskDetails
		}
	}

	get addNotificationTaskBatchDetails(): { constructor: Function; } {
		return {
			constructor: EmailAddNotificationTaskBatchDetails
		}
	}

	get notificationTaskBatchDetails(): { constructor: Function; } {
		return {
			constructor: EmailAddNotificationTaskBatchDetails
		}
	}

	get notificationTemplateDetails(): { constructor: Function; } {
		return {
			constructor: EmailNotificationTemplateDetails
		}
	}
}