import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { dto } from "shared";
import { IUserClient, IUserClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { validate } from "class-validator";
import useClient from "../../../hook/useClient";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";


interface Model {
	userId: string,
	oldPassword: string,
	password: string,
	confirmPassword: string,
	showPassword: boolean
}

export type AccountChangePasswordModel = Model

const createModel = (user: dto.User): Model => {
	return {
		userId: user.id,
		oldPassword: "",
		password: "",
		confirmPassword: "",
		showPassword: false
	}
}

const createChangeUserPasswordRequest = (model: Model) => {
	const request = new dto.ChangeUserPasswordRequest()
	request.oldPassword = model.oldPassword
	request.password = model.password
	request.confirmPassword = model.confirmPassword
	return request
}


export const useAccountAccountChangePassword = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const client = useClient<IUserClient>(IUserClientId)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)

	const loadInitialModel = useCallback(() => {
		(async () => {
			try {
				setLoading(true)
				setGlobalMessage(null)
				setModelInitialValues(null)
				const user = await client.getCurrentUser()
				const model = createModel(user)
				setModelInitialValues(model)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [setLoading, setGlobalMessage, setModelInitialValues, client])


	useEffect(() => {
		loadInitialModel()
	}, [])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const errorModel: FormikErrors<Model> = {}
		const request = createChangeUserPasswordRequest(model)
		let validateErrors = await validate(request);
		setFieldErrors<Model>(validateErrors, ["password", "confirmPassword", "oldPassword"], errorModel)
		return errorModel
	}, [])


	const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {
			const request = createChangeUserPasswordRequest(model)
			await client.changeUserPassword(model.userId, request)
			history.replace("/account")
			setGlobalMessage({ message: "Your password is changed.", type: "success" })
		}, { setFieldError, setGlobalMessage, fieldNames: ["password", "confirmPassword", "oldPassword"] })
	}, [client, setGlobalMessage, history])

	return {
		modelInitialValues,
		onSubmit,
		onValidate,
		loading,
	}
}

export const useAccountChangePasswordForm = () => {
	const { setFieldValue, values: model } = useTypedFormikContext<Model>()
	const toggleShowPassword = useCallback(() => {
		setFieldValue("showPassword", !model.showPassword)
	}, [setFieldValue, model.showPassword])

	return {
		toggleShowPassword,
	}
}


