import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IClientCredentialClient, IClientCredentialClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"

const pageSize = 20

export const useClientCredentials = () => {
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const clientCredentialClient = useClient<IClientCredentialClient>(IClientCredentialClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [clientCredentials, setClientCredentials] = useState<dto.ClientCredential[]>([])
    const [companies, setCompanies] = useState<dto.Company[]>([])
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)


    const onPageRequest = (pageIndex: number) => {
        loadForm({ pageIndex, selectedCompanyId })
        setPageIndex(pageIndex)
    }

    const { pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

    const onClientCredentialSelected = useCallback((clientCredentialId: string) => {
        history.push("/client-credentials/" + clientCredentialId)
    }, [history])

    const loadForm = useCallback(async (options: { pageIndex: number, selectedCompanyId: string | null }) => {
        try {
            const { pageIndex, selectedCompanyId } = options
            if (selectedCompanyId == null) {
                setClientCredentials([])
                return
            }
            setLoading(true)
            setClientCredentials([])
            const request = new dto.GetClientCredentialsRequest({
                companyId: selectedCompanyId,
                paging: new dto.PagingRequest({ pageIndex, pageSize }),
                sortBy: [new dto.SortItem({ name: "createdDate", type: "desc" })]
            })

            const pagedResponse = (await clientCredentialClient.getClientCredentials(request))
            setClientCredentials(pagedResponse.entities)
            setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        } finally {
            setLoading(false)
        }
    }, [setGlobalMessage, setTotalNumberOfPages, clientCredentialClient])

    useEffect(() => {
        if (selectedCompanyId == null) {
            return
        }
        (async () => {
            setPageIndex(0)
            await loadForm({ pageIndex: 0, selectedCompanyId })
        })()
    }, [selectedCompanyId]);

    // Load companies
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const companies = await companyClient.getCurrentCompanies()
                setCompanies(companies)
                setSelectedCompanyId(companies.length > 0 ? companies[0].id : null)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])

    const onAddClientCredential = useCallback(() => {
        history.push("/client-credentials/add/client-credential/")
    }, [history])


    const addNewClientCredentialEnabled = useMemo(() => {
        return companies.length > 0
    }, [companies])

    return {
        clientCredentials,
        onClientCredentialSelected,
        loading,
        companyItems,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        pagination,
        onAddClientCredential,
        addNewClientCredentialEnabled,
    }
}