import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./unregistered-user.scss"
import { UserModel, useUnregisteredUser, useUnregisteredUserForm } from "./useUnregisteredUser"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { useLocalization } from "../../../hook/use-localization"
import { ConsentField } from "../../../_common/fields/consent/consent-field"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"
import { dto } from "shared"


export const UnregisteredUser: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteUser, isAddingNewUser, onAddNewUser, deleteDataProcessingConsent, onUserAttributesSelected, onUserEventsSelected } = useUnregisteredUser()
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);
    const confirmationDeleteDataProcessingConsentRef = useRef<ConfirmationHandle>(null);


    return (
        <Shell pageTitle={isAddingNewUser ? t("AddContact") : t("Contact")}
            desktopHeaderContent={<>
                {!isAddingNewUser && <div className="unregistered-user-shortcuts-container">
                    <Button className="unregistered-user-shortcut" onClick={onUserEventsSelected}>{t("Events")}</Button>
                    <Button className="unregistered-user-shortcut" onClick={onUserAttributesSelected}>{t("Attributes")}</Button>
                </div>}
            </>}>

            <Loading loading={loading} />
            {
                modelInitialValues != null && <>
                    <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                        validateOnBlur={true}
                        validate={onValidate}>
                        {() => (
                            <Form>
                                <FormContent isAdd={isAddingNewUser} />
                            </Form>
                        )}
                    </Formik>

                    {!isAddingNewUser && <DangerZone>
                        <div className="delete-title">{t("DeleteContact")} </div>
                        <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
                        <div className="delete-message">{t("DeleteContactDescription")} </div>
                        <Confirmation body={t("Confirmation.DeleteContact")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteUser()} />
                    </DangerZone>}

                    {!isAddingNewUser && modelInitialValues.givenUserConsentNames.find(x => x === dto.ConsentName.dataProcessingConsent) != null && <DangerZone>
                        <div className="delete-title">{t("DeleteDataProcessingConsent")} </div>
                        <Button onClick={() => confirmationDeleteDataProcessingConsentRef.current?.show()}>{t("DeleteConsent")}</Button>
                        <div className="delete-message">{t("DoYouWantToDeleteDataProcessingConsentDescription")} </div>
                        <Confirmation body={t("DoYouWantToDeleteDataProcessingConsentDescription")} ref={confirmationDeleteDataProcessingConsentRef} onOkCallback={() => deleteDataProcessingConsent()} />
                    </DangerZone>}

                    {modelInitialValues?.userId != null && <div className="alert alert-secondary zone-container indent-container" role="alert">
                        <Button onClick={onAddNewUser}>{t("AddNewContact")}</Button>
                        <div>{t("AddNewContact")}</div>
                    </div>}
                </>
            }
        </Shell >
    )
}

interface FormContentProps {
    isAdd: boolean
}

const FormContent: React.FC<FormContentProps> = ({ isAdd }) => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<UserModel>()
    const { editForm, cancelEditForm, genders, saveUserEnabled, companyItems, companyDropDownEnabled, onUserAttributesSelected, onUserEventsSelected, didGiveDataProcessingConsent } = useUnregisteredUserForm()

    const consentItems = model.consentItems.map((consentItem, index) => {
        const name = `consentItems.${index}.enabled`;
        let isDisabled = model.readonly
        if (consentItem.consent.name === dto.ConsentName.dataProcessingConsent && !isAdd &&
            model.givenUserConsentNames.find(x => x === dto.ConsentName.dataProcessingConsent) != null) {
            isDisabled = true
        }
        return <div className="consent-container" key={consentItem.consent.id} >
            <ConsentField disabled={isDisabled} hideDescription={true} className="contact-consent-field" name={name} consent={consentItem.consent} />
        </div >
    });

    return (
        <div className="indent-container unregistered-user-form-content">
            <CompanyDropDownField disabled={!companyDropDownEnabled} name="companyId" label={t("Company")} items={companyItems} />
            <TextInput name="firstName" disabled={model.readonly} label={t("FirstName")} />
            <TextInput name="lastName" disabled={model.readonly} label={t("LastName")} />
            <div className="delivery-address--verified-wrapper">
                <TextInput name="mobilePhoneNumber" disabled={model.readonly} label={t("MobilePhone")} />
                {model.mobilePhoneNumberVerified && <img className="verified-img" src="/verified.png" alt="Verified" title="Verified"></img>}
            </div>
            <div className="delivery-address--verified-wrapper">
                <TextInput name="email" disabled={model.readonly} label={t("Email")} />
                {model.emailVerified && <img className="verified-img" src="/verified.png" alt="Verified" title="Verified"></img>}
            </div>
            <DatePickerField name="dateOfBirth" disabled={model.readonly} label={t("DateOfBirth")} />
            <DropDownField name="gender" disabled={model.readonly} label={t("Gender")} items={genders} />
            <TextInput name="externalId" disabled={model.readonly} label={t("ExternalId")} />
            {consentItems}
            <div className="action-buttons-row">
                {model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>Cancel</Button>}
                {saveUserEnabled && !model.readonly && <ButtonSubmit disabled={!didGiveDataProcessingConsent}>{t("Save")}</ButtonSubmit>}
            </div>

            {model.userId != null && <div className="alert alert-secondary zone-container indent-container" role="alert">
                <Button onClick={onUserAttributesSelected}>{t("Attributes")}</Button>
                <div>{t("ViewAllAttributesDescription")}</div>
            </div>}

            {model.userId != null && <div className="alert alert-secondary zone-container indent-container" role="alert">
                <Button onClick={onUserEventsSelected}>{t("Events")}</Button>
                <div>{t("ViewAllUserEventsDescription")}</div>
            </div>}

        </div>)
}
