import React from "react"
import { useEffect } from "react"
import { SharedDataHelper } from "shared"
import { useAppContext } from "../../../hook/use-app-context"
import useIsMobile from "../../../hook/use-is-mobile"
import { Button } from "../../../_common/common/button/Button"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import Shell from "../../../_common/layout/shell/shell"
import "./companies.scss"
import { useCompanies } from "./useCompanies"
import { useLocalization } from "../../../hook/use-localization"


export const Companies: React.FC = () => {
	const { t } = useLocalization()
	const isMobile = useIsMobile()
	const { companies, onCompanySelected, loading, onAddCompany } = useCompanies()

	const rowClick = (companyId: string) => {
		if (isMobile) {
			onCompanySelected(companyId)
		}
	}



	const listItems = companies.map((company) =>
		<div className="companies__item data-row-item" key={company.id} onClick={() => rowClick(company.id)}>
			<div className="companies__item-name-address">
				<div className="companies__item-name"><button onClick={() => { onCompanySelected(company.id) }}>{company.name}</button></div>
				<div className="companies__item-address">{company.address}</div>
			</div>
			<div className="companies__item-status-updated">
				<div className="companies__item-status">{SharedDataHelper.ensureFirstLetterUppercase(company.status)}</div>
				<div className="companies__item-updated">{t("UpdatedAt")} {SharedDataHelper.getFormattedDate(company.updatedDate ?? company.createdDate, "dd.MM.yyyy")}</div>
			</div>
		</div>
	);
	return (
		<Shell pageTitle={t("Companies")}
			mobileHeaderContent={<button className="mobile-add-new" onClick={onAddCompany}>Add</button>}
			desktopHeaderContent={<Button onClick={onAddCompany}>{t("AddCompany")}</Button>}>
			<Loading loading={loading} />
			<div className="companies__container">
				{listItems}
				{companies.length === 0 && <NoEntities />}
			</div>
		</Shell >
	)
}