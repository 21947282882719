import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import useClient from "../../../hook/useClient"

export const useCompanies = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const client = useClient<ICompanyClient>(ICompanyClientId)
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const [loading, setLoading] = useState(false)

	const onCompanySelected = useCallback((companyId: string) => {
		history.push("/companies/" + companyId)
	}, [history])

	const onAddCompany = useCallback(() => {
		history.push("/companies/add/company")
	}, [history])

	useEffect(() => {
		(async () => {
			try {
				setGlobalMessage(null)
				setLoading(true)
				const companies = await client.getCurrentCompanies()
				setCompanies(companies)

			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])
	return {
		companies,
		onCompanySelected,
		loading,
		onAddCompany
	}
}