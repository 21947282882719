

const clientCredentialPaths = {
    base: "/client-credentials",
    clientCredentials: "",
    clientCredential: "/:clientcredentialid",
}

export { clientCredentialPaths as clientCredential }

