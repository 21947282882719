import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUserClient, IUserClientId, IUserRoleClient, IUserRoleClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useAppContext } from "../../../hook/use-app-context"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import useClient from "../../../hook/useClient"
import { useUserRole } from "../../../hook/useUserRole"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"


export const useUserRoles = () => {
	const history = useHistory()
	let { id: userId } = useParams<{ id: string }>();
	const { user, setUser, userRoles: signedUserRoles } = useAppContext()
	const { setGlobalMessage } = useGlobalMessageContext()
	const { isCompanyAdmin } = useUserRole()
	const [loading, setLoading] = useState(false)
	const userClient = useClient<IUserClient>(IUserClientId)
	const userRoleClient = useClient<IUserRoleClient>(IUserRoleClientId)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [userRoles, setUserRoles] = useState<dto.UserRole[] | null>(null)
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)


	const loadUserRoles = useCallback((companyId: string | null) => {
		(async () => {
			try {
				if (companyId == null) {
					setUserRoles([])
					return
				}
				setLoading(true)
				const user = await userClient.getUser(userId)
				if (user == null) {
					history.push("/not-found")
				}

				let userRoles = user.roles.filter(x => x.companyId === companyId && (x.roleId === dto.RoleId.companyAdmin || x.roleId === dto.RoleId.companyUser))
				setUserRoles(userRoles)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()

	}, [history, userClient, userId, setGlobalMessage])

	const onSelectedCompany = useCallback((companyId: string | null) => {
		setSelectedCompanyId(companyId)
		loadUserRoles(companyId)
	}, [setSelectedCompanyId, loadUserRoles])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const user = await userClient.getUser(userId)
				if (user == null) {
					history.push("/not-found")
				}

				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)

				const companyUserRole = user.roles.find(x => x.companyId != null)
				let _selectedCompanyId: string | null = null
				if (companyUserRole != null) {
					_selectedCompanyId = companyUserRole.companyId
				}
				else if (companies.length > 0) {
					_selectedCompanyId = companies[0].id
				}

				if (_selectedCompanyId != null) {
					setSelectedCompanyId(_selectedCompanyId)
					loadUserRoles(_selectedCompanyId)
				}
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])


	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies)
	}, [companies])


	const addCompanyAdminRole = useCallback(() => {
		(async () => {
			const request = new dto.AddUserRoleRequest()
			request.companyId = selectedCompanyId!
			request.roleId = dto.RoleId.companyAdmin
			request.userId = userId
			await userRoleClient.addUserRole(request)
			loadUserRoles(selectedCompanyId!)
		})()
	}, [loadUserRoles, selectedCompanyId, userId, userRoleClient])

	const deleteUserRole = useCallback((userRoleId: string) => {
		(async () => {
			const userRole = userRoles!.find(x => x.id === userRoleId)
			const wasCompanyUserRole = userRole?.roleId === dto.RoleId.companyUser
			await userRoleClient.deleteUserRole(userRoleId)
			const user = await userClient.getCurrentUser()
			setGlobalMessage({ message: "User role deleted", type: "success" })
			setUser(user)
			if (wasCompanyUserRole) {
				history.replace("/account")
			}
			else {
				loadUserRoles(selectedCompanyId!)
			}
		})()
	}, [loadUserRoles, selectedCompanyId, userRoleClient, history, setGlobalMessage, userRoles, userClient, setUser])

	const isAllowedCompanyAdminToAdd = useCallback(() => {
		if (userRoles == null) {
			return false
		}
		if (selectedCompanyId != null) {
			const _isCompanyAdmin = isCompanyAdmin(user, selectedCompanyId)
			const _isAdmin = signedUserRoles.isAdmin
			if (_isCompanyAdmin || _isAdmin) {
				const companyUserRole = userRoles.find(x => x.companyId === selectedCompanyId && x.roleId === dto.RoleId.companyUser)
				if (companyUserRole != null) {
					return userRoles.find(x => x.companyId === selectedCompanyId && x.roleId === dto.RoleId.companyAdmin) == null
				}
			}
		}
		return false
	}, [userRoles, selectedCompanyId, isCompanyAdmin, user, signedUserRoles.isAdmin])

	const userNameSuffix = useMemo(() => {
		if (user == null) {
			return "-"
		}
		return ` - ${SharedDataHelper.firstLetterUppercase(user.firstName)} ${SharedDataHelper.firstLetterUppercase(user.lastName)}`
	}, [user])

	return {
		userRoles,
		loading,
		companyItems,
		selectedCompanyId,
		onCompanySelected: onSelectedCompany,
		addCompanyAdminRole,
		deleteUserRole,
		isAllowedCompanyAdminToAdd,
		userNameSuffix
	}
}