import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { ICompanyClient, ICompanyClientId, IUserAttributeNameClient, IUserAttributeNameClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useAppContext } from "../../../hook/use-app-context";
import { useLocalization } from "../../../hook/use-localization";


interface Model {
    userAttributeNameId: string | null,
    companyId: string | null,
    name: string,
    description: string,
    attributeType: dto.UserAttributeNameType | null,
    selectValues: string
    readonly: boolean,
    companies: dto.Company[],
    originalModel: Omit<Model, "originalModel"> | null
}

export type UserAttributeNameModel = Model

const createModel = (
    userAttributeName: dto.UserAttributeName | null,
    companies: dto.Company[],
    selectedCompanyId: string | null,
    readonly: boolean,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        userAttributeNameId: userAttributeName?.id ?? null,
        companyId: selectedCompanyId,
        name: userAttributeName?.name ?? "",
        description: userAttributeName?.description ?? "",
        companies: companies,
        attributeType: userAttributeName?.attributeType ?? null,
        selectValues: userAttributeName?.selectValues?.join("\n") ?? "",
        originalModel: originalModel ?? null
    }
}

const createUpdateUserAttributeNameRequest = (model: Model): dto.UpdateUserAttributeNameRequest => {
    const request = new dto.UpdateUserAttributeNameRequest()
    request.name = model.name
    request.description = model.description
    request.selectValues = null
    if (model.attributeType === dto.UserAttributeNameType.select) {
        request.selectValues = model.selectValues.trim().split("\n").filter(x => x !== "")
    }
    return request
}

const createAddUserAttributeNameRequest = (model: Model): dto.AddUserAttributeNameRequest => {
    const request = new dto.AddUserAttributeNameRequest()
    request.name = model.name
    request.description = model.description
    request.companyId = model.companyId!
    request.attributeType = model.attributeType!
    if (request.attributeType === dto.UserAttributeNameType.select) {
        request.selectValues = model.selectValues.trim().split("\n").filter(x => x !== "")
    }
    return request
}

export const useUserAttributeName = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const { t } = useLocalization()
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userAttributeName, setUserAttributeName] = useState<dto.UserAttributeName | null>(null)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId)
    const { userRoles } = useAppContext()


    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                let companies = await companyClient.getCurrentCompanies()
                let readonly = id != null
                let userAttributeName: dto.UserAttributeName | null = null
                let companyId: string | null = null
                if (id != null) {
                    userAttributeName = await userAttributeNameClient.getUserAttributeName(id)
                    companyId = userAttributeName.companyId
                    companies = companies.filter(x => x.id === companyId)
                } else {
                    companyId = CompanyHelper.getDefaultCompany(companies)
                }

                const originalModel = createModel(userAttributeName, companies, companyId, readonly)
                const model = createModel(userAttributeName, companies, companyId, readonly, originalModel)
                setModelInitialValues(model)
                setUserAttributeName(userAttributeName)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, userAttributeNameClient, companyClient])


    useEffect(() => {
        loadInitialModel()
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        let validateErrors: ValidationError[] = []
        if (id != null) {
            const request = createUpdateUserAttributeNameRequest(model)
            validateErrors = await validate(request);
        } else {
            const request = createAddUserAttributeNameRequest(model)
            validateErrors = await validate(request);
        }
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["name", "description", "companyId", "attributeType", "selectValues"], errorModel)
        return errorModel
    }, [id])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            if (id != null) {
                const request = createUpdateUserAttributeNameRequest(model)
                await userAttributeNameClient.updateUserAttributeName(id, request)
                loadInitialModel()
                setGlobalMessage({ message: t("UserAttributeName.Updated"), type: "success" })
            } else {
                const request = createAddUserAttributeNameRequest(model)
                const createdUserAttributeName = await userAttributeNameClient.createUserAttributeName(request)
                history.replace("/user-attribute-names/" + createdUserAttributeName.id)
                setGlobalMessage({ message: t("UserAttributeName.Added"), type: "success" })
            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["name", "description", "companyId"] })
    }, [id, userAttributeNameClient, loadInitialModel, setGlobalMessage, history, t])

    const onDeleteUserAttributeName = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await userAttributeNameClient.deleteUserAttributeName(id)
                history.replace("/user-attribute-names")
                setGlobalMessage({ message: t("UserAttributeName.Deleted"), type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, userAttributeNameClient, history, setGlobalMessage, t])

    const isAddingNewUserAttributeName = useMemo(() => {
        return id == null
    }, [id])

    const isAllowedToDeleteAttributeName = useMemo(() => {
        return userRoles.isAdmin && id != null && !(
            userAttributeName?.name === dto.UserAttribute.sharedAttributes.dateOfBirth ||
            userAttributeName?.name === dto.UserAttribute.sharedAttributes.gender
        );
    }, [id, userRoles.isAdmin, userAttributeName?.name])

    const isAllowedToEditAttributeName = useMemo(() => {
        return id != null && !(
            userAttributeName?.name === dto.UserAttribute.sharedAttributes.dateOfBirth ||
            userAttributeName?.name === dto.UserAttribute.sharedAttributes.gender
        );
    }, [id, userAttributeName?.name])

    const onAddNewUserAttributeName = useCallback(() => {
        history.replace(`/user-attribute-names/add/user-attribute-name`)
    }, [history])

    const onViewAllUserAttributeNames = useCallback(() => {
        history.replace(`/user-attribute-names`)
    }, [history])




    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteUserAttributeName,
        isAllowedToDeleteAttributeName,
        isAllowedToEditAttributeName,
        isAddingNewUserAttributeName,
        onAddNewUserAttributeName,
        onViewAllUserAttributeNames
    }
}

export const useUnregisteredUserForm = () => {
    const { t } = useLocalization()
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies)
    }, [model.companies])

    const companyDropDownEnabled = useMemo(() => {
        return !model.readonly && model.userAttributeNameId == null
    }, [model.readonly, model.userAttributeNameId])

    const attributeTypes = useMemo(() => {
        return [
            { text: t("UserAttributeType.Exists"), value: dto.UserAttributeNameType.exists },
            { text: t("UserAttributeType.Select"), value: dto.UserAttributeNameType.select },
            { text: t("UserAttributeType.Text"), value: dto.UserAttributeNameType.text },
            { text: t("UserAttributeType.Number"), value: dto.UserAttributeNameType.number },
            { text: t("UserAttributeType.Date"), value: dto.UserAttributeNameType.date }
        ]
    }, [])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    const saveUserEnabled = useMemo(() => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.companyId)
    }, [model.companyId])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        attributeTypes,
        saveUserEnabled,
        companyItems,
        companyDropDownEnabled
    }
}
