import { ValidationError } from "class-validator"

export class ErrorResponse {

    //400 Bad Request
    static readonly BAD_INPUT_DATA = "bad_input_data"

    //401 Unauthorized
    static readonly UNAUTHORIZED = "unauthorized"

    // 403 Forbidden
    static readonly FORBIDDEN = "forbidden";

    //404 Not Found
    static readonly NOT_FOUND = "not_found";

    //Mapped to also 400 Bad Request
    static readonly VALIDATION = "validation";

    //500
    static readonly INTERNAL_SERVER_ERROR = "internal_server_error";

    dataKey?: string
    message?: string
    type: ErrorResponseType
    validationErrors?: ValidationError[]
    requestedUrl: string

    readonly status = "error"
    readonly serverError = true;
}

export type ErrorResponseType = "bad_input_data" | "unauthorized" | "forbidden" | "not_found" | "validation" | "internal_server_error";