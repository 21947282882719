export class PredefinedNotificationTemplate {
    type: string
    nameEn: string
    nameSr: string
    previewUrl: string
    design: string


    constructor(options: {
        type: string,
        nameEn: string,
        nameSr: string,
        previewUrl: string,
        design: string
    }) {
        if (options == null) {
            return
        }

        this.type = options.type
        this.nameEn = options.nameEn
        this.nameSr = options.nameSr
        this.previewUrl = options.previewUrl
        this.design = options.design
    }
}
