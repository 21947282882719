import { IsEmail } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";


export class UserRecoverPasswordRecoverRequest {
    @ContainsValidString()
    @IsEmail()
    email: string;

    @ContainsValidString()
    clientId: string;
}
