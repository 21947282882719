import { ConsentSourceName } from "../consent"
import { User } from "../user"
import { CompanyStatus } from "./company-status"

export class Company {
    id: string
    email: string
    name: string
    address: string
    status: CompanyStatus
    timezone: string
    users: User[]
    consentSourceName: ConsentSourceName
    createdDate: Date
    updatedDate: Date
}