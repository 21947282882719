


const notificationTaskPaths = {
    base: "/notificationTasks",
    addBatchNotificationTasks: "/add-batch",
    notificationTasks: "",
    notificationTask: "/:notificationtaskid"
}

export { notificationTaskPaths as notificationTask }

