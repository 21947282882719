import { QueuedJobQueueName } from "./queued-job-queue-name"
import { QueuedJobStatus } from "./queued-job-status"

export class QueuedJob {
    id: string
    status: QueuedJobStatus
    queueName: QueuedJobQueueName
    data: any
    date: Date
    errorDetails: string | undefined

    constructor(options: { id: string, status: QueuedJobStatus, queueName: QueuedJobQueueName, data: any, date: Date, errorDetails: string | undefined }) {
        this.id = options.id
        this.status = options.status
        this.queueName = options.queueName
        this.data = options.data
        this.date = options.date
        this.errorDetails = options.errorDetails
    }
}