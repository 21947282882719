import { SharedDataHelper, dto } from "shared";
import { ITokenRepository } from "./i-token-repository";

export class ClientCredentialsTokenRepository implements ITokenRepository {

    private accessToken: string
    constructor(authResponse: dto.OauthTokensResponse) {
        if (authResponse.grantType != "client_credentials") {
            throw new Error("Invalid grantType for client_credentials:" + authResponse.grantType)
        }
        if (authResponse.refreshToken != null) {
            throw new Error("Invalid refreshToken(must be null) for client_credentials:" + authResponse.refreshToken)
        }

        if (SharedDataHelper.stringIsNullTrimEmpty(authResponse.accessToken)) {
            throw new Error("Access token missing for client_credentials")

        }
        this.accessToken = authResponse.accessToken
    }

    async getAccessToken(): Promise<string> {
        return this.accessToken
    }

    async saveAccessToken(accessToken: string): Promise<void> {
        this.accessToken = accessToken
    }

    async deleteAccessToken(): Promise<void> {
        this.accessToken = null
    }

    get refreshTokenSupported(): boolean {
        return false
    }

    async getRefreshToken(): Promise<string> {
        return null
    }

    async saveRefreshToken(refreshToken: string): Promise<void> {
        throw Error("Not supported setting refresh token for ClientCredentialsTokenRepository")
    }

    async deleteRefreshToken(): Promise<void> {

    }
}