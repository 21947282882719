
import { Transform } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";
import { BooleanTransform } from "../../transform";

export class GetConsentsRequest {

    @IsOptional()
    @IsBoolean()
    @Transform(BooleanTransform)
    sendSmsPromo?: boolean | null

    @IsOptional()
    @IsBoolean()
    @Transform(BooleanTransform)
    sendEmailPromo?: boolean | null

    @IsOptional()
    @IsBoolean()
    @Transform(BooleanTransform)
    dataProcessing?: boolean | null


}