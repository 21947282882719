import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { INotificationTaskClient } from "./i-notification-task-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class NotificationTaskClient extends BaseClient implements INotificationTaskClient {

    async getNotificationTasks(data: dto.GetNotificationTasksRequest): Promise<dto.PagingResponse<dto.NotificationTask>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTask.base + apiPaths.notificationTask.notificationTasks), params: data });
            return response.data as dto.PagingResponse<dto.NotificationTask>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getNotificationTask(notificationtaskId: string): Promise<dto.NotificationTask> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTask.base + apiPaths.notificationTask.notificationTask.replace(":notificationtaskid", notificationtaskId)) });
            return response.data as dto.NotificationTask;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addNotificationTask(data: dto.AddNotificationTaskRequest): Promise<dto.NotificationTask> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.notificationTask.base + apiPaths.notificationTask.notificationTasks), data: data });
            return response.data as dto.NotificationTask;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteNotificationTask(notificationtaskId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.notificationTask.base + apiPaths.notificationTask.notificationTask.replace(":notificationtaskid", notificationtaskId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}