import { isBoolean, IsBoolean, IsEnum, isEnum, IsOptional, IsString } from "class-validator"
import { ConsentSourceName } from "../consent"
import { LambdaExpression } from "../../../validators/lambda-expression"


export abstract class BaseMessageSettingsConfiguration {

    @IsOptional()
    @IsString()
    notificationApiKey: string

    @IsOptional()
    @IsString()
    notificationApiSecret: string

    @IsOptional()
    @IsString()
    promoApiKey: string

    @IsOptional()
    @IsString()
    promoApiSecret: string

    @IsBoolean()
    notificationEnabled: boolean

    @LambdaExpression({
        expression: (obj: BaseMessageSettingsConfiguration) => {
            if (!isBoolean(obj.promoEnabled)) {
                // other validation will check boolean
                return true
            }
            if (!isBoolean(obj.notificationEnabled)) {
                // other validation will check boolean
                return true
            }
            if (obj.promoEnabled && !obj.notificationEnabled) {
                return false
            }
            return true
        }
    }, { message: "PromoVsNotificationEnabledValidation" })
    _metaPromoVsNotificationEnabled? = true

    @IsBoolean()
    promoEnabled: boolean



    constructor(options: { notificationEnabled: boolean, promoEnabled: boolean, notificationApiKey: string, notificationApiSecret: string, promoApiKey: string, promoApiSecret: string }) {
        if (options == null) {
            return
        }
        this.notificationEnabled = options.notificationEnabled
        this.notificationApiKey = options.notificationApiKey
        this.notificationApiSecret = options.notificationApiSecret
        this.promoEnabled = options.promoEnabled
        this.promoApiKey = options.promoApiKey
        this.promoApiSecret = options.promoApiSecret
    }

}