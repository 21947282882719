
import { SortItem } from "../api/dto/common/query/sort-item";
import { SortTypeValues } from "../api/dto/common/query/sort-type";
import { transformAndValidate } from "class-transformer-validator";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface AreValidSortItemsOptions {
    allowed: string[]
}

@ValidatorConstraint({ async: true })
export class AreValidSortItemsConstraint implements ValidatorConstraintInterface {

    async validate(obj: any, args: ValidationArguments) {
        var valid = await this.validateSortItems(obj, args);
        return valid;
    }

    private async validateSortItems(obj: any, args: ValidationArguments): Promise<boolean> {
        try {
            if (Array.isArray(obj)) {
                for (let i = 0; i < obj.length; i++) { 
                    const item = obj[i];
                    const sortItem = await transformAndValidate(SortItem, item) as SortItem;
                    if (args.constraints.find(x => x == sortItem.name) == null) {
                        return false;
                    }
                }
                return true;
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        return `Sort items must be specified with sort names:${args.constraints.toString()} and sort type values:${SortTypeValues.toString()}`;
    }
}

export function AreValidSortItems(options: AreValidSortItemsOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: options.allowed,
            validator: AreValidSortItemsConstraint
        });
    };
}