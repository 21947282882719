export const copyElementInnerText = (id: string) => {
  var textToCopy = window!.document.getElementById(id)!.innerText;
  var tempInput = document.createElement('input');
  tempInput.value = textToCopy;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
}

export const copyElementValue = (id: string) => {
  var textToCopy = (window!.document.getElementById(id)! as any).value;
  var tempInput = document.createElement('input');
  tempInput.value = textToCopy;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
}