import { Form, Formik } from "formik"
import React from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { Loading } from "../../../_common/components/loading/loading"
import { CheckBoxField } from "../../../_common/fields/checkbox/check-box-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./account-change-password"
import { AccountChangePasswordModel, useAccountAccountChangePassword, useAccountChangePasswordForm } from "./useAccountChangePassword"
import { useLocalization } from "../../../hook/use-localization"


export const AccountChangePassword: React.FC = () => {
	const { t } = useLocalization();
	const { modelInitialValues, onSubmit, onValidate, loading } = useAccountAccountChangePassword()

	return (
		<Shell pageTitle={t("Account") + " - " + t("ChangePassword")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>

			</>
			}
		</Shell >
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization()
	const { model } = useTypedFormikContext<AccountChangePasswordModel>()
	const { toggleShowPassword } = useAccountChangePasswordForm()
	return (
		<div className="indent-container">
			<TextInput name="oldPassword" type={model.showPassword ? "text" : "password"} label={t("OldPassword")} />
			<TextInput name="password" type={model.showPassword ? "text" : "password"} label={t("NewPassword")} />
			<TextInput name="confirmPassword" type={model.showPassword ? "text" : "password"} label={t("ConfirmNewPassword")} />
			<CheckBoxField name="showPassword" onChange={toggleShowPassword} className="user-registration-initial__show_password" label={t("ShowPassword")} />
			<div className="action-buttons-row">
				{<ButtonSubmit>{t("Save")}</ButtonSubmit>}
			</div>
		</div>)
}
