import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserActionsClient } from "./i-user-actions-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserActionsClient extends BaseClient implements IUserActionsClient {

    async invoke(actionId: string): Promise<void> {
        try {
            await this.publicAxios({ method: 'get', url: (apiPaths.userAction.base + apiPaths.userAction.action.replace(":actionid", actionId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUnregisterUserDetails(actionId: string, userId: string): Promise<dto.UserActionGetUnregisteredUser> {
        try {
            const response = await this.publicAxios({ method: 'get', url: (apiPaths.userAction.base + apiPaths.userAction.unregisteredUserDetails.replace(":actionid", actionId).replace(":userid", userId)) });
            return response.data as dto.UserActionGetUnregisteredUser;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateUnregisterUserDetails(actionId: string, userId: string, data: dto.UserActionUpdateUnregisteredUserRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'patch', url: (apiPaths.userAction.base + apiPaths.userAction.unregisteredUserDetails.replace(":actionid", actionId).replace(":userid", userId)), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}