import React from "react"
import { combineClassNames } from "../../helpers/user-interface-helper"
import "./no-entities.scss"
import { useLocalization } from "../../../hook/use-localization"
interface Props {
	className?: string,
	message?: string
}
export const NoEntities: React.FC<Props> = ({ className, message }) => {
	const { t } = useLocalization()
	return (<div className={combineClassNames("no-entities__container", className)}>{message ?? t("NoResults")}</div>)
}
