
import React, { useCallback, useEffect } from "react";
import { LinkButton } from "../../common/link-button/LinkButton";
import "./shell.scss"
import { useHistory } from "react-router-dom";
import { ShellMenuItem, useShell } from "./useShell";
import { WebSocketConnectionInfo } from "../../components/ws-connection/ws-connection-info";
import { Languages } from "../../components/language/language/languages";
import { useLocalization } from "../../../hook/use-localization";

type ShellProps = {
  pageTitle: string | React.ReactNode
  activePageLink?: "unknown" | "home" | "settings",
  hideNavigationLinks?: boolean,
  error?: string | null,
  desktopHeaderContent?: React.ReactNode
  mobileHeaderContent?: React.ReactNode
};
const Shell: React.FC<React.PropsWithChildren<ShellProps>> = (props) => {
  const { t } = useLocalization()
  const { openMobileMenu, closeMobileMenu, mobileMenuOpen, menuItems, isTablet, isMobile, user, globalMessage, setGlobalMessage, roleDescription, appType, userCompanyName } = useShell()
  const desktopMenuItemsElements = !isTablet ? menuItems.filter(x => x.id !== "logout").map(x => <DesktopSideBarItem {...x} key={`dsbi:${x.id}`} />) : []
  const mobileMenuItemsElements = isTablet ? menuItems.map(x => <MobileMenuItem {...x} />) : []
  const logoutMenuItem = menuItems.find(x => x.id === "logout")
  const isTabletButNotMobile = isTablet && !isMobile

  useEffect(() => {
    const oldMessageStr = JSON.stringify(globalMessage)
    let timeoutId = setTimeout(() => {
      if (JSON.stringify(globalMessage) === oldMessageStr) {
        setGlobalMessage(null)
      }
    }, 3000);

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
      }

    }
  }, [globalMessage])

  const getErrorMsg = useCallback((message: string | null | undefined) => {
    if (message == null) {
      return t("GeneralError")
    }
    return t(message!.replaceAll(":", "."))
  }, [t])

  return (
    <>
      {!isTablet && <div className="desktop-shell__container">
        <div className={"desktop-shell__header " + appType}>
          <img className="desktop-shell__header-img" src="/d.s.logo.svg" alt={""} />
          <Languages className="desktop-shell_lang" />
          <div className="desktop-shell__header-user">{user?.firstName} {user?.lastName}
              <div className="desktop-shell__header-user-role-name">{userCompanyName && <>{userCompanyName} - </>} {roleDescription}</div>
          </div>
          <LinkButton className="logout" onClick={logoutMenuItem!.onClick}>{logoutMenuItem!.text}</LinkButton>
        </div>
        <div className="desktop-shell__side-bar-content">
          <div className="desktop-shell__side-bar">
            {desktopMenuItemsElements}
            <WebSocketConnectionInfo className="ws-sidebar-ws-info" />
          </div>

          <div id="desktop-shell__content" className="desktop-shell__content">
            <div className="desktop-shell__content-header">
              <div className="desktop-shell__content-title">{props.pageTitle}</div>
              {props.desktopHeaderContent}
            </div>

            {globalMessage?.type === "error" && <div className="shell__error error-text">
              {getErrorMsg(globalMessage?.message)}
            </div>}
            {globalMessage?.type === "success" && <div className="shell__success text-success">
              {globalMessage?.message ?? "Success"}
            </div>}
            {globalMessage?.type === "info" && <div className="shell__info text-info">
              {globalMessage?.message}
            </div>}

            <div>
              {props.children}
            </div>
          </div>


        </div>
        <div className="desktop-shell__footer"></div>
      </div>}

      {isTablet &&
        <>
          <div className="mobile-shell__container" >
            <div className={"mobile-shell__header " + appType}>
              <img className="mobile-shell__header-img" src="/d.s.logo.svg" alt={""} />
              <div className="mobile-shell__header-title">{props.pageTitle}</div>
              {props.mobileHeaderContent}
              <div className="mobile-shell__header-user-name">{user?.firstName?.substring(0, 1)} {user?.lastName.substring(0, 1)}</div>
              <button className="mobile-shell_open-menu" onClick={openMobileMenu}>
                <img src="/mobile-menu.svg" alt="" />
              </button>
            </div>
            <div className={"mobile-shell__content-container " + (isTabletButNotMobile ? "is-tablet-not-mobile" : "")}>
              {globalMessage?.type === "error" && <div className="mobile-shell__error error-text indent-container">
                {getErrorMsg(globalMessage?.message)}
              </div>}
              {globalMessage?.type === "success" && <div className="mobile-shell__success text-success indent-container">
                {globalMessage?.message ?? "Success"}
              </div>}
              {globalMessage?.type === "info" && <div className="mobile-shell__info text-info indent-container">
                {globalMessage?.message}
              </div>}
              <div className="mobile-shell__content">{props.children}</div>
            </div>
          </div>
          {mobileMenuOpen && <div className="mobile-menu__container">
            <div className="mobile-menu__background-container" onClick={closeMobileMenu}></div>
            <div className="mobile-menu__content-container">
              <div className="mobile-menu__title-container" >
                <button className="mobile-shell_close-menu" onClick={closeMobileMenu}>
                  <img src="/close-mobile-menu.svg" alt="" />
                </button>

              </div>
              {mobileMenuItemsElements}
            </div>
          </div>}
        </>}


    </>

  )
}



const DesktopSideBarItem: React.FC<ShellMenuItem> = ({ id, imagePath, text, navigationPath, onClick }) => {
  const history = useHistory();

  const onSideBarItemClick = useCallback(() => {
    if (onClick != null) {
      onClick()
    } else {
      history.replace(navigationPath!)
    }
  }, [history, navigationPath, onClick])

  const clsName = "desktop-sidebar-item__container desktop-sidebar-item__container-" + id
  return (
    <div className={clsName}>
      <img src={imagePath} alt={""} />
      <button onClick={onSideBarItemClick}>{text}</button>
    </div>)
}

const MobileMenuItem: React.FC<ShellMenuItem> = ({ id, imagePath, text, navigationPath, onClick }) => {
  const history = useHistory();

  const onMenuItemClick = useCallback(() => {
    if (onClick != null) {
      onClick()
    } else {
      history.replace(navigationPath!)
    }
  }, [history, navigationPath, onClick])

  const clsName = "mobile-item__container mobile-item__container-" + id
  return (
    <div className={clsName} onClick={onMenuItemClick}>
      <img src={imagePath} alt={""} />
      <button >{text}</button>
    </div>)
}


export default Shell





