import { IsArray, IsDate, IsOptional, IsString, MaxLength } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";

export class UpdateEventRequest {

    @ContainsValidString()
    eventTypeId: string;

    @IsDate()
    date: Date;

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsString()
    @MaxLength(1000)
    description?: string


    @IsArray()
    @ContainsValidString({ each: true })
    unregisterUserIds: string[]


    @IsArray()
    @ContainsValidString({ each: true })
    userIds: string[]
}