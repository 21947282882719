import { Contains } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";

export class ClientCredentialGrant {

    @ContainsValidString()
    clientId: string;

    @ContainsValidString()
    clientSecret: string;

    @ContainsValidString()
    grantType: string
}