import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { IsDefined, IsOptional, ValidateNested } from "class-validator";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { UserAttributeQueryCondition } from "../user-attribute/user-attribute-query";

export class GetUnregisteredUsersRequest {
    @IsOptional()
    @ContainsValidString()
    firstName?: string;

    @IsOptional()
    @ContainsValidString()
    lastName?: string;

    @ContainsValidString()
    companyId: string;

    @IsOptional()
    @ValidateNested()
    query?: UserAttributeQueryCondition | null

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["lastName", "firstName", "createdDate"] })
    sortBy: SortItem[]
}


