import { NotificationTaskDetails } from "../notification-task-details";

export class EmailNotificationTaskDetails extends NotificationTaskDetails {

    email: string | null;
    bodyToSend: string | null
    sentBody: string | null;
    subjectToSend: string | null
    sentSubject: string | null
    sentToEmail: string | null

    constructor(options: {
        email: string | null;
        bodyToSend: string | null
        sentBody: string | null;
        subjectToSend: string | null
        sentSubject: string | null,
        sentToEmail: string | null
    }) {
        super()
        this.email = options.email
        this.bodyToSend = options.bodyToSend
        this.sentBody = options.sentBody
        this.subjectToSend = options.subjectToSend
        this.sentSubject = options.sentSubject
        this.sentToEmail = options.sentToEmail
    }

}