import React from "react";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import "./auth-shell.scss"
import { useLocalization } from "../../../hook/use-localization";
import { Languages } from "../../components/language/language/languages";

type AuthShellProps = React.PropsWithChildren<{ title: string, error?: string | null }>
const AuthShell: React.FC<React.PropsWithChildren<AuthShellProps>> = (props) => {
  const { t } = useLocalization()
  const { globalMessage } = useGlobalMessageContext()
  return (
    <>
      <div className="auth-shell__container">
        <div className="auth-shell__content-wrapper">
          <Languages className="auth-languages" />
          <div className="auth-shell__content-logo">
            <img src="/d.s.logo-2.svg" alt={""} style={{ width: "7rem", height: "7rem" }} className="align-middle" />
          </div>
          <div className="auth-shell__content-title">
            {props.title}
          </div>
          <div className="auth-shell__content">
            {props.children}
          </div>
          {globalMessage?.type === "error" && <div className="auth-shell__error error-text">
            {t(globalMessage?.message ?? "GeneralError")}
          </div>}
          {globalMessage?.type === "success" && <div className="auth-shell__success text-success">
            {t(globalMessage?.message ?? "Success")}
          </div>}
          {globalMessage?.type === "info" && <div className="auth-shell__info text-info">
            {t(globalMessage?.message)}
          </div>}
        </div>
      </div>

    </>
  );
}

export default AuthShell;








