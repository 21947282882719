import { dto } from "shared"

export const IUserAttributeNameClientId = Symbol.for('IUserAttributeNameClient');

export interface IUserAttributeNameClient {
    getUserAttributeNames(data: dto.GetUserAttributeNamesRequest): Promise<dto.PagingResponse<dto.UserAttributeName>>
    getUserAttributeName(userattributenameId: string): Promise<dto.UserAttributeName>
    createUserAttributeName(data: dto.AddUserAttributeNameRequest): Promise<dto.UserAttributeName>
    updateUserAttributeName(userattributenameId: string, data: dto.UpdateUserAttributeNameRequest): Promise<void>
    deleteUserAttributeName(userattributenameId: string): Promise<void>
}