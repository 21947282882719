export class DataUtil {
  static firstLetterUppercase(text: string | null | undefined): string | null | undefined {
    if (text == null) {
      return text
    }

    if (text.length === 1) {
      return text.toUpperCase();
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}