import { IsBoolean, IsNotEmptyObject, IsOptional, MinLength, Validate, ValidateNested } from 'class-validator';
import { ContainsValidString } from '../../../../validators/contains-valid-string';
import { ValidateIfNotStrictUndefined } from '../../../../validators/validate-if-not-strict-undefined';
import { NotificationTemplateDetails, UserAttributeQueryCondition } from '../..';
import { Type } from 'class-transformer';
import { NotificationSpecificFactory } from '../specific/notification-specific-factory';

export class UpdateNotificationTemplateRequest {


    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(3)
    name?: string | undefined;

    @ValidateIfNotStrictUndefined()
    @IsNotEmptyObject()
    @ValidateNested()
    @Type(({ object }) => {
        // check comment A10923 for templateType
        const notificationSpecific = NotificationSpecificFactory.createByTemplate(object.templateType)
        return notificationSpecific.notificationTemplateDetails.constructor
    })
    details?: NotificationTemplateDetails


    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsBoolean()
    isUserRelated?: boolean

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsBoolean()
    isUnregisteredUserRelated?: boolean

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString()
    eventTypeId?: string | null | undefined

    @IsOptional()
    @ValidateNested()
    query?: UserAttributeQueryCondition | null
}