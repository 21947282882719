import React, { ReactChild, useMemo } from "react"
import { dto } from "shared"
import { Dropdown, DropdownItem } from "../../common/dropdown/DropDown"
import "./company-dropdown.scss"
import { useCallback } from "react"
import { useLocalization } from "../../../hook/use-localization"

export interface CompanyDropdownItem {
	name: string,
	address: string,
	companyId: string | null
}

export const createCompanyDropDownItems = (companies: dto.Company[], options?: { addEmptyItem?: boolean }): CompanyDropdownItem[] => {
	const array: CompanyDropdownItem[] = []
	if (options?.addEmptyItem) {
		array.push({
			companyId: null,
			name: "-",
			address: " "
		})
	}
	if (companies != null) {
		for (const company of companies) {
			array.push({
				companyId: company.id,
				name: company.name,
				address: company.address
			})
		}
	}
	return array
}

interface Props {
	items: CompanyDropdownItem[],
	selectedCompanyId: string | null,
	onSelect: (companyId: string | null) => void
}

export const CompanyDropdown: React.FC<Props> = ({ items, selectedCompanyId, onSelect }) => {
	const { t } = useLocalization()
	const dropdownItems: DropdownItem[] = items.map(c => {
		const textNode: ReactChild = <div className="company-dropdown__item"><div className="company-dropdown__item-name">{c.name}</div><div className="company-dropdown__item-address">{c.address}</div></div>
		return {
			textNode,
			value: c.companyId == null ? "______null-value" : c.companyId as any,
			text: c.name
		}
	})

	const companyOnSelect = useCallback((companyId: string | null) => {
		document.cookie = `defaultCompanyId=${companyId}; expires=Sun, 1 Jan 2090 00:00:00 UTC; path=/`
		onSelect(companyId === "______null-value" ? null : companyId)
	}, [onSelect])

	return (
		<Dropdown label={t("Company")} className="company-dropdown" items={dropdownItems} onSelect={companyOnSelect} selectedValue={selectedCompanyId} ></Dropdown>
	)
}