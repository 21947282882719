import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, INotificationTemplateClient, INotificationTemplateClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { CompanyHelper } from "../../../_common/helpers/company-helper"

const pageSize = 20

export const useNotificationTemplates = () => {
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const notificationTemplateClient = useClient<INotificationTemplateClient>(INotificationTemplateClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [notificationTemplates, setNotificationTemplates] = useState<dto.NotificationTemplate[]>([])
    const [companies, setCompanies] = useState<dto.Company[]>([])
    const { pageIndex, setPageIndex, pagination, setTotalNumberOfPages } = usePagination()
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)

    const onNotificationTemplateSelected = useCallback((notificationTemplateId: string) => {
        history.push("/notification-templates/" + notificationTemplateId)
    }, [history])


    useEffect(() => {
        (async () => {
            try {
                if (selectedCompanyId == null) {
                    setNotificationTemplates([])
                    return
                }
                setLoading(true)
                setNotificationTemplates([])
                const request = new dto.GetNotificationTemplatesRequest()
                request.companyId = selectedCompanyId
                request.paging = new dto.PagingRequest({ pageIndex, pageSize })
                request.sortBy = []
                request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
                const pagedResponse = (await notificationTemplateClient.getNotificationTemplates(request))
                setNotificationTemplates(pagedResponse.entities)
                setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [pageIndex, selectedCompanyId])

    useEffect(() => {
        setPageIndex(0)
    }, [selectedCompanyId])

    // Load companies
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const companies = await companyClient.getCurrentCompanies()
                setCompanies(companies)
                const defaultCompanyId = CompanyHelper.getDefaultCompany(companies)
                setSelectedCompanyId(defaultCompanyId)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])

    const onAddTemplate = useCallback(() => {
        history.push("/notification-templates/add/notification-template/")
    }, [history])

    const addNewUserEnabled = useMemo(() => {
        return companies.length > 0
    }, [companies])

    return {
        notificationTemplates,
        onNotificationTemplateSelected,
        loading,
        companyItems,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        pagination,
        onAddTemplate,
        addNewUserEnabled
    }
}