import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { SharedDataHelper, dto } from "shared";
import { ICompanyClient, ICompanyClientId, INotificationTaskClient, INotificationTaskClientId } from "shared-client";
import { handleError, submitWrapper } from "../../../../common/form/form";
import useClient from "../../../hook/useClient";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { notification } from "../../../../common/notification/notification";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { NotificationSpecificFactory } from "../../../../common/notification/notification-specific-factory";
import { useLocalization } from "../../../hook/use-localization";
import { useAppContext } from "../../../hook/use-app-context";


// only status is allowed to be changed
interface Model {
    readonly: boolean,
    notificationTaskId: string | null,
    userId: string | null,
    userName: string,
    notificationAddress: string | null,
    unregisteredUserId: string | null,
    unregisteredUserName: string;
    companies: dto.Company[],
    selectedCompanyId: string | null,
    eventId: string | null,
    eventTypeId: string,
    eventTypeName: string,
    eventDescription: string,
    batchId: string | null,
    batchName: string,
    notificationTaskType: dto.NotificationTaskType;
    numberOfFailedTry: number | null
    status: dto.NotificationTaskStatus | null,
    statusTranslated: string | null
    statusDescription: string;
    taskDate: Date | null;
    wasCleanUp: string
    createdDate: Date | null;
    updatedDate: Date | null;
    details: dto.NotificationTaskDetails | dto.AddNotificationTaskDetails | null;
    sendNow: boolean,
    messageType: dto.MessageType
}

export type NotificationTaskModel = Model

const createModel = (options: { readonly: boolean, notificationTask: dto.NotificationTask | null, companies: dto.Company[], selectedCompanyId: string | null, sendNow: boolean, t: (key: string) => string }): Model => {
    const { notificationTask, companies, selectedCompanyId, readonly, sendNow, t } = options

    let notificationAddress: string | null = null
    if (notificationTask != null) {
        notificationAddress = notification.getAddress(notificationTask)
    }

    return {
        readonly,
        notificationTaskId: notificationTask?.id ?? "",
        userId: notificationTask?.userId ?? null,
        userName: `${notificationTask?.user?.firstName ?? ""} ${notificationTask?.user?.lastName ?? ""}`.trim(),
        unregisteredUserId: notificationTask?.unregisteredUserId ?? null,
        unregisteredUserName: `${notificationTask?.unregisteredUser?.firstName ?? ""} ${notificationTask?.unregisteredUser?.lastName ?? ""}`.trim(),
        selectedCompanyId: selectedCompanyId,
        companies: companies,
        eventId: notificationTask?.eventId ?? null,
        eventTypeId: notificationTask?.event?.eventTypeId ?? "",
        eventTypeName: notificationTask?.event?.eventTypeName ?? "",
        eventDescription: notificationTask?.event?.description ?? "",
        batchId: notificationTask?.batchId ?? null,
        batchName: notificationTask?.batch?.name ?? "",
        notificationTaskType: notificationTask?.notificationTaskType ?? notification.defaultNotificationTaskType,
        numberOfFailedTry: notificationTask?.numberOfFailedTry ?? null,
        status: notificationTask?.status ?? null,
        statusTranslated: notificationTask?.status != null ? `Notification.Status.${SharedDataHelper.ensureFirstLetterUppercase(notificationTask.status)}` : null,
        statusDescription: notificationTask?.statusDescription ?? "",
        taskDate: notificationTask?.taskDate ?? null,
        wasCleanUp: notificationTask?.wasCleanUp ? t("Yes") : t("No"),
        createdDate: notificationTask?.createdDate ?? null,
        updatedDate: notificationTask?.updatedDate ?? null,
        details: notificationTask?.details ?? null,
        sendNow,
        notificationAddress,
        messageType: notificationTask?.messageType ?? dto.MessageType.promo
    }
}


export const useNotificationTask = () => {
    const { t } = useLocalization();
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const history = useHistory()
    const { userRoles } = useAppContext()
    const [loading, setLoading] = useState(false)
    const [model, setModel] = useState<Model | null>(null)
    const { setGlobalMessage } = useGlobalMessageContext()
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const notificationTaskClient = useClient<INotificationTaskClient>(INotificationTaskClientId)


    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModel(null)
                let readonly = id != null
                let notificationTask: dto.NotificationTask | null = null
                let companies: dto.Company[] = []
                let selectedCompanyId: string | null = null
                if (id != null) {
                    notificationTask = await notificationTaskClient.getNotificationTask(id)
                    if (notificationTask != null) {
                        companies.push(notificationTask.company)
                        selectedCompanyId = notificationTask.companyId
                    }
                } else {
                    if (!userRoles.isAdmin) {
                        history.replace("/not-found");
                        return
                    }
                    companies = await companyClient.getCurrentCompanies()
                    selectedCompanyId = CompanyHelper.getDefaultCompany(companies)
                }
                const model = createModel({ readonly, notificationTask, companies, selectedCompanyId, sendNow: true, t })
                setModel(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModel, notificationTaskClient, companyClient, t, userRoles.isAdmin, history])


    useEffect(() => {
        loadInitialModel()
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const errorModel: FormikErrors<Model> = {}
        return errorModel
    }, [])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            const request = createAddNotificationTaskRequest(model)
            const notificationTask = await notificationTaskClient.addNotificationTask(request)
            history.replace("/notification-tasks/" + notificationTask.id)
            setGlobalMessage({ message: t("NotificationCreated"), type: "success" })
        }, { setFieldError, setGlobalMessage, fieldNames: ["notificationTaskType", "taskDate", "selectedCompanyId", "sendNow"] })
    }, [history, setGlobalMessage, notificationTaskClient, t])

    const createAddNotificationTaskRequest = (model: Model): dto.AddNotificationTaskRequest => {
        const request = new dto.AddNotificationTaskRequest()
        request.companyId = model.selectedCompanyId!
        request.sendNow = model.sendNow
        request.taskDate = model.taskDate
        request.notificationTaskType = model.notificationTaskType
        request.details = model.details as dto.AddNotificationTaskDetails
        request.messageType = model.messageType
        return request
    }
    const onDeleteNotificationTask = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await notificationTaskClient.deleteNotificationTask(id)
                history.replace("/notification-tasks")
                setGlobalMessage({ message: t("NotificationTaskDeleted"), type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, notificationTaskClient, history, setGlobalMessage, t])

    const deleteEnabled = useMemo(() => {
        return model?.status === dto.NotificationTaskStatus.pending
    }, [model?.status])



    return {
        model,
        onSubmit,
        onValidate,
        onDeleteNotificationTask,
        loading,
        deleteEnabled
    }
}

export const useNotificationTaskForm = () => {
    const { t } = useLocalization()
    const { model } = useTypedFormikContext<Model>()
    const whenToExecuteItems = useMemo((): { value: boolean, text: string }[] => {
        return [
            { text: t("SendNow"), value: true },
            { text: t("ScheduleLater"), value: false }]
    }, [t])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model?.companies ?? [])
    }, [model?.companies])

    const saveEnabled = useMemo(() => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.selectedCompanyId)
    }, [model.selectedCompanyId])

    const notificationAddress = useMemo((): string => {
        if (model?.notificationTaskType != null) {
            const notificationSpecific = NotificationSpecificFactory.createTaskSpecific(model.notificationTaskType)
            return notificationSpecific.getAddressInputTitle()
        }
        return ""
    }, [model?.notificationTaskType])

    return {
        saveEnabled,
        companyItems,
        whenToExecuteItems,
        taskTypes: notification.notificationTaskTypes
    }
}


