import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUserClient, IUserClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { useAppContext } from "../../../hook/use-app-context"

const pageSize = 20

export const useUsers = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const userClient = useClient<IUserClient>(IUserClientId)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [users, setUsers] = useState<dto.User[]>([])
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const onPageRequest = (pageIndex: number) => {
		setPageIndex(pageIndex)
		loadForm({ pageIndex, selectedCompanyId })
	}
	const { setTotalNumberOfPages, setPageIndex, pagination } = usePagination({
		onPageRequest
	})
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
	const { userRoles } = useAppContext()

	const onUserSelected = useCallback((userId: string) => {
		history.push("/users/" + userId)
	}, [history])

	const loadForm = useCallback(async (options: { selectedCompanyId: string | null, pageIndex: number, }) => {
		const { selectedCompanyId, pageIndex } = options
		try {
			setUsers([])
			if (!userRoles.isAdmin && selectedCompanyId == null) {
				return
			}
			setLoading(true)
			const request = new dto.GetUsersRequest()
			if (selectedCompanyId != null) {
				request.companyId = selectedCompanyId
			}
			request.paging = new dto.PagingRequest({ pageIndex, pageSize })
			request.sortBy = []
			request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
			const pagedResponse = (await userClient.getUsers(request))
			setUsers(pagedResponse.entities)
			setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

		} catch (err) {
			handleError(err, { setGlobalMessage: setGlobalMessage })
		} finally {
			setLoading(false)
		}

	}, [setGlobalMessage, userClient, setTotalNumberOfPages, userRoles.isAdmin])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)
				if (userRoles.isAdmin) {
					setSelectedCompanyId(null)
				}
				else if (companies.length > 0) {
					setSelectedCompanyId(companies[0].id)
				}
				setPageIndex(0)
				loadForm({ pageIndex: 0, selectedCompanyId: selectedCompanyId })
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	const onCompanySelected = useCallback(async (companyId: string | null) => {
		setPageIndex(0)
		setSelectedCompanyId(companyId)
		loadForm({ pageIndex: 0, selectedCompanyId: companyId })
	}, [loadForm, setPageIndex])


	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies, { addEmptyItem: userRoles.isAdmin });
	}, [companies, userRoles.isAdmin])

	return {
		users,
		onUserSelected,
		loading,
		companyItems,
		selectedCompanyId,
		onCompanySelected: onCompanySelected,
		pagination
	}
}