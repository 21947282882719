import React from "react";
import { useTypedFormikContext } from "../../../../hook/use-formik-context-typed";
import { NotificationTemplateModel } from "../useNotificationTemplate";
import { EmailTemplate } from "../../../../_common/fields/email-template/email-template";
import { TextInput } from "../../../../_common/fields/text-input/TextInput";
import { useLocalization } from "../../../../hook/use-localization";
import { dto } from "shared";

interface Props {
    setDefaultMessage: boolean,
    companyName: string,
    userAttributeNames: dto.UserAttributeName[],
    useEventMarkups: boolean,
    onAskPredefinedTemplates: () => void
    design: string | null
}

export const EmailNotificationTemplateDetails: React.FC<Props> = ({ userAttributeNames, useEventMarkups, onAskPredefinedTemplates, design }) => {
    const { t } = useLocalization()
    const { model } = useTypedFormikContext<NotificationTemplateModel>();

    return (
        <>
            <TextInput
                name="details.subject"
                disabled={model.readonly}
                label={t("EmailSubject")}
            />
            <EmailTemplate
                name="details.bodyTemplate"
                designName="details.design"
                isReadonly={model.readonly}
                showMarkups={!model.readonly}
                label={t("EmailBody")}
                userAttributeNames={userAttributeNames}
                useEventMarkups={useEventMarkups}
                onAskPredefinedTemplates={onAskPredefinedTemplates}
                design={design}
            />
        </>
    );
};


