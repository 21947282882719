import React, { useMemo } from "react";
import { TextInput } from "../../../../_common/fields/text-input/TextInput";
import { useTypedFormikContext } from "../../../../hook/use-formik-context-typed";
import { NotificationTaskModel } from "../useNotificationTask";
import { EmailTemplate } from "../../../../_common/fields/email-template/email-template";
import { dto, SharedDataHelper } from "shared";
import { useLocalization } from "../../../../hook/use-localization";
import { TextArea } from "../../../../_common/fields/text-area/TextArea";
import "./email-notification-task-details.scss"
import { TemplateMarkups } from "../../../../_common/components/template-markups/template-markups";

export const EmailNotificationTaskDetails: React.FC = () => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<NotificationTaskModel>();

    const subjectToSend = useMemo((): string | null => {
        if (model.readonly) {
            const details = model.details as dto.EmailNotificationTaskDetails;
            return details.subjectToSend;
        }
        return null;
    }, [model]);

    const bodyToSend = useMemo((): string | null => {
        if (model.readonly) {
            const details = model.details as dto.EmailNotificationTaskDetails;
            return details.bodyToSend;
        }
        return null;
    }, [model]);

    const emailDetails = model.details as dto.EmailNotificationTaskDetails
    const showSentMessage = SharedDataHelper.stringNotNullTrimEmpty(emailDetails.sentBody)

    return (
        <>
            {model.readonly && (
                <>
                    <TextInput name="notificationAddress" disabled={true} label="Email" />
                    {subjectToSend != null && (
                        <TextInput
                            name="details.subjectToSend"
                            disabled={true}
                            label={t("EmailSubjectToSend")}
                        />
                    )}
                    {bodyToSend != null && (
                        <TextArea
                            name="details.bodyToSend"
                            disabled={true}
                            label={t("EmailBodyToSend")}
                            className="notification-task-email-details-body"
                        />
                    )}

                    {showSentMessage && <>
                        <TextInput
                            name="details.sentSubject"
                            disabled={true}
                            label={t("EmailSubjectSent")} />

                        <TextArea
                            name="details.sentBody"
                            disabled={true}
                            label={t("EmailBodySent")}
                            className="notification-task-email-details-body" />

                    </>}
                </>
            )}
            {!model.readonly && (
                <>
                    <TextInput name="details.email" label={t("Email")} />
                    <TextInput name="details.subject" label={t("Subject")} />
                    {model.messageType === dto.MessageType.promo && <TemplateMarkups useOnlyUnsubscribeMarkups={true} context={"email"} useEventMarkups={false} userAttributeNames={[]} />}
                    <EmailTemplate
                        name="details.body"
                        designName="details.design"
                        label={t("Body")}
                        isReadonly={false}
                        showMarkups={false}
                        userAttributeNames={[]}
                        useEventMarkups={false}
                        design={null}
                        onAskPredefinedTemplates={() => { }}
                    />
                </>
            )}
        </>
    );
};
