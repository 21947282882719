import { injectable } from "inversify";
import { repository } from "shared-client"

const ACCESS_TOKEN = "ACCESS_TOKEN_KEY"
const REFRESH_TOKEN = "REFRESH_TOKEN_KEY"

@injectable()
export class TokenRepository implements repository.ITokenRepository {

    get refreshTokenSupported(): boolean {
        return true
    }

    async getAccessToken(): Promise<string | null> {
        return localStorage.getItem(ACCESS_TOKEN);
    }

    async deleteAccessToken(): Promise<void> {
        localStorage.removeItem(ACCESS_TOKEN);
    }

    async saveAccessToken(accessToken: string): Promise<void> {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
    }

    async getRefreshToken(): Promise<string | null> {
        return localStorage.getItem(REFRESH_TOKEN);
    }

    async deleteRefreshToken(): Promise<void> {
        localStorage.removeItem(REFRESH_TOKEN);
    }

    async saveRefreshToken(refreshToken: string): Promise<void> {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

}