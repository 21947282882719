import { Company } from "../../company/company";
import { NotificationTemplate } from "../notification-template/notification-template";

export class NotificationTaskBatch {
    id: string;
    companyId: string;
    company: Company;
    name: string;
    taskDate: Date;
    expirationDate: Date;
    templateId: string;
    template: NotificationTemplate
    createdDate: Date;
    updatedDate: Date;
}






