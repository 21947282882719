import { useMemo } from "react"
import { useState } from "react"
import { PaginationProps } from "../_common/components/pagination/pagination"

interface Props {
	onPageRequest?: (pageIndex: number) => void
}

export const usePagination = (options?: Props) => {
	const [pageIndex, setPageIndex] = useState(0)
	const [totalNumberOfPages, setTotalNumberOfPages] = useState(0)

	const pagination = useMemo((): PaginationProps => {
		const pagination = {
			onPageRequest: options?.onPageRequest ?? setPageIndex,
			pageIndex: pageIndex,
			totalNumberOfPages
		}
		return pagination
	}, [pageIndex, totalNumberOfPages, options?.onPageRequest])

	return {
		pagination,
		pageIndex,
		setTotalNumberOfPages,
		setPageIndex
	}
}