module.exports = function(){
	let port = ""
	const appType = process.env.REACT_APP_APP_TYPE
	switch(appType){
		case 'api-tests':
			port = '8092';
			break;
		case 'development-apigateway':
			port = '8999';
			break;
        case 'production':
                port = '28999';
                break;
		case 'development':
			port = '8070';
			break;
		default:
			console.error(`Browser app: not mapped appType => port for ${appType}. Check proxy file.`)
		
	}
	return port
}