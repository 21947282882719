import { isEmail } from 'class-validator';
import validator from 'validator';
const moment = require('moment-timezone');


export class DataHelper {

    // Date Format that will be presented on Browser or Mobile app.
    private static readonly DefaultFormatForUIPresentation = "dd.MM.yyyy";

    static readonly regexIso8601 = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

    static firstOrDefault<T>(array: T[] | null | undefined): T | null {
        return (array?.length ?? 0) > 0 ? array[0] : null
    }

    static randomString(length?: number): string {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < (length ?? 10); i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    static isStringInteger(val: string | null): boolean {
        if (val == null) {
            return false;
        }

        return validator.isInt(val);
    }

    static isStringPositiveIntegerOrZero(val: string | null): boolean {
        if (!this.isStringInteger(val)) {
            return false;
        }

        const number = Number(val);
        return number >= 0;
    }

    static isNumberInteger(number: number | null): boolean {
        if (number != null) {
            return validator.isInt(number.toString());
        }
        return null;
    }

    static stringNotNullTrimEmpty(val: string | null | undefined): boolean {
        if (val == null) {
            return false;
        }

        if (val.trim() == "") {
            return false;
        }

        return true;
    }

    static stringIsNullTrimEmpty(val: string | null): boolean {
        if (val == null) {
            return true;
        }

        if (val.trim() == "") {
            return true;
        }

        return false;
    }

    static stringNotNullEmpty(val: string | null | undefined): boolean {
        if (val == null) {
            return false;
        }

        if (val == "") {
            return false;
        }

        return true;
    }

    static replaceAll(text: string | null, replaceWhat: string, replaceTo: string): string | null {
        if (text == null) {
            return null
        }
        var re = new RegExp(replaceWhat, 'g');
        return text.replace(re, replaceTo);
    }

    static returnNullIfEmptyOtherwiseTrimValue(val: string | null): string {
        return val == null || val.trim() == "" ? null : val.trim();
    }

    static getDateWithoutTime(date: Date): Date {
        let copiedDate = new Date(date.getTime());
        copiedDate.setHours(0, 0, 0, 0);
        return copiedDate;
    }

    static addSeconds(date: Date, seconds: number): Date {
        return new Date(date.getTime() + seconds * 1000);
    }

    static addMinutes(date: Date, minutes: number): Date {
        return new Date(date.getTime() + minutes * 60000);
    }

    static addHour(date: Date, hours: number): Date {
        return new Date(date.getTime() + hours * 60000 * 60);
    }

    static addDays(date: Date, days: number): Date {
        return new Date(date.getTime() + days * 60000 * 60 * 24);
    }

    static addMonths(date: Date, months: number): Date {
        const copy = new Date(date.getTime())
        return new Date(copy.setMonth(copy.getMonth() + months));
    }


    static addYears(date: Date, years: number): Date {
        const copy = new Date(date.getTime())
        return new Date(copy.setFullYear(copy.getFullYear() + years));
    }

    static firstLetterUppercase(text: string): string {
        if (text == null) {
            return null;
        }
        let nameCapitalized = text.charAt(0).toUpperCase();
        if (text.length > 1) {
            nameCapitalized += text.slice(1)
        }
        return nameCapitalized;
    }

    static firstLetterLowercase(text: string): string {
        if (text == null) {
            return null;
        }
        let nameCapitalized = text.charAt(0).toLowerCase();
        if (text.length > 1) {
            nameCapitalized += text.slice(1)
        }
        return nameCapitalized;
    }

    static ensureDotAtEnd(text: string): string {
        if (text == null) {
            return null;
        }

        if (!text.endsWith(".")) {
            return text + "."
        }
        return text;
    }

    static ensureFirstLetterUppercase(text: string): string {
        if (text == null) {
            return text;
        }

        if (text.length == 0) {
            return text;
        }

        let ch = text[0]
        ch = ch.toUpperCase()

        if (text.length > 1) {
            return ch + text.substring(1)
        }

        return ch


    }

    static objectsHaveSameValues(obj1: any, obj2: any): boolean {
        if (obj1 == null && obj2 == null) {
            return true;
        }

        if (obj1 == null || obj2 == null) {
            return false;
        }
        return JSON.stringify(obj1) == JSON.stringify(obj2);
    }

    static plainObjectToQueryStringParameters(params: any): string {
        return Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    static getFormattedDate(date: Date | null, format?: "dd-MM-yyyy" | "dd.MM.yyyy" | "dd.MM.yyyy HH:mm" | "HH:mm"): string {
        if (date == null) {
            return null;
        }

        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        var hours = date.getHours().toString();
        hours = hours.length > 1 ? hours : '0' + hours;

        var minutes = date.getMinutes().toString();
        minutes = minutes.length > 1 ? minutes : '0' + minutes;

        if (format == "HH:mm") {
            return `${hours}:${minutes}`;
        }

        if (format == "dd-MM-yyyy") {
            return `${month}-${day}-${year}`;
        }

        if (format == "dd.MM.yyyy") {
            return `${day}.${month}.${year}`;
        }

        if (format == "dd.MM.yyyy HH:mm") {
            return `${day}.${month}.${year} ${hours}:${minutes}`;
        }

        return date.toISOString();

    }

    // Used to display date in client app(browser or mobile)
    // it shows only date
    static getFormattedDateForPresentation(date: Date | null) {
        return DataHelper.getFormattedDate(date, DataHelper.DefaultFormatForUIPresentation);
    }

    static matchISO8601(val: string | null): boolean {
        if (val == null) {
            return false;
        }

        var match: any;
        if (match = val.match(DataHelper.regexIso8601)) {
            return true;
        }
        return false;
    }

    static parseISO8601(val: string | null): Date {
        if (val == null) {
            return null;
        }

        var match: any;
        if (match = val.match(DataHelper.regexIso8601)) {
            var milliseconds = Date.parse(match[0]);
            if (!isNaN(milliseconds)) {
                return new Date(milliseconds);
            }
        }
        throw Error(`ParseISO8601 error for:${val}`)
    }

    static formatMobilePhoneNumber(text: string | null): string {
        const valid = DataHelper.isValidMobilePhoneNumber(text)
        if (valid) {
            return text.substring(0, 4) + " " + text.substring(4, 6) + " " + text.substring(6)
        }

        return text
    }

    static replaceAllWhiteSpaces(text: string): string {
        if (text == null) {
            return text
        }

        return DataHelper.replaceAll(text, " ", "").trim()
    }

    static isValidEmail(text: string): boolean {
        if (text == null) {
            return false
        }
        return isEmail(text)
    }

    static isValidMobilePhoneNumber(text: string): boolean {
        if (DataHelper.stringIsNullTrimEmpty(text)) {
            return false;
        }

        if (text.startsWith(" ")) {
            return false;
        }

        if (text.endsWith(" ")) {
            return false;
        }

        if (!text.startsWith("+381")) {
            return false;
        }

        if (text.length > 13) {
            return false
        }

        let phoneNumber = text.substring(4).trim()
        for (var i = 0; i < phoneNumber.length; i++) {
            const ch = phoneNumber.charAt(i);
            let isNum = /^\d+$/.test(phoneNumber);
            if (!isNum) {
                return false;
            }
        }

        return true
    }

    static ensureOnlyOneDefined(obj1: any, obj2: any, key: string) {
        if (obj1 == null && obj2 == null) {
            throw new Error(`Only one must be defined. Both null. Key is:${key}`)
        }

        if (obj1 != null && obj2 != null) {
            throw new Error(`Only one must be defined. Both not null. Key is:${key}`)
        }
    }

    static getTimeZone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    static getUTCTimezoneOffsetInMinutes(timezone: string, date: Date) {
        const tz = moment(date).tz(timezone);
        return tz.utcOffset();
    }

    static getUTCTimezoneOffsetInHours(timezone: string, date: Date) {
        return DataHelper.getUTCTimezoneOffsetInMinutes(timezone, date) / 60
    }

    static isStringValidNumber(input: string): boolean {
        if (input == null) {
            return false
        }
        const decimalRegex = /^-?\d+(\.\d+)?$/;
        return decimalRegex.test(input);
    }

    static getStartOfDay(date: Date, clientTimeZone: string, timezone: string): Date {
        const dateInput = moment.tz(date, clientTimeZone);

        const dateOutput = dateInput.tz(timezone).startOf('day');

        const resultDate = dateOutput.toDate();
        return resultDate
    }

    static getStartOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    }
    static getStartOfNextMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0);
    }

    static convertISOToTimeZoneAndReturnHoursMinutesInTimeZone(utcISOString: string, timezone: string): { hours: number, minutes: number } {
        const momentInTimezone = moment.utc(utcISOString).tz(timezone);

        const dateInTimezone = DataHelper.convertISOToTimeZone(utcISOString, timezone)

        const hours = momentInTimezone.hours();
        const minutes = momentInTimezone.minutes();

        return {
            hours,
            minutes
        };
    }

    static convertISOToTimeZone(utcISOString: string, timezone: string): Date {
        const momentInUTC = moment.utc(utcISOString);

        const offsetInMilliseconds = moment.tz.zone(timezone).utcOffset(momentInUTC.valueOf()) * 60 * 1000;

        const adjustedMomentInUTC = momentInUTC.subtract(-1 * offsetInMilliseconds);

        return adjustedMomentInUTC.toDate()
    }

    static formatDateInTimezone(date: Date, timezone: string, format: string): string {
        const momentInTimezone = moment(date).tz(timezone);

        const formattedDate = momentInTimezone.format(format);

        return formattedDate;
    }


    static removeSecondsAndMilliseconds(date: Date): Date {
        let newDate = new Date(date.getTime());
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        return newDate
    }

    static createMarkup(name: string): string {
        return `##${name}##`
    }
}