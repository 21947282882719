import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUserInvitationClient, IUserInvitationClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { CompanyHelper } from "../../../_common/helpers/company-helper"

const pageSize = 20

export const useUserInvitations = () => {
	const history = useHistory()
	const { setGlobalMessage } = useGlobalMessageContext()
	const [loading, setLoading] = useState(false)
	const userInvitationClient = useClient<IUserInvitationClient>(IUserInvitationClientId)
	const companyClient = useClient<ICompanyClient>(ICompanyClientId)
	const [userInvitations, setUserInvitations] = useState<dto.UserInvitation[]>([])
	const [companies, setCompanies] = useState<dto.Company[]>([])
	const { pageIndex, pagination, setTotalNumberOfPages } = usePagination()
	const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)

	const onUserInvitationSelected = useCallback((userInvitationId: string) => {
		history.push("/user-invitations/" + userInvitationId)
	}, [history])


	useEffect(() => {
		(async () => {
			try {
				if (selectedCompanyId == null) {
					setUserInvitations([])
					return
				}
				setLoading(true)
				setUserInvitations([])
				const request = new dto.GetUserInvitationsRequest()
				request.companyId = selectedCompanyId
				request.paging = new dto.PagingRequest({ pageIndex, pageSize })
				request.sortBy = []
				request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
				const pagedResponse = (await userInvitationClient.getUserInvitations(request))
				setUserInvitations(pagedResponse.entities)
				setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [pageIndex, selectedCompanyId])

	// Load companies
	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const companies = await companyClient.getCurrentCompanies()
				setCompanies(companies)
				const defaultCompanyId = CompanyHelper.getDefaultCompany(companies)
				setSelectedCompanyId(defaultCompanyId)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [])

	const companyItems = useMemo((): CompanyDropdownItem[] => {
		return createCompanyDropDownItems(companies)
	}, [companies])

	const onAddUserInvitation = useCallback(() => {
		history.push("/user-invitations/add")
	}, [history])

	const addNewInvitationEnabled = useMemo(() => {
		return companies.length > 0
	}, [companies])

	return {
		userInvitations,
		onUserInvitationSelected,
		loading,
		companyItems,
		selectedCompanyId,
		onCompanySelected: setSelectedCompanyId,
		pagination,
		onAddUserInvitation,
		addNewInvitationEnabled
	}
}