import { NotificationTaskType } from "../notification-task-type";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { IsBoolean, IsDate, IsDefined, IsEnum, IsOptional, ValidateIf, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { AddNotificationTaskDetails } from "..";
import { OnlyOneDefined } from "../../../../validators/only-one-defined";
import { ValidateIfNotStrictUndefined } from "../../../../validators/validate-if-not-strict-undefined";
import { NotificationSpecificFactory } from "../specific/notification-specific-factory";
import { PropertyDefined } from "../../../../validators/property-defined";
import { OnlyOneExpressionTrue } from "../../../../validators/only-one-expression-true";
import { MessageType } from "../../message-type/_index";


export class AddNotificationTaskRequest {

    @ContainsValidString()
    companyId: string;

    @ValidateIf((object) => object.sendNow !== true)
    @IsDate()
    taskDate: Date | null;

    @ValidateIf((object) => object.sendNow !== true)
    @IsDate()
    expirationDate: Date | null

    @IsBoolean()
    sendNow: boolean

    @IsEnum(NotificationTaskType)
    notificationTaskType: NotificationTaskType;

    @IsOptional()
    @ContainsValidString()
    userId?: string;

    @IsOptional()
    @ContainsValidString()
    unregisteredUserId?: string;

    // add more types for email, viber...
    @ValidateIfNotStrictUndefined()
    @ValidateNested()
    @Type(({ object }) => {
        const notificationSpecific = NotificationSpecificFactory.create(object.notificationTaskType)
        return notificationSpecific.addNotificationTaskDetails.constructor
    })
    details: AddNotificationTaskDetails

    @IsDefined()
    @IsEnum(MessageType)
    messageType: MessageType


    @OnlyOneDefined({
        properties: (obj: AddNotificationTaskRequest) => {
            const notificationSpecific = NotificationSpecificFactory.create(obj.notificationTaskType)
            return ["userId", "unregisteredUserId", `details.${notificationSpecific.addNotificationTaskDetails.addressProperty}`]
        }
    })
    private ___metaValidationToWhomSendDto?: boolean = true

    @PropertyDefined({
        property: (obj: AddNotificationTaskRequest) => {
            const notificationSpecific = NotificationSpecificFactory.create(obj.notificationTaskType)
            return `details.${notificationSpecific.addNotificationTaskDetails.messageToSendProperty}`
        }
    })
    private ___metaValidationMessageToSendDto?: boolean = true

    @OnlyOneExpressionTrue({
        expressions: [
            (obj: AddNotificationTaskRequest) => obj.sendNow && obj.taskDate == null && obj.expirationDate == null,
            (obj: AddNotificationTaskRequest) => !obj.sendNow && obj.taskDate != null && obj.expirationDate != null
        ]
    })
    private __metaValidationOneOfSendNowAndDate? = true
}
