import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto } from "shared";
import { ICompanyClient, ICompanyClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";
import { useAppContext } from "../../../hook/use-app-context";
import { useUserRole } from "../../../hook/useUserRole";


interface Model {
    companyId: string | null,
    name: string,
    email: string,
    address: string,
    timezone: string,
    status: dto.CompanyStatus
    consentSourceName: string | null,
    lastUpdated: Date | null,
    readonly: boolean,
    originalModel: Omit<Model, "originalModel"> | null
}

export type CompanyModel = Model

const createModel = (company: dto.Company, originalModel?: Model | null): Model => {
    return {
        readonly: true,
        companyId: company.id,
        email: company.email,
        name: company.name,
        address: company.address,
        status: company.status,
        timezone: company.timezone,
        consentSourceName: company.consentSourceName === dto.ConsentSourceName.application ? "Digital Software" : company.name,
        lastUpdated: company.updatedDate ?? company.createdDate,
        originalModel: originalModel ?? null
    }
}

const createUpdateRequest = (model: Model): dto.UpdateCompanyRequest => {
    const request = new dto.UpdateCompanyRequest()
    request.name = model.name
    request.address = model.address
    request.email = model.email
    request.status = model.status
    request.timezone = model.timezone
    return request
}

const createAddRequest = (model: Model): dto.AddCompanyRequest => {
    const request = new dto.AddCompanyRequest()
    request.name = model.name
    request.address = model.address
    request.email = model.email
    request.status = model.status
    request.timezone = model.timezone
    return request
}

export const useCompany = () => {
    let { id } = useParams<{ id: string }>();
    const history = useHistory()
    const { user: currentUser } = useAppContext()
    const { isAdmin } = useUserRole()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const client = useClient<ICompanyClient>(ICompanyClientId)

    const addNewCompany = useMemo(() => {
        return id == null
    }, [id])

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setModelInitialValues(null)
                const company = await client.getCompany(id)
                const originalModel = createModel(company)
                const model = createModel(company, originalModel)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, client])


    useEffect(() => {
        if (id != null) {
            loadInitialModel()
        } else {
            setModelInitialValues({
                readonly: false,
                companyId: null,
                email: "",
                name: "",
                address: "",
                timezone: "",
                consentSourceName: null,
                status: dto.CompanyStatus.blocked,
                lastUpdated: null,
                originalModel: null
            })
        }
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const errorModel: FormikErrors<Model> = {}
        let validateErrors: ValidationError[] = []
        if (id != null) {
            const request = createUpdateRequest(model)
            validateErrors = await validate(request);
        } else {
            const request = createAddRequest(model)
            validateErrors = await validate(request);
        }
        setFieldErrors<Model>(validateErrors, ["name", "address", "status", "email", "timezone"], errorModel)
        return errorModel
    }, [])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            if (id != null) {
                const request = createUpdateRequest(model)
                await client.updateCompany(id, request)
                loadInitialModel()
                setGlobalMessage({ message: "Company is updated.", type: "success" })
            } else {
                const request = createAddRequest(model)
                const company = await client.addCompany(request)
                setGlobalMessage({ message: "Company is added.", type: "success" })
                history.replace("/companies/" + company.id)

            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["name", "address", "status", "email", "timezone"] })
    }, [id, client, loadInitialModel, setGlobalMessage, history])

    const onDeleteCompany = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await client.deleteCompany(id)
                history.replace("/companies")
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, client, history, setGlobalMessage])

    const onGoToCompanyConfiguration = useCallback(() => {
        history.push(`/companies/${id}/configuration`)
    }, [history, id])

    const companyConfigurationVisible = useMemo(() => {
        return isAdmin(currentUser)
    }, [currentUser, isAdmin])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteCompany,
        addNewCompany,
        onGoToCompanyConfiguration,
        companyConfigurationVisible
    }
}

export const useCompanyForm = () => {
    const { t } = useLocalization();
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const statuses = useMemo(() => {
        return [
            { text: t("Active"), value: dto.CompanyStatus.active },
            { text: t("Blocked"), value: dto.CompanyStatus.blocked }]
    }, [])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        statuses
    }
}
