import { SortItem } from "../../common/query/sort-item";
import { PagingRequest } from "../../common/paging/paging-request";
import { AreValidSortItems } from '../../../../validators/are-valid-sort-items';
import { IsDate, IsDefined, ValidateNested } from "class-validator";

export class GetApiLogsRequest {

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]

    @IsDate()
    dateFrom: Date

    @IsDate()
    dateTo: Date
}