import { dto } from "shared"

export const IEventTypeClientId = Symbol.for('IEventTypeClient');

export interface IEventTypeClient {
    getEventTypes(data: dto.GetEventTypesRequest): Promise<dto.PagingResponse<dto.EventType>>
    getEventType(eventtypeId: string): Promise<dto.EventType>
    addEventType(data: dto.AddEventTypeRequest): Promise<dto.EventType>
    updateEventType(eventtypeId: string, data: dto.UpdateEventTypeRequest): Promise<void>
    deleteEventType(eventtypeId: string): Promise<void>
}