import { ValidateIf, ValidationOptions } from "class-validator";


/**
 * Skips validation if the target is undefined
 */

export function ValidateIfNotStrictUndefined(options?: ValidationOptions): PropertyDecorator {
    return function IsUndefinedDecorator(prototype: Object, propertyKey: string | symbol) {
        ValidateIf((obj) => (obj)[propertyKey] !== undefined, options)(prototype, propertyKey);
    };
}

