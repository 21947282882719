

const eventTypePaths = {
    base: "/event-types",
    eventTypes: "",
    eventType: "/:eventtypeid"
}

export { eventTypePaths as eventType }

