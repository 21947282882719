import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./user-attribute-name.scss"
import { UserAttributeNameModel, useUserAttributeName, useUnregisteredUserForm } from "./useUserAttributeName"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextArea } from "../../../_common/fields/text-area/TextArea"
import { dto, SharedDataHelper } from "shared"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const UserAttributeName: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteUserAttributeName, isAddingNewUserAttributeName, isAllowedToDeleteAttributeName, isAllowedToEditAttributeName, onAddNewUserAttributeName, onViewAllUserAttributeNames } = useUserAttributeName()
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);

    return (
        <Shell pageTitle={isAddingNewUserAttributeName ? t("AddContactAttribute") : t("ContactAttribute")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent isAdd={isAddingNewUserAttributeName} allowEdit={isAllowedToEditAttributeName} />
                        </Form>
                    )}
                </Formik>

                {!isAddingNewUserAttributeName && isAllowedToDeleteAttributeName && <DangerZone>
                    <div className="delete-title">{t("DeleteContactAttribute")}</div>
                    <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")} </Button>
                    <div className="delete-message">{t("DeleteContactAttributeDescription")}</div>
                    <Confirmation level="require-number-confirmation-strong" body={t("Confirmation.Delete")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteUserAttributeName()} />
                </DangerZone>}

                {modelInitialValues?.userAttributeNameId != null && <div className="alert alert-secondary zone-container indent-container" role="alert">
                    <Button onClick={onAddNewUserAttributeName}>{t("AddNewAttribute")} </Button>
                    <div>{t("CreateNewCompanyAttribute")}</div>
                </div>}

                <div className="alert alert-secondary zone-container indent-container" role="alert">
                    <Button onClick={onViewAllUserAttributeNames}>{t("ViewAllAttributes")}</Button>
                    <div>{t("ViewAllCompanyAttributes")}</div>
                </div>
            </>
            }
        </Shell>
    )
}

interface FormContentProps {
    isAdd: boolean,
    allowEdit: boolean
}

const FormContent: React.FC<FormContentProps> = ({ isAdd, allowEdit }) => {
    const { t } = useLocalization();
    const { model, submitForm } = useTypedFormikContext<UserAttributeNameModel>()
    const { editForm, cancelEditForm, saveUserEnabled, companyItems, companyDropDownEnabled, attributeTypes } = useUnregisteredUserForm()
    const confirmationRef = useRef<ConfirmationHandle>(null);

    return (
        <div className="indent-container">
            <CompanyDropDownField disabled={!companyDropDownEnabled} name="companyId" label={t("Company")} items={companyItems} />
            <TextInput name="name" disabled={model.readonly} label={t("Name")} />
            <TextInput name="description" disabled={model.readonly} label={t("Description")} />
            <DropDownField name="attributeType" disabled={!isAdd} label={t("Type")} items={attributeTypes} />
            {model.attributeType === dto.UserAttributeNameType.select && <TextArea name="selectValues" disabled={model.readonly} label={t("Values") + (model.readonly ? "" : ` - ${t("SeparateBySpecialCharacterN")}`)} />}

            <div className="action-buttons-row">
                {model.readonly && allowEdit && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {saveUserEnabled && !model.readonly && <Button onClick={() => confirmationRef.current?.show()}>{t("Save")}</Button>}
                <Confirmation level="require-number-confirmation" body={t("Confirmation.Save")} ref={confirmationRef} onOkCallback={() => submitForm()} />
            </div>
        </div>)
}
