
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface PropertyDefinedOptions {
    property: (obj: any) => string
}

@ValidatorConstraint({ async: true })
export class PropertyDefinedConstraint implements ValidatorConstraintInterface {

    async validate(obj: any, args: ValidationArguments) {
        const holder = args.object
        const onlyOneOption: PropertyDefinedOptions = args.constraints[0]
        const propertyCallback: (obj: any) => string = onlyOneOption.property
        const propObj = propertyCallback(holder)
        let countDefined = 0
        const subProps = (propObj as string).split(".")
        let propVal: any = null
        for (let i = 0; i < subProps.length; i++) {
            let subProp = subProps[i]
            propVal = (i == 0 ? holder : propVal)[subProp]
            if (propVal == null) {
                break
            }
        }

        if (propVal != null) {
            countDefined++
        }

        return countDefined == 1;
    }

    defaultMessage(args: ValidationArguments) {
        return `Property must be defined`;
    }
}

export function PropertyDefined(options: PropertyDefinedOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [options as any],
            validator: PropertyDefinedConstraint
        });
    };
}