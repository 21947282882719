import { IsEnum, IsOptional, IsString } from "class-validator";
import { BaseMessageSettingsConfiguration } from "../base-message-settings-configuration";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { LambdaExpression } from "../../../../validators/lambda-expression";
import { SharedDataHelper } from "../../../../helper/_index";
import { SmsUnsubscribeType } from "./sms-unsubscribe-type";



export class SmsSettingsConfiguration extends BaseMessageSettingsConfiguration {

    @ContainsValidString()
    notificationSenderName: string

    @IsOptional()
    @IsString()
    promoSenderName: string


    @IsEnum(SmsUnsubscribeType)
    smsUnsubscribeType: SmsUnsubscribeType

    @IsOptional()
    @IsString()
    stopUnsubscribeOnNumberMessage: string | null

    @LambdaExpression({
        expression: (obj: SmsSettingsConfiguration) => {
            if (obj.smsUnsubscribeType == SmsUnsubscribeType.stopUnsubscribeOnNumber) {
                return SharedDataHelper.stringNotNullTrimEmpty(obj.stopUnsubscribeOnNumberMessage)
            }
            return SharedDataHelper.stringIsNullTrimEmpty(obj.stopUnsubscribeOnNumberMessage)
        }
    }, { message: "useStopUnsubscribeOnNumberValidation" })
    _meta? = true

    constructor(options: {
        notificationEnabled: boolean,
        notificationSenderName: string,
        notificationApiKey: string,
        notificationApiSecret: string,
        promoEnabled: boolean,
        promoSenderName: string
        promoApiKey: string,
        promoApiSecret: string,
        smsUnsubscribeType: SmsUnsubscribeType,
        stopUnsubscribeOnNumberMessage: string | null,
    }) {
        super({
            notificationEnabled: options?.notificationEnabled,
            notificationApiKey: options?.notificationApiKey,
            notificationApiSecret: options?.notificationApiSecret,
            promoEnabled: options?.promoEnabled,
            promoApiKey: options?.promoApiKey,
            promoApiSecret: options?.promoApiSecret
        });
        if (options == null) {
            return
        }
        this.notificationSenderName = options.notificationSenderName
        this.promoSenderName = options.promoSenderName
        this.smsUnsubscribeType = options.smsUnsubscribeType
        this.stopUnsubscribeOnNumberMessage = options.stopUnsubscribeOnNumberMessage
    }
}