import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IBusinessConfigurationClient } from "./i-business-configuration-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class BusinessConfigurationClient extends BaseClient implements IBusinessConfigurationClient {

    async getBusinessConfiguration(companyId: string): Promise<dto.BusinessCompanyConfiguration> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.businessConfiguration.base + apiPaths.businessConfiguration.businessConfiguration.replace(":companyid", companyId)) });
            return response.data as dto.BusinessCompanyConfiguration;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async saveBusinessConfiguration(companyId: string, data: dto.BusinessConfiguration.Configuration): Promise<dto.BusinessCompanyConfiguration> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.businessConfiguration.base + apiPaths.businessConfiguration.businessConfiguration.replace(":companyid", companyId)), data: data });
            return response.data as dto.BusinessCompanyConfiguration;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}