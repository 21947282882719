import React from "react";

const useIsTablet = () => {
  const [windowDimension, setWindowDimension] = React.useState(0);

  React.useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimension < 980;
}

export default useIsTablet;


