import './App.css';
import React from 'react';
import { Context } from "shared"
import AppContent from "./app-content/AppContent"
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import AppContextProvider from "../context/app/app-context-provider";
import "reflect-metadata"
import GlobalMessageContextProvider from '../context/global-message/global-message-provider';
import { BaseClient } from 'shared-client';
import WebSocketContextProvider from '../context/websocket/web-socket-provider';

BaseClient.logRequestResponse = false

function App() {
    const baseHistory = createBrowserHistory();

    // Override push i replace metode
    const push = baseHistory.push;
    const replace = baseHistory.replace;

    baseHistory.push = function (path, state) {
        console.log(`Navigating to: ${path}`);  
        push.call(baseHistory, path, state);
    };

    baseHistory.replace = function (path, state) {
        console.log(`Replacing with: ${path}`);  
        replace.call(baseHistory, path, state);
    };
    React.useEffect(() => {
        document.title = Context.entityName;
    }, []);
    return (
        <div className="App" >
            <Router history={baseHistory}>
                <GlobalMessageContextProvider>
                    <WebSocketContextProvider>
                        <AppContextProvider>
                            <AppContent />
                        </AppContextProvider>
                    </WebSocketContextProvider>
                </GlobalMessageContextProvider>
            </Router>
        </div >
    );
}

export default App;
