import { Container } from "inversify";
import { AppTypeClient } from "./app-type/app-type-client";
import { IAppTypeClient, IAppTypeClientId} from "./app-type/i-app-type-client";
import { AuthClient } from "./auth/auth-client";
import { IAuthClient, IAuthClientId} from "./auth/i-auth-client";
import { BusinessConfigurationClient } from "./business-configuration/business-configuration-client";
import { IBusinessConfigurationClient, IBusinessConfigurationClientId} from "./business-configuration/i-business-configuration-client";
import { ClientCredentialClient } from "./client-credential/client-credential-client";
import { IClientCredentialClient, IClientCredentialClientId} from "./client-credential/i-client-credential-client";
import { CompanyClient } from "./company/company-client";
import { ICompanyClient, ICompanyClientId} from "./company/i-company-client";
import { ConsentClient } from "./consent/consent-client";
import { IConsentClient, IConsentClientId} from "./consent/i-consent-client";
import { EventClient } from "./event/event-client";
import { IEventClient, IEventClientId} from "./event/i-event-client";
import { EventTypeClient } from "./event-type/event-type-client";
import { IEventTypeClient, IEventTypeClientId} from "./event-type/i-event-type-client";
import { HealthClient } from "./health/health-client";
import { IHealthClient, IHealthClientId} from "./health/i-health-client";
import { ApiLogClient } from "./api-log/api-log-client";
import { IApiLogClient, IApiLogClientId} from "./api-log/i-api-log-client";
import { NotificationTaskBatchClient } from "./notification-task-batch/notification-task-batch-client";
import { INotificationTaskBatchClient, INotificationTaskBatchClientId} from "./notification-task-batch/i-notification-task-batch-client";
import { NotificationTaskClient } from "./notification-task/notification-task-client";
import { INotificationTaskClient, INotificationTaskClientId} from "./notification-task/i-notification-task-client";
import { NotificationTemplateClient } from "./notification-template/notification-template-client";
import { INotificationTemplateClient, INotificationTemplateClientId} from "./notification-template/i-notification-template-client";
import { QueuedJobClient } from "./queued-job/queued-job-client";
import { IQueuedJobClient, IQueuedJobClientId} from "./queued-job/i-queued-job-client";
import { UnregisteredUserClient } from "./unregistered-user/unregistered-user-client";
import { IUnregisteredUserClient, IUnregisteredUserClientId} from "./unregistered-user/i-unregistered-user-client";
import { UserActionsClient } from "./user-actions/user-actions-client";
import { IUserActionsClient, IUserActionsClientId} from "./user-actions/i-user-actions-client";
import { UserAttributeClient } from "./user-attribute/user-attribute-client";
import { IUserAttributeClient, IUserAttributeClientId} from "./user-attribute/i-user-attribute-client";
import { UserAttributeNameClient } from "./user-attribute-name/user-attribute-name-client";
import { IUserAttributeNameClient, IUserAttributeNameClientId} from "./user-attribute-name/i-user-attribute-name-client";
import { UserConsentHistoryClient } from "./user-consent-history/user-consent-history-client";
import { IUserConsentHistoryClient, IUserConsentHistoryClientId} from "./user-consent-history/i-user-consent-history-client";
import { UserClient } from "./user/user-client";
import { IUserClient, IUserClientId} from "./user/i-user-client";
import { UserInvitationClient } from "./user-invitation/user-invitation-client";
import { IUserInvitationClient, IUserInvitationClientId} from "./user-invitation/i-user-invitation-client";
import { UserRecoverPasswordClient } from "./user-recover-password/user-recover-password-client";
import { IUserRecoverPasswordClient, IUserRecoverPasswordClientId} from "./user-recover-password/i-user-recover-password-client";
import { UserRegistrationClient } from "./user-registration/user-registration-client";
import { IUserRegistrationClient, IUserRegistrationClientId} from "./user-registration/i-user-registration-client";
import { UserRoleClient } from "./user-role/user-role-client";
import { IUserRoleClient, IUserRoleClientId} from "./user-role/i-user-role-client";


export const registerClientDependencies = (container: Container) => {
    container.bind<IAppTypeClient>(IAppTypeClientId).to(AppTypeClient).inTransientScope();
    container.bind<IAuthClient>(IAuthClientId).to(AuthClient).inTransientScope();
    container.bind<IBusinessConfigurationClient>(IBusinessConfigurationClientId).to(BusinessConfigurationClient).inTransientScope();
    container.bind<IClientCredentialClient>(IClientCredentialClientId).to(ClientCredentialClient).inTransientScope();
    container.bind<ICompanyClient>(ICompanyClientId).to(CompanyClient).inTransientScope();
    container.bind<IConsentClient>(IConsentClientId).to(ConsentClient).inTransientScope();
    container.bind<IEventClient>(IEventClientId).to(EventClient).inTransientScope();
    container.bind<IEventTypeClient>(IEventTypeClientId).to(EventTypeClient).inTransientScope();
    container.bind<IHealthClient>(IHealthClientId).to(HealthClient).inTransientScope();
    container.bind<IApiLogClient>(IApiLogClientId).to(ApiLogClient).inTransientScope();
    container.bind<INotificationTaskBatchClient>(INotificationTaskBatchClientId).to(NotificationTaskBatchClient).inTransientScope();
    container.bind<INotificationTaskClient>(INotificationTaskClientId).to(NotificationTaskClient).inTransientScope();
    container.bind<INotificationTemplateClient>(INotificationTemplateClientId).to(NotificationTemplateClient).inTransientScope();
    container.bind<IQueuedJobClient>(IQueuedJobClientId).to(QueuedJobClient).inTransientScope();
    container.bind<IUnregisteredUserClient>(IUnregisteredUserClientId).to(UnregisteredUserClient).inTransientScope();
    container.bind<IUserActionsClient>(IUserActionsClientId).to(UserActionsClient).inTransientScope();
    container.bind<IUserAttributeClient>(IUserAttributeClientId).to(UserAttributeClient).inTransientScope();
    container.bind<IUserAttributeNameClient>(IUserAttributeNameClientId).to(UserAttributeNameClient).inTransientScope();
    container.bind<IUserConsentHistoryClient>(IUserConsentHistoryClientId).to(UserConsentHistoryClient).inTransientScope();
    container.bind<IUserClient>(IUserClientId).to(UserClient).inTransientScope();
    container.bind<IUserInvitationClient>(IUserInvitationClientId).to(UserInvitationClient).inTransientScope();
    container.bind<IUserRecoverPasswordClient>(IUserRecoverPasswordClientId).to(UserRecoverPasswordClient).inTransientScope();
    container.bind<IUserRegistrationClient>(IUserRegistrationClientId).to(UserRegistrationClient).inTransientScope();
    container.bind<IUserRoleClient>(IUserRoleClientId).to(UserRoleClient).inTransientScope();

}
