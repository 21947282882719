import React from "react";
import { SharedDataHelper } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./user-attribute-names.scss";
import { useUserAttributeNames } from "./useUserAttributeNames";
import { useLocalization } from "../../../hook/use-localization";

export const UserAttributeNames: React.FC = () => {
  const { t } = useLocalization()
  const isMobile = useIsMobile();
  const {
    userAttributeNames,
    companyItems,
    selectedCompanyId,
    onUserAttributeNameSelected,
    onCompanySelected,
    loading,
    pagination,
    onAddUser,
    addNewUserAttributeEnabled,
  } = useUserAttributeNames();

  const rowClick = (id: string) => {
    if (isMobile) {
      onUserAttributeNameSelected(id);
    }
  };

  const listItems = userAttributeNames.map((userAttributeName) => (
    <div
      className="user-attribute-names__item data-row-item"
      key={userAttributeName.id}
      onClick={() => rowClick(userAttributeName.id)}
    >
      <div className="user-attribute-names__item-name-description">
        <div className="user-attribute-names__item-name">
          <button
            onClick={() => {
              onUserAttributeNameSelected(userAttributeName.id);
            }}
          >
            {userAttributeName.name}
          </button>
        </div>
        <div className="user-attribute-names__item-description">
          {userAttributeName.description}
        </div>
      </div>
      <div className="user-attribute-names__item-dates">
        {t("Created")}  {SharedDataHelper.getFormattedDate(userAttributeName.updatedDate ?? userAttributeName.createdDate, "dd.MM.yyyy")}
      </div>
    </div>
  ));
  return (
    <Shell
      pageTitle={t("CompanyContactAttributes")}
      mobileHeaderContent={
        <button
          disabled={!addNewUserAttributeEnabled}
          className="mobile-add-new"
          onClick={onAddUser}
        >
          {t("Add")}
        </button>
      }
      desktopHeaderContent={
        <Button disabled={!addNewUserAttributeEnabled} onClick={onAddUser}>
           {t("AddAttribute")}
        </Button>
      }
    >
      <Loading loading={loading} />
      <div className="indent-container users-header-filter">
        <CompanyDropdown
          items={companyItems}
          selectedCompanyId={selectedCompanyId}
          onSelect={onCompanySelected}
        />
        <Pagination {...pagination} />
      </div>
      {listItems}
      {userAttributeNames.length === 0 && <NoEntities />}
    </Shell>
  );
};
