export class UploadUserAttributesByExcelFileRequest {
    static contactAttributesSheetName = "ContactAttributes"
}

export enum UploadUserAttributesByExcelFileColumns {
    Name = "Name",
    Value = "Value",
    Description = "Description",
    ExternalId = "ExternalId",
    ContactExternalId = "ContactExternalId"
}