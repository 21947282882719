import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./add-user-invitation.scss"
import { AddUserInvitationModel, useAddUserInvitation, useAddUserInvitationForm } from "./useAddUserInvitation"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"
import { Button } from "../../../_common/common/button/Button"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"


export const AddUserInvitation: React.FC = () => {
	const { t } = useLocalization();
	const { modelInitialValues, onSubmit, onValidate, loading } = useAddUserInvitation()

	return (
		<Shell pageTitle={t("InviteUser")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>
			</>}
		</Shell>
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization();
	const { submitForm } = useTypedFormikContext<AddUserInvitationModel>()
	const { addInvitationEnabled, companyItems } = useAddUserInvitationForm()
	const confirmationRef = useRef<ConfirmationHandle>(null);
	return (
		<div className="indent-container">
			<CompanyDropDownField disabled={!addInvitationEnabled} name="companyId" label={t("Company")} items={companyItems} />
			<TextInput disabled={!addInvitationEnabled} name="userId" label={t("UserId")} />
			<div>{t("InviteUserDescription")}</div>
			<Button onClick={() => confirmationRef.current?.show()}>{t("Invite")}</Button>
			<Confirmation level="require-number-confirmation" body={t("Confirmation.Invite")} ref={confirmationRef} onOkCallback={() => submitForm()} />
		</div>)
}
