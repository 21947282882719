import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { Button } from "../../../_common/common/button/Button"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import { useUserReceivedInvitation } from "./useUserReceivedInvitation"
import "./user-received-invitation.scss"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const UserReceivedInvitation: React.FC = () => {
	const { t } = useLocalization();
	const { modelInitialValues, onValidate, loading, onAcceptUserInvitation, onRejectUserInvitation, isPending } = useUserReceivedInvitation()
	const confirmationAcceptRef = useRef<ConfirmationHandle>(null);
	const confirmationRejectRef = useRef<ConfirmationHandle>(null);


	return (
		<Shell pageTitle={t("ReceivedInvitation")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={() => { }}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
							{isPending && <div className="action-buttons-row">
								
								<Button className="cancel-button" onClick={() => confirmationRejectRef.current?.show()}>{t("Reject")}</Button>
								<Confirmation level="require-number-confirmation" body={t("Confirmation.Reject")} ref={confirmationRejectRef} onOkCallback={() => onRejectUserInvitation()} />

								<Button onClick={() => confirmationAcceptRef.current?.show()}>{t("Accept")} </Button>
								<Confirmation level="require-number-confirmation" body={t("Confirmation.Accept")} ref={confirmationAcceptRef} onOkCallback={() => onAcceptUserInvitation()} />

							</div>}
						</Form>
					)}
				</Formik>


			</>
			}
		</Shell>
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization();
	return (
		<div className="indent-container">
			<TextInput name="firstName" disabled={true} label={t("FirstName")} />
			<TextInput name="lastName" disabled={true} label={t("LastName")} />
			<TextInput name="email" disabled={true} label={t("Email")} />
			<TextInput name="companyName" disabled={true} label={t("Company")} />
			<TextInput name="status" disabled={true} label={t("Status")} />
			<div className="action-buttons-row">
			</div>
		</div>)
}
