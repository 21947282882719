
export class OauthTokensResponse {
    accessToken: string | null;
    refreshToken: string | null;
    tokenType: string;
    grantType: string

    constructor(options: { grantType: string, accessToken?: string, refreshToken?: string, tokenType?: string }) {
        this.grantType = options.grantType
        this.accessToken = options.accessToken
        this.refreshToken = options.refreshToken
        this.tokenType = options.tokenType
    }
}