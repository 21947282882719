import { UnregisteredUser } from "../unregistered-user/unregistered-user"

export enum UserActionResponseType {
  redirection = "redirection"
}

export class UserActionRedirectionDetails {
  redirectionUrl: string
}

export class UserActionResponse {
  id: string
  type: UserActionResponseType
  redirectionDetails: UserActionRedirectionDetails
   
}