
import { DataHelper } from "../helper/data-helper";
import { JoinOperatorValues } from "../api/dto/common/query/join-operator";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsUndefinedOrJoinOperatorConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        //If join operator is not specified, server will use 'AND' as default.
        if( DataHelper.stringIsNullTrimEmpty(text)){
            return true;
        }
        return JoinOperatorValues.find(x => x == text) != null;
    }

    defaultMessage(args: ValidationArguments) {
        return "JoinOperator must be some of: " + JoinOperatorValues.toString();
    }
}

export function IsUndefinedOrJoinOperator(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsUndefinedOrJoinOperatorConstraint
        });
    };
}