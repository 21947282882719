




const companyPaths = {
    base: "/companies",
    companies: "",
    company: "/:companyid",
    current: "/company/current"
}

export { companyPaths as company }

