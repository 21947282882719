import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typeahead as BootstrapTypeahead, } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./typeahead.scss"
import { Option } from "react-bootstrap-typeahead/types/types";
import { combineClassNames } from "../../helpers/user-interface-helper";

export interface TypeaheadDropdownItem {
  text: string;
  value: string;
  description?: string | null
}
interface Props {
  id: string,
  label: string;
  className?: string;
  onSelect: (value: string | null) => void;
  selectedValue: string | null;
  items: TypeaheadDropdownItem[];
  disabled?: boolean;
  placeholder: string
}


const TypeaheadMenuItem: React.FC<{ option: any }> = ({ option }) => {
  return (
    <div>
      <strong>{option.text}</strong>
      <p>{option.description}</p>
    </div>
  );
};

export const Typeahead: React.FC<Props> = (props) => {
  const { items, label, className, placeholder, onSelect, selectedValue, disabled, id } = props
  const [selectedOption, setSelectedOption] = useState<TypeaheadDropdownItem | null>(null);

  useEffect(() => {
    if (selectedValue == null || selectedValue === "" || (items?.length ?? 0) === 0) {
      setSelectedOption(null)
      return
    }
    const item = items.find(x => x.value.toLowerCase() === selectedValue.toLowerCase().trim());
    setSelectedOption(item ?? null)

  }, [selectedValue, items])

  const _selectedOption = useMemo((): TypeaheadDropdownItem[] | undefined => {
    return selectedOption != null ? [selectedOption] : undefined
  }, [selectedOption])

  const handleChange = useCallback((selectedArray: Option[]) => {
    if ((selectedArray?.length ?? 0) > 0) {
      const item = selectedArray[0] as TypeaheadDropdownItem
      setSelectedOption(item);
      onSelect(item.value)
    } else {
      setSelectedOption(null)
      onSelect(null)
    }
  }, [onSelect]);

  const handleInputChange = useCallback((text: string) => {
    if (text == null) {
      return
    }
    const item = items.find(x => x.text.toLowerCase() === text.toLowerCase().trim());
    if (item != null) {
      setSelectedOption(item)
      onSelect(item.value)
    }
  }, [items, onSelect]);

  return (
    <div className={combineClassNames("typeahead_container", className)}>
      <div className="typeahead_container_label">{label}</div>
      <BootstrapTypeahead
        id={id}
        labelKey={(option) => (option as TypeaheadDropdownItem).text}
        options={items}
        disabled={disabled}
        selected={_selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        renderMenuItemChildren={(option, props, index) => (
          <TypeaheadMenuItem key={index} option={option} />
        )}

      />
    </div>
  );
};

export default Typeahead;
