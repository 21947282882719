import React from "react";
import { TextInput } from "../../../../_common/fields/text-input/TextInput";
import { NotificationTaskModel } from "../useNotificationTask";
import { useTypedFormikContext } from "../../../../hook/use-formik-context-typed";
import { SmsTemplate } from "../../../../_common/fields/sms-template/sms-template";
import { useLocalization } from "../../../../hook/use-localization";
import { TemplateMarkups } from "../../../../_common/components/template-markups/template-markups";
import { dto, SharedDataHelper } from "shared";


export const SmsNotificationTaskDetails: React.FC = () => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<NotificationTaskModel>()

    const showMessageToSend = SharedDataHelper.stringNotNullTrimEmpty((model?.details as dto.SmsNotificationTaskDetails)?.messageToSend)

    const showSentMessage = [dto.NotificationTaskStatus.apiGatewayAccepted, dto.NotificationTaskStatus.apiGatewaySent, dto.NotificationTaskStatus.completed].indexOf(model.status!) > -1
    return (
        <>
            {model.readonly &&
                <>
                    <TextInput name="notificationAddress" disabled={model.readonly} label={t("MobilePhone")} />
                    {showMessageToSend && <SmsTemplate userAttributeNames={[]} name="details.messageToSend" disabled={model.readonly} label={t("MessageToSend")} showTemplateMarkups={false} useEventMarkups={false} />}
                    {showSentMessage && <SmsTemplate userAttributeNames={[]} name="details.sentMessage" disabled={true} label={t("SentMessage")} showTemplateMarkups={false} useEventMarkups={false} />}
                </>
            }
            {!model.readonly &&
                <>
                    <TextInput name="details.mobilePhoneNumber" label={t("MobilePhone")} />
                    {model.messageType === dto.MessageType.promo && <TemplateMarkups useOnlyUnsubscribeMarkups={true} context={"sms"} useEventMarkups={false} userAttributeNames={[]} />}
                    <SmsTemplate userAttributeNames={[]} name="details.messageToSend" label={t("MessageToSend")} showTemplateMarkups={false} useEventMarkups={false} />
                </>
            }
        </>

    )
}