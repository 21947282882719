import "./analytics-container.scss";
import React from "react";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import Shell from "../../../_common/layout/shell/shell";
import { useAnalyticsContainer } from "./useAnalyticsContainer";
import { Analytic } from "../analytic/analytic";
import { useLocalization } from "../../../hook/use-localization";

export const AnalyticsContainer: React.FC = () => {
    const { t } = useLocalization();
    const {
        loading,
        selectedCompanyId,
        companyItems,
        analytics,
        onCompanySelected,
        onAnalyticsAdded,
        userAttributeNames,
        onDeleteAnalytics
    } = useAnalyticsContainer();

    const listItems = analytics.map(analyticItemId => (
        <div
            className="analytic-container"
            key={analyticItemId}
        >
            <div className="analytic-header"></div>
            <div className="analytic-content">
                {userAttributeNames && <Analytic id={analyticItemId} canDelete={true} userAttributeNames={userAttributeNames} companyId={selectedCompanyId} onDeleteRequested={onDeleteAnalytics} />}
            </div>
        </div>))

    return (
        <Shell
            pageTitle={t("Analytics")}
            mobileHeaderContent={
                <button
                    className="mobile-add-new"
                    onClick={onAnalyticsAdded}
                >
                    {t("AddAnalytics")}
                </button>
            }
            desktopHeaderContent={
                <Button onClick={onAnalyticsAdded}>
                    {t("AddAnalytics")}
                </Button>
            }
        >
            <Loading loading={loading} />
            <div className="indent-container event-types-header-filter">
                <div className="event-types-filter">
                    <div className="company-container">
                        <CompanyDropdown
                            items={companyItems}
                            selectedCompanyId={selectedCompanyId}
                            onSelect={onCompanySelected}
                        />
                    </div>

                </div>

            </div>
            <div className="separator"></div>
            {listItems}

        </Shell>
    );
};
