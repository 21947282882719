import { Form, Formik } from "formik"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./notification-template.scss"
import { NotificationTemplateModel, replaceCompanyDetailsFromTemplate, useNotificationTemplate, useNotificationTemplateForm } from "./useNotificationTemplate"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { SmsNotificationTemplateDetails } from "./sms/sms-notification-template-details"
import { SharedDataHelper, dto } from "shared"
import { EmailNotificationTemplateDetails } from "./email/email-notification-template-details"
import { UserQueryField } from "../../../_common/fields/user-query/user-query-field"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"
import { MessageTypeDropdownField } from "../../../_common/fields/message-type-dropdown/message-type-dropdown-field"
import { PredefinedNotificationTemplates } from "../predefined-templates/predefined-notification-templates"


export const NotificationTemplate: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteTemplate, isAddingNewTemplate, addGroupMessageFromTemplate, isAddGroupMessageFromTemplateVisible } = useNotificationTemplate()
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);
    const [showPredefinedTemplates, setShowPredefinedTemplates] = useState(false)
    const [predefinedTemplateDesign, setPredefinedTemplateDesign] = useState<string | null>(null)
    const onAskPredefinedTemplate = useCallback(() => {
        setShowPredefinedTemplates(true)
    }, [])

    const onPredefinedTemplateSelected = useCallback((template: dto.PredefinedNotificationTemplate) => {
        setPredefinedTemplateDesign(template.design)
        setShowPredefinedTemplates(false)
    }, [])

    const onPredefinedTemplateClosed = useCallback(() => {
        setShowPredefinedTemplates(false)
    }, [])

    const displayStyle = showPredefinedTemplates ? 'none' : 'initial';

    return (
        <>
            <div style={{ display: displayStyle }}>
                <Shell pageTitle={isAddingNewTemplate ? t("AddTemplate") : t("Template")}
                    desktopHeaderContent={<>
                        {isAddGroupMessageFromTemplateVisible && <Button onClick={addGroupMessageFromTemplate}>{t("AddBulkMessage")}</Button>}
                    </>}>
                    <Loading loading={loading} />
                    {modelInitialValues != null && <>
                        <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                            validateOnBlur={true}
                            validate={onValidate}>
                            {() => (
                                <Form autoComplete="off">
                                    <FormContent isAdd={isAddingNewTemplate} onAskPredefinedTemplate={onAskPredefinedTemplate} predefinedTemplateDesign={predefinedTemplateDesign} />
                                </Form>
                            )}
                        </Formik>

                        {!isAddingNewTemplate && <DangerZone>
                            <div className="delete-title">{t("DeleteNotificationTemplate")} </div>
                            <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
                            <div className="delete-message">{t("DeleteNotificationTemplateDescription")}</div>
                            <Confirmation level="require-number-confirmation-strong" body={t("Confirmation.DeleteTemplate")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteTemplate()} />
                        </DangerZone>}
                        <div className="bottom-margin-of-template">&nbsp;</div>
                    </>
                    }
                </Shell>
            </div>
            {showPredefinedTemplates && <PredefinedNotificationTemplates onPredefinedTemplateSelected={onPredefinedTemplateSelected} onPredefinedTemplateClosed={onPredefinedTemplateClosed} />}
        </>

    )

}

interface FormContentProps {
    isAdd: boolean,
    onAskPredefinedTemplate: () => void
    predefinedTemplateDesign: string | null
}

const FormContent: React.FC<FormContentProps> = ({ isAdd, onAskPredefinedTemplate, predefinedTemplateDesign }) => {
    const { t } = useLocalization();
    const { model, submitForm } = useTypedFormikContext<NotificationTemplateModel>()
    const { editForm, cancelEditForm, saveTemplateEnabled, companyDropDownEnabled, companyItems, templateTypes, userAttributeNames, useQueryItems, eventTypesItems, selectedCompanyName, isBirthDayTemplate, useEventsMarkups } = useNotificationTemplateForm()
    const [nameTooltipVisible, setNameTooltip] = useState(false);
    const confirmationSaveRef = useRef<ConfirmationHandle>(null);
    const [templateDesignWithCompanyDetails, setTemplateDesignWithCompanyDetails] = useState<string | null>(null)

    useEffect(() => {
        setTemplateDesignWithCompanyDetails(null)
    }, [model.companyId])

    useEffect(() => {
        if (predefinedTemplateDesign == null) {
            setTemplateDesignWithCompanyDetails(null);
            return
        }
        const company = model?.companies.find(x => x.id === model.companyId)
        const design = replaceCompanyDetailsFromTemplate(company!, predefinedTemplateDesign)
        setTemplateDesignWithCompanyDetails(design)

    }, [model.companyId, model.companies, predefinedTemplateDesign])


    return (
        <div className="indent-container notification-template-form-content">
            <CompanyDropDownField disabled={!companyDropDownEnabled} name="companyId" label={t("Company")} items={companyItems} />
            <div className="name-container">
                <TextInput name="name" disabled={model.readonly} label={t("Name")} />
                <img className="info-img" src="/info.png" onMouseEnter={() => setNameTooltip(true)} onMouseLeave={() => setNameTooltip(false)} alt="" title=""></img>
                {nameTooltipVisible && <div className="name-tooltip">{t("Birthday.Template.Description")}</div>}
            </div>
            {!isBirthDayTemplate && <DropDownField name="eventTypeId" disabled={model.notificationTemplateId != null} label={t("EventType")} items={eventTypesItems} />}
            <DropDownField name="templateType" disabled={model.readonly || model.notificationTemplateId != null} label={t("Type")} items={templateTypes} />
            <MessageTypeDropdownField disabled={model.readonly || model.notificationTemplateId != null} name="messageType" />
            {model.templateType === dto.NotificationTemplateType.sms && <SmsNotificationTemplateDetails setDefaultMessage={isAdd} companyName={selectedCompanyName} userAttributeNames={userAttributeNames} useEventMarkups={useEventsMarkups} />}
            {model.templateType === dto.NotificationTemplateType.email && <EmailNotificationTemplateDetails setDefaultMessage={isAdd} companyName={selectedCompanyName} userAttributeNames={userAttributeNames} useEventMarkups={useEventsMarkups} onAskPredefinedTemplates={onAskPredefinedTemplate} design={templateDesignWithCompanyDetails} />}
            {!isBirthDayTemplate && SharedDataHelper.stringIsNullTrimEmpty(model.eventTypeId) && <DropDownField name="useQuery" disabled={model.readonly} label={t("UseQuery")} items={useQueryItems} />}
            {!isBirthDayTemplate && model.useQuery && <UserQueryField disabled={model.readonly} name="query" label={t("FilterQuery")} query={model.query!} userAttributeNames={userAttributeNames} />}
            <div className="action-buttons-row">
                {model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {saveTemplateEnabled && !model.readonly && <Button onClick={() => confirmationSaveRef.current?.show()}>{t("Save")}</Button>}
                <Confirmation level="require-number-confirmation" body={t("Confirmation.SaveTemplate")} ref={confirmationSaveRef} onOkCallback={() => submitForm()} />
            </div>
        </div>)
}
