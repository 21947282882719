import { SortItem } from "../../common/query/sort-item";
import { IsDefined, IsOptional, ValidateNested } from "class-validator";
import { PagingRequest } from "../../common/paging/paging-request";
import { AreValidSortItems } from '../../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { NotificationTemplateType } from "./notification-template-type";

export class GetNotificationTemplatesRequest {

    @ContainsValidString()
    companyId: string

    @IsOptional()
    templateType?: NotificationTemplateType

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]
}