import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./company.scss"
import { CompanyModel, useCompany, useCompanyForm } from "./useCompany"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const Company: React.FC = () => {
    const { t } = useLocalization()
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteCompany, addNewCompany, onGoToCompanyConfiguration, companyConfigurationVisible } = useCompany()
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);


    return (
        <Shell pageTitle={addNewCompany ? t("AddCompany") : t("Company")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent />
                        </Form>
                    )}
                </Formik>
                {companyConfigurationVisible && <div className="alert alert-secondary zone-container indent-container" role="alert">
                    <div className="message-title">{t("Config")}</div>
                    <Button onClick={onGoToCompanyConfiguration}>{t("Company.Configuration")}</Button>
                    <div>{t("Company.Configuration.Change")}</div>
                </div>}
                {!addNewCompany && <DangerZone>
                    <div className="delete-title">{t("DeleteCompany")}</div>
                    <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
                    <div className="delete-message">{t("DeleteCompanyDescription")}</div>
                    <Confirmation level="require-number-confirmation-strong" body={t("Confirmation.DeleteCompany")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteCompany()} />
                </DangerZone>}</>}
        </Shell>
    )
}

const FormContent: React.FC = () => {
    const { t } = useLocalization()
    const { model, submitForm } = useTypedFormikContext<CompanyModel>()
    const { editForm, cancelEditForm, statuses } = useCompanyForm()
    const confirmationSaveRef = useRef<ConfirmationHandle>(null);

    return (
        <div className="indent-container">
            <TextInput name="name" disabled={model.readonly} label={t("Name")} />
            <TextInput name="address" disabled={model.readonly} label={t("Address")} />
            <TextInput name="email" disabled={model.readonly} label={t("Email")} />
            <TextInput name="timezone" disabled={model.readonly} label={t("Timezone")} />
            {model.companyId != null && <TextInput name="consentSourceName" disabled={true} label={t("ConsentSource")} />}

            <DropDownField name="status" disabled={model.readonly} label={t("Status")} items={statuses} />
            {model.companyId != null && <DatePickerField name="lastUpdated" disabled={true} label={t("LastUpdated")} />}

            <div className="action-buttons-row">
                {model.companyId != null && model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
                {model.companyId != null && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {!model.readonly && <Button onClick={() => confirmationSaveRef.current?.show()}>{t("Save")}</Button>}
                <Confirmation level="require-number-confirmation" body={t("Confirmation.SaveCompany")} ref={confirmationSaveRef} onOkCallback={() => submitForm()} />
            </div>
        </div>)
}
