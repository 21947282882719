import { dto } from "../.."
import { ConsentName, ConsentSourceName } from "../consent"
import { UserAttribute } from "../user-attribute/user-attribute"
import { UserGender } from "../user/user/user-gender"

export class UnregisteredUser {
    id: string
    email: string
    emailVerified: boolean
    firstName: string
    lastName: string
    dateOfBirth: Date
    gender: UserGender
    createdByCompanyId: string
    createdByCompany: dto.Company
    createdByUserId: string
    mobilePhoneNumber: string
    mobilePhoneNumberVerified: boolean
    consentSourceOfEmailVerified: ConsentSourceName | null
    consentSourceOfMobilePhoneNumberVerified: ConsentSourceName | null
    externalId: string
    userAttributes: UserAttribute[]
    givenConsents: ConsentName[]
    timezone: string
    createdDate: Date
    updatedDate: Date
}