

const notificationTemplatePaths = {
    base: "/notification-templates",
    notificationTemplates: "",
    notificationTemplate: "/:notificationtemplateid",
    predefinedTemplates: "/predefined/templates"
}

export { notificationTemplatePaths as notificationTemplate }

