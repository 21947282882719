import { IsArray, IsEmail } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { IsMobilePhoneNumber } from "../../../../validators/is-mobile-phone-number";


export class UserRegistrationCompleteRequest {
    @ContainsValidString()
    @IsEmail()
    email: string;

    @IsMobilePhoneNumber()
    mobilePhoneNumber: string;

    @ContainsValidString()
    emailToken: string;

    @ContainsValidString()
    smsToken: string;

    @ContainsValidString()
    clientId: string;
}