import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import "./sms-template.scss"
import { TemplateMarkups } from "../../components/template-markups/template-markups";
import { dto } from "shared";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label: string,
    name: string,
    showTemplateMarkups: boolean,
    userAttributeNames: dto.UserAttributeName[],
    useEventMarkups: boolean

}
export const SmsTemplate: React.FC<Props> = (props => {
    const { label, name, value, showTemplateMarkups, userAttributeNames, useEventMarkups, ...inputProps } = props
    const [field, meta] = useField(name);
    console.log("SmsTemplate Value:" + field.value)
    return (
        <div className="text-area__container">
            <div>{label}</div>
            {showTemplateMarkups && <TemplateMarkups useEventMarkups={useEventMarkups} userAttributeNames={userAttributeNames} context="sms" />}
            <textarea className="text-area__input form-control sms-template-text-area" {...field} {...inputProps} value={field.value ?? ""} />
            {meta.touched && meta.error ? (
                <div className="error-text">{meta.error}</div>
            ) : null}
        </div>
    );
})