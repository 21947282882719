import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserAttributeClient } from "./i-user-attribute-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserAttributeClient extends BaseClient implements IUserAttributeClient {

    async getUserAttributes(data: dto.GetUserAttributesRequest): Promise<dto.PagingResponse<dto.UserAttribute>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.userAttributes), params: data });
            return response.data as dto.PagingResponse<dto.UserAttribute>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUserAttribute(userattributeId: string): Promise<dto.UserAttribute> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.userAttribute.replace(":userattributeid", userattributeId)) });
            return response.data as dto.UserAttribute;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async createUserAttribute(data: dto.AddUserAttributeRequest): Promise<dto.UserAttribute> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.userAttributes), data: data });
            return response.data as dto.UserAttribute;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateUserAttribute(userattributeId: string, data: dto.UpdateUserAttributeRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.userAttribute.replace(":userattributeid", userattributeId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUserAttribute(userattributeId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.userAttribute.replace(":userattributeid", userattributeId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async uploadUserAttributesUsingExcel(companyId: string, data: dto.UploadUserAttributesByExcelFileRequest): Promise<dto.UploadUserAttributesByExcelFileResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.userAttribute.base + apiPaths.userAttribute.uploadExcelFile.replace(":companyid", companyId)), data: data });
            return response.data as dto.UploadUserAttributesByExcelFileResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}