import React from "react";
import { SharedDataHelper } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./unregistered-users.scss";
import { useUnregisteredUsers } from "./useUnregisteredUsers";
import DebouncedCallbackTextField from "../../../_common/common/debounced-callback-text-field";
import { useLocalization } from "../../../hook/use-localization";

export const UnregisteredUsers: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useLocalization()
    const {
        unregisteredUsers,
        companyItems,
        selectedCompanyId,
        onUnregisteredUserSelected,
        onCompanySelected,
        loading,
        pagination,
        onAddUser,
        onUploadExcel,
        addNewUserEnabled,
        setFirstName,
        setLastName,
        onViewEvents,
        onUserAttributesUploadExcel,
        onEventsUploadExcel
    } = useUnregisteredUsers();

    const rowClick = (userid: string) => {
        if (isMobile) {
            onUnregisteredUserSelected(userid);
        }
    };

    const listItems = unregisteredUsers.map((user) => (
        <div
            className="unregistered-users__item data-row-item"
            key={user.id}
            onClick={() => rowClick(user.id)}
        >
            <div className="unregistered-users__item-personal-info">
                <div className="unregistered-users__item-name">
                    <button
                        onClick={() => {
                            onUnregisteredUserSelected(user.id);
                        }}
                    >
                        {user.firstName} {user.lastName}
                    </button>
                </div>
                <div className="unregistered-users__item-address">{user.email}</div>
                <div className="unregistered-users__item-mobile-phone">
                    {user.mobilePhoneNumber}
                </div>
                <div className="unregistered-users__item-events">
                    <button onClick={() => onViewEvents(user.id)}>{t("Events")}</button>
                </div>
            </div>
            <div className="unregistered-users__item-dates-ext-id">
                <div className="unregistered-users__item-external-id">{SharedDataHelper.stringNotNullTrimEmpty(user.externalId) ? `ExtId: ${user.externalId}` : ""}</div>
                <div className="unregistered-users__item-date-of-birth">
                    {SharedDataHelper.getFormattedDate(user.dateOfBirth, "dd.MM.yyyy")}
                </div>
            </div>
        </div>
    ));
    return (
        <Shell
            pageTitle={t("Contacts")}
            mobileHeaderContent={
                <div>
                    <button
                        disabled={!addNewUserEnabled}
                        className="mobile-add-new mobile-add-new-contact-context"
                        onClick={onEventsUploadExcel}
                    >
                        {t("ExcelEvents")}
                    </button>
                    <button
                        disabled={!addNewUserEnabled}
                        className="mobile-add-new mobile-add-new-contact-context"
                        onClick={onUserAttributesUploadExcel}
                    >
                        {t("ExcelAttr")}
                    </button>
                    <button
                        disabled={!addNewUserEnabled}
                        className="mobile-add-new mobile-add-new-contact-context"
                        onClick={onUploadExcel}
                    >
                        {t("ExcelContacts")}
                    </button>
                    <button
                        disabled={!addNewUserEnabled}
                        className="mobile-add-new mobile-add-new-contact-context"
                        onClick={onAddUser}
                    >
                        +
                    </button>
                </div>
            }
            desktopHeaderContent={
                <div>
                    <Button className="unregistered-users-btn-upload-excel-events" disabled={!addNewUserEnabled} onClick={onEventsUploadExcel}>
                        {t("UploadExcelEvents")}
                    </Button>
                    <Button className="unregistered-users-btn-upload-excel-attributes" disabled={!addNewUserEnabled} onClick={onUserAttributesUploadExcel}>
                        {t("UploadExcelAttributes")}
                    </Button>
                    <Button disabled={!addNewUserEnabled} onClick={onUploadExcel}>
                        {t("UploadExcel")}
                    </Button>
                    <Button className="unregistered-user-add-button" disabled={!addNewUserEnabled} onClick={onAddUser}>
                        {t("AddContact")}
                    </Button>
                </div>
            }
        >
            <Loading loading={loading} />
            <div className="indent-container users-header-filter">
                <div className="unregistered-users-filter">
                    <CompanyDropdown
                        items={companyItems}
                        selectedCompanyId={selectedCompanyId}
                        onSelect={onCompanySelected}
                    />
                    <div className="text-input__container unregistered-users-first-name-filter">
                        <div>{t("FirstName")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setFirstName} />
                    </div>
                    <div className="text-input__container unregistered-users-last-name-filter">
                        <div>{t("LastName")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setLastName} />
                    </div>
                </div>
                <Pagination {...pagination} />
            </div>
            {listItems}
            {unregisteredUsers.length === 0 && <NoEntities />}
        </Shell>
    );
};
