import { IsArray, IsOptional } from "class-validator";
import { IsMobilePhoneNumber } from "../../../../../validators/is-mobile-phone-number"
import { AddNotificationTaskBatchDetails } from "../add-notification-task-batch-details";

export class SmsAddNotificationTaskBatchDetails implements AddNotificationTaskBatchDetails {

    @IsOptional()
    @IsArray()
    @IsMobilePhoneNumber({ each: true })
    mobilePhoneNumbers: string[] | null


    constructor(options: { mobilePhoneNumbers: string[] | null }) {
        if (options == null) {
            return
        }

        this.mobilePhoneNumbers = options.mobilePhoneNumbers
    }
}