import validator from 'validator';

const userPaths = {
    user: {
        base: "/users",
        users: "",
        user: "/:userid",
        userByEmail: "/user-by-email/:email",
        currentUser: "/user/current",
        changePassword: "/:userid/password",
        changeStatus: "/:userid/status"

    },

    userRoles: {
        base: "/users-roles",
        userRoles: "",
        userRole: "/:userroleid",
    },

    registration: {
        base: "/users/registration",
        register: "/register",
        verifyEmail: "/verify-email",
        resendVerification: "/resend-verification",
        complete: "/complete"
    },

    recoverPassword: {
        base: "/users/recover-password",
        recover: "/recover",
        challengeToken: "/challenge-token",
        complete: "/complete"
    },

    invitation: {
        base: "/user-invitations",
        invitations: "",
        selfInvitations: "/invitation/self",
        accept: "/:userinvitationid/accept",
        reject: "/:userinvitationid/reject",
        invitation: "/:userinvitationid"
    }
}

export { userPaths as user }

