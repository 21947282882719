import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserClient } from "./i-user-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserClient extends BaseClient implements IUserClient {

    async getCurrentUser(): Promise<dto.User> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.user.base + apiPaths.user.user.currentUser) });
            return response.data as dto.User;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUsers(data: dto.GetUsersRequest): Promise<dto.PagingResponse<dto.User>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.user.base + apiPaths.user.user.users), params: data });
            return response.data as dto.PagingResponse<dto.User>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUser(userId: string): Promise<dto.User> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.user.base + apiPaths.user.user.user.replace(":userid", userId)) });
            return response.data as dto.User;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUserByEmail(email: string): Promise<dto.User> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.user.user.base + apiPaths.user.user.userByEmail.replace(":email", email)) });
            return response.data as dto.User;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateUser(userId: string, data: dto.UpdateUserRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.user.user.base + apiPaths.user.user.user.replace(":userid", userId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUser(userId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.user.user.base + apiPaths.user.user.user.replace(":userid", userId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async changeUserPassword(userId: string, data: dto.ChangeUserPasswordRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.user.user.base + apiPaths.user.user.changePassword.replace(":userid", userId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async changeUserStatus(userId: string, data: dto.ChangeUserStatusRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.user.user.base + apiPaths.user.user.changeStatus.replace(":userid", userId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}