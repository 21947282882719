import { useCallback, useEffect, useMemo, useState } from "react"
import { dto } from "shared"
import { IUnregisteredUserClient, IUnregisteredUserClientId } from "shared-client"
import useClient from "../../../hook/useClient"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { validateOrReject } from "class-validator";
import { useHistory } from "react-router-dom"



const defaultQuery = new dto.UserAttributeQueryCondition()
defaultQuery.name = dto.UserAttribute.sharedAttributes.dateOfBirth
defaultQuery.type = dto.UserAttributeQueryType.lowerOrEqual
defaultQuery.value = "2000-01-01"


interface Props {
    companyId: string | null
}

export const useAnalytic = ({ companyId: selectedCompanyId }: Props) => {
    const history = useHistory()
    const [query, setQuery] = useState(defaultQuery)
    const { setGlobalMessage } = useGlobalMessageContext()
    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)
    const [numberOfUnregisteredUsers, setNumberOfUnregisteredUsers] = useState<number | null>(null)
    const [totalNumberOfUnregisteredUsers, setTotalNumberOfUnregisteredUsers] = useState<number | null>(null)
    const [queryError, setQueryError] = useState(false)

    useEffect(() => {
        (async () => {
            if (selectedCompanyId == null) {
                setNumberOfUnregisteredUsers(null);
                setTotalNumberOfUnregisteredUsers(null)
                return
            }
            try {
                const totalNum = await loadUnregisteredUsers(selectedCompanyId!, undefined)
                const numOfUsers = await loadUnregisteredUsers(selectedCompanyId!, query)
                setNumberOfUnregisteredUsers(numOfUsers)
                setTotalNumberOfUnregisteredUsers(totalNum)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            }
        })()

    }, [selectedCompanyId])

    const onSetQuery = async (query: dto.UserAttributeQueryCondition) => {
        setQuery(query);
        try {
            await validateOrReject(query);
            setQueryError(false)
        }
        catch (err) {
            setQueryError(true)
            return;
        }
        try {
            const numOfUsers = await loadUnregisteredUsers(selectedCompanyId!, query);
            setNumberOfUnregisteredUsers(numOfUsers);
        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        }
    };

    const loadUnregisteredUsers = async (companyId: string, query: dto.UserAttributeQueryCondition | undefined | null): Promise<number> => {
        const request = new dto.GetUnregisteredUsersRequest()
        request.companyId = companyId
        request.paging = new dto.PagingRequest({ pageIndex: 0, pageSize: 1 })
        request.sortBy = []
        request.query = query;
        request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
        const pagedResponse = await unregisteredUserClient.getUsers(request)
        return pagedResponse.paging.totalNumberOfItems
    }

    const addTemplateEnabled = useMemo(() => {
        return !queryError
    }, [queryError])

    const addTemplate = useCallback(() => {
        (window as any).TEMP_USER_ATTRIBUTE_QUERY = query;
        history.push("/notification-templates/add/notification-template/")
    }, [query, history])

    return {
        query,
        onSetQuery,
        numberOfUnregisteredUsers,
        totalNumberOfUnregisteredUsers,
        queryError,
        addTemplateEnabled,
        addTemplate
    }
}