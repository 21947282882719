
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class NotContainsUnicodeCharactersConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {

        if (text == null) {
            return true
        }

        if (typeof text !== "string") {
            // other validators will fix it
            return true
        }

        const lowerCaseText = text.toLowerCase()
        const unicodeCharacters = ["š", "đ", "č", "ć", "ž", "ш", "ђ", "ж", "ћ", "ч", "њ"]
        for (const ch of unicodeCharacters) {
            if (lowerCaseText.indexOf(ch) > -1) {
                return false
            }
        }

        return true;
    }

    defaultMessage(args: ValidationArguments) {
        return `MessageCanNotContainUnicodeCharacters`;
    }
}

export function NotContainsUnicodeCharacters(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: NotContainsUnicodeCharactersConstraint
        });
    };
}