import React from "react";

const useWindowSize = () => {
  const [windowDimensionWidth, setWindowDimensionWidth] = React.useState(0);
  const [windowDimensionHeight, setWindowDimensionHeight] = React.useState(0);


  React.useEffect(() => {
    setWindowDimensionWidth(window.innerWidth);
    setWindowDimensionHeight(window.innerHeight);
  }, []);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensionWidth(window.innerWidth);
      setWindowDimensionHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowWidth: windowDimensionWidth,
    windowHeight: windowDimensionHeight
  }
}

export default useWindowSize;


