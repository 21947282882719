import { Match } from "../../../../validators/match"
import { MaxLength, MinLength } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";

export class ChangeUserPasswordRequest {

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    oldPassword: string; 
     
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    password: string; 

    @ContainsValidString()
    @Match('password',{ message :'Password and confirm password are not equal.'})
    confirmPassword: string; 

}