import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./event-type.scss"
import { UserModel, useEventType, useEventTypeForm } from "./useEventType"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { TextArea } from "../../../_common/fields/text-area/TextArea"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"


export const EventType: React.FC = () => {
    const { t } = useLocalization()
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteEventType, isAddingNewEventType } = useEventType()
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);

    return (
        <Shell pageTitle={isAddingNewEventType ? t("AddEventType") : t("EventType")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent isAdd={isAddingNewEventType} />
                        </Form>
                    )}
                </Formik>

                {!isAddingNewEventType && <DangerZone>
                    <div className="delete-title">{t("DeleteEventType")}</div>
                    <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
                    <div className="delete-message">{t("DeleteEventTypeDescription")}</div>
                    <Confirmation level="require-number-confirmation-strong"
                        body={t("Confirmation.Delete.EventType")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteEventType()} />
                </DangerZone>}


            </>
            }
        </Shell>
    )
}

interface FormContentProps {
    isAdd: boolean
}

const FormContent: React.FC<FormContentProps> = ({ isAdd }) => {
    const { t } = useLocalization()
    const { model, submitForm } = useTypedFormikContext<UserModel>()
    const { editForm, cancelEditForm, saveEventTypeEnabled, companyItems, companyDropDownEnabled } = useEventTypeForm()
    const confirmationRef = useRef<ConfirmationHandle>(null);

    return (
        <div className="indent-container">
            <CompanyDropDownField disabled={!companyDropDownEnabled} name="companyId" label={t("Company")} items={companyItems} />
            <TextInput name="name" disabled={model.readonly} label={t("Name")} />
            <TextInput name="description" disabled={model.readonly} label={t("Description")} />
            <div className="example-sendNotificationsBefore">
                {t("AddValuesLike")}:<br />
                <div>1-hour</div>
                <div>3-day</div>
                <div>-2-minute</div>
            </div>
            <TextArea name="sendNotificationsBefore" disabled={model.readonly} label={t("SendNotificationBefore")} />
            <div>{t("EventTypeNegativeValuesDescription")}</div>
            <div className="action-buttons-row">
                {model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {saveEventTypeEnabled && !model.readonly && <Button onClick={() => confirmationRef.current?.show()}>{t("Save")}</Button>}
                <Confirmation level="require-number-confirmation" body={t("Confirmation.Save")} ref={confirmationRef} onOkCallback={() => submitForm()} />
            </div>
        </div>)
}
