import React from "react";
import { SharedDataHelper } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./client-credentials.scss";
import { useClientCredentials } from "./useClientCredentials";
import { useLocalization } from "../../../hook/use-localization";
import { useUserRole } from "../../../hook/useUserRole";

export const ClientCredentials: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useLocalization()
    const { descriptionFromRoleName } = useUserRole()

    const {
        clientCredentials,
        companyItems,
        selectedCompanyId,
        onClientCredentialSelected,
        onCompanySelected,
        loading,
        pagination,
        onAddClientCredential,
        addNewClientCredentialEnabled
    } = useClientCredentials();

    const rowClick = (clientCredentialId: string) => {
        if (isMobile) {
            onClientCredentialSelected(clientCredentialId);
        }
    };

    const listItems = clientCredentials.map((clientCredential) => (
        <div
            className="client-credentials__item data-row-item"
            key={clientCredential.id}
            onClick={() => rowClick(clientCredential.id)}
        >
            <div className="client-credentials__item-name-role">
                <div className="client-credentials__item-name">
                    <button
                        onClick={() => {
                            onClientCredentialSelected(clientCredential.id);
                        }}
                    >
                        {clientCredential.name}
                    </button>
                </div>
                <div className="client-credentials__item-role">{t(descriptionFromRoleName(clientCredential.role))}</div>
            </div>
            <div className="client-credentials__item-created-date">
                {SharedDataHelper.getFormattedDate(clientCredential.createdDate, "dd.MM.yyyy HH:mm")}
            </div>
        </div>
    ));
    return (
        <Shell
            pageTitle={t("ClientCredentials")}
            mobileHeaderContent={
                <div>
                    <button
                        disabled={!addNewClientCredentialEnabled}
                        className="mobile-add-new"
                        onClick={onAddClientCredential}>
                        {t("Add")}
                    </button>
                </div>
            }
            desktopHeaderContent={
                <div>

                    <Button className="client-credential-add-button" disabled={!addNewClientCredentialEnabled} onClick={onAddClientCredential}>
                        {t("Add")}
                    </Button>
                </div>
            }
        >
            <Loading loading={loading} />
            <div className="indent-container users-header-filter">
                <div className="client-credentials-filter">
                    <CompanyDropdown
                        items={companyItems}
                        selectedCompanyId={selectedCompanyId}
                        onSelect={onCompanySelected}
                    />
                </div>
                <Pagination {...pagination} />
            </div>
            {listItems}
            {clientCredentials.length === 0 && <NoEntities />}
        </Shell>
    );
};
