import { Form, Formik } from "formik"
import React from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./account.scss"
import { AccountModel, useAccount, useAccountForm } from "./useAccount"
import { useLocalization } from "../../../hook/use-localization"
import { copyElementValue } from "../../../../common/form/copy"
import { ConsentField } from "../../../_common/fields/consent/consent-field"
import { dto } from "shared"


export const Account: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteAccount, userId, accountDeleteEnabled, onChangePassword, hasCompanyRole, onEditUserRoles, onViewReceivedInvitations } = useAccount()


    return (
        <Shell pageTitle={t("Account")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent />
                        </Form>
                    )}
                </Formik>

                {hasCompanyRole && <div className="alert zone-container  alert-secondary indent-container" role="alert">
                    <div className="zone-title">{t("CompanyRoles")} </div>
                    <Button onClick={onEditUserRoles}>{t("Edit")} </Button>
                    <div className="zone-message">{t("EditCompanyRolesDescription")} </div>
                </div>}
                <div className="alert zone-container  alert-secondary indent-container" role="alert">
                    <div className="zone-title">{t("Password")} </div>
                    <Button onClick={onChangePassword}>{t("ChangePassword")}</Button>
                    <div className="zone-message">{t("ChangePassword.Description.1")}<br /> {t("ChangePassword.Description.2")}</div>
                </div>
                <div className="alert zone-container  alert-secondary indent-container" role="alert">
                    <div className="zone-title">{t("ReceivedInvitations")}</div>
                    <Button onClick={onViewReceivedInvitations}>{t("ReceivedInvitations")}</Button>
                    <div className="zone-message">{t("ReceivedInvitationsDescription")}</div>
                </div>
                {accountDeleteEnabled && <DangerZone>
                    <div className="delete-title">{t("DeleteAccount")}</div>
                    <Button onClick={() => onDeleteAccount(userId!)}>{t("Delete")}</Button>
                    <div className="delete-message">{t("DeleteAccountDescription")}</div>
                </DangerZone>}
            </>
            }
        </Shell>
    )
}

const FormContent: React.FC = () => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<AccountModel>()
    const { editForm, cancelEditForm, genders } = useAccountForm();
    const consentItems = model.consentItems.map((consentItem, index) => {
        const name = `consentItems.${index}.enabled`;
        const isDisabled = consentItem.consent.name == dto.ConsentName.dataProcessingConsent || model.readonly
        return <div className="consent-container" key={consentItem.consent.id} >
            <ConsentField disabled={isDisabled} className="contact-consent-field" name={name} consent={consentItem.consent} />
        </div >
    });

    return (
        <div className="account-container indent-container">
            <TextInput name="firstName" disabled={model.readonly} label={t("FirstName")} />
            <TextInput name="lastName" disabled={model.readonly} label={t("LastName")} />
            <TextInput name="email" disabled={true} label={t("Email")} />
            <TextInput name="mobilePhoneNumber" disabled={true} label={t("MobilePhone")} />
            <DatePickerField name="dateOfBirth" disabled={model.readonly} label={t("DateOfBirth")} />
            <DropDownField name="gender" disabled={model.readonly} label={t("Gender")} items={genders} />
            <TextInput name="userId" id="userId__" disabled={true} label={t("Identifier")} />
            <div className="account__user-id-copy-holder">
                <img
                    className="copy"
                    onClick={() => copyElementValue("userId__")}
                    src="/copy.svg"
                    alt="Copy"
                    title={t("Copy")}
                ></img>
            </div>
            <TextInput name="roleDescription" disabled={true} label={t("CompanyRole")} />
            <TextInput name="companyName" disabled={true} label={t("Company")} />
            <div className="account-delete-data-processing-consent-desc">{t("AccountDeleteDataProcessConsent")}</div>
            {consentItems}
            <div className="action-buttons-row">
                {model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {!model.readonly && <ButtonSubmit>{t("Save")}</ButtonSubmit>}
            </div>
        </div>)
}
