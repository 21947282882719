import { dto } from "shared";
import { INotificationTaskSpecific } from "./i-notification-task-specific";
import { SmsNotificationTaskSpecific } from "./sms/sms-notification-task-specific";
import { EmailNotificationTaskSpecific } from "./email/email-notification-task-specific";

export class NotificationSpecificFactory {

	private static smsSpecific = new SmsNotificationTaskSpecific();
	private static emailSpecific = new EmailNotificationTaskSpecific();


	static createTaskSpecific(taskType: dto.NotificationTaskType): INotificationTaskSpecific {
		if (taskType === dto.NotificationTaskType.sendSms) {
			return NotificationSpecificFactory.smsSpecific
		}
		if (taskType === dto.NotificationTaskType.sendEmail) {
			return NotificationSpecificFactory.emailSpecific
		}

		throw new Error("createTaskSpecific: Not supported task type")
	}
}