import { useField } from "formik";
import React, { FC, InputHTMLAttributes } from "react"
import { useMemo } from "react";
import { combineClassNames, randomId } from "../../helpers/user-interface-helper";
import "./check-box-field.scss"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label: string,
	name: string,
}

export const CheckBoxField: FC<Props> = (props => {
	const { label, name, className, ...inputProps } = props
	const [field, meta] = useField(name);
	const cbId = useMemo(() => {
		return randomId()
	}, [])

	return (
		<div className={combineClassNames("checkbox__container", className)}>
			<input id={cbId} className="checkbox-input" type="checkbox" {...field}  {...inputProps} />
			<label className="form-check-label" htmlFor={cbId}>
				{label}
			</label>
		</div>
	);
})