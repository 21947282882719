import { Form, Formik } from "formik";
import React from "react"
import { Route, Switch } from "react-router-dom";
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { LinkButton } from "../../../_common/common/link-button/LinkButton";
import { CheckBoxField } from "../../../_common/fields/checkbox/check-box-field";
import { TextInput } from "../../../_common/fields/text-input/TextInput";
import AuthShell from "../../../_common/layout/auth/auth-shell";
import { UserRegistrationModel, useUserVerifyEmail, useUserVerifyMobilePhone, useUserRegistration, useUserRegistrationInitial, useRegistrationCompleted } from "./useUserRegistration";
import "./user-registration.scss"
import { Button } from "../../../_common/common/button/Button";
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field";
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit";
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field";
import { useLocalization } from "../../../hook/use-localization";
import { ConsentField } from "../../../_common/fields/consent/consent-field";

export const UserRegistration: React.FC = () => {
    const { t } = useLocalization();
    const { initialValues, onSubmit, validate, paths } = useUserRegistration()

    return (
        <>
            <AuthShell title={t("SignUp")}>
                {initialValues.dataProcessingConsent && initialValues.smsConsent && initialValues.emailConsent && <Formik initialValues={initialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={validate}>
                    {() => (
                        <Form>
                            <div className="form-container">
                                <Switch>
                                    <Route path={paths.PathInitial} component={Initial} />
                                    <Route path={paths.PathVerifyEmail} component={VerifyEmail} />
                                    <Route path={paths.PathVerifyMobilePhone} component={VerifyMobilePhone} />
                                    <Route path={paths.PathCompleted} component={Completed} />
                                </Switch>
                            </div>
                        </Form>
                    )}
                </Formik>}
            </AuthShell>
        </>
    );
}

const Initial: React.FC = () => {
    const { t } = useLocalization();
    const { values: model, isSubmitting } = useTypedFormikContext<UserRegistrationModel>()
    const { toggleShowPassword, genders } = useUserRegistrationInitial()

    return (
        <>
            <TextInput name="firstName" label={t("FirstName")} />
            <TextInput name="lastName" label={t("LastName")} />
            <TextInput name="email" label={t("Email")} />
            <TextInput name="mobilePhoneNumber" label={t("MobilePhone")} />
            <TextInput name="password" type={model.showPassword ? "text" : "password"} label={t("Password")} />
            <TextInput name="confirmPassword" type={model.showPassword ? "text" : "password"} label={t("ConfirmPassword")} />
            <CheckBoxField name="showPassword" onChange={toggleShowPassword} className="user-registration-initial__show_password" label={t("ShowPassword")} />
            <DropDownField name="gender" label={t("Gender")} items={genders} />
            <DatePickerField name="dateOfBirth" label={t("DateOfBirth")} />
            <div>
                {model.dataProcessingConsent && <ConsentField className="registration-consent-field" name="giveDataProcessing" consent={model.dataProcessingConsent} />}
                {model.smsConsent && <ConsentField className="registration-consent-field" name="sendSmsPromoMessages" consent={model.smsConsent} />}
                {model.emailConsent && <ConsentField className="registration-consent-field" name="sendEmailPromoMessages" consent={model.emailConsent} />}
            </div>
            <ButtonSubmit className="margin-left-auto" disabled={!model.giveDataProcessing || isSubmitting}>
                {t("Next")}
            </ButtonSubmit>
        </>
    )
}

const VerifyEmail: React.FC = () => {
    const { t } = useLocalization();
    const { resendToken } = useUserVerifyEmail()
    const { isSubmitting, model } = useTypedFormikContext<UserRegistrationModel>()

    return (
        <>
            <p className="font-weight-normal">
                {t("EmailCodeSent").replace("{{ email }}", model.email)}</p>
            <TextInput name="emailToken" label={t("EmailToken")} />
            <div className="user-registration-challenge-token__resend-container">
                {t("Didn'tReceiveEmailCode")}<LinkButton disabled={isSubmitting} onClick={resendToken}>{t("Resend")}</LinkButton>
            </div>
            <ButtonSubmit className="margin-left-auto" disabled={isSubmitting}>
                {t("Next")}
            </ButtonSubmit>
        </>
    );
}

const VerifyMobilePhone: React.FC = () => {
    const { t } = useLocalization();
    const { resendToken } = useUserVerifyMobilePhone()
    const { isSubmitting, model } = useTypedFormikContext<UserRegistrationModel>()

    return (
        <>
            <p className="font-weight-normal">
                {t("SmsCodeSent").replace("{{ mobilePhoneNumber }}", model.mobilePhoneNumber)}</p>
            <TextInput name="smsToken" label={t("SmsToken")} />
            <div className="user-registration-challenge-token__resend-container">
                {t("Didn'tReceiveSmsCode")}<LinkButton disabled={isSubmitting} onClick={resendToken}>{t("Resend")}</LinkButton>
            </div>
            <ButtonSubmit className="margin-left-auto" disabled={isSubmitting}>
                {t("Next")}
            </ButtonSubmit>
        </>
    );
}

const Completed: React.FC = (props) => {
    const { t } = useLocalization();
    const { onCloseRequested } = useRegistrationCompleted()
    return (
        <div className="user-recover-password-completed__container">
            <p className="text-success mb-4">{t("SuccessfullyRegisteredUserDescription")} </p>
            <Button className="user-recover-password-completed__close margin-left-auto" onClick={onCloseRequested}>{t("Close")}</Button>
        </div>
    );
}