import { IsPositiveIntOrZero } from "../../../../validators/is-positive-integer-or-zero";

export class PagingRequest {
    @IsPositiveIntOrZero()
    pageIndex: number;
    @IsPositiveIntOrZero()
    pageSize: number;

    constructor(options: { pageIndex: number, pageSize: number }) {
        this.pageIndex = options.pageIndex;
        this.pageSize = options.pageSize
    }

    static createMaxPaging(): PagingRequest {
        return new PagingRequest({ pageIndex: 0, pageSize: 100000 })
    }
}

