import React, { useMemo } from "react"
import "./user-attribute-name-dropdown.scss"
import Typeahead from "../../common/typeahead/typeahead"
import { DataUtil } from "../../../../common/data/data-util"
import { useUserAttributeNameDropDown } from "./useUserAttributeNameDropDown"
import { useLocalization } from "../../../hook/use-localization"


interface Props {
    companyId: string
    selectedUserAttributeName: string | null,
    onSelect: (userAttributeName: string | null) => void,
    disabled?: boolean,
    allowSharedAttributes: boolean
}

export const UserAttributeNameDropdown: React.FC<Props> = ({ selectedUserAttributeName, onSelect, disabled, companyId, allowSharedAttributes }) => {
    const { t } = useLocalization()
    const { userAttributeNameItems } = useUserAttributeNameDropDown(companyId, allowSharedAttributes)

    const id = useMemo(() => {
        return DataUtil.generateGuid();
    }, [])

    return (
        <Typeahead disabled={disabled} id={id} className="user-attribute-name-dropdown" label={t("Name")} selectedValue={selectedUserAttributeName} items={userAttributeNameItems} onSelect={onSelect} placeholder="Select or enter name" />
    )
}