import React from 'react';
import { Route, Switch } from "react-router-dom";
import AuthShell from "../../../_common/layout/auth/auth-shell"
import { UserRecoverPasswordModel, useUserRecoveredPassword, useUserRecoverPasswordChallengeToken, useUserRecoverPasswordCompleted, useUserRecoverPasswordEnterPassword } from './useUserRecoveredPassword';
import { Form, Formik } from 'formik';
import "./user-recover-password.scss"
import { useTypedFormikContext } from '../../../hook/use-formik-context-typed';
import { TextInput } from '../../../_common/fields/text-input/TextInput';
import { LinkButton } from '../../../_common/common/link-button/LinkButton';
import { CheckBoxField } from '../../../_common/fields/checkbox/check-box-field';
import { Button } from '../../../_common/common/button/Button';
import { ButtonSubmit } from '../../../_common/common/button/ButtonSubmit';
import { useLocalization } from '../../../hook/use-localization';



const UserRecoverPassword: React.FC = () => {
  const { t } = useLocalization()
  const { initialValues, validate, onSubmit, paths } = useUserRecoveredPassword()

  return (
    <AuthShell title={t("AccountRecovery")}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}
        validateOnBlur={true}
        validate={validate}>
        {() => (
          <Form>
            <div className="form-container">
              <Switch>
                <Route path={paths.PathInitial} component={Initial} />
                <Route path={paths.PathChallengeToken} component={ChallengeToken} />
                <Route path={paths.PathEnterPassword} component={EnterPassword} />
                <Route path={paths.PathCompleted} component={Completed} />
              </Switch>
            </div>
          </Form>)}
      </Formik>
    </AuthShell >
  )
}

const Initial: React.FC = () => {
  const { t } = useLocalization()
  const { isSubmitting } = useTypedFormikContext()
  return (
    <>
      <p className="font-weight-normal">{t("PleaseEnterYourEmail")}</p>
      <TextInput name="email" label={t("Email")} />
      <ButtonSubmit className='margin-left-auto' disabled={isSubmitting}>
        {t("Next")}
      </ButtonSubmit>
    </>
  );
}

const ChallengeToken: React.FC = () => {
  const { t } = useLocalization()
  const { resendToken } = useUserRecoverPasswordChallengeToken()
  const { isSubmitting } = useTypedFormikContext<UserRecoverPasswordModel>()

  return (
    <>
      <p className="font-weight-normal">{t("AccountRecovery.Code.Sent.ToEmail")}</p>
      <TextInput name="token" label="Token" />
      <ButtonSubmit className='margin-left-auto' disabled={isSubmitting}>
        {t("Next")}
      </ButtonSubmit>
      <div className="user-recover-password-challenge-token__resend-container">
        {t("Didn'tReceiveCode")}<LinkButton disabled={isSubmitting} onClick={resendToken}>{t("Resend")}</LinkButton>
      </div>
    </>
  );
}

const EnterPassword: React.FC = () => {
  const { t } = useLocalization()
  const { onToggleShowPassword } = useUserRecoverPasswordEnterPassword()
  const { isSubmitting, values: model } = useTypedFormikContext<UserRecoverPasswordModel>()
  return (
    <>
      <p className="font-weight-normal">{t("ChooseNewPasswordDescription")}</p>
      <TextInput name="password" type={model.showPassword ? "text" : "password"} label={t("Password")} />
      <TextInput name="confirmPassword" type={model.showPassword ? "text" : "password"} label={t("ConfirmPassword")} />
      <CheckBoxField onChange={onToggleShowPassword} name="showPassword" label={t("ShowPassword")} />
      <ButtonSubmit className='margin-left-auto' disabled={isSubmitting}>{t("Next")}</ButtonSubmit>
    </>
  );
}

const Completed: React.FC = (props) => {
  const { t } = useLocalization()
  const { onCloseRequested } = useUserRecoverPasswordCompleted()
  return (
    <div className="user-recover-password-completed__container">
      <p className="text-success mb-4">{t("AccountRecovery.SuccessMessage")} </p>
      <Button className="user-recover-password-completed__close margin-left-auto" onClick={onCloseRequested}>{t("Close")}</Button>
    </div>
  );
}

export default UserRecoverPassword;