import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { ICompanyClient, ICompanyClientId, IClientCredentialClient, IClientCredentialClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown";
import { CompanyHelper } from "../../../_common/helpers/company-helper";
import { useLocalization } from "../../../hook/use-localization";
import { reactDelay } from "../../../_common/helpers/common";
import { useUserRole } from "../../../hook/useUserRole";



// only status is allowed to be changed
interface Model {
    clientCredentialId: string | null,
    clientSecret: string | null,
    companyId: string | null,
    name: string,
    role: dto.RoleName | null,
    readonly: boolean,
    companies: dto.Company[],
    originalModel: Omit<Model, "originalModel"> | null
}

export type UserModel = Model

const createModel = (
    clientCredential: dto.ClientCredential | null,
    companies: dto.Company[],
    selectedCompanyId: string | null,
    readonly: boolean,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        clientCredentialId: clientCredential?.id ?? null,
        clientSecret: clientCredential?.clientSecret ?? null,
        companyId: selectedCompanyId,
        name: clientCredential?.name ?? "",
        role: clientCredential?.role as dto.RoleName ?? null,
        companies: companies,
        originalModel: originalModel ?? null
    }
}



const createAddUserRequest = (model: Model): dto.AddClientCredentialRequest => {
    const request = new dto.AddClientCredentialRequest({
        companyId: model.companyId!,
        role: model.role!,
        name: model.name!
    })
    return request
}

export const useClientCredential = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    const history = useHistory()
    const { t } = useLocalization();
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const clientCredentialClient = useClient<IClientCredentialClient>(IClientCredentialClientId)
    const [createdClientCredential, setCreatedClientCredential] = useState<dto.ClientCredential | null>(null)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                let companies = await companyClient.getCurrentCompanies()
                let readonly = id != null
                let clientCredential: dto.ClientCredential | null = null
                let companyId: string | null = null
                if (id != null) {
                    clientCredential = await clientCredentialClient.getClientCredential(id)
                    companyId = clientCredential.companyId
                    companies = companies.filter(x => x.id === companyId)
                } else {
                    companyId = CompanyHelper.getDefaultCompany(companies)
                }

                const originalModel = createModel(clientCredential, companies, companyId, readonly)
                const model = createModel(clientCredential, companies, companyId, readonly, originalModel)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage, history })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, clientCredentialClient, companyClient, history])


    useEffect(() => {
        loadInitialModel()
    }, [id])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const request = createAddUserRequest(model)
        const validateErrors = await validate(request)
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["name", "role", "companyId"], errorModel)
        return errorModel
    }, [])


    const onSubmit = useCallback(async (model: Model, { setFieldError }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            const request = createAddUserRequest(model)
            const createdClientCredential = await clientCredentialClient.createClientCredential(request)
            setCreatedClientCredential(createdClientCredential)
            reactDelay(() => setGlobalMessage({ message: t("ClientCredential.Created"), type: "success" }))
        }, { setFieldError, setGlobalMessage, fieldNames: ["name", "role", "companyId"] })
    }, [clientCredentialClient, setGlobalMessage, t])

    const onDeleteClientCredential = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await clientCredentialClient.deleteClientCredential(id)
                history.replace("/client-credentials")
                reactDelay(() => setGlobalMessage({ message: t("ClientCredential.Deleted"), type: "success" }))
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, clientCredentialClient, history, setGlobalMessage, t])

    const isAddClientCredential = useMemo(() => {
        return id == null
    }, [id])

    const onClientSecretCopiedByUser = useCallback(() => {
        const clientId = createdClientCredential?.id
        setCreatedClientCredential(null)
        history.replace("/client-credentials/" + clientId)

    }, [history, createdClientCredential])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteClientCredential,
        isAddClientCredential,
        createdClientCredential,
        onClientSecretCopiedByUser
    }
}

export const useClientCredentialForm = () => {
    const { t } = useLocalization()
    const { descriptionFromRoleName } = useUserRole()
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(model.companies)
    }, [model.companies])

    const companyDropDownEnabled = useMemo(() => {
        return !model.readonly && model.clientCredentialId == null
    }, [model.readonly, model.clientCredentialId])

    const roles = useMemo(() => {
        return [
            { text: t(descriptionFromRoleName(dto.RoleName.companyUser)), value: dto.RoleName.companyUser },
            { text: t(descriptionFromRoleName(dto.RoleName.companyAdmin)), value: dto.RoleName.companyAdmin }]
    }, [t, descriptionFromRoleName])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    const saveClientCredentialEnabled = useMemo(() => {
        return SharedDataHelper.stringNotNullTrimEmpty(model.companyId)
    }, [model.companyId])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        roles,
        saveClientCredentialEnabled,
        companyItems,
        companyDropDownEnabled
    }
}
