import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto } from "shared";
import { IUserConsentHistoryClient, IUserConsentHistoryClientId } from "shared-client";
import { handleError } from "../../../../common/form/form";
import useClient from "../../../hook/useClient";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";


interface Model {
    userConsentHistoryId: string,
    userIdentifier: string,
    companyName: string | null,
    firstName: string,
    lastName: string,
    email: string,
    mobilePhoneNumber: string
    consentName: string,
    consentEnabled: string,
    consentSourceName: string | null
}

export type UserConsentHistoryModel = Model

const createModel = (obj: dto.UserConsentHistory): Model => {
    return {
        userConsentHistoryId: obj.id,
        userIdentifier: obj.userIdentifier,
        firstName: obj.firstName,
        lastName: obj.lastName,
        mobilePhoneNumber: obj.mobilePhoneNumber,
        email: obj.email,
        companyName: obj.createdByCompanyName,
        consentName: "Accept_" + obj.consentName,
        consentEnabled: obj.consentEnabled.toString().toLowerCase(),
        consentSourceName: obj.consentEnabled ? obj.consentSourceName : null
    }
}

export const useUserConsentHistory = () => {
    let { id } = useParams<{ id: string }>();
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const userConsentHistoryClient = useClient<IUserConsentHistoryClient>(IUserConsentHistoryClientId)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                const userConsentHistory = await userConsentHistoryClient.getUserConsentHistory(id)
                const model = createModel(userConsentHistory)
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage, history })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, userConsentHistoryClient, history])


    useEffect(() => {
        loadInitialModel()
    }, [id])

    return {
        loading,
        modelInitialValues
    }
}


