import React from 'react';
import { Redirect, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "../../pages/auth/login/login";
import NotFound from '../../pages/not-found/not-found';
import PublicRoute from '../../_common/route/public-route';
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "../../_common/route/private-route";
import UserRecoverPassword from "../../pages/auth/recover-password/user-recover-password";
import { UserRegistration } from '../../pages/auth/registration/user-registration';
import { Companies } from '../../pages/company/companies/companies';
import { Company } from '../../pages/company/company/company';
import { Users } from '../../pages/user/users/users';
import { User } from '../../pages/user/user/user';
import { UserRoles } from '../../pages/user/roles/user-roles';
import { UnregisteredUsers } from '../../pages/unregistered-user/unregistered-users/unregistered-users';
import { UnregisteredUser } from '../../pages/unregistered-user/user/unregistered-user';
import { UserInvitations } from '../../pages/user/invitations/user-invitations';
import { UserInvitation } from '../../pages/user/invitation/user-invitation';
import { AddUserInvitation } from '../../pages/user/add-invitation/add-user-invitation';
import { UserReceivedInvitations } from '../../pages/user/received-invitations/received-user-invitations';
import { Account } from '../../pages/account/account/account';
import { AccountChangePassword } from '../../pages/account/change-password/account-change-password';
import { UserReceivedInvitation } from '../../pages/user/received-invitation/user-received-invitation';
import { useAppContent } from './useAppContent';
import { NotificationTemplates } from '../../pages/notification-template/notification-templates/notification-templates';
import { NotificationTemplate } from '../../pages/notification-template/notification-template/notification-template';
import { NotificationTasks } from '../../pages/notification-tasks/notification-tasks/notification-tasks';
import { NotificationTask } from '../../pages/notification-tasks/notification-task/notification-task';
import { NotificationTaskBatches } from '../../pages/notification-task-batch/notification-task-batches/notification-task-batches';
import { NotificationTaskBatch } from '../../pages/notification-task-batch/notification-task-batch/notification-task-batch';
import { UserAttributeName } from '../../pages/user-attribute-name/user-attribute-name/user-attribute-name';
import { UserAttributeNames } from '../../pages/user-attribute-name/user-attribute-names/user-attribute-names';
import { UserAttribute } from '../../pages/user-attribute/user-attribute/user-attribute';
import { UserAttributes } from '../../pages/user-attribute/user-attributes/user-attributes';
import { EventTypes } from '../../pages/event-types/event-types/event-types';
import { EventType } from '../../pages/event-types/event-type/event-type';
import BadRequest from '../../pages/bad-request/bad-request';
import { Events } from '../../pages/events/events/events';
import { Event } from '../../pages/events/event/event';
import { AnalyticsContainer } from '../../pages/analytics/analytics-container/analytics-container';
import { UnregisteredUserCreation } from '../../pages/user-action/unregistered-user-creation/unregistered-user-creation';
import { OptOut } from '../../pages/opt-out/opt-out';
import { CompanyConfiguration } from '../../pages/company/company-configuration/company-configuration';
import { UnregisteredUserUploadExcel } from '../../pages/unregistered-user/upload-excel/unregistered-user-upload-excel';
import { QueuedJobs } from '../../pages/queued-job/queued-jobs/queued-jobs';
import { QueuedJob } from '../../pages/queued-job/queued-job/queued-job';
import { UserAttributesUploadExcel } from '../../pages/user-attribute/upload-excel/user-attributes-upload-excel';
import { ClientCredentials } from '../../pages/client-credential/client-credentials/client-credentials';
import { ClientCredential } from '../../pages/client-credential/client-credential/client-credential';
import { EventsUploadExcel } from '../../pages/events/upload-excel/events-upload-excel';
import { UserConsentHistories } from '../../pages/user-consent-history/user-consent-histories/user-consent-histories';
import { UserConsentHistory } from '../../pages/user-consent-history/user-consent-history/user-consent-history';
import { PredefinedNotificationTemplates } from '../../pages/notification-template/predefined-templates/predefined-notification-templates';

const AppContent: React.FC = () => {
    const history = useHistory();
    const { defaultPage, loading } = useAppContent()

    return (
        <>
            {!loading && <Router history={history}>
                <Switch>
                    <PublicRoute path="/signin" component={Login} />
                    <PublicRoute path="/registration" component={UserRegistration} />
                    <PublicRoute path="/recover-password" component={UserRecoverPassword} />
                    <PublicRoute exact path="/user-actions/:actionid/unregistered-users/reg/:unregistereduserid" component={UnregisteredUserCreation} />
                    <PublicRoute exact path="/opt-out/:id" component={OptOut} />

                    <PrivateRoute exact path="/companies" component={Companies} />
                    <PrivateRoute exact path="/companies/add/company" component={Company} />
                    <PrivateRoute exact path="/companies/:id" component={Company} />
                    <PrivateRoute exact path="/companies/:id/configuration" component={CompanyConfiguration} />

                    <PrivateRoute exact path="/users" component={Users} />
                    <PrivateRoute exact path="/users/:id" component={User} />
                    <PrivateRoute exact path="/users/:id/user-roles" component={UserRoles} />

                    <PrivateRoute exact path="/unregistered-users" component={UnregisteredUsers} />
                    <PrivateRoute exact path="/unregistered-users/:id" component={UnregisteredUser} />
                    <PrivateRoute exact path="/unregistered-users/add/user" component={UnregisteredUser} />
                    <PrivateRoute exact path="/unregistered-users/upload/excel" component={UnregisteredUserUploadExcel} />

                    <PrivateRoute exact path="/client-credentials" component={ClientCredentials} />
                    <PrivateRoute exact path="/client-credentials/:id" component={ClientCredential} />
                    <PrivateRoute exact path="/client-credentials/add/client-credential" component={ClientCredential} />

                    <PrivateRoute exact path="/queued-jobs" component={QueuedJobs} />
                    <PrivateRoute exact path="/queued-jobs/:queuename/:id" component={QueuedJob} />

                    <PrivateRoute exact path="/events" component={Events} />
                    <PrivateRoute exact path="/unregistered-users/events/upload/excel" component={EventsUploadExcel} />

                    <PrivateRoute exact path="/event-types" component={EventTypes} />
                    <PrivateRoute exact path="/event-types/:id" component={EventType} />
                    <PrivateRoute exact path="/event-types/add/event-type" component={EventType} />

                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/user-attributes" component={UserAttributes} />
                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/user-attributes/:id" component={UserAttribute} />
                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/user-attributes/add/user-attribute" component={UserAttribute} />
                    <PrivateRoute exact path="/unregistered-users/user-attributes/upload/excel" component={UserAttributesUploadExcel} />


                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/events" component={Events} />
                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/events/:id" component={Event} />
                    <PrivateRoute exact path="/unregistered-users/:unregistereduserid/events/add/event" component={Event} />

                    <PrivateRoute exact path="/user-invitations" component={UserInvitations} />
                    <PrivateRoute exact path="/user-invitations/add" component={AddUserInvitation} />
                    <PrivateRoute exact path="/user-invitations/:id" component={UserInvitation} />

                    <PrivateRoute exact path="/user-received-invitations" component={UserReceivedInvitations} />
                    <PrivateRoute exact path="/user-received-invitations/:id" component={UserReceivedInvitation} />

                    <PrivateRoute exact path="/notification-templates" component={NotificationTemplates} />
                    <PrivateRoute exact path="/notification-templates/:id" component={NotificationTemplate} />
                    <PrivateRoute exact path="/notification-templates/add/notification-template" component={NotificationTemplate} />


                    <PrivateRoute exact path="/notification-tasks" component={NotificationTasks} />
                    <PrivateRoute exact path="/notification-tasks/:id" component={NotificationTask} />
                    <PrivateRoute exact path="/notification-tasks/add/notification-task" component={NotificationTask} />

                    <PrivateRoute exact path="/notification-task-batches" component={NotificationTaskBatches} />
                    <PrivateRoute exact path="/notification-task-batches/:id" component={NotificationTaskBatch} />
                    <PrivateRoute exact path="/notification-task-batches/add/notification-task-batch" component={NotificationTaskBatch} />

                    <PrivateRoute exact path="/user-attribute-names" component={UserAttributeNames} />
                    <PrivateRoute exact path="/user-attribute-names/add/user-attribute-name" component={UserAttributeName} />
                    <PrivateRoute exact path="/user-attribute-names/:id" component={UserAttributeName} />

                    <PrivateRoute exact path="/analytics" component={AnalyticsContainer} />

                    <PrivateRoute exact path="/user-consent-history" component={UserConsentHistories} />
                    <PrivateRoute exact path="/user-consent-history/:id" component={UserConsentHistory} />

                    <PrivateRoute exact path="/account" component={Account} />
                    <PrivateRoute exact path="/account/change-password" component={AccountChangePassword} />
                    <PrivateRoute exact path="/">
                        <Redirect to={defaultPage} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/not-found">
                        <NotFound />
                    </PrivateRoute>
                    <PrivateRoute exact path="/bad-request">
                        <BadRequest />
                    </PrivateRoute>
                    {<Route component={NotFound} />}
                </Switch>
            </Router>
            }
        </>

    );
}

export default AppContent;
