import { useFormikContext } from "formik";
import React from "react";
import { LinkButton } from "./LinkButton";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}
export const LinkButtonSubmit: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const { className, ...buttonProps } = props
	return (
		<LinkButton {...buttonProps} type="submit" />
	);
}