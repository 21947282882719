import React from "react"
import { dto } from "shared"
import { Button } from "../../../_common/common/button/Button"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import Shell from "../../../_common/layout/shell/shell"
import { usePredefinedNotificationTemplates } from "./usePredefinedNotificationTemplates"
import "./predefined-notification-templates.scss"
import { useLocalization } from "../../../hook/use-localization"
import useIsTablet from "../../../hook/use-is-tablet"

interface Props {
    onPredefinedTemplateSelected: (template: dto.PredefinedNotificationTemplate) => void,
    onPredefinedTemplateClosed: () => void
}

export const PredefinedNotificationTemplates: React.FC<Props> = ({ onPredefinedTemplateSelected, onPredefinedTemplateClosed }) => {
    const { t } = useLocalization()
    const isTablet = useIsTablet()
    const { predefinedTemplates, loading, } = usePredefinedNotificationTemplates()

    return (
        <Shell pageTitle={t("PredefinedTemplates")}>

            <Loading loading={loading} />
            <div className="predefined-template-close"><Button onClick={onPredefinedTemplateClosed}>{t("Back")}</Button></div>
            <div className="predefined-templates-container">
                {predefinedTemplates.map((template) => (
                    <div className="predefined-templates-template"><img onClick={() => {
                        if (isTablet) {
                            onPredefinedTemplateSelected(template)
                        }
                    }} alt="" src={template.previewUrl} />
                        {!isTablet && <div className="hover-content"><Button onClick={() => onPredefinedTemplateSelected(template)}>{t("GetStarted")}</Button></div>}
                    </div>
                ))}
            </div>
            {predefinedTemplates.length === 0 && <NoEntities />}
        </Shell >
    )
}