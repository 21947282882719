import { FormikErrors } from "formik";
import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { SharedDataHelper, dto } from "shared";
import { handleError } from "../../../../common/form/form";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";
import { ICompanyClient, ICompanyClientId, IQueuedJobClient, IQueuedJobClientId } from "shared-client";
import useClient from "../../../hook/useClient";

declare type JobRecord = { title: string, value: string, fieldName: string }

// only status is allowed to be changed
interface Model {
    jobId: string,
    data: JobRecord[],
    date: string,
    readonly: boolean,
    queueName: string,
    errorDetails: string | null,
    status: string
    companyName: string,
    companyId: string,
    originalModel: Omit<Model, "originalModel"> | null
}

export type QueuedJobModel = Model



const createModel = (job: dto.QueuedJob, company: dto.Company, t: (key: string) => string, originalModel?: Model | null): Model => {
    const jobRecords = createJobRecords(job.data)
    return {
        readonly: true,
        jobId: job.id,
        queueName: t("QueueJobSingular_" + job.queueName),
        status: t("QueuedJob_" + job.status),
        companyName: company.name,
        companyId: company.name,
        data: jobRecords,
        errorDetails: job.errorDetails != null ? t(job.errorDetails) : null,
        date: SharedDataHelper.getFormattedDate(job.date, "dd.MM.yyyy HH:mm"),
        originalModel: originalModel ?? null
    }
}

const createJobRecords = (data: any): JobRecord[] => {
    if (data == null) {
        return []
    }
    let entries = Object.entries(data).filter(e => e[0] !== "companyIdentifier")
    for (const entry of entries) {
        if (entry[0].toLowerCase().includes("date") && (entry[1] instanceof Date)) {
            entry[1] = SharedDataHelper.getFormattedDate(entry[1], "dd.MM.yyyy HH:mm")
        }
    }

    const records: JobRecord[] = entries.map(e => {
        return {
            title: `QueuedJobField_${e[0]}`,
            value: e[1] as any,
            fieldName: e[0]
        }
    })


    return records
}



export const useQueuedJob = () => {
    const history = useHistory()
    const { t } = useLocalization()
    let { id, queuename: queueName } = useParams<{ id: string, queuename: string }>();
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const jobClient = useClient<IQueuedJobClient>(IQueuedJobClientId)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                const job = await jobClient.getJob(queueName, id)
                const companies = await companyClient.getCurrentCompanies()
                const company = companies.find(x => x.id === job.data.companyIdentifier)
                if (company == null) {
                    throw new Error("CanNotFindCompany")
                }
                const originalModel = createModel(job, company, t)
                const model = createModel(job, company, t, originalModel);
                setModelInitialValues(model)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage, history })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, jobClient, companyClient, queueName, t, history])


    useEffect(() => {
        loadInitialModel()
    }, [])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        return {}
    }, [])


    const onSubmit = useCallback(async () => {
    }, [])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        loading,

    }
}


