import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IApiLogClient } from "./i-api-log-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class ApiLogClient extends BaseClient implements IApiLogClient {

    async getApiLogs(data: dto.GetApiLogsRequest): Promise<dto.PagingResponse<dto.ApiLog>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.apiLog.base + apiPaths.apiLog.apiLogs), params: data });
            return response.data as dto.PagingResponse<dto.ApiLog>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getApiLog(apilogId: string): Promise<dto.ApiLog> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.apiLog.base + apiPaths.apiLog.apiLog.replace(":apilogid", apilogId)) });
            return response.data as dto.ApiLog;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}