import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IConsentClient } from "./i-consent-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class ConsentClient extends BaseClient implements IConsentClient {

    async getConsents(data: dto.GetConsentsRequest): Promise<dto.Consent[]> {
        try {
            const response = await this.publicAxios({ method: 'get', url: (apiPaths.consent.base + apiPaths.consent.consents), params: data });
            return response.data as dto.Consent[];
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUserConsentByShortId(userconsentshortId: string): Promise<void> {
        try {
            await this.publicAxios({ method: 'delete', url: (apiPaths.consent.base + apiPaths.consent.userConsent.replace(":userconsentshortid", userconsentshortId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}