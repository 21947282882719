import React from "react";
import { SharedDataHelper } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./user-consent-histories.scss";
import { useUserConsentHistories } from "./useUserConsentHistories";
import DebouncedCallbackTextField from "../../../_common/common/debounced-callback-text-field";
import { useLocalization } from "../../../hook/use-localization";

export const UserConsentHistories: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useLocalization()
    const {
        userConsentHistories,
        companyItems,
        selectedCompanyId,
        onUserConsentHistorySelected,
        onCompanySelected,
        loading,
        pagination,
        setFirstName,
        setLastName,
        setEmail,
        setMobilePhoneNumber
    } = useUserConsentHistories();

    const rowClick = (id: string) => {
        if (isMobile) {
            onUserConsentHistorySelected(id);
        }
    };

    const listItems = userConsentHistories.map((record) => (
        <div
            className="user-consent-histories__item data-row-item"
            key={record.id}
            onClick={() => rowClick(record.id)}
        >
            <div className="user-consent-histories__item-personal-info">
                <div className="user-consent-histories__item-name">
                    <button
                        onClick={() => {
                            onUserConsentHistorySelected(record.id);
                        }}
                    >
                        {record.firstName} {record.lastName}
                    </button>
                </div>
                <div className="user-consent-histories__item-type">{t("Type_" + record.consentName) + ": " + t(record.consentEnabled.toString().toLowerCase())}</div>
                <div className="user-consent-histories__item-mobile-phone">
                    {record.mobilePhoneNumber}
                </div>
                <div className="user-consent-histories__item-email">
                    {record.email}
                </div>
            </div>
            <div className="user-consent-histories__item-dates-action-type">
                <div className="user-consent-histories__item-action-type">{t(record.actionType?.toLowerCase())}</div>
                <div className="user-consent-histories__item-created">
                    {SharedDataHelper.getFormattedDate(record.createdDate, "dd.MM.yyyy HH:mm")}
                </div>
            </div>
        </div>
    ));
    return (
        <Shell
            pageTitle={t("UserConsentHistoryList")}
            mobileHeaderContent={
                <></>
            }
            desktopHeaderContent={
                <></>
            }
        >
            <Loading loading={loading} />
            <div className="indent-container users-header-filter">
                <div className="user-consent-histories-filter">

                    <div className=" user-consent-histories-company-filter">
                        <CompanyDropdown
                            items={companyItems}
                            selectedCompanyId={selectedCompanyId}
                            onSelect={onCompanySelected}
                        />
                    </div>
                    <div className="text-input__container user-consent-histories-first-name-filter">
                        <div>{t("FirstName")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setFirstName} />
                    </div>
                    <div className="text-input__container user-consent-histories-last-name-filter">
                        <div>{t("LastName")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setLastName} />
                    </div>
                    <div className="user-consent-histories-email-filter">
                        <div>{t("Email")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setEmail} />
                    </div>
                    <div className="user-consent-histories-mobile-phone-number-filter">
                        <div>{t("MobilePhoneNumber")}</div>
                        <DebouncedCallbackTextField debouncedCallback={setMobilePhoneNumber} />
                    </div>
                </div>
                <Pagination {...pagination} />
            </div>
            {listItems}
            {userConsentHistories.length === 0 && <NoEntities />}
        </Shell>
    );
};
