import { INotificationTaskSpecific } from "../i-notification-task-specific";
import { SharedDataHelper, dto } from "shared";

export class SmsNotificationTaskSpecific implements INotificationTaskSpecific {
    getAddress(notificationTask: dto.NotificationTask): string {
        const details = notificationTask.details as dto.SmsNotificationTaskDetails
        if (SharedDataHelper.stringNotNullTrimEmpty(details.sentToMobilePhoneNumber)) {
            return details.sentToMobilePhoneNumber!
        }
        if (SharedDataHelper.stringNotNullTrimEmpty(details.mobilePhoneNumber)) {
            return details.mobilePhoneNumber!
        }
        if (notificationTask.user != null) {
            return notificationTask.user.mobilePhoneNumber
        }

        if (notificationTask.unregisteredUser != null) {
            return notificationTask.unregisteredUser.mobilePhoneNumber
        }
        return ""
    }

    createAddNotificationTaskBatchDetails(addresses: string[]): dto.AddNotificationTaskBatchDetails {
        return new dto.SmsAddNotificationTaskBatchDetails({ mobilePhoneNumbers: addresses })
    }

    isValidAddress(address: string): boolean {
        return SharedDataHelper.isValidMobilePhoneNumber(address)
    }

    getAddressesInputTitle(): string {
        return "MobilePhoneNumbers"
    }

    getAddressInputTitle(): string {
        return "MobilePhone"
    }

    validateAddresses(addresses: string[]): { total: number; success: number; failed: number; } {
        const validAddresses = (addresses ?? []).filter(x => SharedDataHelper.isValidMobilePhoneNumber(x))
        return {
            total: addresses.length,
            success: validAddresses.length,
            failed: addresses.length - validAddresses.length
        }
    }


}