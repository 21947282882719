import React from "react"
import { Form, Formik } from "formik"
import "./opt-out.scss"
import { OptOutModel, useOptOut } from "./useOptOut"
import { useLocalization } from "../../hook/use-localization"
import AuthShell from "../../_common/layout/auth/auth-shell"
import { Loading } from "../../_common/components/loading/loading"
import { useTypedFormikContext } from "../../hook/use-formik-context-typed"
import { Button } from "../../_common/common/button/Button"


export const OptOut: React.FC = () => {
	const { t } = useLocalization();
	const { modelInitialValues, onSubmit, onValidate, loading, finishedUpdate } = useOptOut()

	return (
		<AuthShell title={t("OptOut")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							{!finishedUpdate && <FormContent />}
							{finishedUpdate &&
								<div className="opt-out-successfully">
									<div className="success-info">{t("OptOut.Success")}</div>
									<div className="additional-msg">{t("OptOut.Success.More")}</div>
								</div>
							}
						</Form>
					)}
				</Formik>
			</>
			}
		</AuthShell>
	)
}


const FormContent: React.FC = () => {
	const { t } = useLocalization();
	const { submitForm } = useTypedFormikContext<OptOutModel>()


	return (
		<div className="indent-container opt-out-form-content">

			<div className="opt-out-description">
				{t("OptOut.OptOutMe.Description")}
			</div>

			<div className="action-buttons-row">
				<Button onClick={submitForm} className="margin-left-auto">{t("OptOut.OptOutMe")}</Button>
			</div>
		</div>)
}
