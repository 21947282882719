import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserRegistrationClient } from "./i-user-registration-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserRegistrationClient extends BaseClient implements IUserRegistrationClient {

    async register(data: dto.UserRegistrationRegisterRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.registration.base + apiPaths.user.registration.register), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async verifyEmail(data: dto.UserRegistrationVerifyEmailRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.registration.base + apiPaths.user.registration.verifyEmail), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async complete(data: dto.UserRegistrationCompleteRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.user.registration.base + apiPaths.user.registration.complete), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}