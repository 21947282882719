import React from "react"
import "./loading.scss"
import { useLocalization } from "../../../hook/use-localization"

interface Props {
    loading: boolean
}

export const Loading: React.FC<Props> = ({ loading }) => {
    const { t } = useLocalization()
    let cls = "loading__container"
    if (loading !== true) {
        cls = cls + " loading__container-hide"
    }
    return (
        <div className={cls}><img alt="loading" src="/loading-icon.gif" /></div >
    )
}