import React, { ReactNode, useEffect } from "react";
import { WSClient } from "shared-client"
import { SharedDataHelper, sleep, ws as wsDto } from "shared"
import { IWebSocket } from "./web-socket";
import { WebSocketCtx } from "./websocket-context";
import useTokenRepository from "../../hook/useTokenRepository";
const getServerPortFromAppType = require("../../../get-server-port-from-apptype")

type WeSocketContextProviderProps = { children?: ReactNode }

export const wsClient = new WSClient();

const WebSocketContextProvider: React.FC<WeSocketContextProviderProps> = (props) => {
	const tokenRepository = useTokenRepository();



	const [websocket, setWebSocket] = React.useState<IWebSocket>({ isConnected: false });

	useEffect(() => {
		(async () => {

			while (true) {
				try {
					const accessToken = await tokenRepository.getAccessToken()
					if (!SharedDataHelper.stringNotNullTrimEmpty(accessToken)) {
						return
					}

					if (!wsClient.isConnected) {
						const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
						let { host } = window.location;
						const port = getServerPortFromAppType()
						const baseUrl = process.env.REACT_APP_WSPROXY === 'true' ? `localhost:${port}` : host
						const wsUrl = `${protocolPrefix}//${baseUrl}/ws`
						await wsClient.connect(wsUrl)
						const authRequest: Omit<wsDto.WebSocketAuthRequest, "requestId"> = { type: "auth", accessToken: accessToken! }
						const authResponse: wsDto.WebSocketAuthResponse = await wsClient.sendRequest(authRequest)
						if (!(authResponse.isAuthenticated && authResponse.type === "auth")) {
							throw new Error("Invalid auth0 response")
						}
					}

					const pingResponse: wsDto.WebSocketPongResponse = await wsClient.sendRequest({ type: "ping" })
					if (pingResponse.type !== "pong") {
						throw new Error("Invalid ping-pong response")
					}

					setWebSocket({ isConnected: true })

				} catch (err) {
					wsClient.close();
					setWebSocket({ isConnected: false })
					console.error("WS error:" + err)
				} finally {
					await sleep(3000)
				}
			}
		})()
		return () => {
			wsClient.close()
		}
	}, [])



	return (
		<WebSocketCtx.Provider value={{ websocket, setWebSocket }}>
			{props.children}
		</WebSocketCtx.Provider>
	)
}

export default WebSocketContextProvider;


