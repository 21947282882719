import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IHealthClient } from "./i-health-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class HealthClient extends BaseClient implements IHealthClient {

    async health(): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'get', url: (apiPaths.healthPaths.base + apiPaths.healthPaths.health) });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}