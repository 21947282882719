import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { SharedDataHelper, dto } from "shared";
import { IUnregisteredUserClient, IUnregisteredUserClientId, IUserAttributeNameClient, IUserAttributeNameClientId, IUserAttributeClient, IUserAttributeClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";


interface Model {
    userAttributeId: string | null,
    name: string,
    value: string,
    description: string,
    externalId: string,
    unregisteredUserId: string,
    readonly: boolean,
    companyId: string,
    userAttributeNames: dto.UserAttributeName[],
    userAttributeName: dto.UserAttributeName | null,
    unregisteredUserName: string,
    originalModel: Omit<Model, "originalModel"> | null
}

export type UserAttributeModel = Model

const createModel = (
    userAttribute: dto.UserAttribute | null,
    readonly: boolean,
    unregisteredUserId: string,
    companyId: string,
    userAttributeNames: dto.UserAttributeName[],
    userAttributeName: dto.UserAttributeName | null,
    unregisteredUserName: string,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        userAttributeId: userAttribute?.id ?? null,
        name: userAttribute?.name ?? "",
        value: userAttribute?.value ?? "",
        description: userAttribute?.description ?? "",
        unregisteredUserId: unregisteredUserId,
        companyId: companyId,
        userAttributeNames: userAttributeNames,
        userAttributeName: userAttributeName,
        unregisteredUserName: unregisteredUserName,
        externalId: userAttribute?.externalId ?? "",
        originalModel: originalModel ?? null
    }
}

const createUpdateUserAttributeRequest = (model: Model): dto.UpdateUserAttributeRequest => {
    const request = new dto.UpdateUserAttributeRequest()
    request.name = model.name
    request.value = model.value;
    if (model.userAttributeName?.attributeType === dto.UserAttributeNameType.exists) {
        request.value = "true"
    }
    request.description = model.description
    request.externalId = SharedDataHelper.stringNotNullTrimEmpty(model.externalId) ? model.externalId : null
    return request
}

const createAddUserAttributeRequest = (model: Model): dto.AddUserAttributeRequest => {
    const request = new dto.AddUserAttributeRequest()
    request.name = model.name
    request.description = model.description
    request.value = model.value
    if (model.userAttributeName?.attributeType === dto.UserAttributeNameType.exists) {
        request.value = "true"
    }
    request.unregisteredUserId = model.unregisteredUserId
    request.externalId = SharedDataHelper.stringNotNullTrimEmpty(model.externalId) ? model.externalId : null
    return request
}



export const useUserAttribute = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    let { unregistereduserid: unregisteredUserId } = useParams<{ unregistereduserid: string }>();
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userAttribute, setUserAttribute] = useState<dto.UserAttribute | null>(null)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const userAttributeClient = useClient<IUserAttributeClient>(IUserAttributeClientId)
    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)
    const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId)

    const getUserAttributeNames = useCallback(async (companyId: string): Promise<dto.UserAttributeName[]> => {
        const pagedResponse = await userAttributeNameClient.getUserAttributeNames({
            paging: dto.PagingRequest.createMaxPaging(),
            sortBy: [new dto.SortItem({ name: "name" })],
            companyId: companyId,
        });
        return pagedResponse.entities
    }, [userAttributeNameClient]);

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                const unregisteredUser = await unregisteredUserClient.getUser(unregisteredUserId)
                if (unregisteredUser == null) {
                    history.replace("/not-found");
                    return
                }
                let readonly = id != null
                let userAttribute: dto.UserAttribute | null = null;
                let userAttributeName: dto.UserAttributeName | null = null;
                const userAttributeNames = await getUserAttributeNames(unregisteredUser.createdByCompanyId)
                if (id != null) {
                    userAttribute = await userAttributeClient.getUserAttribute(id);
                    if (userAttribute == null) {
                        history.replace("/not-found");
                        return
                    }
                    userAttributeName = userAttributeNames.find(x => x.name === userAttribute!.name) ?? null
                }

                const contactName = `${unregisteredUser.firstName} ${unregisteredUser.lastName}`
                const originalModel = createModel(userAttribute, readonly, unregisteredUser.id, unregisteredUser.createdByCompanyId, userAttributeNames, userAttributeName, contactName)
                const model = createModel(userAttribute, readonly, unregisteredUser.id, unregisteredUser.createdByCompanyId, userAttributeNames, userAttributeName, contactName, originalModel)
                setModelInitialValues(model)
                setUserAttribute(userAttribute)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage, history })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, userAttributeClient, unregisteredUserClient, unregisteredUserId, getUserAttributeNames, history])



    useEffect(() => {
        loadInitialModel()
    }, [id])



    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        let validateErrors: ValidationError[] = []
        if (id != null) {
            const request = createUpdateUserAttributeRequest(model)
            validateErrors = await validate(request);
        } else {
            const request = createAddUserAttributeRequest(model)
            validateErrors = await validate(request);
        }
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["name", "description", "value"], errorModel)
        return errorModel
    }, [id])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            if (id != null) {
                const request = createUpdateUserAttributeRequest(model)
                await userAttributeClient.updateUserAttribute(id, request)
                loadInitialModel()
                setGlobalMessage({ message: "User attribute updated.", type: "success" })
            } else {
                const request = createAddUserAttributeRequest(model)
                const createdUserAttribute = await userAttributeClient.createUserAttribute(request)
                history.replace(`/unregistered-users/${model.unregisteredUserId}/user-attributes/${createdUserAttribute.id}`)
                setGlobalMessage({ message: "User Attribute created.", type: "success" })
            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["name", "description", "value"] })
    }, [id, userAttributeClient, loadInitialModel, setGlobalMessage, history])

    const onDeleteUserAttribute = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await userAttributeClient.deleteUserAttribute(id)
                history.replace(`/unregistered-users/${unregisteredUserId}/user-attributes`)
                setGlobalMessage({ message: "User attribute deleted", type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, userAttributeClient, history, setGlobalMessage, unregisteredUserId])

    const isAddingNewUserAttribute = useMemo(() => {
        return id == null
    }, [id])

    const isSharedUserAttribute = useMemo(() => {
        if (userAttribute?.name == null) {
            return false
        }
        return dto.UserAttribute.isSharedAttribute(userAttribute?.name)
    }, [userAttribute])

    const isAllowedToDeleteAttribute = useMemo(() => {
        return id != null && !isSharedUserAttribute
    }, [id, isSharedUserAttribute])

    const isAllowedToEditAttribute = useMemo(() => {
        return id != null && !isSharedUserAttribute;
    }, [id, isSharedUserAttribute])

    const onViewAllUserAttributes = useCallback(() => {
        history.replace(`/unregistered-users/${unregisteredUserId}/user-attributes`)
    }, [unregisteredUserId, history])

    const onAddNewUserAttribute = useCallback(() => {
        history.replace(`/unregistered-users/${unregisteredUserId}/user-attributes/add/user-attribute`)
    }, [history, unregisteredUserId])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteUserAttribute,
        isAllowedToDeleteAttribute,
        isAllowedToEditAttribute,
        isAddingNewUserAttribute,
        onViewAllUserAttributes,
        onAddNewUserAttribute,
        isSharedUserAttribute,
         
    }
}

export const useUserAttributeForm = () => {
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    useEffect(() => {
        (async () => {
            const userAttrName = model.userAttributeNames.find(x => x.name === model.name);
            setFieldValue("userAttributeName", userAttrName)
        })()
    }, [model.name, model.userAttributeNames, setFieldValue])

    const userAttributeNamesDropDownEnabled = useMemo(() => {
        return !model.readonly && model.userAttributeId == null
    }, [model.readonly, model.userAttributeId])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])


    const isSharedUserAttribute = useCallback((name: string) => {
        if (name == null) {
            return false
        }
        return dto.UserAttribute.isSharedAttribute(name)
    }, [])

    const isDateOfBirthSharedAttribute = useCallback((name: string) => {
        if (name == null) {
            return false
        }
        return dto.UserAttribute.isDateOfBirthSharedAttribute(name)
    }, [])


    return {
        editForm,
        cancelEditForm: cancelEdit,
        userAttributeNamesDropDownEnabled,
        isSharedUserAttribute,
        isDateOfBirthSharedAttribute
    }
}
