import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { dto } from "shared"
import { ICompanyClient, ICompanyClientId, IQueuedJobClient, IQueuedJobClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { DropdownItem } from "../../../_common/common/dropdown/DropDown"
import { useLocalization } from "../../../hook/use-localization"

const pageSize = 20

declare type JobStatus = "all" | "pending" | "completed" | "failed"


export const useQueuedJobs = () => {
    const { t } = useLocalization()
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const jobClient = useClient<IQueuedJobClient>(IQueuedJobClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [jobs, setJobs] = useState<dto.QueuedJob[]>([])
    const [companies, setCompanies] = useState<dto.Company[]>([])
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
    const [queueName, setQueueName] = useState<dto.QueuedJobQueueName>(dto.QueuedJobQueueName.unregisteredUserAttributes)
    const [jobStatus, setJobStatus] = useState<JobStatus>("all")
    const [allowGetData, setAllowGetData] = useState<boolean>(false)


    const onPageRequest = (pageIndex: number) => {
        loadForm({ pageIndex, selectedCompanyId, jobStatus, queueName })
        setPageIndex(pageIndex)
    }

    const { pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

    const onJobSelected = useCallback((jobId: string, queueName: dto.QueuedJobQueueName) => {
        history.push("/queued-jobs/" + queueName + "/" + jobId)
    }, [history])



    const loadForm = useCallback(async (options: { pageIndex: number, selectedCompanyId: string | null, jobStatus: JobStatus | null, queueName: dto.QueuedJobQueueName }) => {
        try {
            const { pageIndex, selectedCompanyId, jobStatus, queueName } = options
            if (selectedCompanyId == null) {
                setJobs([])
                return
            }
            setLoading(true)
            setJobs([])
            const request = new dto.GetQueuedJobsRequest()
            request.queueName = queueName
            request.companyId = selectedCompanyId
            request.paging = new dto.PagingRequest({ pageIndex, pageSize })
            request.sortBy = [new dto.SortItem({ name: "createdDate", type: "desc" })]
            request.statuses = []
            if ((jobStatus as any) === "all") {
                request.statuses.push(dto.QueuedJobStatus.completed)
                request.statuses.push(dto.QueuedJobStatus.active)
                request.statuses.push(dto.QueuedJobStatus.delayed)
                request.statuses.push(dto.QueuedJobStatus.paused)
                request.statuses.push(dto.QueuedJobStatus.waiting)
                request.statuses.push(dto.QueuedJobStatus.failed)
            } else {
                switch (jobStatus) {
                    case "completed":
                        request.statuses.push(dto.QueuedJobStatus.completed)
                        break
                    case "pending":
                        request.statuses.push(dto.QueuedJobStatus.active)
                        request.statuses.push(dto.QueuedJobStatus.delayed)
                        request.statuses.push(dto.QueuedJobStatus.paused)
                        request.statuses.push(dto.QueuedJobStatus.waiting)
                        break
                    case "failed":
                        request.statuses.push(dto.QueuedJobStatus.failed)
                        break
                    default:
                        throw new Error("Unsupported status:" + jobStatus)

                }
            }

            const pagedResponse = await jobClient.getJobs(request);
            setJobs(pagedResponse.entities)
            setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        } finally {
            setLoading(false)
        }
    }, [setGlobalMessage, setTotalNumberOfPages, jobClient])

    useEffect(() => {
        if (!allowGetData) {
            return
        }
        (async () => {

            setPageIndex(0)
            await loadForm({ pageIndex: 0, selectedCompanyId, jobStatus, queueName })
        })()
    }, [selectedCompanyId, queueName, jobStatus, allowGetData]);



    // Load companies
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const companies = await companyClient.getCurrentCompanies()
                setCompanies(companies)
                const companyId = companies.length > 0 ? companies[0].id : null
                setSelectedCompanyId(companyId)
                if (companyId != null) {
                    await loadForm({ pageIndex: 0, selectedCompanyId, jobStatus, queueName })
                    setAllowGetData(true)
                }
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])


    const jobStatusItems = useMemo((): DropdownItem[] => {
        const statusList: JobStatus[] = ["all", "pending", "completed", "failed"]
        return statusList.map(x => {
            return {
                text: t(`QueuedJob_${x}`),
                value: x
            }
        })
    }, [t])

    const queueNameItems = useMemo((): DropdownItem[] => {
        const statusList: dto.QueuedJobQueueName[] = [dto.QueuedJobQueueName.unregisteredUsers, dto.QueuedJobQueueName.unregisteredUserAttributes, dto.QueuedJobQueueName.unregisteredUserEvents]
        return statusList.map(x => {
            return {
                text: t("QueueName_" + x),
                value: x
            }
        })
    }, [t])


    return {
        jobs,
        onJobSelected,
        loading,
        companyItems,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        setJobStatus,
        setQueueName,
        pagination,
        jobStatusItems,
        queueNameItems,
        queueName,
        jobStatus
    }
}