import { dto } from "shared"

export const IUserInvitationClientId = Symbol.for('IUserInvitationClient');

export interface IUserInvitationClient {
    getUserInvitations(data: dto.GetUserInvitationsRequest): Promise<dto.PagingResponse<dto.UserInvitation>>
    getSelfUserInvitations(data: dto.GetSelfUserInvitationsRequest): Promise<dto.PagingResponse<dto.UserInvitation>>
    getUserInvitation(userinvitationId: string): Promise<dto.UserInvitation>
    addUserInvitation(data: dto.AddUserInvitationRequest): Promise<dto.UserInvitation>
    acceptUserInvitation(userinvitationId: string): Promise<void>
    rejectUserInvitation(userinvitationId: string): Promise<void>
    deleteUserInvitation(userinvitationId: string): Promise<void>
}