import { Form, Formik } from "formik"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextArea } from "../../../_common/fields/text-area/TextArea"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./notification-task-batch.scss"
import { NotificationBatchModel, useNotificationTaskBatch, useNotificationTaskBatchForm } from "./useNotificationTaskBatch"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { DropDownTitleSubtitleField } from "../../../_common/fields/dropdown-title-subtitle/dropdown-title-subtitle-field"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle, ConfirmationLevel } from "../../../_common/common/confirmation/confirmation"


export const NotificationTaskBatch: React.FC = () => {
	const { t } = useLocalization()
	const { modelInitialValues, onSubmit, onValidate, loading, onCancelNotificationTaskBatch, onDeleteNotificationTaskBatch, deleteNotificationTaskBatchEnabled, cancelNotificationTaskBatchEnabled, isAddingNewNotificationTaskBatch } = useNotificationTaskBatch()
	const confirmationDeleteRef = useRef<ConfirmationHandle>(null);
	const confirmationCancelRef = useRef<ConfirmationHandle>(null);


	return (
		<Shell pageTitle={isAddingNewNotificationTaskBatch ? t("AddBulkMessage") : t("BulkMessage")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>

				{cancelNotificationTaskBatchEnabled && <DangerZone>
					<div className="delete-title">{t("CancelBulkMessage")} </div>
					<Button onClick={() => confirmationCancelRef.current?.show()}>{t("Cancel")}</Button>
					<div className="delete-message">{t("CancelBulkMessageDescription")}</div>
					<Confirmation level="require-number-confirmation-strong" body={t("Confirmation.CancelBatch")} ref={confirmationCancelRef} onOkCallback={() => onCancelNotificationTaskBatch()} />
				</DangerZone>}

				{deleteNotificationTaskBatchEnabled && <DangerZone>
					<div className="delete-title">{t("DeleteBulkMessage")}</div>
					<Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
					<div className="delete-message">{t("DeleteBulkMessageDescription")}</div>
					<Confirmation level="require-number-confirmation-strong" body={t("Confirmation.DeleteBatch")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteNotificationTaskBatch()} />
				</DangerZone>}
			</>
			}
		</Shell>
	)
}



const FormContent: React.FC = () => {
	const { t } = useLocalization()
	const { model, submitForm } = useTypedFormikContext<NotificationBatchModel>()
	const { notificationTemplateItems, companyItems, notificationTaskTypes, actionItems, actionTypeSubtitle, addressesTitle, onAddressChanged } = useNotificationTaskBatchForm()
	const confirmationRef = useRef<ConfirmationHandle>(null);

	useEffect(() => {
		onAddressChanged(model.addresses)
	}, [model.addresses, onAddressChanged])

	const actionZoneType = useMemo(() => {
		switch (model.actionType) {
			case "estimate":
				return "info"
			case "create_only_for_me":
				return "warning"
			default:
				return "danger"
		}
	}, [model.actionType])

	const onSubmit = useCallback(() => {
		if (model.actionType === "estimate") {
			submitForm()
		} else {
			confirmationRef.current?.show()
		}
	}, [submitForm, model.actionType])

	const confirmationLevel = useMemo((): ConfirmationLevel => {
		if (model.actionType === "create_only_for_me") {
			return "require-number-confirmation"
		} else if (model.actionType === "create_notifications") {
			return "require-number-confirmation-strong"
		}
		return "default"
	}, [model.actionType])

	const confirmationBody = useMemo((): string => {
		if (model.actionType === "create_only_for_me") {
			return t("Confirm.Send.Message.To.Me")
		} else if (model.actionType === "create_notifications") {
			return t("Confirm.Batch.Create")
		}
		return ""
	}, [model.actionType, t])

	const onConfirmed = useCallback(()=>{

	},[])

	return (
		<div className="indent-container">
			<CompanyDropDownField disabled={model.readonly} name="companyId" label={t("Company")} items={companyItems} />
			<TextInput name="name" disabled={model.readonly} label={t("Name")} />
			<DropDownField name="notificationTaskType" disabled={model.readonly} label={t("Type")} items={notificationTaskTypes} />
			<DropDownTitleSubtitleField name="templateId" disabled={model.readonly} label={t("Template")} items={notificationTemplateItems} />
			<DatePickerField name="taskDate" disabled={model.readonly} label={t("NotificationDate")} showTimeInput />
            <DatePickerField name="expirationDate" disabled={model.readonly} label={t("ExpirationDate")} showTimeInput />
            <TextArea name="addresses" hidden={true} disabled={model.readonly} label={addressesTitle} />
			<div>{model.addressesValidationMessage}</div>
			{model.readonly &&
				<>
					<TextInput name="numberOfCompletedTasks" disabled={true} label={t("NumberOfCompletedMessages")} />
					<TextInput name="numberOfPendingTasks" disabled={true} label={t("NumberOfPendingMessages")} />
					<TextInput name="numberOfFailedTasks" disabled={true} label={t("NumberOfFailedMessages")} />
					<DatePickerField name="createdDate" disabled={true} label={t("CreatedDate")} />
					<DatePickerField name="updatedDate" disabled={true} label={t("UpdatedDate")} />
				</>}


			{!model.readonly &&
				<div className={`alert alert-${actionZoneType} zone-container indent-container`} role="alert">
					<DropDownTitleSubtitleField name="actionType" disabled={model.readonly} label={t("ActionType")} items={actionItems} />
					<div>{actionTypeSubtitle}</div>
					<div className="action-buttons-row">
						{!model.readonly && <Button onClick={onSubmit}>{t('Submit')}</Button>}
					</div>
					<Confirmation level={confirmationLevel} body={confirmationBody} ref={confirmationRef} onOkCallback={() => submitForm()} />
				</div>
			}

		</div>)
}
