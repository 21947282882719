

const queuedJobPaths = {
    base: "/queued-jobs",
    queuedJobs: "",
    queuedJob: "/:queuename/:queuedjobid"
}

export { queuedJobPaths as queuedJob }

