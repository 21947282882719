import { ValidationError } from "class-validator";
import { FormikErrors, FormikHelpers } from "formik";
import { ClientError, ClientErrorStatusCode } from "shared-client";
import { SharedDataHelper } from "shared"
import { IGlobalMessage } from "../../ui/context/global-message/global-message";

export type OnFormSubmit<Values> = (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
export type FormValidate<Values> = (values: Values) => void | object | Promise<FormikErrors<Values>>;

const ensureGrammaticallyCorrect = (text: string | null | undefined): string | null | undefined => {
    if (text != null) {
        return SharedDataHelper.ensureFirstLetterUppercase(SharedDataHelper.ensureDotAtEnd(text))
    }

    return text
}

const fieldError = (validationErrors: ValidationError[], name: string): string | undefined => {
    const validationError = validationErrors.find(x => x.property === name && Object.keys(x.constraints ?? {}).length > 0)
    if (validationError != null) {
        const key = Object.keys(validationError!.constraints!)[0]
        const errorDescription = validationError!.constraints![key]
        return errorDescription
    }
    return undefined
}

export const setFieldError = <Model>(validationErrors: ValidationError[], name: keyof Model & string, errorModel: FormikErrors<Model>) => {
    const errorDescription = fieldError(validationErrors, name)
    if (errorDescription != null) {
        (errorModel as any)[name] = ensureGrammaticallyCorrect(errorDescription)
    } else {
        delete errorModel[name]
    }
}

export const setFieldErrors = <Model>(validationErrors: ValidationError[], names: (keyof Model & string)[], errorModel: FormikErrors<Model>) => {
    for (const name of names) {
        setFieldError(validationErrors, name, errorModel)
    }
}


export const submitWrapper = async <Model>(
    callback: () => Promise<void>, options: {
        setGlobalMessage: (globalMessage: IGlobalMessage | null) => void,
        setFieldError: (field: string, message: string | undefined) => void,
        fieldNames?: (keyof Model & string)[]
    }) => {
    try {
        // reset errors
        options.setGlobalMessage(null);
        for (const fieldName of options.fieldNames ?? []) {
            options.setFieldError(fieldName, undefined)
        }

        await callback()
    } catch (err) {
        //debugger
        handleError(err as Error, options)
    }
}


export const handleError = (error: Error | any, options: {
    setGlobalMessage: (globalMessage: IGlobalMessage | null) => void,
    setFieldError?: (field: string, message: string | undefined) => void,
    fieldNames?: string[],
    history?: { replace: (route: string) => void }

}) => {
    if (options?.history != null && error["statusCode"] === 404) {
        window.setTimeout(() => {
            options?.history?.replace("/not-found");
        }, 100)
        return
    }

    let clientError: ClientError | null = null
    if (ClientError.isClientError(error)) {
        clientError = error as ClientError
    }

    if (clientError != null && clientError.statusCode === ClientErrorStatusCode.badRequest
        && (clientError.validationDetails?.length ?? 0) > 0) {
        const validationDetails = clientError.validationDetails ?? []
        const supportedFieldsNames = options.fieldNames ?? []

        let uniqueFieldKeys: string[] = [...Array.from(new Set(validationDetails.filter(x => x.key != null).map(x => x.key!)))];

        const supportedUniqueFieldKeys = uniqueFieldKeys.filter(x => supportedFieldsNames.find(y => y === x) != null)
        for (const fieldKey of supportedUniqueFieldKeys) {
            const errorMessage = validationDetails.find(x => x.key === fieldKey)!.message ?? "Generic error"
            if (options.setFieldError != null) {
                options.setFieldError(fieldKey, errorMessage)
            }
        }

        let globalError: string = ""
        const unsupportedUniqueFieldKeys = uniqueFieldKeys.filter(x => supportedFieldsNames.find(y => y === x) == null)
        let unknownError: string | null = null
        for (const fieldKey of unsupportedUniqueFieldKeys) {
            const validationDetail = validationDetails.find(x => x.key === fieldKey)
            if (validationDetail?.constraint === "unknown") {
                unknownError = validationDetail.key
                continue
            }
            const errorMessage = validationDetail?.message ?? "Generic error"
            if (globalError !== "") {
                globalError = globalError! + "."
            }
            globalError = globalError! + SharedDataHelper.firstLetterUppercase(fieldKey) + ":" + SharedDataHelper.firstLetterLowercase(errorMessage)
        }

        if (SharedDataHelper.stringIsNullTrimEmpty(globalError) && SharedDataHelper.stringNotNullTrimEmpty(unknownError)) {
            globalError = unknownError!
        }

        if (globalError !== "") {
            options.setGlobalMessage({
                message: globalError,
                type: "error"
            })
        }
        return
    }

    options.setGlobalMessage({
        message: error.message,
        type: "error"
    })

}
