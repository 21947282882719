import { IsBoolean, IsDate, IsEmail, IsEnum, IsOptional, MaxLength, MinLength, ValidateIf } from "class-validator";
import { UserGender } from "..";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { IsMobilePhoneNumber } from "../../../validators/is-mobile-phone-number";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";
import { ContainsValidTimezone } from "../../../validators/contains-valid-timezone";
import { LambdaExpression } from "../../../validators/lambda-expression";


export class UpdateUnregisteredUserRequest {

    @IsOptional()
    @ContainsValidTimezone()
    timezone: string

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString()
    externalId?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    firstName?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    lastName?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsMobilePhoneNumber()
    mobilePhoneNumber?: string | null | undefined

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsDate()
    dateOfBirth?: Date | undefined | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsEnum(UserGender)
    gender?: UserGender | undefined | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsEmail()
    email?: string | null | undefined;

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString({ each: true })
    deleteConsentIds?: string[] | undefined

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @ContainsValidString({ each: true })
    askConsentIds?: string[] | undefined

    @LambdaExpression({
        expression: (obj: UpdateUnregisteredUserRequest) => {
            if (obj.dateOfBirth != null && obj.timezone == null) {
                return false
            }
            return true
        }
    }, { message: "TimeZoneRequiredWhenDateOfBirthSet" })
    readonly _meta?: boolean = true

}