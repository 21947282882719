import { useCallback, useState } from "react";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { IUserClient, IUserClientId, repository } from "shared-client";
import { useAppContext } from "../../hook/use-app-context";
import useClient from "../../hook/useClient";
import { useUserRole } from "../../hook/useUserRole";

export const useAppContent = () => {
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const { pathname } = useLocation()
    const { hasCompanyAdminRole, hasCompanyUserRole, hasAdminRole } = useUserRole()
    const { setUser, user } = useAppContext()
    const userClient = useClient<IUserClient>(IUserClientId)
    const tokenRepository = useClient<repository.ITokenRepository>(repository.ITokenRepositoryId)

    useEffect(() => {
        setLoading(true);
        (async () => {
            let user: dto.User | null = null
            try {
                setUser(null)
                const accessToken = await tokenRepository.getAccessToken()
                const refreshToken = await tokenRepository.getRefreshToken()
                if (SharedDataHelper.stringNotNullEmpty(accessToken) && SharedDataHelper.stringNotNullEmpty(refreshToken)) {
                    user = await userClient.getCurrentUser()
                    setUser(user)
                }
            } catch (err) {
                await tokenRepository.deleteAccessToken()
                await tokenRepository.deleteRefreshToken()
            }
            setLoading(false)
            if (user == null &&
                !(pathname.startsWith("/signin") ||
                    pathname.startsWith("/registration") ||
                    pathname.startsWith("/recover-password") ||
                    pathname.startsWith("/user-actions") ||
                    pathname.startsWith("/opt-out"))) {
                history.replace("/signin/initial")
            }
            else if (user != null && pathname === "/") {
                history.replace(getDefaultPage(user?.roles ?? []))
            }

        })()

    }, [])

    const getDefaultPage = useCallback((userRoles: dto.UserRole[]) => {
        if (hasAdminRole(userRoles)) {
            return "/companies"
        }

        if (hasCompanyAdminRole(userRoles)) {
            // TODO: change to notifications-batch page
            return "/unregistered-users"
        }

        if (hasCompanyUserRole(userRoles)) {
            // TODO: change to notifications-batch page
            return "/unregistered-users"
        }

        return "/account"
    }, [hasAdminRole, hasCompanyUserRole, hasCompanyAdminRole])

    const defaultPage = useMemo(() => {
        return getDefaultPage(user?.roles ?? [])
    }, [getDefaultPage, user?.roles])

    return {
        defaultPage,
        loading
    }
}