import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import "./confirmation.scss"
import { useLocalization } from "../../../hook/use-localization";
import { DataHelper } from "../../helpers/data-helper";

export type ConfirmationLevel = "default" | "require-number-confirmation" | "require-number-confirmation-strong"

export interface Props {
    contextIdentifier?: string,
    level?: ConfirmationLevel
    title?: string,
    body: string,
    okText?: string,
    cancelText?: string,
    onOkCallback: (contextIdentifier: string | null) => void,
    onCancelCallback?: (contextIdentifier: string | null) => void,
}

export interface ConfirmationHandle {
    show: (ctx?: any) => void;
    hide: () => void;
}

const NumberLength = 4
const NumberLengthStrong = 8

export const Confirmation = forwardRef<ConfirmationHandle, Props>((props, ref) => {
    const { t } = useLocalization()
    const [isOpen, setIsOpen] = useState(false);
    const [numberInput, setNumberInput] = useState("")
    const [numberInputFromUser, setNumberInputFromUser] = useState("")
    const { body, contextIdentifier, onCancelCallback, onOkCallback, level } = props;
    const okText = props.okText ?? t("Yes");
    const cancelText = props.cancelText ?? t("No");
    const titleText = props.title ?? t("Confirmation")
    const [ctx, setCtx] = useState<any>(null)

    useEffect(() => {
        const num = level === "require-number-confirmation-strong" ? NumberLengthStrong : NumberLength
        setNumberInput(DataHelper.generateRandomStringBasedOnNumbers(num))
        setNumberInputFromUser("")
    }, [isOpen, level])

    useImperativeHandle(ref, () => ({
        show: (ctx?: any) => {
            setNumberInputFromUser("")
            setIsOpen(true)
            setCtx(ctx)
        },
        hide: () => setIsOpen(false),
    }));

    const handleCancel = useCallback(() => {
        setIsOpen(false);
        if (onCancelCallback) {
            onCancelCallback(contextIdentifier ?? null);
        }
    }, [onCancelCallback, contextIdentifier])

    const handleOK = useCallback(() => {
        setIsOpen(false);
        onOkCallback(ctx ?? contextIdentifier ?? null);
    }, [onOkCallback, contextIdentifier, ctx])

    const enabledYesButton = useMemo(() => {
        if (level === "require-number-confirmation" || level === "require-number-confirmation-strong") {
            return numberInput === numberInputFromUser
        }

        return true
    }, [level, numberInput, numberInputFromUser])


    return (
        <>
            {isOpen &&

                <div className="confirmation-modal-dialog-wrapper">
                    <div className="confirmation-modal-dialog-bg"></div>
                    <div className="confirmation-modal">
                        <Modal.Header closeButton onHide={handleCancel}>
                            <Modal.Title>{titleText}</Modal.Title>
                        </Modal.Header>
                        <div className="separator"></div>
                        <Modal.Body>
                            <p>{body}</p>
                            {(level === "require-number-confirmation" || level === "require-number-confirmation-strong") && <>
                                <p>{t("Please.Enter.Same.Code")}</p>
                                <input disabled={true} className="require-number-confirmation-input text-input__input form-control" value={numberInput}></input>
                                <input className="require-number-confirmation-input text-input__input form-control" value={numberInputFromUser} onChange={e => setNumberInputFromUser(e.target.value)} ></input>
                            </>}
                        </Modal.Body>
                        <div className="separator"></div>

                        <Modal.Footer>
                            <Button variant="secondary" className="action-button" onClick={handleCancel}>{cancelText}</Button>
                            <Button variant="primary" disabled={!enabledYesButton} className="action-button" onClick={handleOK}>{okText}</Button>
                        </Modal.Footer>
                    </div>
                </div>

            }
        </>
    );
})
