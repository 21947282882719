

const businessConfigurationPaths = {
    base: "/business-configurations",
    businessConfigurations: "",
    businessConfiguration: "/:companyid"
}

export { businessConfigurationPaths as businessConfiguration }

