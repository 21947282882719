import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IAuthClient } from "./i-auth-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class AuthClient extends BaseClient implements IAuthClient {

    async token(data: dto.OAuthPasswordGrant | dto.OAuthRefreshToken | dto.ClientCredentialGrant): Promise<dto.OauthTokensResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.Auth.base + apiPaths.Auth.token), data: data });
            return response.data as dto.OauthTokensResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async initLogin(data: dto.InitLoginRequest): Promise<dto.StatusResponse> {
        try {
            const response = await this.publicAxios({ method: 'post', url: (apiPaths.Auth.base + apiPaths.Auth.initLogin), data: data });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async logout(): Promise<dto.StatusResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.Auth.base + apiPaths.Auth.logout) });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async logoutByUserIdentifier(userId: string): Promise<dto.StatusResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.Auth.base + apiPaths.Auth.logoutByUserIdentifier.replace(":userid", userId)) });
            return response.data as dto.StatusResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}