import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserRoleClient } from "./i-user-role-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserRoleClient extends BaseClient implements IUserRoleClient {

    async addUserRole(data: dto.AddUserRoleRequest): Promise<dto.UserRole> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.user.userRoles.base + apiPaths.user.userRoles.userRoles), data: data });
            return response.data as dto.UserRole;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUserRole(userroleId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.user.userRoles.base + apiPaths.user.userRoles.userRole.replace(":userroleid", userroleId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}