import { Container } from "inversify";
import { AppTypeClient } from "./app-type/app-type-client";
import { IAppTypeClient} from "./app-type/i-app-type-client";
import { AuthClient } from "./auth/auth-client";
import { IAuthClient} from "./auth/i-auth-client";
import { BusinessConfigurationClient } from "./business-configuration/business-configuration-client";
import { IBusinessConfigurationClient} from "./business-configuration/i-business-configuration-client";
import { ClientCredentialClient } from "./client-credential/client-credential-client";
import { IClientCredentialClient} from "./client-credential/i-client-credential-client";
import { CompanyClient } from "./company/company-client";
import { ICompanyClient} from "./company/i-company-client";
import { ConsentClient } from "./consent/consent-client";
import { IConsentClient} from "./consent/i-consent-client";
import { EventClient } from "./event/event-client";
import { IEventClient} from "./event/i-event-client";
import { EventTypeClient } from "./event-type/event-type-client";
import { IEventTypeClient} from "./event-type/i-event-type-client";
import { HealthClient } from "./health/health-client";
import { IHealthClient} from "./health/i-health-client";
import { ApiLogClient } from "./api-log/api-log-client";
import { IApiLogClient} from "./api-log/i-api-log-client";
import { NotificationTaskBatchClient } from "./notification-task-batch/notification-task-batch-client";
import { INotificationTaskBatchClient} from "./notification-task-batch/i-notification-task-batch-client";
import { NotificationTaskClient } from "./notification-task/notification-task-client";
import { INotificationTaskClient} from "./notification-task/i-notification-task-client";
import { NotificationTemplateClient } from "./notification-template/notification-template-client";
import { INotificationTemplateClient} from "./notification-template/i-notification-template-client";
import { QueuedJobClient } from "./queued-job/queued-job-client";
import { IQueuedJobClient} from "./queued-job/i-queued-job-client";
import { UnregisteredUserClient } from "./unregistered-user/unregistered-user-client";
import { IUnregisteredUserClient} from "./unregistered-user/i-unregistered-user-client";
import { UserActionsClient } from "./user-actions/user-actions-client";
import { IUserActionsClient} from "./user-actions/i-user-actions-client";
import { UserAttributeClient } from "./user-attribute/user-attribute-client";
import { IUserAttributeClient} from "./user-attribute/i-user-attribute-client";
import { UserAttributeNameClient } from "./user-attribute-name/user-attribute-name-client";
import { IUserAttributeNameClient} from "./user-attribute-name/i-user-attribute-name-client";
import { UserConsentHistoryClient } from "./user-consent-history/user-consent-history-client";
import { IUserConsentHistoryClient} from "./user-consent-history/i-user-consent-history-client";
import { UserClient } from "./user/user-client";
import { IUserClient} from "./user/i-user-client";
import { UserInvitationClient } from "./user-invitation/user-invitation-client";
import { IUserInvitationClient} from "./user-invitation/i-user-invitation-client";
import { UserRecoverPasswordClient } from "./user-recover-password/user-recover-password-client";
import { IUserRecoverPasswordClient} from "./user-recover-password/i-user-recover-password-client";
import { UserRegistrationClient } from "./user-registration/user-registration-client";
import { IUserRegistrationClient} from "./user-registration/i-user-registration-client";
import { UserRoleClient } from "./user-role/user-role-client";
import { IUserRoleClient} from "./user-role/i-user-role-client";

import { ITokenRepository } from "../../repository";

export class Client{
    static container:Container;
    
    static appType(options?: { tokenRepository?: ITokenRepository }):IAppTypeClient{
        let client = new AppTypeClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static auth(options?: { tokenRepository?: ITokenRepository }):IAuthClient{
        let client = new AuthClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static businessConfiguration(options?: { tokenRepository?: ITokenRepository }):IBusinessConfigurationClient{
        let client = new BusinessConfigurationClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static clientCredential(options?: { tokenRepository?: ITokenRepository }):IClientCredentialClient{
        let client = new ClientCredentialClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static company(options?: { tokenRepository?: ITokenRepository }):ICompanyClient{
        let client = new CompanyClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static consent(options?: { tokenRepository?: ITokenRepository }):IConsentClient{
        let client = new ConsentClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static event(options?: { tokenRepository?: ITokenRepository }):IEventClient{
        let client = new EventClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static eventType(options?: { tokenRepository?: ITokenRepository }):IEventTypeClient{
        let client = new EventTypeClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static health(options?: { tokenRepository?: ITokenRepository }):IHealthClient{
        let client = new HealthClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static apiLog(options?: { tokenRepository?: ITokenRepository }):IApiLogClient{
        let client = new ApiLogClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static notificationTaskBatch(options?: { tokenRepository?: ITokenRepository }):INotificationTaskBatchClient{
        let client = new NotificationTaskBatchClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static notificationTask(options?: { tokenRepository?: ITokenRepository }):INotificationTaskClient{
        let client = new NotificationTaskClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static notificationTemplate(options?: { tokenRepository?: ITokenRepository }):INotificationTemplateClient{
        let client = new NotificationTemplateClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static queuedJob(options?: { tokenRepository?: ITokenRepository }):IQueuedJobClient{
        let client = new QueuedJobClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static unregisteredUser(options?: { tokenRepository?: ITokenRepository }):IUnregisteredUserClient{
        let client = new UnregisteredUserClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userActions(options?: { tokenRepository?: ITokenRepository }):IUserActionsClient{
        let client = new UserActionsClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userAttribute(options?: { tokenRepository?: ITokenRepository }):IUserAttributeClient{
        let client = new UserAttributeClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userAttributeName(options?: { tokenRepository?: ITokenRepository }):IUserAttributeNameClient{
        let client = new UserAttributeNameClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userConsentHistory(options?: { tokenRepository?: ITokenRepository }):IUserConsentHistoryClient{
        let client = new UserConsentHistoryClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static user(options?: { tokenRepository?: ITokenRepository }):IUserClient{
        let client = new UserClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userInvitation(options?: { tokenRepository?: ITokenRepository }):IUserInvitationClient{
        let client = new UserInvitationClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userRecoverPassword(options?: { tokenRepository?: ITokenRepository }):IUserRecoverPasswordClient{
        let client = new UserRecoverPasswordClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userRegistration(options?: { tokenRepository?: ITokenRepository }):IUserRegistrationClient{
        let client = new UserRegistrationClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }

    static userRole(options?: { tokenRepository?: ITokenRepository }):IUserRoleClient{
        let client = new UserRoleClient();
        client.tokenRepository = options?.tokenRepository;
        return client;
    }


}