import { dto } from "shared"

export function createDefaultSimpleQuery(options: { type?: dto.UserAttributeQueryType, setValue: boolean, setDescription: boolean }): dto.UserAttributeQueryCondition {
  const query = new dto.UserAttributeQueryCondition();
  query.conditions = undefined
  query.name = dto.UserAttribute.sharedAttributes.dateOfBirth
  query.type = options?.type ?? dto.UserAttributeQueryType.greaterOrEqual
  if (options.setValue) {
    query.value = "2000-01-01"
  }
  if (options.setDescription) {
    query.description = "2000-01-01"
  }
  return query
}

export function createDefaultCompositeQuery(options: { type: dto.UserAttributeQueryType, conditions?: dto.UserAttributeQueryCondition[], setValue: boolean, setDescription: boolean }): dto.UserAttributeQueryCondition {
  const query = new dto.UserAttributeQueryCondition();
  query.type = options?.type ?? dto.UserAttributeQueryType.and
  if (options?.conditions != null) {
    query.conditions = options.conditions
  } else {
    query.conditions = []
    query.conditions.push(createDefaultSimpleQuery({ type: dto.UserAttributeQueryType.greaterOrEqual, setValue: options.setValue, setDescription: options.setDescription }))
    query.conditions.push(createDefaultSimpleQuery({ type: dto.UserAttributeQueryType.lowerOrEqual, setValue: options.setValue, setDescription: options.setDescription }))
  }
  return query
}