import { IsEmail } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";


export class UserRecoverPasswordChallengeTokenRequest {
    @ContainsValidString()
    @IsEmail()
    email:string;

    @ContainsValidString()
    clientId: string;

    @ContainsValidString()
    token: string;  
}