


const notificationTaskBatchPaths = {
    base: "/notificationTaskBatches",
    notificationTaskBatches: "",
    notificationTaskBatch: "/:notificationtaskbatchid",
    cancelNotificationTaskBatch: "/cancel/:notificationtaskbatchid"
}

export { notificationTaskBatchPaths as notificationTaskBatch }

