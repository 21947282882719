import { useCallback, useMemo } from "react";
import { dto } from "shared";
import { UserHelper } from "../_common/helpers/user-helper";
import { useLocalization } from "./use-localization";

export const roleDescriptions = (roles: dto.UserRole[], t?: (text: string) => string): string => {

	let roleSet = new Set<string>([]);
	for (const userRole of roles) {
		roleSet.add(userRole.roleName)
	}

	let result = ""
	let roleArray = Array.from(roleSet.values())
	for (const roleName of roleArray) {
		if (result !== "") {
			result += ", "
		}
		if (t != null) {
			result += t(descriptionFromRoleName(roleName))
		} else {
			result += descriptionFromRoleName(roleName)
		}
	}
	return result
}

const descriptionFromRoleName = (role: string): string => {
	if (role === "admin") {
		return "Admin"
	}

	if (role === "companyAdmin") {
		return "CompanyAdmin"
	}

	if (role === "companyUser") {
		return "CompanyUser"
	}

	if (role === "user") {
		return "User"
	}

	throw new Error("Unsupported user role:" + role)
}

export const useUserRole = () => {

	const { t } = useLocalization();

	const isAdmin = useCallback((user: dto.User | null) => {
		if (user == null){
			return false
		}
		return UserHelper.isAdmin(user)
	}, [])


	const roleDescription = useCallback((user: dto.User, companyId: string | null) => {

		const adminRole = user.roles.find(x => x.roleName === "admin")
		if (adminRole != null) {
			return t(descriptionFromRoleName(adminRole.roleName))
		}

		if (companyId != null) {
			const companyAdminRole = user.roles.find(x => x.companyId === companyId && x.roleName === "companyAdmin")
			if (companyAdminRole != null) {
				return t(descriptionFromRoleName(companyAdminRole.roleName))
			}

			const companyUserRole = user.roles.find(x => x.companyId === companyId && x.roleName === "companyUser")
			if (companyUserRole != null) {
				return t(descriptionFromRoleName(companyUserRole.roleName))
			}
		}
		const userRole = user.roles.find(x => x.roleName === "user")
		if (userRole != null) {
			return t(descriptionFromRoleName(userRole.roleName))
		}

		return t("User")

	}, [t])

	const _roleDescriptions = useCallback(roleDescription, [descriptionFromRoleName, roleDescription])

	const hasAdminRole = useCallback((userRoles: dto.UserRole[] | null) => {
		return UserHelper.hasAdminRole(userRoles)
	}, [])

	const isCompanyAdmin = useCallback((user: dto.User | null, companyId?: string) => {
		return UserHelper.isCompanyAdmin(user, companyId)
	}, [])

	const hasCompanyAdminRole = useCallback((userRoles: dto.UserRole[] | null, companyId?: string) => {
		return UserHelper.hasCompanyAdminRole(userRoles, companyId)
	}, [])

	const isCompanyUser = useCallback((user: dto.User | null, companyId?: string) => {
		return UserHelper.isCompanyUser(user, companyId)
	}, [])

	const hasCompanyUserRole = useCallback((userRoles: dto.UserRole[] | null, companyId?: string) => {
		return UserHelper.hasCompanyUserRole(userRoles, companyId)
	}, [])

	return {
		roleDescription: _roleDescriptions,
		isAdmin,
		roleDescriptions,
		descriptionFromRoleName,
		isCompanyAdmin,
		isCompanyUser,
		hasCompanyAdminRole,
		hasCompanyUserRole,
		hasAdminRole,
	}
}