import React from "react"
import { SharedDataHelper } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { useUserRole } from "../../../hook/useUserRole"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import "./users.scss"
import { useUsers } from "./useUsers"
import { useLocalization } from "../../../hook/use-localization"


export const Users: React.FC = () => {
	const { t } = useLocalization()
	const isMobile = useIsMobile()
	const { users, companyItems, selectedCompanyId, onUserSelected, onCompanySelected, loading, pagination } = useUsers()
	const { roleDescription } = useUserRole()

	const rowClick = (userid: string) => {
		if (isMobile) {
			onUserSelected(userid)
		}
	}

	const listItems = users.map((user) =>
		<div className="users__item data-row-item" key={user.id} onClick={() => rowClick(user.id)}>
			<div className="users__item-name-address">
				<div className="users__item-name"><button onClick={() => { onUserSelected(user.id) }}>{user.firstName} {user.lastName}</button></div>
				<div className="users__item-address">{user.email}</div>
			</div>
			<div className="users__item-status-updated">
				<div className="users__item-status">{t("UserStatus." + SharedDataHelper.ensureFirstLetterUppercase(user.status))}</div>
				<div className="users__item-role"> {roleDescription(user, selectedCompanyId)}</div>
			</div>
		</div>
	);
	return (
		<Shell pageTitle={t("Users")}>

			<Loading loading={loading} />
			<div className="indent-container users-header-filter">
				<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
				<Pagination {...pagination} />
			</div>
			{listItems}
			{users.length === 0 && <NoEntities />}

		</Shell >
	)
}