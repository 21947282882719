import { dto } from "shared"

export const INotificationTaskBatchClientId = Symbol.for('INotificationTaskBatchClient');

export interface INotificationTaskBatchClient {
    addNotificationTaskBatch(data: dto.AddNotificationTaskBatchRequest): Promise<dto.AddNotificationTaskBatchResponse>
    getNotificationTaskBatchInfo(notificationtaskbatchId: string): Promise<dto.NotificationTaskBatchInfo>
    getNotificationTaskBatchInfos(data: dto.GetNotificationTaskBachInfosRequest): Promise<dto.PagingResponse<dto.NotificationTaskBatchInfo>>
    cancelNotificationTaskBatch(notificationtaskbatchId: string): Promise<void>
    deleteNotificationTaskBatch(notificationtaskbatchId: string): Promise<void>
}