import { FormikErrors, useFormikContext } from "formik"
import { useCallback } from "react"


export function useTypedFormikContext<Model>() {
	const { setFieldError: setFieldErrorFormik, setFieldValue: setFieldValueFormik, setValues, submitForm, ...other } = useFormikContext<Model>()

	const setFieldError = useCallback((field: keyof Model & string, message: string | undefined) => {
		setFieldErrorFormik(field, message)
	}, [setFieldErrorFormik])

	const setFieldValue = useCallback((field: keyof Model & string, value: any, shouldValidate?: boolean) => {
		setFieldValueFormik(field, value, shouldValidate)
	}, [setFieldValueFormik])

	return {
		...other,
		setFieldError,
		setFieldValue,
		model: other.values,
		setFormValues: setValues,
		submitForm:submitForm
	}
}


export function cancelEditForm<Model>(model: any, options: {
	setErrors: (errors: FormikErrors<Model>) => void,
	setValues: (values: React.SetStateAction<Model>, shouldValidate?: boolean) => void
}) {
	const { originalModel: _originalModel, ...others } = model
	const newModel = { ..._originalModel!, originalModel: _originalModel! }
	options.setValues(newModel, false)
	options.setErrors({})
}