import React from "react";
import { Button } from "../../common/button/Button";
import "./button-submit.scss"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}
export const ButtonSubmit: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <Button {...props} type="submit" />
    );
}