import { useCallback } from "react";

export function useShellContext() {

  const scrollContentToBottom = useCallback(() => {
    const shellContent = window.document.getElementById("desktop-shell__content")
    if (shellContent != null) {
      shellContent.scrollTop = shellContent.scrollHeight;
    }
  }, [])

  return {
    scrollContentToBottom
  }
}