import { Type } from 'class-transformer';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { AddUnregisteredUserRequest } from "../unregistered-user/add-unregistered-user-request";
import { ArrayMaxSize, IsArray, IsDate, IsOptional, IsString, MaxLength, ValidateNested } from "class-validator";

export class AddEventRequest {

    @ContainsValidString()
    eventTypeId: string;

    @ContainsValidString()
    companyId: string;

    @IsDate()
    date: Date;

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    description?: string

    @IsOptional()
    @IsArray()
    @ContainsValidString({ each: true })
    attachToUnregisterUserIds?: string[]

    @IsOptional()
    @IsArray()
    @ContainsValidString({ each: true })
    attachUserIds?: string[]
}