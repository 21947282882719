import React, { ReactChild, useEffect, useMemo, useState } from "react"
import { dto } from "shared"
import { Dropdown, DropdownItem } from "../../common/dropdown/DropDown"
import "./user-attribute-value.scss"
import { useCallback } from "react"
import { DatePicker } from "../../common/date-picker/date-picker"
import { DataHelper } from "../../helpers/data-helper"
import { useLocalization } from "../../../hook/use-localization"



interface Props {
    userAttributeName: dto.UserAttributeName,
    value: string | null,
    onChange: (value: string | null) => void,
    disabled?: boolean,
    label: string,
}

export const UserAttributeValue: React.FC<Props> = ({ userAttributeName, value, onChange, disabled, label }) => {
    const { t } = useLocalization();
    const [dateValue, setDateValue] = useState<Date | null>(null);

    useEffect(() => {
        if (userAttributeName.attributeType === dto.UserAttributeNameType.date) {
            if (value == null || value === "" || !DataHelper.isDate(value)) {
                const today = new Date(new Date().toDateString());
                setDateValue(today);
                return
            }

            if (value !== dateValue?.toISOString()) {
                setDateValue(new Date(value!));
            }
        }

    }, [userAttributeName?.name, value, onChange, userAttributeName.attributeType])

    const selectItems: DropdownItem[] = useMemo(() => {
        return (userAttributeName.selectValues ?? []).map(x => {
            return {
                text: t("UserAttributeNameValue." + x).replace("UserAttributeNameValue.", ""),
                value: x
            }
        })
    }, [userAttributeName.selectValues])



    return (
        <>
            {userAttributeName.attributeType !== dto.UserAttributeNameType.exists && <div className="user-attribute__container">
                <div>{label}</div>
                {userAttributeName.attributeType === dto.UserAttributeNameType.number && <input className="text-input__input form-control" disabled={disabled} value={value ?? ""} onChange={e => onChange(e.target.value)} />}
                {userAttributeName.attributeType === dto.UserAttributeNameType.text && <input className="text-input__input form-control" disabled={disabled} value={value ?? ""} onChange={e => onChange(e.target.value)} />}
                {userAttributeName.attributeType === dto.UserAttributeNameType.date && dateValue != null && <DatePicker className="no-top-margin" disabled={disabled} date={dateValue} showTimeInput={true} onChange={val => onChange(val?.toISOString() ?? null)} />}
                {userAttributeName.attributeType === dto.UserAttributeNameType.select && <Dropdown disabled={disabled} className="no-top-margin" hideLabel={true} label="" selectedValue={value} items={selectItems} onSelect={val => onChange(val)} />}
            </div>}
        </>
    )
}