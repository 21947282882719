import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IEventTypeClient } from "./i-event-type-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class EventTypeClient extends BaseClient implements IEventTypeClient {

    async getEventTypes(data: dto.GetEventTypesRequest): Promise<dto.PagingResponse<dto.EventType>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.eventType.base + apiPaths.eventType.eventTypes), params: data });
            return response.data as dto.PagingResponse<dto.EventType>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getEventType(eventtypeId: string): Promise<dto.EventType> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.eventType.base + apiPaths.eventType.eventType.replace(":eventtypeid", eventtypeId)) });
            return response.data as dto.EventType;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addEventType(data: dto.AddEventTypeRequest): Promise<dto.EventType> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.eventType.base + apiPaths.eventType.eventTypes), data: data });
            return response.data as dto.EventType;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateEventType(eventtypeId: string, data: dto.UpdateEventTypeRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.eventType.base + apiPaths.eventType.eventType.replace(":eventtypeid", eventtypeId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteEventType(eventtypeId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.eventType.base + apiPaths.eventType.eventType.replace(":eventtypeid", eventtypeId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}