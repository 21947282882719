import React from "react"
import "./notification-task-status-icon.scss"
import { dto } from "shared"


export interface PaginationProps {
	status: dto.NotificationTaskStatus
	className?: string
}

export const NotificationTaskStatusIcon: React.FC<PaginationProps> = ({ status, className }) => {
	return (
		<div className={`notification-task-status-icon ${status} ${className ?? ""}`}>&nbsp;</div>
	)
}