import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { dto } from "shared";
import {
  ICompanyClient,
  ICompanyClientId,
  IUserAttributeNameClient,
  IUserAttributeNameClientId,
} from "shared-client";
import { handleError } from "../../../../common/form/form";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { usePagination } from "../../../hook/use-pagination";
import useClient from "../../../hook/useClient";
import {
  CompanyDropdownItem,
  createCompanyDropDownItems,
} from "../../../_common/components/company-dropdown/company-dropdown";

const pageSize = 20;

export const useUserAttributeNames = () => {
  const history = useHistory();
  const { setGlobalMessage } = useGlobalMessageContext();
  const [loading, setLoading] = useState(false);
  const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId);
  const companyClient = useClient<ICompanyClient>(ICompanyClientId);
  const [userAttributeNames, setUserAttributeNames] = useState<dto.UserAttributeName[]>([]);
  const [companies, setCompanies] = useState<dto.Company[]>([]);
  const { pageIndex, pagination, setTotalNumberOfPages } = usePagination();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);

  const onUserAttributeNameSelected = useCallback(
    (id: string) => {
      history.push("/user-attribute-names/" + id);
    },
    [history]
  );

  useEffect(() => {
    (async () => {
      try {
        if (selectedCompanyId == null) {
          setUserAttributeNames([]);
          return;
        }
        setLoading(true);
        setUserAttributeNames([]);
        const request = new dto.GetUserAttributeNamesRequest();
        request.companyId = selectedCompanyId;
        request.paging = new dto.PagingRequest({ pageIndex, pageSize });
        request.sortBy = [];
        request.sortBy.push(
          new dto.SortItem({ name: "createdDate", type: "desc" })
        );
        const pagedResponse = await userAttributeNameClient.getUserAttributeNames(request);
        setUserAttributeNames(pagedResponse.entities);
        setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages);
      } catch (err) {
        handleError(err, { setGlobalMessage: setGlobalMessage });
      } finally {
        setLoading(false);
      }
    })();
  }, [pageIndex, selectedCompanyId, userAttributeNameClient]);

  // Load companies
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const companies = await companyClient.getCurrentCompanies();
        setCompanies(companies);
        setSelectedCompanyId(companies.length > 0 ? companies[0].id : null);
      } catch (err) {
        handleError(err, { setGlobalMessage: setGlobalMessage });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const companyItems = useMemo((): CompanyDropdownItem[] => {
    return createCompanyDropDownItems(companies);
  }, [companies]);

  const onAddUserAttributeName = useCallback(() => {
    history.push("/user-attribute-names/add/user-attribute-name/");
  }, [history]);

  const addNewUserAttributeEnabled = useMemo(() => {
    return companies.length > 0;
  }, [companies]);

  return {
    userAttributeNames: userAttributeNames,
    onUserAttributeNameSelected: onUserAttributeNameSelected,
    loading,
    companyItems,
    selectedCompanyId,
    onCompanySelected: setSelectedCompanyId,
    pagination,
    onAddUser: onAddUserAttributeName,
    addNewUserAttributeEnabled: addNewUserAttributeEnabled,
  };
};
