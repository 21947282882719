import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import {  IUserRegistrationRepository  } from "./i-user-registration-repository";
import { BaseNetworkRepository } from "../../base/base-network-repository"

@injectable()
export class UserRegistrationRepository extends BaseNetworkRepository implements IUserRegistrationRepository {
    
    async register(request:dto.UserRegistrationRegisterRequest) : Promise<dto.StatusResponse>{
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.registration.register , data: request });
        return response.data as dto.StatusResponse;
    }

    async resendVerification(request:dto.UserRegistrationResendVerificationRequest) : Promise<dto.StatusResponse>{
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.registration.resendVerification , data: request });
        return response.data as dto.StatusResponse;
    }

    async complete(request:dto.UserRegistrationCompleteRequest) : Promise<dto.StatusResponse>{
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.registration.complete , data: request });
        return response.data as dto.StatusResponse;
    }
}