import { NotificationTaskDetails } from "../notification-task-details";

export class SmsNotificationTaskDetails extends NotificationTaskDetails {

    mobilePhoneNumber: string | null;
    messageToSend: string | null
    sentMessage: string | null;
    sentToMobilePhoneNumber: string | null

    constructor(options: {
        mobilePhoneNumber: string | null;
        messageToSend: string | null
        sentMessage: string | null;
        sentToMobilePhoneNumber: string | null
    }) {
        super()
        this.mobilePhoneNumber = options.mobilePhoneNumber
        this.messageToSend = options.messageToSend
        this.sentMessage = options.sentMessage
        this.sentToMobilePhoneNumber = options.sentToMobilePhoneNumber

    }

}