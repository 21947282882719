import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { INotificationTemplateClient } from "./i-notification-template-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class NotificationTemplateClient extends BaseClient implements INotificationTemplateClient {

    async getNotificationTemplates(data: dto.GetNotificationTemplatesRequest): Promise<dto.PagingResponse<dto.NotificationTemplate>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.notificationTemplates), params: data });
            return response.data as dto.PagingResponse<dto.NotificationTemplate>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getNotificationTemplate(notificationtemplateId: string): Promise<dto.NotificationTemplate> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.notificationTemplate.replace(":notificationtemplateid", notificationtemplateId)) });
            return response.data as dto.NotificationTemplate;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addNotificationTemplate(data: dto.AddNotificationTemplateRequest): Promise<dto.NotificationTemplate> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.notificationTemplates), data: data });
            return response.data as dto.NotificationTemplate;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateNotificationTemplate(notificationtemplateId: string, data: dto.UpdateNotificationTemplateRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.notificationTemplate.replace(":notificationtemplateid", notificationtemplateId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteNotificationTemplate(notificationtemplateId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.notificationTemplate.replace(":notificationtemplateid", notificationtemplateId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getPredefinedTemplates(): Promise<dto.PredefinedNotificationTemplate[]> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTemplate.base + apiPaths.notificationTemplate.predefinedTemplates) });
            return response.data as dto.PredefinedNotificationTemplate[];
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}