import React, { useMemo } from "react"
import "./languages.scss"
import { combineClassNames } from "../../../helpers/user-interface-helper"
import { useLocalization } from "../../../../hook/use-localization"

interface Props {
  className?: string
}

export const Languages: React.FC<Props> = ({ className }) => {

  const { language, setLanguage } = useLocalization();

  const languages: { lang: string, name: string }[] = useMemo(() => {
    return [
      {
        lang: "en",
        name: "English"
      },
      {
        lang: "sr",
        name: "Serbia"
      }
    ]
  }, [])

  const listItems = languages.map(({ lang, name }) =>
    <div onClick={() => setLanguage(lang)} className={combineClassNames("language-item", language === lang ? 'selected' : '')} key={"language-" + lang}>
      <img src={`/lang-${lang}.png`} alt={lang} /> <div>{name}</div>
    </div>
  );

  return (
    <div className={combineClassNames("languages-container", className)} >
      {listItems}
    </div >
  )
}