import "./consent-field.scss"
import { useField } from "formik";
import React, { FC, useState } from "react"
import { useMemo } from "react";
import { combineClassNames, randomId } from "../../helpers/user-interface-helper";
import { SharedDataHelper, dto } from "shared";
import { useLocalization } from "../../../hook/use-localization";

interface ConsentFieldProps {
    consent: dto.Consent;
    name: string
    className?: string,
    disabled?: boolean,
    hideDescription?: boolean
}

export const ConsentField: FC<ConsentFieldProps> = (props => {
    const { t, language } = useLocalization()
    const { consent, name, className, disabled } = props
    const [field,] = useField(name);
    const [expandedDetails, setExpandedDetails] = useState(false)
    const cbId = useMemo(() => {
        return randomId()
    }, [])

    const consentName = useMemo(() => {
        return (consent as any)[`name${SharedDataHelper.firstLetterUppercase(language)}`]
    }, [consent, language])

    const consentDescription = useMemo(() => {
        return (consent as any)[`text${SharedDataHelper.firstLetterUppercase(language)}`]
    }, [consent, language])

    const obj: any = {}
    if (disabled) {
        obj["disabled"] = "true"
    }

    return (
        <div className={combineClassNames("consent-field__container", className)}>
            <div className="consent-checkbox-container">
                <input id={cbId} className="checkbox-input" type="checkbox" {...field} {...obj} checked={field.value} />
                <label className="form-check-label" htmlFor={cbId}>
                    {consentName}
                </label>
            </div>
            {!(props.hideDescription ?? false) && <>
                {expandedDetails && <div className="consent-description">
                    {consentDescription}
                </div>
                }
                <div className="toogle-consent-details" onClick={() => setExpandedDetails(!expandedDetails)}>{t(expandedDetails ? "ReadLess" : "ReadMore")}</div>
            </>}

        </div>
    );
})