import { ContainsValidString } from "../../../validators/contains-valid-string";
import { IsDefined, IsEnum, MaxLength, MinLength } from "class-validator";
import { RoleName } from "../user";

export class AddClientCredentialRequest {

    @IsDefined()
    @IsEnum(RoleName)
    role: RoleName

    @ContainsValidString()
    companyId: string

    @ContainsValidString()
    @MinLength(5)
    @MaxLength(50)
    name: string


    constructor(options: { role: RoleName, companyId: string, name: string }) {
        if (options == null) {
            return
        }
        this.role = options.role
        this.companyId = options.companyId
        this.name = options.name
    }
}