import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto } from "shared";
import { IUnregisteredUserClient, IUnregisteredUserClientId, IEventClient, IEventClientId, IEventTypeClient, IEventTypeClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { DropdownItem } from "../../../_common/fields/dropdown/dropdown-field";


interface Model {
    eventId: string | null,
    company: dto.Company,
    eventTypeId: string | null;
    description: string | null
    eventTypes: dto.EventType[],
    date: Date | null
    unregisteredUser: dto.UnregisteredUser,
    readonly: boolean,
    originalModel: Omit<Model, "originalModel"> | null
}

export type EventModel = Model

const createModel = (
    event: dto.Event | null,
    selectedEventTypeId: string | null,
    company: dto.Company,
    unregisteredUser: dto.UnregisteredUser,
    eventTypes: dto.EventType[],
    readonly: boolean,
    originalModel?: Model | null): Model => {
    return {
        readonly,
        eventId: event?.id ?? null,
        eventTypes: eventTypes,
        company: company,
        eventTypeId: selectedEventTypeId,
        unregisteredUser: unregisteredUser,
        date: event?.date ?? null,
        description: event?.description ?? null,
        originalModel: originalModel ?? null
    }
}

const createUpdateEventRequest = (model: Model): dto.UpdateEventRequest => {
    const request = new dto.UpdateEventRequest()
    request.date = model.date!
    request.description = model.description!
    request.eventTypeId = model.eventTypeId!;
    request.unregisterUserIds = [model.unregisteredUser.id]
    request.userIds = []
    return request
}

const createAddEventRequest = (model: Model): dto.AddEventRequest => {
    const request = new dto.AddEventRequest()
    request.attachUserIds = []
    request.attachToUnregisterUserIds = [model.unregisteredUser.id]
    request.date = model.date!
    request.description = model.description!
    request.eventTypeId = model.eventTypeId!
    request.companyId = model.company.id;
    return request
}

export const useEvent = () => {
    //one of id or companyid is defined
    let { id } = useParams<{ id: string }>();
    let { unregistereduserid: unregisteredUserId } = useParams<{ unregistereduserid: string }>();
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [event, setEvent] = useState<dto.Event | null>(null)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
    const eventClient = useClient<IEventClient>(IEventClientId)
    const eventTypeClient = useClient<IEventTypeClient>(IEventTypeClientId)

    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)
                const unregisteredUser = await unregisteredUserClient.getUser(unregisteredUserId)
                if (unregisteredUser == null) {
                    history.replace("/not-found");
                    return
                }

                const { entities: eventTypes } = await eventTypeClient.getEventTypes({
                    paging: new dto.PagingRequest({ pageIndex: 0, pageSize: 10000 }),
                    sortBy: [new dto.SortItem({ name: "name", type: "asc" })],
                    companyId: unregisteredUser.createdByCompanyId
                })


                let readonly = id != null
                let event: dto.Event | null = null;
                let eventTypeId: string | null = null;
                if (id != null) {
                    event = await eventClient.getEvent(id);

                    if (event == null) {
                        history.replace("/not-found");
                        return
                    }

                    if (event.companyId !== unregisteredUser.createdByCompany.id) {
                        history.replace("/bad-request");
                        return
                    }

                    if ((event.unregisteredUsers?.length ?? 0) !== 1) {
                        history.replace("/bad-request");
                        return
                    }

                    if (event.unregisteredUsers[0].id !== unregisteredUserId) {
                        history.replace("/bad-request");
                        return
                    }



                    eventTypeId = event.eventTypeId
                } else {
                    eventTypeId = eventTypes.length > 0 ? eventTypes[0].id : null
                }

                const originalModel = createModel(event, eventTypeId, unregisteredUser.createdByCompany, unregisteredUser, eventTypes, readonly)
                const model = createModel(event, eventTypeId, unregisteredUser.createdByCompany, unregisteredUser, eventTypes, readonly, originalModel)
                setModelInitialValues(model)
                setEvent(event)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, setLoading, setGlobalMessage, setModelInitialValues, eventClient, unregisteredUserClient, eventTypeClient, unregisteredUserId, history])


    useEffect(() => {
        loadInitialModel()
    }, [id])



    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        let validateErrors: ValidationError[] = []
        if (id != null) {
            const request = createUpdateEventRequest(model)
            validateErrors = await validate(request);
        } else {
            const request = createAddEventRequest(model)
            validateErrors = await validate(request);
        }
        const errorModel: FormikErrors<Model> = {}
        setFieldErrors<Model>(validateErrors, ["date", "eventTypeId"], errorModel)
        return errorModel
    }, [id])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            if (id != null) {
                const request = createUpdateEventRequest(model)
                await eventClient.updateEvent(id, request)
                loadInitialModel()
                setGlobalMessage({ message: "Event updated.", type: "success" })
            } else {
                const request = createAddEventRequest(model)
                const createdEvent = await eventClient.addEvent(request)
                history.replace(`/unregistered-users/${model.unregisteredUser.id}/events/${createdEvent.id}`)
                setGlobalMessage({ message: "Event created.", type: "success" })
            }
        }, { setFieldError, setGlobalMessage, fieldNames: ["date", "eventTypeId"] })
    }, [id, eventClient, loadInitialModel, setGlobalMessage, history])

    const onDeleteEvent = useCallback(() => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await eventClient.deleteEvent(id)
                history.replace(`/unregistered-users/${unregisteredUserId}/events`)
                setGlobalMessage({ message: "Event deleted", type: "success" })
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [id, eventClient, history, setGlobalMessage, unregisteredUserId])

    const isAddingNewEvent = useMemo(() => {
        return id == null
    }, [id])


    const onViewAllEvents = useCallback(() => {
        history.replace(`/unregistered-users/${unregisteredUserId}/events`)
    }, [unregisteredUserId, history])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteEvent,
        isAddingNewEvent,
        onViewAllEvents,
    }
}

export const useEventForm = () => {
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const eventTypes = useMemo((): DropdownItem[] => {
        const array: DropdownItem[] = model.eventTypes.map(x => ({
            text: x.name,
            value: x.id,
        }))
        return array
    }, [model.eventTypes])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    useEffect(() => {
        if (model.eventId != null) {
            return
        }
        if (model?.eventTypeId == null) {
            return
        }
        if (model?.eventTypeId == null) {
            return
        }
        if (model!.eventTypes == null) {
            return
        }
        const eventType = model!.eventTypes.find(x => x.id === model!.eventTypeId)!
        let countInstant = eventType.sendNotificationsBefore!.filter(x => x.value <= 0).length
        if (countInstant === eventType.sendNotificationsBefore!.length) {
            setFieldValue("date", new Date())
        } else {
            setFieldValue("date", null)
        }
    }, [model?.eventId, model?.eventTypeId])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        eventTypes
    }
}
