import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom";
import { dto } from "shared";
import { IBusinessConfigurationClient, IBusinessConfigurationClientId } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import useClient from "../../../hook/useClient";
import { validate, ValidationError } from "class-validator";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { useLocalization } from "../../../hook/use-localization";
import { useUserRole } from "../../../hook/useUserRole";
import { useAppContext } from "../../../hook/use-app-context";


interface Model {
	companyId: string | null,
	companyName: string,
	configJSON: string,
	readonly: boolean,
	originalModel: Omit<Model, "originalModel"> | null
}

export type CompanyConfigurationModel = Model

const createModel = (businessConfiguration: dto.BusinessCompanyConfiguration, originalModel?: Model | null): Model => {
	return {
		readonly: true,
		companyId: businessConfiguration.company.id,
		companyName: businessConfiguration.company.name,
		configJSON: JSON.stringify(businessConfiguration.configuration, null, 4),
		originalModel: originalModel ?? null
	}
}

const createUpdateRequest = (model: Model): dto.BusinessConfiguration.Configuration => {
	const request = JSON.parse(model.configJSON)
	return request
}

export const useCompanyConfiguration = () => {
	let { id: companyId } = useParams<{ id: string }>();
	const { t } = useLocalization();
	const { user: currentUser } = useAppContext()
	const { isAdmin } = useUserRole()
	const history = useHistory();
	const { setGlobalMessage } = useGlobalMessageContext()
	const [editMode, setEditMode] = useState(false)
	const [loading, setLoading] = useState(false)
	const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null)
	const client = useClient<IBusinessConfigurationClient>(IBusinessConfigurationClientId)

	const loadInitialModel = useCallback((companyId: string) => {
		(async () => {
			try {
				setLoading(true)
				setModelInitialValues(null)
				const company = await client.getBusinessConfiguration(companyId)
				const originalModel = createModel(company)
				const model = createModel(company, originalModel)
				setModelInitialValues(model)
			} catch (err) {
				handleError(err, { setGlobalMessage: setGlobalMessage })
			} finally {
				setLoading(false)
			}
		})()
	}, [setLoading, setGlobalMessage, setModelInitialValues, client])


	useEffect(() => {
		if (!isAdmin(currentUser!)) {
			history.replace("/not-found")
		}
		loadInitialModel(companyId)
	}, [companyId, currentUser])

	const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
		const errorModel: FormikErrors<Model> = {}
		let validateErrors: ValidationError[] = []
		let request: dto.BusinessConfiguration.Configuration | null = null
		try {
			request = createUpdateRequest(model)
		} catch {
			setGlobalMessage({ message: t("Config.Error"), type: "error" })
			return {}
		}
		validateErrors = await validate(request);
		setFieldErrors<Model>(validateErrors, [], errorModel)
		return errorModel
	}, [setGlobalMessage, t])


	const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
		await submitWrapper<Model>(async () => {

			const request = createUpdateRequest(model)
			await client.saveBusinessConfiguration(companyId, request)
			loadInitialModel(companyId)
			setGlobalMessage({ message: t("Saved"), type: "success" })

		}, { setFieldError, setGlobalMessage, fieldNames: [] })
	}, [companyId, client, loadInitialModel, setGlobalMessage, t])


	const goToCompany = useCallback(() => {
		history.replace("/companies/" + companyId)
	}, [history, companyId])

	return {
		modelInitialValues,
		onSubmit,
		onValidate,
		editMode,
		setEditMode,
		loading,
		goToCompany
	}
}

export const useCompanyConfigurationForm = () => {
	const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

	const editForm = useCallback(() => {
		setFieldValue("readonly", false, false)
	}, [setFieldValue])

	const cancelEdit = useCallback(() => {
		cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
	}, [setFormValues, setErrors, model])

	return {
		editForm,
		cancelEditForm: cancelEdit,
	}
}
