import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { DatePicker } from "../../common/date-picker/date-picker";
import "./date-picker-field.scss"
import { useLocalization } from "../../../hook/use-localization";



interface Props {
	label: string,
	name: string,
	disabled?: boolean,
	showTimeInput?: boolean
}
export const DatePickerField: React.FC<Props> = (props) => {
	const { t } = useLocalization()
	const { name, label, showTimeInput } = props
	const [_, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null

	const onChange = useCallback((date: Date | null) => {
		setFieldValue(name, date, true)
	}, [setFieldValue, name])
	return (
		<div className="date-picker-field__container">
			<div>{label}</div>
			<DatePicker disabled={props.disabled} date={selectedValue} showTimeInput={showTimeInput} onChange={onChange} />
			{meta.touched && meta.error ? (
				<div className="error-text">{t(meta.error)}</div>
			) : null}
		</div>
	);
}