

export enum RoleId {
    admin = 1,
    user = 2,
    companyAdmin = 3,
    companyUser = 4
}


export enum RoleName {
    admin = "admin",
    user = "user",
    companyAdmin = "companyAdmin",
    companyUser = "companyUser"
}

 