import "./queued-jobs.scss";
import React from "react";
import { SharedDataHelper, dto } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import { useQueuedJobs } from "./useQueuedJobs";
import { useLocalization } from "../../../hook/use-localization";
import { Dropdown } from "../../../_common/common/dropdown/DropDown";

export const QueuedJobs: React.FC = () => {
    const isMobile = useIsMobile();
    const { t } = useLocalization()
    const {
        jobs,
        companyItems,
        selectedCompanyId,
        onJobSelected,
        onCompanySelected,
        loading,
        pagination,
        queueName,
        queueNameItems,
        jobStatusItems,
        jobStatus,
        setQueueName,
        setJobStatus

    } = useQueuedJobs();

    const rowClick = (jobId: string, queueName: dto.QueuedJobQueueName) => {
        if (isMobile) {
            onJobSelected(jobId, queueName);
        }
    };

    const findEntryValue = (entries: any[], name: string) => {
        const entry = entries.find(x => x[0] === name)
        return entry != null ? entry[1] : ""
    }

    const listItems = jobs.map((job) => {
        let name = ""
        if (job.queueName === dto.QueuedJobQueueName.unregisteredUserEvents) {
            const entries = Object.entries(job.data).filter(entry => entry[0] === "eventTypeName" || entry[0] === "date" || entry[0] === "unregisteredUserExternalId")
            name = findEntryValue(entries, "eventTypeName")
            name += (" " + findEntryValue(entries, "unregisteredUserExternalId"))
        }
        if (job.queueName === dto.QueuedJobQueueName.unregisteredUsers) {
            const entries = Object.entries(job.data).filter(entry => entry[0] === "firstName" || entry[0] === "lastName")
            name = findEntryValue(entries, "firstName")
            name += (" " + findEntryValue(entries, "lastName"))
        }
        if (job.queueName === dto.QueuedJobQueueName.unregisteredUserAttributes) {
            const entries = Object.entries(job.data).filter(entry => entry[0] === "name" || entry[0] === "value" || entry[0] === "unregisteredUserExternalId")
            name = findEntryValue(entries, "unregisteredUserExternalId")
            if (SharedDataHelper.stringIsNullTrimEmpty(name)) {
                name += ": "
            }
            name += (" " + findEntryValue(entries, "name"))
            name += (" " + findEntryValue(entries, "value"))
        }
        return <div
            className="queued-jobs__item data-row-item"
            key={job.id}
            onClick={() => rowClick(job.id, job.queueName)}
        >
            <div className="queued-jobs__item-personal-info">
                <div className="queued-jobs__item-contact-name">
                    <button
                        onClick={() => {
                            onJobSelected(job.id, job.queueName);
                        }}
                    >
                        {name}
                    </button>
                </div>
                <div className="queued-jobs__item-status">{t(`QueuedJob_${job.status}`)}</div>
                <div className="queued-jobs__item-data">
                    {t(`QueueJobSingular_${job.queueName}`)}
                </div>
            </div>
            <div className="queued-jobs__item-dates">
                <div className="queued-jobs__item-date">
                    {SharedDataHelper.getFormattedDate(job.date, "dd.MM.yyyy HH:mm")}
                </div>
            </div>
        </div>
    });
    return (
        <Shell
            pageTitle={t("QueuedJobs")}
            mobileHeaderContent={<div />}
            desktopHeaderContent={<div />}
        >
            <Loading loading={loading} />
            <div className="indent-container jobs-header-filter">
                <div className="jobs-filter">
                    <CompanyDropdown
                        items={companyItems}
                        selectedCompanyId={selectedCompanyId}
                        onSelect={onCompanySelected}
                    />
                    <div className="text-input__container jobs-queue-name">
                        <Dropdown className="undo-drop-down-margin-top" label={t("Type")} items={queueNameItems} selectedValue={queueName} onSelect={(val) => setQueueName(val as any)} />
                    </div>
                    <div className="text-input__container jobs-status">
                        <Dropdown className="undo-drop-down-margin-top" label={t("Status")} items={jobStatusItems} selectedValue={jobStatus} onSelect={(val) => setJobStatus(val as any)} />
                    </div>
                </div>
                <Pagination {...pagination} />
            </div>
            {listItems}
            {jobs.length === 0 && <NoEntities />}
        </Shell >
    );
};
