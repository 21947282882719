import { StatusResponse } from "../common/status-response";

export class SetUnregisteredUserByExternalIdResponse {
    status: StatusResponse
    externalId: string
    userIdentifier: string
    addedNewUser: boolean

    constructor(options: { status: StatusResponse, externalId: string, userIdentifier: string, addedNewUser: boolean }) {
        this.status = options.status;
        this.externalId = options.externalId
        this.userIdentifier = options.userIdentifier
        this.addedNewUser = options.addedNewUser
    }
}