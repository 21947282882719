import { Form, Formik } from "formik"
import React from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./event.scss"
import { EventModel, useEvent, useEventForm } from "./useEvent"
import { DatePickerField } from "../../../_common/fields/date-picker-field/date-picker-field"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { useLocalization } from "../../../hook/use-localization"


export const Event: React.FC = () => {
	const { t } = useLocalization()
	const { modelInitialValues, onSubmit, onValidate, loading, onDeleteEvent, isAddingNewEvent, onViewAllEvents } = useEvent()


	return (
		<Shell pageTitle={isAddingNewEvent ? t("AddNewEvent") : t("Event")}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent isAdd={isAddingNewEvent} />
						</Form>
					)}
				</Formik>

				<div className="alert alert-secondary zone-container indent-container" role="alert">
					<Button onClick={onViewAllEvents}>{t("Events")}</Button>
					<div> {t("ViewAllUserEvents")}</div>
				</div>

				{!isAddingNewEvent && <DangerZone>
					<div className="delete-title"> {t("DeleteEvent")}</div>
					<Button onClick={onDeleteEvent}>{t("Delete")}</Button>
					<div className="delete-message">{t("DeleteEventDescription")}</div>
				</DangerZone>}
			</>
			}
		</Shell>
	)
}

interface FormContentProps {
	isAdd: boolean,
}

const FormContent: React.FC<FormContentProps> = ({ isAdd }) => {
	const { t } = useLocalization()
	const { model } = useTypedFormikContext<EventModel>()
	const { editForm, cancelEditForm, eventTypes } = useEventForm()
	return (
		<div className="indent-container">
			<TextInput name="company.name" disabled={true} label={t("Company")} />
			<TextInput name="unregisteredUser.firstName" disabled={true} label={t("Contact") + " - " + t("FirstName")} />
			<TextInput name="unregisteredUser.lastName" disabled={true} label={t("Contact") + " - " + t("LastName")} />
			<DropDownField name="eventTypeId" disabled={model.readonly} label={t("Type")} items={eventTypes} />
			<DatePickerField name="date" showTimeInput={true} disabled={model.readonly} label={t("Date")} />
			<TextInput name="description" disabled={model.readonly} label={t("Description")} />

			<div className="action-buttons-row">
				{model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
				{!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
				{!model.readonly && <ButtonSubmit>{t("Save")}</ButtonSubmit>}
			</div>
		</div>)
}
