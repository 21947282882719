import { INotificationTaskSpecific } from "../i-notification-task-specific";
import { SharedDataHelper, dto } from "shared";

export class EmailNotificationTaskSpecific implements INotificationTaskSpecific {
	getAddress(notificationTask: dto.NotificationTask): string {
		const details = notificationTask.details as dto.EmailNotificationTaskDetails
        if (SharedDataHelper.stringNotNullTrimEmpty(details.sentToEmail)) {
            return details.sentToEmail!
        }
        if (SharedDataHelper.stringNotNullTrimEmpty(details.email)) {
			return details.email!
		}
		if (notificationTask.user != null) {
			return notificationTask.user.email
		}

		if (notificationTask.unregisteredUser != null) {
			return notificationTask.unregisteredUser.email
		}
		return ""
	}

	createAddNotificationTaskBatchDetails(addresses: string[]): dto.AddNotificationTaskBatchDetails {
		return new dto.EmailAddNotificationTaskBatchDetails({ emails: addresses })
	}

	isValidAddress(address: string): boolean {
		return SharedDataHelper.isValidEmail(address)
	}

	getAddressesInputTitle(): string {
		return "Emails"
	}

	getAddressInputTitle(): string {
		return "Email"
	}

	validateAddresses(addresses: string[]): { total: number; success: number; failed: number; } {
		const validAddresses = (addresses ?? []).filter(x => SharedDataHelper.isValidEmail(x))
		return {
			total: addresses.length,
			success: validAddresses.length,
			failed: addresses.length - validAddresses.length
		}
	}
}