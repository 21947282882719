import { ContainsValidString } from "../../../../../validators/contains-valid-string";
import { NotificationTemplateDetails } from "../notification-template-details";

export class SmsNotificationTemplateDetails extends NotificationTemplateDetails {

    @ContainsValidString({ message: "Message is not valid" })
    messageTemplate: string

    templateType?: string

    constructor(options: { messageTemplate: string }) {
        super()
        if (options == null) {
            return
        }
        this.messageTemplate = options.messageTemplate
        this.templateType = "sms"
    }
}