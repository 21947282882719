export default class UserInterfaceHelper {
    static convertRemToPixels(rem: number) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
}

export const combineClassNames = (...classNames: (string | null | undefined)[]): string => {
    let result = ""
    for (const name of classNames) {
        if (name != null) {
            if (result !== "") {
                result += " "
            }
            result += name
        }
    }
    return result
}

export const randomId = (): string => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}