
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";
import { EmailUnsubscribeMarkup } from "../api/dto";


@ValidatorConstraint({ async: true })
export class ContainsEmailUnsubscribeMarkupConstraint implements ValidatorConstraintInterface {
    validate(text: any) {
        if (text == null) {
            return false
        }
        if (typeof text !== "string") {
            return false
        }
        let markups: string[] = []
        for (const markup in EmailUnsubscribeMarkup) {
            const contains = text.indexOf(`##${markup}##`) > -1;
            if (contains) {
                markups.push(markup)
            }
        }

        return markups.length > 0
    }

    defaultMessage(args: ValidationArguments) {
        return "MissingEmailUnsubscribeMarkup"
    }
}

export function ContainsEmailUnsubscribeMarkup(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: ContainsEmailUnsubscribeMarkupConstraint
        });
    };
}