
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface LambdaExpressionOptions {
    expression: (obj: any) => boolean
}

@ValidatorConstraint({ async: true })
export class LambdaExpressionConstraint implements ValidatorConstraintInterface {

    async validate(obj: any, args: ValidationArguments) {
        const holder = args.object
        const constraint: LambdaExpressionOptions = args.constraints[0]
        const valid = constraint.expression(holder)
        return valid;
    }

    defaultMessage(args: ValidationArguments) {
        return `Invalid expressions`;
    }
}

export function LambdaExpression(options: LambdaExpressionOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [options as any],
            validator: LambdaExpressionConstraint
        });
    };
}