import { UserStatus } from "./user-status";
import { IsEnum, IsNotEmpty } from "class-validator";

export class ChangeUserStatusRequest {

    @IsNotEmpty()
    @IsEnum(UserStatus, { message: "Status must be one of: active, blocked, registration-pending" })
    status: UserStatus;
}

