import { dto } from "shared"

export const IEventClientId = Symbol.for('IEventClient');

export interface IEventClient {
    getEvents(data: dto.GetEventsRequest): Promise<dto.PagingResponse<dto.Event>>
    getEvent(eventId: string): Promise<dto.Event>
    addEvent(data: dto.AddEventRequest): Promise<dto.Event>
    updateEvent(eventId: string, data: dto.UpdateEventRequest): Promise<void>
    deleteEvent(eventId: string): Promise<void>
    uploadEventsUsingExcel(companyId: string, data: dto.UploadEventsByExcelFileRequest): Promise<dto.UploadEventsByExcelFileResponse>
}