import React, { useCallback } from "react";
import { SharedDataHelper, dto } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./events.scss";
import { useEvents } from "./useEvents";
import { DatePicker } from "../../../_common/common/date-picker/date-picker";
import { useLocalization } from "../../../hook/use-localization";

export const Events: React.FC = () => {
  const { t } = useLocalization();
  const isMobile = useIsMobile();
  const {
    events,
    companyItems,
    selectedCompanyId,
    onEventSelected,
    onCompanySelected,
    loading,
    pagination,
    onAddUnregisteredUserEvent,
    addNewEventVisible,
    userContextInfo,
    dateFrom,
    dateTo,
    onDateFromChanged,
    onDateToChanged
  } = useEvents();

  const onEventRowClick = (eventId: string, unregisteredUserId: string) => {
    if (isMobile) {
      onEventSelected(eventId, unregisteredUserId);
    }
  };

  const personalInfoList = useCallback((event: dto.Event) => {
    return (
      <>

        {event.unregisteredUsers?.map((unregisteredUser) => (
          <div onClick={() => onEventRowClick(event.id, unregisteredUser.id)} className="events__item-users-unregistered-user" key={"unregistered-user-" + unregisteredUser.id}>
            <div className="type">{t("Contact")}</div>
            <div className="first-last-name">
              <button
                onClick={() => {
                  onEventSelected(event.id, unregisteredUser.id)
                }}>

                {unregisteredUser.firstName} {unregisteredUser.lastName}
              </button>
            </div>
            <div className="date-of-birth">{t("DateOfBirth")}: {SharedDataHelper.getFormattedDate(event.date, "dd.MM.yyyy")}</div>
          </div >
        ))
        }
        {
          event.users?.map((user) => (
            <div className="events__item-users-user" key={"user-" + user.id}>
              <div className="type">{t("User")}</div>
              <div className="first-last-name">{user.firstName} {user.lastName}</div>
              <div className="email">{user.email}</div>
            </div>
          ))
        }
      </>
    );
  }, []);


  const listItems = events.map(event => (
    <div
      className="events__item data-row-item"
      key={event.id}>

      <div className="events__item-users">
        {personalInfoList(event)}
      </div>

      <div className="events__item-dates-type">
        <div className="events__item-dates-type-date">
          {event.eventTypeName}: {SharedDataHelper.getFormattedDate(event.date, "dd.MM.yyyy HH:mm")}
        </div>
        <div className="events__item-dates-type-date-updated">
          {t("Created")} {SharedDataHelper.getFormattedDate(event.updatedDate ?? event.createdDate, "dd.MM.yyyy")}
        </div>
      </div>
    </div>
  ));
  return (
    <Shell
      pageTitle={t(`Events`) + userContextInfo}
      mobileHeaderContent={
        <>
          {addNewEventVisible && <button
            className="mobile-add-new"
            onClick={onAddUnregisteredUserEvent}
          >
            {t("Add")}
          </button>}
        </>
      }
      desktopHeaderContent={
        <>
          {addNewEventVisible && <Button onClick={onAddUnregisteredUserEvent}>
            {t("AddEvent")}
          </Button>}
        </>
      }
    >
      <Loading loading={loading} />
      <div className="indent-container events-header-filter">
        <div className="events-filter">
          <div className="company-container">
            <CompanyDropdown
              items={companyItems}
              selectedCompanyId={selectedCompanyId}
              onSelect={onCompanySelected}
            />
          </div>

          <div className="date-picker-field__container date-filter no-top-margin">
            <div>{t("DateFrom")}</div>
            <DatePicker date={dateFrom} showTimeInput={true} onChange={onDateFromChanged} />
          </div>

          <div className="date-picker-field__container date-filter no-top-margin">
            <div>{t("DateTo")}</div>
            <DatePicker date={dateTo} showTimeInput={true} onChange={onDateToChanged} />
          </div>
        </div>
        <div className="pagination">
          <Pagination {...pagination} />
        </div>
      </div>
      {listItems}
      {events.length === 0 && <NoEntities />}
    </Shell >
  );
};
