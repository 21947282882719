import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppContext } from "../../hook/use-app-context";

type PrivateRouteProps = RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {

    const { component: Component = null, render: Render = null, ...rest } = props;
    const { user } = useAppContext()

    return (
        <Route
            {...rest}
            render={props =>
                user != null ? (
                    Render ? (
                        Render(props)
                    ) : Component ? (
                        <Component {...props} />
                    ) : null
                ) : (
                    <Redirect to={'/signin'} />
                )
            }
        />
    );
};
export default PrivateRoute;