import { NotificationTaskType } from "../notification-task-type";
import { NotificationTemplateType } from "../notification-template/notification-template-type";
import { EmailNotificationSpecific } from "./email";
import { INotificationSpecific } from "./i-notification-specific";
import { SmsNotificationSpecific } from "./sms/sms-notification-specific";

export class NotificationSpecificFactory {
	static create(type: NotificationTaskType): INotificationSpecific {
		if (type == NotificationTaskType.sendSms) {
			return new SmsNotificationSpecific()
		}
		if (type == NotificationTaskType.sendEmail) {
			return new EmailNotificationSpecific()
		}
		throw new Error("Not supported NotificationTaskType:" + type)
	}

	static createByTemplate(type: NotificationTemplateType): INotificationSpecific {
		if (type == NotificationTemplateType.sms) {
			return new SmsNotificationSpecific()
		}
		if (type == NotificationTemplateType.email) {
			return new EmailNotificationSpecific()
		}
		throw new Error("Not supported NotificationTemplateType:" + type)
	}
}