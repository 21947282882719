import { Company } from "../../company/company";
import { MessageType, NotificationTemplateDetails, NotificationTemplateType, UserAttributeQueryCondition } from "../..";

export const BirthdayNotificationTemplate = "Birthday"


export class NotificationTemplate {
    id: string;
    messageType: MessageType
    templateType: NotificationTemplateType
    details: NotificationTemplateDetails
    companyId: string;
    company: Company;
    name: string;
    eventTypeId: string | null
    isUserRelated: boolean
    isUnregisteredUserRelated: boolean
    createdDate: Date;
    updatedDate: Date | null;
    query: UserAttributeQueryCondition | null

}