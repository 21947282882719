const userFirstNameMarkup = "##USER_FIRST_NAME##"

const userLastNameMarkup = "##USER_LAST_NAME##"

const eventNameMarkup = "##EVENT_NAME##"
const eventDateMarkup = "##EVENT_DATE##"
const eventTimeMarkups = "##EVENT_TIME##"

const allNameMarkups = [userFirstNameMarkup, userLastNameMarkup]
const allEventMarkups = [eventNameMarkup, eventDateMarkup, eventTimeMarkups]


export const notificationTemplateMarkups = {
    userFirstNameMarkup,
    userLastNameMarkup,
    allNameMarkups,
    allEventMarkups
}