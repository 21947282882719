
import { UserAttribute } from "../api/dto";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";
const moment = require('moment-timezone');


@ValidatorConstraint({ async: true })
export class IsNotSharedUserAttributeConstraint implements ValidatorConstraintInterface {
    validate(text: any, args: ValidationArguments) {
        const values = Object.values(UserAttribute.sharedAttributes)
        return values.find(x => x === text) == null
    }

    defaultMessage(args: ValidationArguments) {
        return "User attribute name can not be shared attribute(dateOfBirth or gender)"
    }
}

export function IsNotSharedUserAttribute(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsNotSharedUserAttributeConstraint
        });
    };
}