import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUnregisteredUserClient, IUnregisteredUserClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"

const pageSize = 20

export const useUnregisteredUsers = () => {
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [unregisteredUsers, setUnregisteredUsers] = useState<dto.UnregisteredUser[]>([])
    const [companies, setCompanies] = useState<dto.Company[]>([])
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
    const [firstName, setFirstName] = useState<string | null>(null)
    const [lastName, setLastName] = useState<string | null>(null)

    const onPageRequest = (pageIndex: number) => {
        loadForm({ pageIndex, selectedCompanyId, firstName, lastName })
        setPageIndex(pageIndex)
    }

    const { pageIndex, pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

    const onUnregisteredUserSelected = useCallback((unregisteredUserId: string) => {
        history.push("/unregistered-users/" + unregisteredUserId)
    }, [history])

    const onViewEvents = useCallback((unregisteredUserId: string) => {
        history.push(`/unregistered-users/${unregisteredUserId}/events`)
    }, [history])


    const loadForm = useCallback(async (options: { pageIndex: number, selectedCompanyId: string | null, firstName: string | null, lastName: string | null }) => {
        try {
            const { pageIndex, selectedCompanyId, firstName, lastName } = options
            if (selectedCompanyId == null) {
                setUnregisteredUsers([])
                return
            }
            setLoading(true)
            setUnregisteredUsers([])
            const request = new dto.GetUnregisteredUsersRequest()
            request.companyId = selectedCompanyId
            request.paging = new dto.PagingRequest({ pageIndex, pageSize })
            request.sortBy = []
            request.firstName = SharedDataHelper.stringNotNullTrimEmpty(firstName) ? firstName?.trim() : undefined
            request.lastName = SharedDataHelper.stringNotNullTrimEmpty(lastName) ? lastName?.trim() : undefined
            request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
            const pagedResponse = (await unregisteredUserClient.getUsers(request))
            setUnregisteredUsers(pagedResponse.entities)
            setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        } finally {
            setLoading(false)
        }
    }, [setGlobalMessage, setTotalNumberOfPages, unregisteredUserClient])

    useEffect(() => {
        (async () => {
            await loadForm({ pageIndex, selectedCompanyId, firstName, lastName })
        })()
    }, [selectedCompanyId]);

    useEffect(() => {
        let _pageIndex = pageIndex
        if (firstName != null || lastName != null) {
            _pageIndex = 0
            setPageIndex(_pageIndex)
        }
        loadForm({ pageIndex: _pageIndex, selectedCompanyId, firstName, lastName });
    }, [firstName, lastName])

    // Load companies
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const companies = await companyClient.getCurrentCompanies()
                setCompanies(companies)
                setSelectedCompanyId(companies.length > 0 ? companies[0].id : null)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])

    const onAddUser = useCallback(() => {
        history.push("/unregistered-users/add/user/")
    }, [history])

    const onUploadExcel = useCallback(() => {
        history.push("/unregistered-users/upload/excel")
    }, [history])

    const onUserAttributesUploadExcel = useCallback(() => {
        history.push("/unregistered-users/user-attributes/upload/excel")
    }, [history])

    const onEventsUploadExcel = useCallback(() => {
        history.push("/unregistered-users/events/upload/excel")
    }, [history])

    const addNewUserEnabled = useMemo(() => {
        return companies.length > 0
    }, [companies])



    return {
        unregisteredUsers,
        onUnregisteredUserSelected,
        loading,
        companyItems,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        pagination,
        onAddUser,
        onUploadExcel,
        addNewUserEnabled,
        setFirstName,
        setLastName,
        onViewEvents,
        onUserAttributesUploadExcel,
        onEventsUploadExcel
    }
}