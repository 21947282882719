import "./client-credential.scss"
import { Form, Formik } from "formik"
import React, { useEffect, useRef } from "react"
import Shell from "../../../_common/layout/shell/shell"
import { useLocalization } from "../../../hook/use-localization"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { DropDownField } from "../../../_common/fields/dropdown/dropdown-field"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import { UserModel, useClientCredential, useClientCredentialForm } from "./useClient-credential"
import { CompanyDropDownField } from "../../../_common/fields/company-drop-down/company-dropdown-field"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"
import { Dialog, DialogHandle } from "../../../_common/common/dialog/dialog"
import { copyElementInnerText } from "../../../../common/form/copy"


export const ClientCredential: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteClientCredential, isAddClientCredential, createdClientCredential, onClientSecretCopiedByUser } = useClientCredential()
    const copySecretDialogRef = useRef<DialogHandle>(null);
    const confirmationDeleteRef = useRef<ConfirmationHandle>(null);


    useEffect(() => {
        if (createdClientCredential != null) {
            copySecretDialogRef.current?.show()
        }

    }, [createdClientCredential])


    return (
        <Shell pageTitle={isAddClientCredential ? t("ClientCredential.Add") : t("ClientCredential")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent isAdd={isAddClientCredential} />
                        </Form>
                    )}
                </Formik>

                {!isAddClientCredential && <DangerZone>
                    <div className="delete-title">{t("ClientCredential.Delete.Title")} </div>
                    <Button onClick={() => confirmationDeleteRef.current?.show()}>{t("Delete")}</Button>
                    <div className="delete-message">{t("ClientCredential.Delete.Description")} </div>
                    <Confirmation body={t("ClientCredential.Delete.Title")} ref={confirmationDeleteRef} onOkCallback={() => onDeleteClientCredential()} level="require-number-confirmation" />
                </DangerZone>}

                <Dialog body={<>
                    <div className="client-credential-created-body">
                        <div className="cli-title">{t("ClientCredential.Copy.Secret")}</div>
                        <div className="cli-body">
                            <div className="cli-body-secret" id="client-credential-created-secret">{createdClientCredential?.clientSecret}</div>
                            <img
                                onClick={() => copyElementInnerText("client-credential-created-secret")}
                                src="/copy.svg"
                                alt="Copy"
                                title="Copy"
                            ></img>
                        </div>
                    </div>
                </>} title={t("ClientCredential.Created")} ref={copySecretDialogRef} onOkCallback={() => onClientSecretCopiedByUser()} />

            </>
            }
        </Shell>
    )
}

interface FormContentProps {
    isAdd: boolean
}

const FormContent: React.FC<FormContentProps> = ({ isAdd }) => {
    const { t } = useLocalization();
    const { model, submitForm } = useTypedFormikContext<UserModel>()
    const { cancelEditForm, roles, saveClientCredentialEnabled, companyItems, companyDropDownEnabled } = useClientCredentialForm()
    const confirmationAddRef = useRef<ConfirmationHandle>(null);

    return (
        <div className="indent-container client-credential-form-content">
            <CompanyDropDownField disabled={!companyDropDownEnabled} name="companyId" label={t("Company")} items={companyItems} />
            {model.clientCredentialId != null && <TextInput name="clientCredentialId" disabled={model.readonly} label={t("ClientCredential.Id")} />}
            <TextInput name="name" disabled={model.readonly} label={t("Name")} />
            {model.clientSecret != null && <TextInput name="clientSecret" disabled={model.readonly} label={t("ClientCredential.Secret")} />}
            <DropDownField name="role" disabled={model.readonly} label={t("Role")} items={roles} />

            <div className="action-buttons-row">
                {saveClientCredentialEnabled && !model.readonly && <Button className="primary-button" onClick={() => confirmationAddRef.current?.show()}>{t("Save")}</Button>}
            </div>

            <Confirmation body={t("ClientCredential.Add")} ref={confirmationAddRef} onOkCallback={() => submitForm()} level="require-number-confirmation" />

        </div>)
}
