import "reflect-metadata"; 
import { Container } from 'inversify';
import { UserRepository } from "../repository/user/user/user-repository";
import { OAuthRepository } from "../repository/auth/oauth/oauth-repository";
import { IUserRepository, IUserRepositoryId } from "..//repository/user/user/i-user-repository"; 
import { IOAuthRepository, IOAuthRepositoryId } from "../repository/auth/oauth/i-oauth-repository";
import { UserRegistrationRepository} from "../repository/user/registration/user-registration-repository"
import { UserRecoverPasswordRepository} from "../repository/user/recover-password/user-recover-password-repository"
import { IUserRegistrationRepository, IUserRegistrationRepositoryId } from "../repository/user/registration/i-user-registration-repository"
import { IUserRecoverPasswordRepository, IUserRecoverPasswordRepositoryId} from "../repository/user/recover-password/i-user-recover-password-repository"


const registerDepedencies = (container:Container) => {

    //Repositories
    container.bind<IUserRepository>(IUserRepositoryId).to(UserRepository).inTransientScope();
    container.bind<IOAuthRepository>(IOAuthRepositoryId).to(OAuthRepository).inTransientScope();
    container.bind<IUserRegistrationRepository>(IUserRegistrationRepositoryId).to(UserRegistrationRepository).inTransientScope();
    container.bind<IUserRecoverPasswordRepository>(IUserRecoverPasswordRepositoryId).to(UserRecoverPasswordRepository).inTransientScope();
}

const container = new Container();

export { registerDepedencies,  container};