import { ContainsValidString } from "../../../validators/contains-valid-string";
import { IsMobilePhoneNumber } from "../../../validators/is-mobile-phone-number";
import { IsArray, IsDate, IsEmail, IsEnum, IsOptional, IsString, MaxLength, MinLength, ValidateIf } from "class-validator";
import { UserGender } from "../user/user/user-gender";
import { ContainsValidTimezone } from "../../../validators/contains-valid-timezone";
import { LambdaExpression } from "../../../validators/lambda-expression";

export class AddUnregisteredUserRequest {

    @ValidateIf(o => o.dateOfBirth != null)
    @IsOptional()
    @ContainsValidTimezone()
    timezone: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    firstName: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    lastName: string

    @IsOptional()
    @IsMobilePhoneNumber()
    mobilePhoneNumber: string | null;

    @IsOptional()
    @IsEnum(UserGender)
    gender: UserGender | null

    @IsOptional()
    @IsDate()
    dateOfBirth: Date | null

    @IsOptional()
    @IsEmail()
    email: string | null;

    @IsString()
    @ContainsValidString()
    createdByCompanyId: string;

    @IsOptional()
    @ContainsValidString()
    externalId: string | null;

    @LambdaExpression({
        expression: (obj: AddUnregisteredUserRequest) => {
            if (obj.dateOfBirth != null && obj.timezone == null) {
                return false
            }
            return true
        }
    }, { message: "TimeZoneRequiredWhenDateOfBirthSet" })
    readonly _meta?: boolean = true

    @IsOptional()
    @IsArray()
    @ContainsValidString({ each: true })
    askConsentIds: string[] | null
}