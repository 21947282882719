import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { ICompanyClient } from "./i-company-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class CompanyClient extends BaseClient implements ICompanyClient {

    async getCurrentCompanies(): Promise<dto.Company[]> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.company.base + apiPaths.company.current) });
            return response.data as dto.Company[];
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getCompanies(data: dto.GetCompaniesRequest): Promise<dto.PagingResponse<dto.Company>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.company.base + apiPaths.company.companies), params: data });
            return response.data as dto.PagingResponse<dto.Company>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getCompany(companyId: string): Promise<dto.Company> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.company.base + apiPaths.company.company.replace(":companyid", companyId)) });
            return response.data as dto.Company;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async addCompany(data: dto.AddCompanyRequest): Promise<dto.Company> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.company.base + apiPaths.company.companies), data: data });
            return response.data as dto.Company;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateCompany(companyId: string, data: dto.UpdateCompanyRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.company.base + apiPaths.company.company.replace(":companyid", companyId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteCompany(companyId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.company.base + apiPaths.company.company.replace(":companyid", companyId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}