import React, { ReactNode, useMemo } from "react";
import { combineClassNames } from "../../helpers/user-interface-helper";
import "./dropdown.scss";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { useCallback } from "react";

export interface DropdownItem {
  text: string;
  value: string | boolean | null;
  textNode?: ReactNode;
  key?: string
}

interface Props {
  label: string;
  className?: string;
  onSelect: (value: string | null) => void;
  selectedValue?: string | null;
  items: DropdownItem[];
  disabled?: boolean;
  hideLabel?: boolean
}
export const Dropdown: React.FC<Props> = (props) => {
  const {
    label,
    className,
    onSelect,
    selectedValue,
    items,
    disabled,
    hideLabel
  } = props;
  const selectedText = useMemo(() => {
    const text = items.find((x) => x.value === selectedValue)?.text;
    return text
  }, [selectedValue, items]);
  const _onSelect = useCallback(
    (e: any) => {
      onSelect(e! !== "" ? e! : null);
    },
    [onSelect]
  );
  //href={item.value != null ? `#/${item.value}` : ""}
  const dropDownItems = items.map((item: DropdownItem) => (
    <li key={item?.key ?? item.value?.toString()}>
      <BootstrapDropdown.Item
        eventKey={(item.value ?? undefined) as any}
        onClick={(e) => {
          _onSelect(item.value);
        }}
      >
        <>
          {item.textNode != null && item.textNode}
          {item.textNode == null && item.text}
        </>
      </BootstrapDropdown.Item>
    </li>
  ));

  const disabledObj: any = {};
  if (disabled) {
    disabledObj["disabled"] = true;
  }

  return (
    <>
      <div className={combineClassNames("dropdown_container", className)}>
        {hideLabel !== true && <div className="dropdown_container_label">{label}</div>}
        <BootstrapDropdown>
          <BootstrapDropdown.Toggle {...disabledObj}>
            {selectedText ?? "-"}
          </BootstrapDropdown.Toggle>
          <BootstrapDropdown.Menu>{dropDownItems}</BootstrapDropdown.Menu>
        </BootstrapDropdown>
      </div>
    </>
  );
};
