
export class UserAttribute {

    static sharedAttributes = {
        dateOfBirth: "dateOfBirth",
        gender: "gender"
    }

    static isSharedAttribute(name: string) {
        return name == UserAttribute.sharedAttributes.dateOfBirth || name == UserAttribute.sharedAttributes.gender;
    }

    static isDateOfBirthSharedAttribute(name: string) {
        return name == UserAttribute.sharedAttributes.dateOfBirth
    }

    static isGenderSharedAttribute(name: string) {
        return name == UserAttribute.sharedAttributes.gender
    }

    id: string
    name: string
    value: string
    description: string | null
    unregisteredUserId: string
    companyId: string
    externalId: string
    createdDate: Date
    updatedDate: Date
}