import { ValidationError } from "class-validator";

export class ClientValidationErrorItem {
    // property key like 'email'
    key: string | null;

    // message(generic) or constraint value("isEmail": "Email is not valid.",)
    message: string | null;

    // constraint("isEmail": "Email is not valid.",)
    constraint: string | null;

    private constructor(options: { key: string, message: string, constraint: string }) {
        this.key = options.key
        this.message = options.message
        this.constraint = options.constraint
    }

    static createFromValidationError(error: ValidationError, options?: { parentKey?: string }): ClientValidationErrorItem[] {
        const propName = options?.parentKey != null ? `${options!.parentKey!}.${error.property})` : error.property;
        let array: ClientValidationErrorItem[] = [];
        if (error.constraints != null) {
            Object.entries(error.constraints).forEach(([key, value]) => {
                array.push(new ClientValidationErrorItem({
                    key: propName,
                    message: value,
                    constraint: key
                }));
            });
        } else {
            array.push(new ClientValidationErrorItem({
                key: propName,
                message: "Unknown property error",
                constraint: "unknown"
            }));
        }
        if (error.children != null) {
            for (const child of error.children) {
                let nextOptions = null;
                if (options != null) {
                    nextOptions = { ...options, parentKey: `${options!.parentKey!}.${error.property})` }
                }
                ClientValidationErrorItem.createFromValidationError(child, nextOptions).forEach(x => array.push(x));
            }
        }
        return ClientValidationErrorItem.sortClientErrorItem(array);
    }

    //validation message by class-validator are not sorted
    //this puts isNotEmpty error on the top of validation error, so it can be presented as first error on UI
    static sortClientErrorItem(array: ClientValidationErrorItem[]) {
        let resultArray = array;
        resultArray = ClientValidationErrorItem.putOnFirstPlace(resultArray, "ContainsValidStringConstraint")
        resultArray = ClientValidationErrorItem.putOnFirstPlace(resultArray, "isNotEmpty")
        return resultArray;
    }

    private static putOnFirstPlace(array: ClientValidationErrorItem[], name: string): ClientValidationErrorItem[] {
        const isNotEmpty = array.filter(x => x.constraint == name);
        const filterArray = array.filter(x => x.constraint != name);
        return isNotEmpty.concat(filterArray);
    }



}