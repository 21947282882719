import React from "react";
import { SharedDataHelper } from "shared";
import useIsMobile from "../../../hook/use-is-mobile";
import { Button } from "../../../_common/common/button/Button";
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown";
import { Loading } from "../../../_common/components/loading/loading";
import { NoEntities } from "../../../_common/components/no-entities/no-entities";
import { Pagination } from "../../../_common/components/pagination/pagination";
import Shell from "../../../_common/layout/shell/shell";
import "./event-types.scss";
import { useEventTypes } from "./useEventTypes";
import { useLocalization } from "../../../hook/use-localization";

export const EventTypes: React.FC = () => {
  const { t } = useLocalization()
  const isMobile = useIsMobile();
  const {
    eventTypes,
    companyItems,
    selectedCompanyId,
    onEventTypeSelected,
    onCompanySelected,
    loading,
    pagination,
    onAddEventType,
    addNewEventTypeEnabled
  } = useEventTypes();

  const rowClick = (userid: string) => {
    if (isMobile) {
      onEventTypeSelected(userid);
    }
  };

  const listItems = eventTypes.map((eventType) => (
    <div
      className="event-types__item data-row-item"
      key={eventType.id}
      onClick={() => rowClick(eventType.id)}
    >
      <div className="event-types__item-info">
        <div className="event-types__item-name">
          <button
            onClick={() => {
              onEventTypeSelected(eventType.id);
            }}
          >
            {eventType.name}
          </button>
        </div>
        <div className="event-types__item-description">
          {eventType.description}
        </div>

      </div>
      <div className="event-types__item-dates">
      {t("Created")} {SharedDataHelper.getFormattedDate(eventType.updatedDate ?? eventType.createdDate, "dd.MM.yyyy")}
      </div>
    </div>
  ));
  return (
    <Shell
      pageTitle={t("EventTypes")}
      mobileHeaderContent={
        <button
          disabled={!addNewEventTypeEnabled}
          className="mobile-add-new"
          onClick={onAddEventType}
        >
          {t("Add")}
        </button>
      }
      desktopHeaderContent={
        <Button disabled={!addNewEventTypeEnabled} onClick={onAddEventType}>
          {t("AddEventType")}
        </Button>
      }
    >
      <Loading loading={loading} />
      <div className="indent-container event-types-header-filter">
        <div className="event-types-filter">
          <div className="company-container">
            <CompanyDropdown
              items={companyItems}
              selectedCompanyId={selectedCompanyId}
              onSelect={onCompanySelected}
            />
          </div>

        </div>
        <Pagination {...pagination} />
      </div>
      {listItems}
      {eventTypes.length === 0 && <NoEntities />}
    </Shell>
  );
};
