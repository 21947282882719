import { Form, Formik } from "formik"
import React, { useCallback, useMemo } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { ButtonSubmit } from "../../../_common/common/button/ButtonSubmit"
import { DangerZone } from "../../../_common/components/danger-zone/danger-zone"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./user-attribute.scss"
import { dto } from "shared"
import { UserAttributeModel, useUserAttribute, useUserAttributeForm } from "./useUserAttribute"
import { UserAttributeNameDropdownField } from "../../../_common/fields/user-attribute-name-dropdown/user-attribute-name-dropdown-field"
import { UserAttributeValueField } from "../../../_common/fields/user-attribute-value/user-attribute-value-field"
import { useLocalization } from "../../../hook/use-localization"
import { useSharedAttribute } from "../../../hook/use-shared-attribute"


export const UserAttribute: React.FC = () => {
    const { t } = useLocalization()
    const { modelInitialValues, onSubmit, onValidate, loading, onDeleteUserAttribute, isAddingNewUserAttribute, isAllowedToDeleteAttribute, isAllowedToEditAttribute, onViewAllUserAttributes, onAddNewUserAttribute, isSharedUserAttribute } = useUserAttribute()

    return (
        <Shell pageTitle={isAddingNewUserAttribute ? t("AddNewAttribute") : t("ContactAttribute")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues} onSubmit={onSubmit}
                    validateOnBlur={true}
                    validate={onValidate}>
                    {() => (
                        <Form>
                            <FormContent isAdd={isAddingNewUserAttribute} allowEdit={isAllowedToEditAttribute} />
                        </Form>
                    )}
                </Formik>

                {!isSharedUserAttribute && !isAddingNewUserAttribute && isAllowedToDeleteAttribute && <DangerZone>
                    <div className="delete-title">{t("DeleteContactAttribute")} </div>
                    <Button onClick={onDeleteUserAttribute}>{t("Delete")}</Button>
                    <div className="delete-message">{t("DeleteContactAttribute")}</div>
                </DangerZone>}

                {modelInitialValues?.userAttributeId != null && <div className="alert alert-secondary zone-container indent-container" role="alert">
                    <Button onClick={onAddNewUserAttribute}>{t("AddNewAttribute")}</Button>
                    <div>{t("AddContactAttribute")}</div>
                </div>}

                <div className="alert alert-secondary zone-container indent-container" role="alert">
                    <Button onClick={onViewAllUserAttributes}>{t("ViewAllAttributes")}</Button>
                    <div>{t("ViewAllAttributes")}</div>
                </div>
            </>
            }
        </Shell>
    )
}

interface FormContentProps {
    isAdd: boolean,
    allowEdit: boolean
}

const FormContent: React.FC<FormContentProps> = ({ isAdd, allowEdit }) => {
    const { t } = useLocalization()
    const { model } = useTypedFormikContext<UserAttributeModel>()
    const { editForm, cancelEditForm, userAttributeNamesDropDownEnabled } = useUserAttributeForm()
    const { transformSharedAttrValue } = useSharedAttribute()

    const transformSharedAttributeValue = useCallback((value: string) => {
        return transformSharedAttrValue(model.name, value)
    }, [model?.name, transformSharedAttrValue])

    return (
        <div className="indent-container">
            <TextInput name="unregisteredUserName" disabled={true} label={t("ContactName")} />
            {dto.UserAttribute.isSharedAttribute(model.name) &&
                <>
                    <TextInput translateValue={true} name="name" disabled={true} label={t("Name")} />
                    <TextInput transformValue={transformSharedAttributeValue} name="value" disabled={true} label={t("Value")} />
                </>}
            {!dto.UserAttribute.isSharedAttribute(model.name) &&

                <>
                    <UserAttributeNameDropdownField allowSharedAttributes={false} companyId={model.companyId} name="name" disabled={model.readonly || !userAttributeNamesDropDownEnabled} className="field-margin-top" />
                    {model.userAttributeName != null &&
                        <>
                            <UserAttributeValueField name="value" disabled={model.readonly} label={t("Value")} userAttributeName={model.userAttributeName} />
                            <TextInput name="description" disabled={model.readonly} label={t("Description")} />
                            <TextInput name="externalId" disabled={model.readonly} label={t("ExternalId")} />
                        </>
                    }
                </>}

            <div className="action-buttons-row">
                {model.readonly && allowEdit && <Button onClick={editForm}>{t("Edit")}</Button>}
                {!isAdd && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
                {!model.readonly && <ButtonSubmit>{t("Save")}</ButtonSubmit>}
            </div>
        </div>)
}
