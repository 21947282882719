import React, { useCallback } from "react"
import { SharedDataHelper, dto } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { Button } from "../../../_common/common/button/Button"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import { useNotificationTasks } from "./useNotificationTasks"
import "./notification-tasks.scss"
import { Dropdown } from "../../../_common/common/dropdown/DropDown"
import { NotificationTaskStatusIcon } from "../../../_common/components/notification-status/notification-task-status-icon"
import { useLocalization } from "../../../hook/use-localization"


export const NotificationTasks: React.FC = () => {
	const { t } = useLocalization();
	const isMobile = useIsMobile()
	const { notificationTasks, companyItems, selectedCompanyId, onNotificationTaskSelected, onCompanySelected, loading, pagination, onAddNotificationTasks, addNewNotificationTaskEnabled, addNewNotificationTaskVisible, getNotificationTaskAddress, getUserName, getNotificationTaskType, batchItems, onBatchSelected, selectedBatchId, getBatchName } = useNotificationTasks()

	const rowClick = (userid: string) => {
		if (isMobile) {
			onNotificationTaskSelected(userid)
		}
	}

	const getBatchNameAndType = useCallback((notificationTask: dto.NotificationTask) => {
		let type = getNotificationTaskType(notificationTask)
		if (SharedDataHelper.stringNotNullEmpty(notificationTask.batchId)) {
			type = `${type}: ${getBatchName(notificationTask.batchId)}`
		}
		return type
	}, [getBatchName, getNotificationTaskType])

	const getAddressOrTaskIdentifier = useCallback((notificationTask: dto.NotificationTask): string => {
		const address = getNotificationTaskAddress(notificationTask)
		if (SharedDataHelper.stringIsNullTrimEmpty(address)) {
			return notificationTask.id.substring(0, 20) + "..."
		}
		return address
	}, [getNotificationTaskAddress])

	const listItems = notificationTasks.map((notificationTask) =>
		<div className="notification-tasks__item data-row-item" key={notificationTask.id} onClick={() => rowClick(notificationTask.id)}>
			<div className="notification-tasks__item-address-username">
				<div className="notification-tasks__item-address"><button className="list-item-link-button" onClick={() => { onNotificationTaskSelected(notificationTask.id) }}>{getAddressOrTaskIdentifier(notificationTask)}</button></div>
				<div className="notification-tasks__item-username">{getUserName(notificationTask)}</div>
			</div>
			<div className="notification-tasks__item-status-task-date-type">
				<div className="notification-tasks__item-status"><NotificationTaskStatusIcon status={notificationTask.status} />
					<span className="notification-tasks__item-status-text">{t(`Notification.Status.${SharedDataHelper.firstLetterUppercase(notificationTask.status)}`)}</span> </div>
				<div className="notification-tasks__item-type">{getBatchNameAndType(notificationTask)}</div>
				<div className="notification-tasks__item-task-date">{t("Date.Of.Send")}:{SharedDataHelper.getFormattedDate(notificationTask.taskDate, "dd.MM.yyyy HH:mm")}</div>
			</div>
		</div>
	);
	return (
		<Shell pageTitle={t("Notifications")}
			mobileHeaderContent={addNewNotificationTaskVisible === true && <button disabled={!addNewNotificationTaskEnabled} className="mobile-add-new" onClick={onAddNotificationTasks}>{t('Add')}</button>}
			desktopHeaderContent={addNewNotificationTaskVisible === true && <Button disabled={!addNewNotificationTaskEnabled} onClick={onAddNotificationTasks}>{t("SendNotification")}</Button>}>

			<Loading loading={loading} />
			<div className="indent-container notification-tasks-header-filter">
				<div className="notification-tasks-header-filter-comp-batch">
					<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
					<Dropdown label={t("Batch")} selectedValue={selectedBatchId} items={batchItems} onSelect={onBatchSelected} />
				</div>
				<Pagination {...pagination} />
			</div>
			{listItems}
			{notificationTasks.length === 0 && <NoEntities />}
		</Shell >
	)
}