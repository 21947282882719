import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { BaseNetworkRepository } from "../../base/base-network-repository"
import { IUserRecoverPasswordRepository } from "./i-user-recover-password-repository"

@injectable()
export class UserRecoverPasswordRepository extends BaseNetworkRepository implements IUserRecoverPasswordRepository {
    
    async recover(request: dto.UserRecoverPasswordRecoverRequest): Promise<dto.StatusResponse> {
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.recoverPassword.recover , data: request });
        return response.data as dto.StatusResponse;
    }

    async challengeToken(request: dto.UserRecoverPasswordChallengeTokenRequest): Promise<dto.StatusResponse> {
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.recoverPassword.challengeToken , data: request });
        return response.data as dto.StatusResponse;
    } 

    async complete(request:dto.UserRecoverPasswordCompleteRequest) : Promise<dto.StatusResponse>{
        const response = await this.publicAxios({ method: 'post', url: apiPaths.user.recoverPassword.complete , data: request });
        return response.data as dto.StatusResponse;
    }
}