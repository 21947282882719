export enum UserAttributeNameType {
    exists = 'exists',
    select = 'select',
    date = 'date',
    text = 'text',
    number = "number"
}

export class UserAttributeName {
    id: string
    name: string
    description: string | null
    attributeType: UserAttributeNameType
    selectValues: string[] | null
    companyId: string
    createdDate: Date
    updatedDate: Date
}

export const CustomUserAttributeNamePrefix = "CA-"