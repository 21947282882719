import { IsDefined, IsEnum, ValidateNested } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";

export enum DiseaseCodebookQueryItemType {
    equal = "equal",
    from = "from",
    to = "to",
    fromTo = "fromTo"
}

export class DiseaseCodebookQueryItem {

    @IsEnum(DiseaseCodebookQueryItemType)
    type: DiseaseCodebookQueryItemType

    @ContainsValidString()
    value: string

    constructor(options: { type: DiseaseCodebookQueryItemType, value: string }) {
        this.type = options.type
        this.value = options.value
    }
}

export class DiseaseCodebookFilter {
    // one of items must be satisfied
    // represent logical OR operator
    @IsDefined()
    @ValidateNested({ each: true })
    items: DiseaseCodebookQueryItem[]
}



