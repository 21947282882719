import React from "react"
import { SharedDataHelper } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { Button } from "../../../_common/common/button/Button"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import { useNotificationTemplates } from "./useNotificationTemplates"
import "./notification-templates.scss"
import { useLocalization } from "../../../hook/use-localization"


export const NotificationTemplates: React.FC = () => {
	const { t } = useLocalization()
	const isMobile = useIsMobile()
	const { notificationTemplates, companyItems, selectedCompanyId, onNotificationTemplateSelected, onCompanySelected, loading, pagination, onAddTemplate, addNewUserEnabled } = useNotificationTemplates()

	const rowClick = (userid: string) => {
		if (isMobile) {
			onNotificationTemplateSelected(userid)
		}
	}

	const listItems = notificationTemplates.map((notificationTemplate) =>
		<div className="notification-templates__item data-row-item" key={notificationTemplate.id} onClick={() => rowClick(notificationTemplate.id)}>
			<div className="notification-templates__item-name-comp-name">
				<div className="notification-templates__item-name"><button className="list-item-link-button" onClick={() => { onNotificationTemplateSelected(notificationTemplate.id) }}>{notificationTemplate.name}</button></div>
				<div className="notification-templates__item-company-name">{notificationTemplate.company.name}</div>
			</div>
			<div className="notification-templates__item-updated-at">{SharedDataHelper.getFormattedDate(notificationTemplate.updatedDate ?? notificationTemplate.createdDate, "dd.MM.yyyy")}</div>
		</div>
	);
	return (
		<Shell pageTitle={t("Templates")}
			mobileHeaderContent={<button disabled={!addNewUserEnabled} className="mobile-add-new" onClick={onAddTemplate}>Add</button>}
			desktopHeaderContent={<Button disabled={!addNewUserEnabled} onClick={onAddTemplate}>{t("AddTemplate")}</Button>}>

			<Loading loading={loading} />
			<div className="indent-container notification-templates-header-filter">
				<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
				<Pagination {...pagination} />

			</div>
			{listItems}
			{notificationTemplates.length === 0 && <NoEntities />}
		</Shell >
	)
}