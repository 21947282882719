import './index.css';
import "reflect-metadata"
import React from 'react';
import App from './ui/_app/App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import "./locales/i18next"
import { BaseClient } from 'shared-client';

console.log(`REACT_APP_WS_URL: ${process.env.REACT_APP_WS_URL}`);
console.log(`REACT_APP_APP_TYPE: ${process.env.REACT_APP_APP_TYPE}`);
console.log(`REACT_APP_EXTERNAL_API_URL: ${process.env.REACT_APP_EXTERNAL_API_URL}`);
console.log(`REACT_APP_USE_API_EXTERNAL_URL: ${process.env.REACT_APP_USE_API_EXTERNAL_URL}`);


console.log(`NODE_ENV: ${process.env.NODE_ENV}`);

if (process.env.NODE_ENV === "production"){
    const _baseNetworkUrl = process.env.REACT_APP_USE_API_EXTERNAL_URL == "true" ? process.env.REACT_APP_EXTERNAL_API_URL!: process.env.REACT_APP_DOCKER_API_URL!;
    console.log("baseNetworkUrl:" + _baseNetworkUrl!)
    BaseClient.baseNetworkUrl = _baseNetworkUrl!
}

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
} else {
  console.error('Root element not found!');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



