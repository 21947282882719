import React from "react"
import { SharedDataHelper } from "shared"
import useIsMobile from "../../../hook/use-is-mobile"
import { Button } from "../../../_common/common/button/Button"
import { CompanyDropdown } from "../../../_common/components/company-dropdown/company-dropdown"
import { Loading } from "../../../_common/components/loading/loading"
import { NoEntities } from "../../../_common/components/no-entities/no-entities"
import { Pagination } from "../../../_common/components/pagination/pagination"
import Shell from "../../../_common/layout/shell/shell"
import "./user-invitations.scss"
import { useUserInvitations } from "./useUserInvitations"
import { useLocalization } from "../../../hook/use-localization"


export const UserInvitations: React.FC = () => {
	const { t } = useLocalization();
	const isMobile = useIsMobile()
	const { userInvitations, companyItems, selectedCompanyId, onUserInvitationSelected, onCompanySelected, loading, pagination, onAddUserInvitation, addNewInvitationEnabled } = useUserInvitations()

	const rowClick = (userid: string) => {
		if (isMobile) {
			onUserInvitationSelected(userid)
		}
	}

	const listItems = userInvitations.map((userInvitation) =>
		<div className="user-invitations__item data-row-item" key={userInvitation.id} onClick={() => rowClick(userInvitation.id)}>
			<div className="user-invitations__item-name-email">
				<div className="user-invitations__item-name"><button onClick={() => { onUserInvitationSelected(userInvitation.id) }}>{userInvitation.user.firstName} {userInvitation.user.lastName}</button></div>
				<div className="user-invitations__item-email">{userInvitation.user.email}</div>
			</div>
			<div className="user-invitations__item-status-company">
				<div className="user-invitations__item-status">{t("UserStatus." + SharedDataHelper.ensureFirstLetterUppercase(userInvitation.statusName))}</div>
				<div className="user-invitations__item-company">{userInvitation.company.name}</div>
			</div>
		</div>
	);
	return (
		<Shell pageTitle={t("UserInvitations")}
			mobileHeaderContent={<button disabled={!addNewInvitationEnabled} className="mobile-add-new" onClick={onAddUserInvitation}>{t("Add")}</button>}
			desktopHeaderContent={<Button disabled={!addNewInvitationEnabled} onClick={onAddUserInvitation}>{t("AddNewUserInvite")}</Button>}>
			<Loading loading={loading} />
			<div className="indent-container user-invitations-header-filter">
				<CompanyDropdown items={companyItems} selectedCompanyId={selectedCompanyId} onSelect={onCompanySelected} />
				<Pagination {...pagination} />
			</div>
			{listItems}
			{userInvitations.length === 0 && <NoEntities />}
		</Shell >
	)
}