import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import "./dialog.scss"
import { useLocalization } from "../../../hook/use-localization";


export interface Props {
    contextIdentifier?: string,
    title?: string,
    body: string | React.ReactNode,
    okText?: string,
    onOkCallback: (contextIdentifier: string | null) => void
}

export interface DialogHandle {
    show: (ctx?: any) => void;
    hide: () => void;
}

export const Dialog = forwardRef<DialogHandle, Props>((props, ref) => {
    const { t } = useLocalization()
    const [isOpen, setIsOpen] = useState(false);
    const { body, contextIdentifier, onOkCallback } = props;
    const okText = props.okText ?? t("Close");
    const titleText = props.title ?? t("Dialog")
    const [ctx, setCtx] = useState<any>(null)

    useImperativeHandle(ref, () => ({
        show: (ctx?: any) => {
            setIsOpen(true)
            setCtx(ctx)
        },
        hide: () => setIsOpen(false),
    }));

    const handleOK = useCallback(() => {
        setIsOpen(false);
        onOkCallback(ctx ?? contextIdentifier ?? null);
    }, [onOkCallback, contextIdentifier, ctx])



    return (
        <>
            {isOpen &&

                <div className="dialog-cst-modal-dialog-wrapper">
                    <div className="dialog-cst-modal-dialog-bg"></div>
                    <div className="dialog-cst-modal">
                        <Modal.Header closeButton onHide={handleOK}>
                            <Modal.Title>{titleText}</Modal.Title>
                        </Modal.Header>
                        <div className="separator"></div>
                        <Modal.Body>
                            <div>{body}</div>

                        </Modal.Body>
                        <div className="separator"></div>

                        <Modal.Footer>
                            <Button variant="primary" className="action-button" onClick={handleOK}>{okText}</Button>
                        </Modal.Footer>
                    </div>
                </div>

            }
        </>
    );
})
