import { IsOptional, IsString } from "class-validator";
import { ContainsValidString } from "../../../../../validators/contains-valid-string";
import { NotificationTemplateDetails } from "../notification-template-details";
import { ContainsEmailUnsubscribeMarkup } from "../../../../../validators/contains-email-unsubscribe-markup";

export class EmailNotificationTemplateDetails extends NotificationTemplateDetails {

    @ContainsValidString({ message: "Subject is not valid" })
    subject: string

    @ContainsValidString({ message: "Body template is not valid" })
    bodyTemplate: string

    @IsOptional()
    @IsString()
    design: string | null

    templateType?: string

    constructor(options: { subject: string, bodyTemplate: string, design: string | null }) {
        super()
        if (options == null) {
            return
        }
        this.subject = options.subject
        this.bodyTemplate = options.bodyTemplate
        this.design = options.design
        this.templateType = "email"
    }
}