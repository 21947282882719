import { User } from "../user/user";
import { Company } from "../../company/company";
import { UserInvitationStatusName } from "./user-invitation-status";

export class UserInvitation {
    id: string
    user: User
    userId: string
    company: Company
    companyId: string
    statusName: UserInvitationStatusName
    expirationDate: Date
    createdDate: Date
    updatedDate: Date
}