import { SortItem } from "../../common/query/sort-item";
import { JoinOperator } from "../../common/query/join-operator";
import { PagingRequest } from "../../common/paging/paging-request";
import { AreValidSortItems } from '../../../../validators/are-valid-sort-items';
import { IsDefined, IsEmail, IsEnum, IsOptional, IsString, ValidateNested } from "class-validator";
import { IsUndefinedOrJoinOperator, IsUndefinedOrHaveValue, ContainsValidString } from "../../../../validators/_index";



export class GetUsersRequest {
    @IsOptional()
    @IsString()
    firstName?: string;

    @IsOptional()
    @IsString()
    lastName?: string;

    @IsOptional()
    @ContainsValidString()
    companyId?: string

    @IsOptional()
    email?: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["lastName", "firstName", "email", "createdDate"] })
    sortBy: SortItem[]
}


