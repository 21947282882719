import { ContainsValidString } from "../../../validators/contains-valid-string";
import { IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { IsNotSharedUserAttribute } from "../../../validators/is-not-shared-user-attribute";

export class AddUserAttributeRequest {
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(200)
    @IsNotSharedUserAttribute()
    name: string

    @ContainsValidString()
    @MinLength(1)
    @MaxLength(1000)
    value: string

    @IsOptional()
    @IsString()
    @MaxLength(100000)
    description: string | null

    @ContainsValidString()
    unregisteredUserId: string;

    @IsOptional()
    @ContainsValidString()
    externalId: string | null

}