import React from "react";
import { combineClassNames } from "../../helpers/user-interface-helper";
import "./button.scss"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}
export const Button: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, children, ...buttonProps } = props
  return (
    <button type="button" className={combineClassNames("btn btn-primary button-submit button-common", className)} {...buttonProps}>
      {children}
    </button>
  );
}