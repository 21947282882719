import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUserConsentHistoryClient, IUserConsentHistoryClientId } from "shared-client"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { usePagination } from "../../../hook/use-pagination"
import useClient from "../../../hook/useClient"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { useAppContext } from "../../../hook/use-app-context"

const pageSize = 20

export const useUserConsentHistories = () => {
    const history = useHistory()
    const { setGlobalMessage } = useGlobalMessageContext()
    const [loading, setLoading] = useState(false)
    const { userRoles } = useAppContext()
    const userConsentHistoryClient = useClient<IUserConsentHistoryClient>(IUserConsentHistoryClientId)
    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const [userConsentHistories, setUserConsentHistories] = useState<dto.UserConsentHistory[]>([])
    const [companies, setCompanies] = useState<dto.Company[]>([])
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
    const [firstName, setFirstName] = useState<string | null>(null)
    const [lastName, setLastName] = useState<string | null>(null)
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)


    const onPageRequest = (pageIndex: number) => {
        loadForm({ pageIndex, selectedCompanyId, firstName, lastName, email, mobilePhoneNumber })
        setPageIndex(pageIndex)
    }

    const { pageIndex, pagination, setTotalNumberOfPages, setPageIndex } = usePagination({ onPageRequest })

    const onUserConsentHistorySelected = useCallback((id: string) => {
        history.push("/user-consent-history/" + id)
    }, [history])




    const loadForm = useCallback(async (options: { pageIndex: number, selectedCompanyId: string | null, firstName: string | null, lastName: string | null, email: string | null, mobilePhoneNumber: string | null }) => {
        try {
            const { pageIndex, selectedCompanyId, firstName, lastName, email, mobilePhoneNumber } = options
            if (selectedCompanyId == null) {
                setUserConsentHistories([])
                return
            }
            setLoading(true)
            setUserConsentHistories([])
            const request = new dto.GetUserConsentHistoriesRequest()
            request.paging = new dto.PagingRequest({ pageIndex, pageSize })
            request.sortBy = []
            request.companyId = selectedCompanyId !== "-" ? selectedCompanyId : null
            request.firstName = SharedDataHelper.stringNotNullTrimEmpty(firstName) ? firstName!.trim() : null
            request.lastName = SharedDataHelper.stringNotNullTrimEmpty(lastName) ? lastName!.trim() : null
            request.email = SharedDataHelper.stringNotNullTrimEmpty(email) ? email!.trim() : null
            request.mobilePhoneNumber = SharedDataHelper.stringNotNullTrimEmpty(mobilePhoneNumber) ? mobilePhoneNumber!.trim() : null
            request.sortBy.push(new dto.SortItem({ name: "createdDate", type: "desc" }))
            const pagedResponse = (await userConsentHistoryClient.getUserConsentHistories(request))
            setUserConsentHistories(pagedResponse.entities)
            setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages)

        } catch (err) {
            handleError(err, { setGlobalMessage: setGlobalMessage })
        } finally {
            setLoading(false)
        }
    }, [setGlobalMessage, setTotalNumberOfPages, userConsentHistoryClient])

    useEffect(() => {
        (async () => {
            await loadForm({ pageIndex, selectedCompanyId, firstName, lastName, mobilePhoneNumber, email })
        })()
    }, [selectedCompanyId]);

    useEffect(() => {
        let _pageIndex = pageIndex
        if (firstName != null || lastName != null) {
            _pageIndex = 0
            setPageIndex(_pageIndex)
        }
        loadForm({ pageIndex: _pageIndex, selectedCompanyId, firstName, lastName, email, mobilePhoneNumber });
    }, [firstName, lastName, email, mobilePhoneNumber])

    // Load companies
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                let companies = await companyClient.getCurrentCompanies()
                if (userRoles.isAdmin) {
                    const nonCompany = new dto.Company();
                    nonCompany.id = "-"
                    nonCompany.name = "-"
                    companies = [nonCompany].concat(companies)
                }
                setCompanies(companies)
                setSelectedCompanyId(companies.length > 0 ? companies[0].id : null)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])

    return {
        userConsentHistories,
        onUserConsentHistorySelected,
        loading,
        companyItems,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        pagination,
        setFirstName,
        setLastName,
        setEmail,
        setMobilePhoneNumber

    }
}