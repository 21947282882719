import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { dto } from "shared";
import {
    IUnregisteredUserClient,
    IUnregisteredUserClientId,
    IUserAttributeClient,
    IUserAttributeClientId,
    IUserAttributeNameClient,
    IUserAttributeNameClientId,
} from "shared-client";
import { handleError } from "../../../../common/form/form";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { usePagination } from "../../../hook/use-pagination";
import useClient from "../../../hook/useClient";

const pageSize = 20;

export const useUserAttributes = () => {
    const history = useHistory();
    let { unregistereduserid: unregisteredUserId } = useParams<{ unregistereduserid: string }>();
    const { setGlobalMessage } = useGlobalMessageContext();
    const [loading, setLoading] = useState(false);
    const userAttributeClient = useClient<IUserAttributeClient>(IUserAttributeClientId);
    const unregisteredUserClient = useClient<IUnregisteredUserClient>(IUnregisteredUserClientId);
    const [userAttributes, setUserAttributes] = useState<dto.UserAttribute[]>([]);
    const { pageIndex, pagination, setTotalNumberOfPages } = usePagination();
    const [unregisteredUser, setUnregisteredUser] = useState<dto.UnregisteredUser | null>(null);
    const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId)
    const [userAttributeNames, setUserAttributeNames] = useState<dto.UserAttributeName[]>([]);

    useEffect(() => {
        (async () => {
            if (unregisteredUser?.createdByCompanyId == null) {
                setUserAttributeNames([]);
                return
            }
            const pagedResponse = await userAttributeNameClient.getUserAttributeNames({
                paging: dto.PagingRequest.createMaxPaging(),
                sortBy: [new dto.SortItem({ name: "name" })],
                companyId: unregisteredUser?.createdByCompanyId,
            });

            setUserAttributeNames(pagedResponse.entities)
        })()
    }, [userAttributeNameClient, unregisteredUser?.createdByCompanyId]);


    const onUserAttributeSelected = useCallback(
        (id: string) => {
            history.push(`/unregistered-users/${unregisteredUserId}/user-attributes/${id}`);
        },
        [history, unregisteredUserId]
    );

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setUserAttributes([]);
                const unregisteredUser = await unregisteredUserClient.getUser(unregisteredUserId);
                setUnregisteredUser(unregisteredUser);
                const request = new dto.GetUserAttributesRequest();
                request.unregisteredUserId = unregisteredUserId;
                request.paging = new dto.PagingRequest({ pageIndex, pageSize });
                request.sortBy = [];
                request.sortBy.push(
                    new dto.SortItem({ name: "createdDate", type: "desc" })
                );
                const pagedResponse = await userAttributeClient.getUserAttributes(request);
                setUserAttributes(pagedResponse.entities);
                setTotalNumberOfPages(pagedResponse.paging.totalNumberOfPages);
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage });
            } finally {
                setLoading(false);
            }
        })();
    }, [pageIndex, unregisteredUserId, userAttributeClient]);

    const onAddUserAttribute = useCallback(() => {
        history.push(`/unregistered-users/${unregisteredUserId}/user-attributes/add/user-attribute`);
    }, [history, unregisteredUserId]);

    const onViewUser = useCallback(() => {
        history.push(`/unregistered-users/${unregisteredUserId}`);

    }, [history, unregisteredUserId])



    return {
        userAttributes,
        onUserAttributeSelected,
        loading,
        pagination,
        onAddUserAttribute,
        unregisteredUser,
        onViewUser,
        userAttributeNames,
    };
};
