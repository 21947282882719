import { IsEmail, IsNotEmpty, IsString } from "class-validator";
import { Match } from "../../../../validators/match";
import { ContainsValidString } from "../../../../validators/contains-valid-string";


export class UserRecoverPasswordCompleteRequest {
    @ContainsValidString()
    @IsEmail()
    email:string;

    @ContainsValidString()
    clientId: string;

    @ContainsValidString()
    token: string; 
 
    @ContainsValidString()
    password: string;

    @ContainsValidString()
    @Match('password', { message: 'Password and confirm password are not equal.' })
    confirmPassword: string;
}