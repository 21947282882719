import React, { useEffect, useState } from "react"
import "./ws-connection-info.scss"
import { useWebSocketContext } from "../../../hook/use-websocket"

interface Props {
	className?: string
}

export const WebSocketConnectionInfo: React.FC<Props> = ({ className }) => {
	const { isConnected } = useWebSocketContext();
	const [connectingNum, setConnectingNum] = useState(0)
	useEffect(() => {
		if (isConnected ?? false) {
			setConnectingNum(0)
			return
		}

		const intervalId = setTimeout(() => {
			let num = connectingNum + 1
			if (num > 4) {
				num = 0
			}
			setConnectingNum(num)
		}, 1000)
		return () => {
			if (intervalId != null) {
				clearTimeout(intervalId)
			}
		}
	}, [isConnected, connectingNum])

	return (
		<div className={`ws-connection-info__container ${className} ${isConnected ? "stable" : "connecting"}`}>
			{isConnected && <img src="/stable-connection.svg" alt="" />}
			{!isConnected && <img src={`/ws-connecting-${connectingNum}.svg`} alt="" />}
			{isConnected ? 'Stable connection' : 'Connecting...'}
		</div>
	)
}