import { useCallback, useEffect, useMemo, useState } from "react"
import { SharedDataHelper, dto } from "shared"
import { ICompanyClient, ICompanyClientId, IUserAttributeNameClient, IUserAttributeNameClientId } from "shared-client"
import useClient from "../../../hook/useClient"
import { handleError } from "../../../../common/form/form"
import { useGlobalMessageContext } from "../../../hook/use-global-message-context"
import { CompanyDropdownItem, createCompanyDropDownItems } from "../../../_common/components/company-dropdown/company-dropdown"
import { useShellContext } from "../../../hook/use-shell-context"



export const useAnalyticsContainer = () => {
    const { setGlobalMessage } = useGlobalMessageContext()
    const { scrollContentToBottom } = useShellContext()
    const [loading, setLoading] = useState(false)

    const companyClient = useClient<ICompanyClient>(ICompanyClientId)
    const userAttributeNameClient = useClient<IUserAttributeNameClient>(IUserAttributeNameClientId);
    const [userAttributeNames, setUserAttributeNames] = useState<dto.UserAttributeName[]>([])

    const [companies, setCompanies] = useState<dto.Company[]>([])
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
    const [analytics, setAnalytics] = useState<string[]>([SharedDataHelper.randomString(20)])

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const companies = await companyClient.getCurrentCompanies()
                setCompanies(companies)
                setSelectedCompanyId(companies.length > 0 ? companies[0].id : null)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setUserAttributeNames([]);

            if (selectedCompanyId == null) {
                return
            }

            const attrRequest = new dto.GetUserAttributeNamesRequest();
            attrRequest.companyId = selectedCompanyId;
            attrRequest.paging = dto.PagingRequest.createMaxPaging();
            attrRequest.sortBy = [];
            attrRequest.sortBy.push(
                new dto.SortItem({ name: "createdDate", type: "desc" })
            );
            const pagedAttrResponse = await userAttributeNameClient.getUserAttributeNames(attrRequest);
            setUserAttributeNames(pagedAttrResponse.entities);
        })();
    }, [selectedCompanyId])

    const companyItems = useMemo((): CompanyDropdownItem[] => {
        return createCompanyDropDownItems(companies)
    }, [companies])

    const onAnalyticsAdded = useCallback(() => {
        const array = analytics.concat([SharedDataHelper.randomString(20)])
        setAnalytics(array)
        window.setTimeout(() => {
            scrollContentToBottom()
        }, 200)
    }, [analytics, scrollContentToBottom])

    const onDeleteAnalytics = useCallback((id: string) => {
        setAnalytics(analytics.filter(x => x !== id))
    }, [analytics])

    return {
        loading,
        companyItems,
        analytics,
        selectedCompanyId,
        onCompanySelected: setSelectedCompanyId,
        onAnalyticsAdded,
        userAttributeNames,
        onDeleteAnalytics
    }
}