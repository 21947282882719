import "./analytic.scss";
import React, { useMemo, useState } from "react";
import { useAnalytic } from "./useAnalytic";
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
import { UserQuery } from "../../../_common/components/user-query/user-query";
import { dto } from "shared";
import { Button } from "../../../_common/common/button/Button";
import useIsMobile from "../../../hook/use-is-mobile";
import useWindowSize from "../../../hook/use-window-size";
import { useLocalization } from "../../../hook/use-localization";


interface Props {
    id: string,
    companyId: string | null,
    userAttributeNames: dto.UserAttributeName[],
    canDelete?: boolean,
    onDeleteRequested?: (id: string) => void
}

export const Analytic: React.FC<Props> = ({ companyId, id, userAttributeNames, canDelete, onDeleteRequested }) => {
    const { t } = useLocalization();
    const GraphWidth = 600
    const isMobile = useIsMobile();
    const { windowWidth } = useWindowSize()
    const {
        query,
        onSetQuery,
        numberOfUnregisteredUsers: _filteredUsers,
        totalNumberOfUnregisteredUsers: totalUsers,
        queryError,
        addTemplateEnabled,
        addTemplate
    } = useAnalytic({ companyId });
    const [animationFinished, setAnimationFinished] = useState(false);

    const handleAnimationEnd = () => {
        setAnimationFinished(true);
    };


    const filteredUsers = _filteredUsers ?? 0


    const data = totalUsers != null ? [
        { name: t("Other"), value: totalUsers! - filteredUsers, fill: '#D3D3D3' },
        { name: t("FilteredUsers"), value: filteredUsers, fill: '#82ca9d' },
    ] : [];

    const renderCustomizedLabel = (props: any) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

        return (
            <text x={x} y={y} fill="#000000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${data[index].value}/${totalUsers} users(${Math.floor(percent * 100)}%)`}
            </text>
        );
    };

    let cX = useMemo(() => {
        if (isMobile && windowWidth < 700) {
            return (GraphWidth / 2) + (windowWidth - GraphWidth) / 2
        }
        return GraphWidth / 2
    }, [isMobile, windowWidth])


    return (
        <div>
            <div className={"user-query-container " + (queryError ? "error" : "")}>
                <UserQuery onChanged={onSetQuery} indexInParent={0} id={id + "_user_query"} userAttributeNames={userAttributeNames} query={query} label={t("Query")}
                    headerCustomContent={
                        <div className="analytics-actions-container">
                            <Button disabled={!addTemplateEnabled} onClick={addTemplate} className="create-template">
                                {t("CreateTemplate")}
                            </Button>
                            {canDelete && onDeleteRequested != null && <Button onClick={() => onDeleteRequested(id)} className="delete-analytics">
                                {t("Delete")}
                            </Button>}
                        </div>}
                />
            </div>
            <div className="pie-chart-container">
                {data.length > 0 && (
                    <PieChart width={GraphWidth} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={!animationFinished}
                            animationDuration={500}
                            data={data}
                            cx={cX}
                            cy={180}
                            onAnimationEnd={handleAnimationEnd}
                            outerRadius={170}
                            fill="#8884d8"
                            label={renderCustomizedLabel}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                        </Pie>
                        {animationFinished && <Legend />}
                    </PieChart>
                )}

            </div>
        </div>
    );
};
