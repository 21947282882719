export class UploadUnregisteredUsersByExcelFileRequest {
    static contactsSheetName = "Contacts"
}

export enum UploadUnregisteredUsersByExcelFileColumns {
    FirstName = "FirstName",
    LastName = "LastName",
    Email = "Email",
    PhoneNumber = "PhoneNumber",
    DateOfBirth = "DateOfBirth",
    Gender = "Gender",
    ExternalId = "ExternalId"
}