import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IQueuedJobClient } from "./i-queued-job-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class QueuedJobClient extends BaseClient implements IQueuedJobClient {

    async getJobs(data: dto.GetQueuedJobsRequest): Promise<dto.PagingResponse<dto.QueuedJob>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.queuedJob.base + apiPaths.queuedJob.queuedJobs), params: data });
            return response.data as dto.PagingResponse<dto.QueuedJob>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getJob(queuename: string, queuedjobId: string): Promise<dto.QueuedJob> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.queuedJob.base + apiPaths.queuedJob.queuedJob.replace(":queuedjobid", queuedjobId).replace(":queuename", queuename)) });
            return response.data as dto.QueuedJob;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}