import React from "react"
import "./table.scss"
import { SharedDataHelper } from "shared";
import { combineClassNames } from "../../helpers/user-interface-helper";
import { useLocalization } from "../../../hook/use-localization";



interface Props {
    keys: string[],
    headers: string[],
    data: any[]
    dateFormat?: "dd-MM-yyyy" | "dd.MM.yyyy" | "dd.MM.yyyy HH:mm"
    messageWhenNoRecords?: string
    tableClassName?: string
}

export const Table: React.FC<Props> = ({ headers, data, keys, dateFormat, messageWhenNoRecords, tableClassName }) => {
    const { t } = useLocalization()
    const headerItems = headers.map((header) => {
        return <th key={header}>
            {header}
        </th>
    });

    const dataItems = (data ?? []).map((data, index) => {
        const tdCollection = keys.map(key => {
            let val = data[key]
            if (val instanceof Date) {
                if (dateFormat != null) {
                    val = SharedDataHelper.getFormattedDate(val, dateFormat)
                } else {
                    val = (val as Date).toISOString()
                }
            }
            if (key.toLowerCase().indexOf("error") > -1) {
                val = t(val)
            }
            return <td key={`table-td-${index}-${key}`}>
                {val}
            </td>
        })
        return <tr key={`table-row-${index}`}>{tdCollection}</tr>
    });

    return (
        <div className="table-component__container">
            {dataItems.length > 0 && <table className={combineClassNames("table-component__", tableClassName)}>
                <thead>
                    <tr>{headerItems}</tr>
                </thead>
                <tbody>
                    {dataItems}
                </tbody>
            </table>}
            {dataItems.length === 0 && messageWhenNoRecords}
        </div>

    )
}