import { User } from "../user/user";
import { Company } from "../../company/company";

export class UserRole {
    id: string

    roleName: string;
    roleId: number;

    userId: string;
    user: User

    companyId: string
    company: Company

    createdDate: Date
    updatedDate: Date
}