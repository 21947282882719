import { FormikErrors, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { dto, SharedDataHelper } from "shared";
import { IConsentClient, IConsentClientId, IUserClient, IUserClientId, repository } from "shared-client";
import { handleError, setFieldErrors, submitWrapper } from "../../../../common/form/form";
import { cancelEditForm, useTypedFormikContext } from "../../../hook/use-formik-context-typed";
import { validate } from "class-validator";
import { roleDescriptions } from "../../../hook/useUserRole";
import { useAppContext } from "../../../hook/use-app-context";
import useClient from "../../../hook/useClient";
import { UserHelper } from "../../../_common/helpers/user-helper";
import { useGlobalMessageContext } from "../../../hook/use-global-message-context";
import { ConsentItem } from "../../../_common/model/consent-item";
import { useLocalization } from "../../../hook/use-localization";


// only status is allowed to be changed
interface Model {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    mobilePhoneNumber: string,
    dateOfBirth: Date,
    gender: dto.UserGender,
    readonly: boolean,
    companyName: string,
    roleDescription: string,
    consentItems: ConsentItem[];
    originalModel: Omit<Model, "originalModel"> | null
}

export type AccountModel = Model

const createModel = (user: dto.User, consentItems: ConsentItem[], t: (text: string) => string, originalModel?: Model | null): Model => {
    const companyNames = user.roles.filter(x => x.company?.name != null).map(r => r.company.name)
    const companyName = Array.from(new Set(companyNames)).toString()
    const companyRoles = user.roles.filter(x => x.roleId === dto.RoleId.companyAdmin || x.roleId === dto.RoleId.companyUser)

    return {
        readonly: true,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhoneNumber: SharedDataHelper.formatMobilePhoneNumber(user.mobilePhoneNumber),
        gender: user.gender,
        dateOfBirth: user.dateOfBirth,
        companyName: companyName,
        consentItems: consentItems,
        roleDescription: roleDescriptions(companyRoles, t),
        originalModel: originalModel ?? null,
    }
}

const createUpdateRequest = (model: Model) => {
    const request = new dto.UpdateUserRequest()
    request.dateOfBirth = model.dateOfBirth
    request.firstName = model.firstName
    request.lastName = model.lastName
    request.gender = model.gender;
    request.consentIds = model.consentItems.filter(x => x.consent.name !== dto.ConsentName.dataProcessingConsent && x.enabled).map(x => x.consent.id)
    return request
}


export const useAccount = () => {
    const history = useHistory()
    const { t } = useLocalization();
    const { userRoles, clearUser } = useAppContext()
    const { setGlobalMessage, } = useGlobalMessageContext()
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<dto.User | null>(null)
    const client = useClient<IUserClient>(IUserClientId);
    const consentClient = useClient<IConsentClient>(IConsentClientId);
    const tokenRepository = useClient<repository.ITokenRepository>(repository.ITokenRepositoryId)
    const [modelInitialValues, setModelInitialValues] = useState<Model | null>(null);

    const loadInitialModel = useCallback(() => {
        (async () => {
            try {
                setLoading(true)
                setGlobalMessage(null)
                setModelInitialValues(null)

                const user = await client.getCurrentUser();
                const consentItems: ConsentItem[] = []
                const allConsents = await consentClient.getConsents({ dataProcessing: true, sendEmailPromo: true, sendSmsPromo: true });
                for (const consent of allConsents) {
                    const enabled = user?.consentNames.find(x => x === consent.name) != null;
                    consentItems.push({
                        enabled: enabled,
                        consent: consent
                    })
                }

                const originalModel = createModel(user, consentItems, t)
                const model = createModel(user, consentItems, t, originalModel)
                setModelInitialValues(model)
                setUser(user)
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [setLoading, setGlobalMessage, setModelInitialValues, client, consentClient])


    useEffect(() => {
        loadInitialModel()
    }, [])

    const onValidate = useCallback(async (model: Model): Promise<FormikErrors<Model>> => {
        const errorModel: FormikErrors<Model> = {}
        const request = createUpdateRequest(model)
        let validateErrors = await validate(request);
        setFieldErrors<Model>(validateErrors, ["firstName", "lastName", "dateOfBirth", "gender"], errorModel)
        return errorModel
    }, [])


    const onSubmit = useCallback(async (model: Model, { setFieldError, setFieldValue }: FormikHelpers<Model>) => {
        await submitWrapper<Model>(async () => {
            const request = createUpdateRequest(model)
            await client.updateUser(model.userId, request)
            loadInitialModel()
            setGlobalMessage({ message: t("YourAccountIsUpdated"), type: "success" })
        }, { setFieldError, setGlobalMessage, fieldNames: ["firstName", "lastName", "dateOfBirth", "gender"] })
    }, [client, loadInitialModel, setGlobalMessage, t])

    const onDeleteAccount = useCallback((userId: string) => {
        (async () => {
            try {
                setGlobalMessage(null)
                setLoading(true)
                await client.deleteUser(userId)
                setGlobalMessage(null)
                clearUser()
                await tokenRepository.deleteAccessToken()
                await tokenRepository.deleteRefreshToken()
                history.replace("/")
            } catch (err) {
                handleError(err, { setGlobalMessage: setGlobalMessage })
            } finally {
                setLoading(false)
            }
        })()
    }, [client, history, setGlobalMessage, clearUser, tokenRepository])

    const onChangePassword = useCallback(() => {
        history.push("/account/change-password")
    }, [history])

    const hasCompanyRole = useMemo(() => {
        return UserHelper.hasCompanyRoles(user)
    }, [user])

    const onEditUserRoles = useCallback(() => {
        history.push(`/users/${user?.id}/user-roles`)
    }, [history, user?.id])

    const onViewReceivedInvitations = useCallback(() => {
        history.push("/user-received-invitations")
    }, [history])

    return {
        modelInitialValues,
        onSubmit,
        onValidate,
        editMode,
        setEditMode,
        loading,
        onDeleteAccount,
        userId: user?.id,
        accountDeleteEnabled: !userRoles.isAdmin,
        onChangePassword,
        hasCompanyRole,
        onEditUserRoles,
        onViewReceivedInvitations
    }
}

export const useAccountForm = () => {
    const { t } = useLocalization();
    const { setFieldValue, setFormValues, setErrors, model } = useTypedFormikContext<Model>()

    const genders = useMemo(() => {
        return [
            { text: t("Male"), value: dto.UserGender.male },
            { text: t("Female"), value: dto.UserGender.female }]
    }, [t])

    const editForm = useCallback(() => {
        setFieldValue("readonly", false, false)
    }, [setFieldValue])

    const cancelEdit = useCallback(() => {
        cancelEditForm<Model>(model, { setErrors, setValues: setFormValues })
    }, [setFormValues, setErrors, model])

    return {
        editForm,
        cancelEditForm: cancelEdit,
        genders
    }
}
