import { dto } from "shared"

export const IUnregisteredUserClientId = Symbol.for('IUnregisteredUserClient');

export interface IUnregisteredUserClient {
    getUsers(data: dto.GetUnregisteredUsersRequest): Promise<dto.PagingResponse<dto.UnregisteredUser>>
    getUser(userId: string): Promise<dto.UnregisteredUser>
    createUser(data: dto.AddUnregisteredUserRequest): Promise<dto.UnregisteredUser>
    updateUser(userId: string, data: dto.UpdateUnregisteredUserRequest): Promise<void>
    deleteUser(userId: string): Promise<void>
    deleteDataProcessingConsentForUnregisteredUser(userId: string): Promise<void>
    uploadUsersUsingExcel(companyId: string, data: dto.UploadUnregisteredUsersByExcelFileRequest): Promise<dto.UploadUnregisteredUsersByExcelFileResponse>
}