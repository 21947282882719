import { BusinessConfiguration } from "../business-configuration/business-configuration";

export class EventType {
    id: string;
    name: string;
    description: string;
    companyId: string
    createdDate: Date;
    updatedDate: Date;
    sendNotificationsBefore: BusinessConfiguration.TimeDifference[] | null
}