import React from "react";
 import { Route, Redirect, RouteProps } from "react-router-dom";
import { AppCtx } from "../../context/app/app-context";

type PublicRouteProps = RouteProps;

const PublicRoute: React.FC<PublicRouteProps> = (props) => {

    const { component: Component = null, render: Render = null, ...rest } = props;
    const appContext = React.useContext(AppCtx);

    return (
        <Route
            {...rest}
            render={props =>
                appContext.app?.user == null ? (
                    Render ? (
                        Render(props)
                    ) : Component ? (
                        <Component {...props} />
                    ) : null
                ) : (
                    <Redirect to={'/'} />
                )
            }
        />
    );
};
export default PublicRoute;