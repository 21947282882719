import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import "./text-area.scss"
import { combineClassNames } from "../../helpers/user-interface-helper";
import { useLocalization } from "../../../hook/use-localization";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label: string,
    name: string,
    className?: string
    translateValue?: boolean,
}
export const TextArea: React.FC<Props> = (props => {
    const { t } = useLocalization();
    const { className, label, name, translateValue, ...inputProps } = props
    const [field, meta] = useField(name);

    const objField = { ...field }
    if (translateValue && objField["value"] != null && objField["value"] != "") {
        objField["value"] = t(objField["value"])
    }
    const hidden = props.hidden ?? false
    return (
        <>
            {!hidden && <div className="text-area__container">
                <div>{label}</div>
                <textarea className={combineClassNames("text-area__input form-control", className)} {...objField} {...inputProps} />
                {meta.touched && meta.error ? (
                    <div className="error-text">{t(meta.error)}</div>
                ) : null}
            </div>
            }
        </>
    );
})