import { Form, Formik } from "formik"
import React from "react"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./user-consent-history.scss"
import { UserConsentHistoryModel, useUserConsentHistory } from "./useUserConsentHistory"
import { useLocalization } from "../../../hook/use-localization"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { SharedDataHelper } from "shared"



export const UserConsentHistory: React.FC = () => {
    const { t } = useLocalization();
    const { modelInitialValues, loading } = useUserConsentHistory()

    return (
        <Shell pageTitle={t("UserConsentHistory")}>
            <Loading loading={loading} />
            {modelInitialValues != null && <>
                <Formik initialValues={modelInitialValues}
                    onSubmit={() => { }}
                    validateOnBlur={true}>
                    {() => (
                        <Form>
                            <FormContent />
                        </Form>
                    )}
                </Formik>
            </>
            }
        </Shell>
    )
}


const FormContent: React.FC = () => {
    const { t } = useLocalization();
    const { model } = useTypedFormikContext<UserConsentHistoryModel>()

    return (
        <div className="indent-container user-consent-history-form-content">
            {SharedDataHelper.stringNotNullEmpty(model.companyName) && <TextInput name="companyName" disabled={true} label={t("Company")} />}
            <TextInput name="firstName" disabled={true} label={t("FirstName")} />
            <TextInput name="lastName" disabled={true} label={t("LastName")} />
            <TextInput name="email" disabled={true} label={t("Email")} />
            <TextInput name="mobilePhoneNumber" disabled={true} label={t("MobilePhone")} />
            <TextInput name="consentName" translateValue={true} disabled={true} label={t("ConsentName")} />
            <TextInput name="consentEnabled" translateValue={true} disabled={true} label={t("ConsentValue")} />
            {model.consentSourceName != null && <TextInput translateValue={true} name="consentSourceName" disabled={true} label={t("ConsentSourceName")} />}
            <TextInput name="userIdentifier" disabled={true} label={t("UserIdentifier")} />
        </div>)
}
