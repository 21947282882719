import { useField, useFormikContext } from "formik";
import React from "react";
import { useCallback } from "react";
import { Dropdown } from "../../common/dropdown/DropDown";
import "./dropdown-field.scss"
import { useLocalization } from "../../../hook/use-localization";

export interface DropdownItem {
	text: string,
	value: string | boolean
}

interface Props {
	name: string,
	label: string,
	className?: string,
	disabled?: boolean,
	items: DropdownItem[]
}
export const DropDownField: React.FC<Props> = (props) => {
	const { t } = useLocalization();
	const { name, className, disabled, ...dropdownProps } = props
	const [, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null

	const onSelect = useCallback((value: string | null) => {
		setFieldValue(name, value, true)
	}, [setFieldValue, name])
	return (
		<div className="dropdown-field__container">
			<Dropdown {...dropdownProps} onSelect={onSelect} selectedValue={selectedValue} disabled={disabled} />
			{meta.touched && meta.error ? (
				<div className="error-text">{t(meta.error)}</div>
			) : null}
		</div>
	);
}