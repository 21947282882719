import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { IsDefined, IsOptional, ValidateNested } from "class-validator";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { UserAttributeQueryCondition } from "../user-attribute/user-attribute-query";

export class GetClientCredentialsRequest {

    @ContainsValidString()
    companyId: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["createdDate"] })
    sortBy: SortItem[]

    constructor(options: { companyId: string, paging: PagingRequest, sortBy: SortItem[] }) {
        if (options == null) {
            return
        }
        this.companyId = options.companyId
        this.paging = options.paging
        this.sortBy = options.sortBy
    }
}


