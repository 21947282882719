
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";
import { SmsUnsubscribeMarkup } from "../api/dto";


@ValidatorConstraint({ async: true })
export class ContainsSmsUnsubscribeMarkupConstraint implements ValidatorConstraintInterface {
    validate(_text: any): boolean {
        if (_text == null) {
            return false
        }
        if (typeof _text !== "string") {
            return false
        }
        let text = _text.trim()
        if (text.endsWith(".")) {
            text = text.slice(0, -1)
        }
        let markups: string[] = []
        for (const markup in SmsUnsubscribeMarkup) {
            const contains = text.indexOf(`##${markup}##`) > -1;
            if (contains) {
                markups.push(markup)
            }
        }
        if (markups.length != 1) {
            return false
        }

        const valid = text.endsWith(`##${markups[0]}##`);
        return valid
    }

    defaultMessage(args: ValidationArguments) {
        return "InvalidSmsUnsubscribeMarkup"
    }
}

export function ContainsSmsUnsubscribeMarkup(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: ContainsSmsUnsubscribeMarkupConstraint
        });
    };
}