import { dto } from "shared"

export const IUserRepositoryId = Symbol.for('IUserRepository');

export interface IUserRepository {
    getCurrentUser(): Promise<dto.User>
    getUser(userId: string): Promise<dto.User>;
    getUsers(request: dto.GetUsersRequest): Promise<dto.PagingResponse<dto.User>>
    updateUser(userId: string, updateUserRequest: dto.UpdateUserRequest): Promise<void>
    changePassword(userId: string, request: dto.ChangeUserPasswordRequest): Promise<void>
    changeStatus(userId: string, request: dto.ChangeUserStatusRequest): Promise<void>

}