
import { DataHelper } from "../helper/data-helper";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

interface IsUndefinedOrHaveValueOptions {
    allowed: any[]
}

@ValidatorConstraint({ async: true })
export class IsUndefinedOrHaveValueConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        if (text == null) {
            return true
        }
        for (var constraint of args.constraints ?? []) {
            if (Object.is(text, constraint)) {
                return true;
            }
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        return DataHelper.firstLetterUppercase(`${args.property} must be undefined or to be one of: ${args.constraints?.toString()}.`);
    }
}

export function IsUndefinedOrHaveValue(options: IsUndefinedOrHaveValueOptions, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: options.allowed,
            validator: IsUndefinedOrHaveValueConstraint
        });
    };
}