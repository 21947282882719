import { Form, Formik } from "formik"
import React, { useRef } from "react"
import { useTypedFormikContext } from "../../../hook/use-formik-context-typed"
import { Button } from "../../../_common/common/button/Button"
import { Loading } from "../../../_common/components/loading/loading"
import { TextInput } from "../../../_common/fields/text-input/TextInput"
import Shell from "../../../_common/layout/shell/shell"
import "./company-configuration.scss"
import { CompanyConfigurationModel, useCompanyConfiguration, useCompanyConfigurationForm } from "./useCompanyConfiguration"
import { useLocalization } from "../../../hook/use-localization"
import { Confirmation, ConfirmationHandle } from "../../../_common/common/confirmation/confirmation"
import { TextArea } from "../../../_common/fields/text-area/TextArea"


export const CompanyConfiguration: React.FC = () => {
	const { t } = useLocalization()
	const { modelInitialValues, onSubmit, onValidate, loading, goToCompany } = useCompanyConfiguration()

	return (
		<Shell pageTitle={`${t("Company.Configuration")} - ${modelInitialValues?.companyName}`}>
			<Loading loading={loading} />
			{modelInitialValues != null && <>
				<Formik initialValues={modelInitialValues} onSubmit={onSubmit}
					validateOnBlur={true}
					validate={onValidate}>
					{() => (
						<Form>
							<FormContent />
						</Form>
					)}
				</Formik>
				{<div className="alert alert-secondary zone-container indent-container" role="alert">
					<div className="title">{t("Company")}</div>
					<Button onClick={goToCompany}>{t("Back.To.Company")}</Button>
				</div>}
			</>}
		</Shell>
	)
}

const FormContent: React.FC = () => {
	const { t } = useLocalization()
	const { model, submitForm } = useTypedFormikContext<CompanyConfigurationModel>()
	const { editForm, cancelEditForm } = useCompanyConfigurationForm()
	const confirmationSaveRef = useRef<ConfirmationHandle>(null);

	return (
		<div className="indent-container">
			<TextArea className="configJSON" name="configJSON" disabled={model.readonly} label={t("Config")} />

			<div className="action-buttons-row">
				{model.companyId != null && model.readonly && <Button onClick={editForm}>{t("Edit")}</Button>}
				{model.companyId != null && !model.readonly && <Button className="cancel-button" onClick={cancelEditForm}>{t("Cancel")}</Button>}
				{!model.readonly && <Button onClick={() => confirmationSaveRef.current?.show()}>{t("Save")}</Button>}
				<Confirmation level="require-number-confirmation-strong" body={t("Confirmation.Company.Configuration.Change")} ref={confirmationSaveRef} onOkCallback={() => submitForm()} />
			</div>
		</div>)
}
