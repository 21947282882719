
export enum ConsentName {
    sendSmsPromoMessages = "sendSmsPromoMessages",
    sendEmailPromoMessages = "sendEmailPromoMessages",
    dataProcessingConsent = "dataProcessingConsent"
}


export class Consent {
    id: string;
    name: ConsentName;
    nameSr: string
    textSr: string
    nameEn: string
    textEn: string
    createdDate: Date;
    updatedDate: Date;
}