import { StatusResponse } from "../common/status-response";

export class SetEventByExternalIdResponse {
    status: StatusResponse
    unregisteredUserExternalId: string
    eventId: string
    unregisteredUserId: string

    constructor(options: {
        status: StatusResponse,
        eventIdentifier: string,
        unregisteredUserExternalId: string,
        unregisteredUserId: string
    }) {
        this.status = options.status;
        this.eventId = options.eventIdentifier
        this.unregisteredUserExternalId = options.unregisteredUserExternalId
        this.unregisteredUserId = options.unregisteredUserId
    }
}