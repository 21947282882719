import "reflect-metadata";
import { injectable } from "inversify";
import { ClientError } from "../../entity";
import { Config } from "../../config/config"
import { container } from "../../setup/inversify-config"
import { dto, SharedDataHelper, QuerySerializer } from "shared"
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { ITokenRepository, ITokenRepositoryId } from "../auth/token/i-token-repository"
import { IOAuthRepository, IOAuthRepositoryId } from "../auth/oauth/i-oauth-repository"

@injectable()
export class BaseNetworkRepository {
  private baseUrl: string;
  static baseNetworkUrl: string;

  constructor() {
    this.baseUrl = BaseNetworkRepository.baseNetworkUrl ?? Config.baseApiUrl
  }

  protected get publicAxios(): AxiosInstance {
    let axiosApiInstance = axios.create({
      baseURL: this.baseUrl
    });

    axiosApiInstance.interceptors.request.use(request => {
      console.log('Starting Request', request)
      return request
    })

    axiosApiInstance.interceptors.response.use(
      res => {
        console.log(res.data)
        this.processResponse(res.data);
        return res;
      },
      err => {
        console.log(err)
        throw this.createClientError(err);
      }
    );
    return axiosApiInstance;
  }

  protected get protectedAxios(): AxiosInstance {
    let axiosApiInstance = axios.create({
      baseURL: this.baseUrl
    });
    axiosApiInstance.interceptors.request.use(
      async config => {
        // Transform axios request
        // Currently transform only GET request when config.params is Object
        // It converts params object into query string, so server can deserialize object
        // After converting set config.params to NULL.
        this.transformRequest(config);
        console.log('Axios Starting Request', config)
        const tokenRepository = container.get<ITokenRepository>(ITokenRepositoryId)
        const accessToken = await tokenRepository.getAccessToken();
        config.headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
        return config;
      },
      error => {
        Promise.reject(this.createClientError(error))
      });

    const obj_ = this;

    axiosApiInstance.interceptors.response.use((response) => {
      this.processResponse(response.data);
      return response;
    },
      async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          let accessToken: string = null;
          try {
            accessToken = await obj_.refreshAccessToken();
          } catch (err) {
            return Promise.reject(obj_.createClientError(error));
          }
          if (SharedDataHelper.stringIsNullTrimEmpty(accessToken)) {
            return Promise.reject(obj_.createClientError(error));
          }
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
          return axiosApiInstance(originalRequest);
        }
        return Promise.reject(obj_.createClientError(error));
      });

    return axiosApiInstance;
  }

  // Transform axios request
  // Currently transform only GET request when config.params is Object
  // It converts params object into query string, so server can deserialize object
  // After converting set config.params to NULL.
  private transformRequest(config: AxiosRequestConfig) {
    if (config.method.toLocaleLowerCase() == "get" && typeof (config.params) === "object" && config.params != null && config.url != null) {
      const serializedQuery = QuerySerializer.serialize(config.params, { encode: false });
      const questionIndex = config.url!.indexOf("?");
      if (questionIndex < 0) {
        config.url += ("?" + serializedQuery);
      } else if (questionIndex == (config.url!.length - 1)) {
        config.url += serializedQuery
      } else {
        config.url += ("&" + serializedQuery);
      }
      config.params = null;
    }

  }

  private async refreshAccessToken(): Promise<string> {
    const tokenRepository = container.get<ITokenRepository>(ITokenRepositoryId)
    const oauthRepository = container.get<IOAuthRepository>(IOAuthRepositoryId)
    const refreshToken = await tokenRepository.getRefreshToken();
    if (SharedDataHelper.stringIsNullTrimEmpty(refreshToken)) {
      return null;
    }
    const request = new dto.OAuthRefreshToken();
    request.clientId = Config.OAuthClientId;
    request.refreshToken = refreshToken;
    const tokenHolder = await oauthRepository.getTokenByRefreshToken(request);
    /*if (SharedDataHelper.stringIsNullTrimEmpty(tokenHolder?.accessToken)) {
      throw ClientError.createGeneralError("RefreshAccessToken method: access token is null.")
    }
    if (SharedDataHelper.stringIsNullTrimEmpty(tokenHolder?.refreshToken)) {
      throw ClientError.createGeneralError("RefreshAccessToken method: refresh token is null.")
    }*/
    await tokenRepository.saveAccessToken(tokenHolder.accessToken);
    await tokenRepository.saveRefreshToken(tokenHolder.refreshToken);
    return tokenHolder.accessToken;
  }

  // convert response before delivery
  // This function convert date values like  2010-06-15T05:04:00 to date object
  private processResponse(data_: any) {
    if (data_ != null && typeof data_ === "object") {
      const data = data_ as object;
      for (const key of Object.keys(data)) {
        const value = data[key];
        if (key.toLocaleLowerCase().indexOf("date") > -1 && SharedDataHelper.matchISO8601(value)) {
          data[key] = SharedDataHelper.parseISO8601(value);
        } else if (Array.isArray(value)) {
          for (const arrObj of value) {
            this.processResponse(arrObj);
          }
        }
      }
    }
  }

  private createClientError(error: any): ClientError {
    /*if (error instanceof ClientError) {
      return error;
    }
    let clientError = new ClientError();
    if (error.response?.status == 401) {
      clientError.details.push(
        new ClientErrorItemBuilder()
          .setType(ClientErrorItemType.general)
          .setMessage("Access is denied")
          .setAccessDenied(true));
    } else if (error.response?.status == 404) {
      clientError.details.push(
        new ClientErrorItemBuilder()
          .setType(ClientErrorItemType.general)
          .setMessage("Not found")
          .setNotFound(true));
    }
    else if (error.isAxiosError == true && error.response?.data?.serverError == true) {
      const errorResponse = error.response.data as dto.ErrorResponse;
      clientError = ClientError.createFromErrorResponse(errorResponse);
    } else {
      clientError.details.push(
        new ClientErrorItemBuilder()
          .setType(ClientErrorItemType.general)
          .setMessage("Axios error:" + error.message).build());
    }
    return clientError;*/
    return null
  }
}