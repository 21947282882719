import { SortItem } from "../common/query/sort-item";
import { PagingRequest } from "../common/paging/paging-request";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { IsDate, IsDefined, IsOptional, IsString, ValidateNested } from "class-validator";

export class GetEventsRequest {
    @IsOptional()
    @IsString()
    firstName?: string;

    @IsOptional()
    @IsString()
    lastName?: string;

    @IsOptional()
    @IsDate()
    dateFrom?: Date

    @IsOptional()
    @IsDate()
    dateTo?: Date

    @IsOptional()
    @ContainsValidString()
    userId?: string

    @IsOptional()
    @ContainsValidString()
    unregisteredUserId?: string

    @ContainsValidString()
    companyId: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["date", "createdDate"] })
    sortBy: SortItem[]
}