import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { INotificationTaskBatchClient } from "./i-notification-task-batch-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class NotificationTaskBatchClient extends BaseClient implements INotificationTaskBatchClient {

    async addNotificationTaskBatch(data: dto.AddNotificationTaskBatchRequest): Promise<dto.AddNotificationTaskBatchResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.notificationTaskBatch.base + apiPaths.notificationTaskBatch.notificationTaskBatches), data: data });
            return response.data as dto.AddNotificationTaskBatchResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getNotificationTaskBatchInfo(notificationtaskbatchId: string): Promise<dto.NotificationTaskBatchInfo> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTaskBatch.base + apiPaths.notificationTaskBatch.notificationTaskBatch.replace(":notificationtaskbatchid", notificationtaskbatchId)) });
            return response.data as dto.NotificationTaskBatchInfo;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getNotificationTaskBatchInfos(data: dto.GetNotificationTaskBachInfosRequest): Promise<dto.PagingResponse<dto.NotificationTaskBatchInfo>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.notificationTaskBatch.base + apiPaths.notificationTaskBatch.notificationTaskBatches), params: data });
            return response.data as dto.PagingResponse<dto.NotificationTaskBatchInfo>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async cancelNotificationTaskBatch(notificationtaskbatchId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.notificationTaskBatch.base + apiPaths.notificationTaskBatch.cancelNotificationTaskBatch.replace(":notificationtaskbatchid", notificationtaskbatchId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteNotificationTaskBatch(notificationtaskbatchId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.notificationTaskBatch.base + apiPaths.notificationTaskBatch.notificationTaskBatch.replace(":notificationtaskbatchid", notificationtaskbatchId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}