import { NotificationTaskType } from "../notification-task-type";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { IsBoolean, IsDate, IsEnum, IsOptional, ValidateNested } from "class-validator";
import { Type } from "class-transformer";
import { AddNotificationTaskBatchDetails } from "..";
import { NotificationSpecificFactory } from "../specific/notification-specific-factory";
import { LambdaExpression } from "../../../../validators/lambda-expression";


export class AddNotificationTaskBatchRequest {

    @ContainsValidString()
    name: string

    @IsEnum(NotificationTaskType)
    notificationTaskType: NotificationTaskType;

    @ContainsValidString()
    companyId: string

    @ContainsValidString()
    templateId: string

    @IsOptional()
    @ValidateNested()
    @Type(({ object }) => {
        const notificationSpecific = NotificationSpecificFactory.create(object.notificationTaskType)
        return notificationSpecific.addNotificationTaskBatchDetails.constructor
    })
    details: AddNotificationTaskBatchDetails | null

    @IsDate()
    taskDate: Date

    @IsDate()
    expirationDate: Date

    @IsBoolean()
    generateImmediatelyTaskOnlyForMe: boolean

    @IsBoolean()
    generateTasks: boolean

    @LambdaExpression({
        expression: (obj: AddNotificationTaskBatchRequest) => {
            if (obj.generateImmediatelyTaskOnlyForMe && obj.generateTasks) {
                return false
            }
            return true
        }
    }, { message: "GenerateTasksVsGenerateImmediatelyTaskOnlyForMeError" })
    @IsBoolean()
    __meta?: boolean = true

    @LambdaExpression({
        expression: (obj: AddNotificationTaskBatchRequest) => {
            if (obj.taskDate != null && obj.expirationDate != null) {
                return obj.taskDate.getTime() <= obj.expirationDate.getTime()
            }
            return true
        }
    }, { message: "ExpirationDateMustBeGreaterThenTaskDate" })
    @IsBoolean()
    __meta2?: boolean = true
}