import { UserStatus } from "./user-status"
import { UserRole } from "../user-role/user-role"
import { UserGender } from "./user-gender"
import { ConsentName } from "../../consent"

export class User {
    id: string
    email: string
    mobilePhoneNumber: string
    firstName: string
    lastName: string
    gender: UserGender
    timezone: string
    dateOfBirth: Date
    createdDate: Date
    consentNames: ConsentName[]
    updatedDate: Date
    status: UserStatus
    roles: UserRole[]
}