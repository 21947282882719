

const consentPaths = {
    base: "/consents",
    consents: "",
    userConsent: "/:userconsentshortid",
}

export { consentPaths as consent }

