import React, { ReactNode } from "react";
import { AppCtx } from "./app-context";
import { IApp } from "./i-app";

type AppContextProviderProps = { children?: ReactNode }


const AppContextProvider: React.FC<AppContextProviderProps> = (props) => {
    const [app, setApp] = React.useState<IApp>({ user: null });
    return (
        <AppCtx.Provider value={{ app: app, setApp: setApp }}>
            {props.children}
        </AppCtx.Provider>
    )
}

export default AppContextProvider;


