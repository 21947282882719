import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUnregisteredUserClient } from "./i-unregistered-user-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UnregisteredUserClient extends BaseClient implements IUnregisteredUserClient {

    async getUsers(data: dto.GetUnregisteredUsersRequest): Promise<dto.PagingResponse<dto.UnregisteredUser>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.users), params: data });
            return response.data as dto.PagingResponse<dto.UnregisteredUser>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUser(userId: string): Promise<dto.UnregisteredUser> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.user.replace(":userid", userId)) });
            return response.data as dto.UnregisteredUser;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async createUser(data: dto.AddUnregisteredUserRequest): Promise<dto.UnregisteredUser> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.users), data: data });
            return response.data as dto.UnregisteredUser;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async updateUser(userId: string, data: dto.UpdateUnregisteredUserRequest): Promise<void> {
        try {
            await this.protectedAxios({ method: 'patch', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.user.replace(":userid", userId)), data: data });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteUser(userId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.user.replace(":userid", userId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteDataProcessingConsentForUnregisteredUser(userId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.deleteDataProcessingConsent.replace(":userid", userId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async uploadUsersUsingExcel(companyId: string, data: dto.UploadUnregisteredUsersByExcelFileRequest): Promise<dto.UploadUnregisteredUsersByExcelFileResponse> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.unregisteredUser.base + apiPaths.unregisteredUser.uploadExcelFile.replace(":companyid", companyId)), data: data });
            return response.data as dto.UploadUnregisteredUsersByExcelFileResponse;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}