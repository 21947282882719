import React, { useEffect } from "react";
import { useTypedFormikContext } from "../../../../hook/use-formik-context-typed";
import { NotificationTemplateModel } from "../useNotificationTemplate";
import { SmsTemplate } from "../../../../_common/fields/sms-template/sms-template";
import { dto } from "shared";
import { useLocalization } from "../../../../hook/use-localization";

interface Props {
    setDefaultMessage: boolean,
    companyName: string
    userAttributeNames: dto.UserAttributeName[],
    useEventMarkups: boolean
}

export const SmsNotificationTemplateDetails: React.FC<Props> = ({ setDefaultMessage, companyName, userAttributeNames, useEventMarkups }) => {
    const { model } = useTypedFormikContext<NotificationTemplateModel>()
    return (
        <SmsTemplate name="details.messageTemplate" useEventMarkups={useEventMarkups} userAttributeNames={userAttributeNames} showTemplateMarkups={!model.readonly} disabled={model.readonly} label="SMS" />
    )
}