import { ArrayMinSize, IsArray, IsEnum, IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ValidateIfNotStrictUndefined } from "../../../validators/validate-if-not-strict-undefined";
import { IsNotSharedUserAttribute } from "../../../validators/is-not-shared-user-attribute";
import { UserAttributeNameType } from "./user-attribute-name";
import { IsValidCustomAttributeName } from "../../../validators/is-valid-custom-attribute-name";


export class UpdateUserAttributeNameRequest {
    @ValidateIfNotStrictUndefined()
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(200)
    @IsNotSharedUserAttribute()
    @IsValidCustomAttributeName()
    name?: string | undefined | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsString()
    @MaxLength(100000)
    description?: string | null

    @ValidateIfNotStrictUndefined()
    @IsOptional()
    @IsArray()
    @ArrayMinSize(2)
    @ContainsValidString({ each: true })
    selectValues?: string[] | null

}