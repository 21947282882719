import { IsEmail } from "class-validator";
import { ContainsValidString } from "../../../../validators/contains-valid-string";
import { IsMobilePhoneNumber } from "../../../../validators/is-mobile-phone-number"


export class UserRegistrationResendVerificationRequest {
    @IsEmail({}, { message: "Email is not valid." })
    email: string;

    @IsMobilePhoneNumber()
    mobilePhoneNumber: string;

    @ContainsValidString()
    clientId: string;
}