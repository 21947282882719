export class UserConsentHistory {

    id: string
    firstName: string
    lastName: string
    dateOfBirth: Date
    email: string
    mobilePhoneNumber: string
    userIdentifier: string
    consentName: string
    consentSourceName: string
    consentEnabled: boolean
    createdByCompanyName: string
    createdByCompanyIdentifier: string
    actionType: string
    createdDate: Date
}