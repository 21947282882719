import { useField, useFormikContext } from "formik";
import React from "react";
import { useCallback } from "react";
import { UserAttributeNameDropdown } from "../../components/user-attribute-names/user-attribute-name-dropdown";



interface Props {
	name: string,
	disabled?: boolean,
	companyId: string
	onChange?: (name: string, value: string | null) => void,
	allowSharedAttributes: boolean,
    className?: string
}
export const UserAttributeNameDropdownField: React.FC<Props> = (props) => {
	const { name, disabled, companyId, onChange, allowSharedAttributes, className } = props
	const [, meta] = useField(name)
	const { values, setFieldValue } = useFormikContext()
	const selectedValue = values != null ? (values as any)[name] : null

	const onSelect = useCallback((userAttributeName: string | null) => {
		setFieldValue(name, userAttributeName, true)
		if (onChange != null) {
			onChange(name, userAttributeName)
		}
	}, [setFieldValue, name, onChange])
	return (
		<div className={"dropdown-field__container " + className ?? ""}>
			<UserAttributeNameDropdown allowSharedAttributes={allowSharedAttributes} companyId={companyId} selectedUserAttributeName={selectedValue} onSelect={onSelect} disabled={disabled} />
			{meta.touched && meta.error ? (
				<div className="error-text">{meta.error}</div>
			) : null}
		</div>
	);
}