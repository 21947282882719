
import { DataHelper } from "../helper/data-helper";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsMobilePhoneNumberConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        if (text != null && typeof text === "string") {
            if (!DataHelper.stringNotNullTrimEmpty(text)) {
                return false;
            }

            if (text.startsWith(" ")) {
                return false;
            }

            if (text.endsWith(" ")) {
                return false;
            }

            return DataHelper.isValidMobilePhoneNumber(text)
        }
        return false
    }

    defaultMessage(args: ValidationArguments) {
        if (args.value != null && typeof (args.value) === "string" && args.value.startsWith(" ")) {
            return `Mobile phone number can not start with space.`;
        }

        if (args.value != null && typeof (args.value) === "string" && args.value.endsWith(" ")) {
            return `Mobile phone number can not end with space.`;
        }

        if (args.value != null && typeof (args.value) === "string" && !args.value.startsWith("+381")) {
            return `Mobile phone must start with +381`;
        }
        return `Mobile invalid`;
    }
}

export function IsMobilePhoneNumber(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsMobilePhoneNumberConstraint
        });
    };
}