import { IsBoolean, IsDefined, IsEnum, IsOptional, MinLength, ValidateNested } from 'class-validator';
import { ContainsValidString } from '../../../../validators/contains-valid-string';
import { NotificationTemplateDetails, UserAttributeQueryCondition } from '../..';
import { Type } from 'class-transformer';
import { NotificationTemplateType } from './notification-template-type';
import { MessageType } from "../../message-type/message-type"
import { NotificationSpecificFactory } from '../specific/notification-specific-factory';
import { LambdaExpression } from '../../../../validators/lambda-expression';

export class AddNotificationTemplateRequest {

    @ContainsValidString()
    companyId: string;

    @ContainsValidString()
    @MinLength(3)
    name: string;

    @IsEnum(NotificationTemplateType)
    templateType: NotificationTemplateType

    @ValidateNested({ message: "Template details are not valid" })
    @Type(({ object }) => {
        const notificationSpecific = NotificationSpecificFactory.createByTemplate(object.templateType)
        return notificationSpecific.notificationTemplateDetails.constructor
    })
    details: NotificationTemplateDetails

    @IsBoolean()
    isUserRelated: boolean

    @IsBoolean()
    isUnregisteredUserRelated: boolean

    @IsOptional()
    @ContainsValidString()
    eventTypeId: string | null

    @IsOptional()
    @ValidateNested()
    query?: UserAttributeQueryCondition | null

    @IsEnum(MessageType)
    messageType: MessageType

    @LambdaExpression({
        expression: (obj: AddNotificationTemplateRequest) => {
            return !(obj.query != null && obj.eventTypeId != null)
        }
    }, { message: "One of query or eventTypeId can have value" })
    metaValidation? = true

}