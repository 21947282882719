import { IsBoolean, IsEmail, IsNotEmpty, ValidateIf } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";

export class OAuthPasswordGrant {

    @IsNotEmpty({ message: "Required" })
    @ContainsValidString()
    @IsEmail({}, { message: "Email is not valid." })
    email: string;

    @IsNotEmpty({ message: "Required" })
    @ContainsValidString()
    password: string;

    @ContainsValidString()
    smsToken: string

    @ContainsValidString()
    clientId: string;

    @ContainsValidString()
    grantType: string;
}