
import { SortTypeValues } from "../api/dto/common/query/sort-type";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsSortTypeConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        return SortTypeValues.find(x => x == text) != null;
    }

    defaultMessage(args: ValidationArguments) {
        return "SortType must be some of:" + SortTypeValues.toString();
    }
}

export function IsSortType(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsSortTypeConstraint
        });
    };
}