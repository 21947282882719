

export class AddNotificationTaskBatchResponse {
    id: string
    totalNumberCreated: number
    expectedNumberOfCreated: number
    
    // when user experiment, before send to all users , he wants to send to self
    sentDirectlyToUser: boolean

    // is limited by monthly limit
    isLimitedByMonthlyLimit: boolean
    
    // if not limited, how many would create
    availableWhenNotLimited: number
}