import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IAppTypeClient } from "./i-app-type-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class AppTypeClient extends BaseClient implements IAppTypeClient {

    async appType(): Promise<dto.AppType> {
        try {
            const response = await this.publicAxios({ method: 'get', url: (apiPaths.appTypePaths.base + apiPaths.appTypePaths.appType) });
            return response.data as dto.AppType;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}