

const unregisteredUserPaths = {
    base: "/unregistered-users",
    users: "",
    user: "/:userid",
    uploadExcelFile: "/:companyid/upload-excel-file",
    deleteDataProcessingConsent: "/unregistered-user/delete-processing-consent/:userid",

}

export { unregisteredUserPaths as unregisteredUser }

