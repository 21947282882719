import { SmsAddNotificationTaskBatchDetails } from "../../notification-task-batch/_index";
import { SmsAddNotificationTaskDetails } from "../../notification-task/_index";
import { SmsNotificationTemplateDetails } from "../../notification-template/sms";
import { INotificationSpecific } from "../i-notification-specific";

export class SmsNotificationSpecific implements INotificationSpecific {


	get addNotificationTaskDetails(): { addressProperty: string; messageToSendProperty: string; constructor: Function; } {
		return {
			addressProperty: "mobilePhoneNumber",
			messageToSendProperty: "messageToSend",
			constructor: SmsAddNotificationTaskDetails
		}
	}

	get addNotificationTaskBatchDetails(): { constructor: Function; } {
		return {
			constructor: SmsAddNotificationTaskBatchDetails
		}
	}

	get notificationTaskBatchDetails(): { constructor: Function; } {
		return {
			constructor: SmsAddNotificationTaskBatchDetails
		}
	}

	get notificationTemplateDetails(): { constructor: Function; } {
		return {
			constructor: SmsNotificationTemplateDetails
		}
	}
}