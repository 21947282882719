import { useCallback, useContext } from "react";
import { IGlobalMessage } from "../context/global-message/global-message";
import { GlobalMessageCtx } from "../context/global-message/global-message-context";
 
export const useGlobalMessageContext = () => {
	const { globalMessage, setGlobalMessage } = useContext(GlobalMessageCtx);

	const _setGlobalMessage = useCallback((message: IGlobalMessage | null) => {
		setGlobalMessage(message as any)
	}, [setGlobalMessage])

	return {
		globalMessage,
		setGlobalMessage: _setGlobalMessage
	}
}