
export enum NotificationTaskStatus {
    pending = "pending",
    completed = "completed",
    error = "error",
    expired = "expired",
    validationFailed = "validationFailed",
    apiGatewayAccepted = "apiGatewayAccepted",
    apiGatewaySent = "apiGatewaySent",
    apiGatewayUnknown = "apiGatewayUnknown",
    apiGatewayFailed = "apiGatewayFailed",

}