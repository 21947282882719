import { interfaces } from "inversify";
import { useMemo } from "react";
import { browserContainer } from "../../setup/inversify-config";

function useClient<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>) {
	const client = useMemo((): T => {
		return browserContainer.get(serviceIdentifier)
	}, [serviceIdentifier])
	return client
}

export default useClient;


