
import { DataHelper } from "../helper/data-helper";
import { JoinOperatorValues } from "../api/dto/common/query/join-operator";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";

@ValidatorConstraint({ async: true })
export class IsJoinOperatorConstraint implements ValidatorConstraintInterface {

    validate(text: any, args: ValidationArguments) {
        if (DataHelper.stringNotNullTrimEmpty(text)) {
            return JoinOperatorValues.find(x => x == text) != null;
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        return "JoinOperator value must be some of: " + JoinOperatorValues.toString();
    }
}

export function IsJoinOperator(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsJoinOperatorConstraint
        });
    };
}