import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IUserConsentHistoryClient } from "./i-user-consent-history-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class UserConsentHistoryClient extends BaseClient implements IUserConsentHistoryClient {

    async getUserConsentHistories(data: dto.GetUserConsentHistoriesRequest): Promise<dto.PagingResponse<dto.UserConsentHistory>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userConsentHistory.base + apiPaths.userConsentHistory.userConsentHistories), params: data });
            return response.data as dto.PagingResponse<dto.UserConsentHistory>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getUserConsentHistory(userconsenthistoryId: string): Promise<dto.UserConsentHistory> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.userConsentHistory.base + apiPaths.userConsentHistory.userConsentHistory.replace(":userconsenthistoryid", userconsenthistoryId)) });
            return response.data as dto.UserConsentHistory;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}