import { SortItem } from "../common/query/sort-item";
import { IsDefined, IsOptional, ValidateNested } from "class-validator";
import { PagingRequest } from "../common/paging/paging-request";
import { AreValidSortItems } from '../../../validators/are-valid-sort-items';

export class GetCompaniesRequest {
    @IsOptional()
    name?: string;

    @IsDefined()
    @ValidateNested()
    paging: PagingRequest;

    @AreValidSortItems({ allowed: ["name", "email", "createdDate"] })
    sortBy: SortItem[]
}