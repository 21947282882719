import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import "./text-input.scss"
import { useLocalization } from "../../../hook/use-localization";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string | React.ReactNode,
    name: string,
    translateValue?: boolean,
    transformValue?: (val: any) => any
}
export const TextInput: React.FC<Props> = (props => {
    const { t } = useLocalization()
    const { label, name, translateValue, transformValue, ...inputProps } = props
    const [field, meta] = useField(name);
    const objField = { ...field }
    if (translateValue && objField["value"] != null && objField["value"] != "") {
        objField["value"] = t(objField["value"])
    }
    if (transformValue) {
        objField["value"] = transformValue(objField["value"]);
    }

    return (
        <div className="text-input__container">
            <div>{label}</div>
            <input className="text-input__input form-control" {...objField} {...inputProps} />
            {meta.touched && meta.error ? (
                <div className="error-text">{t(meta.error)}</div>
            ) : null}
        </div>
    );
})