
import { DataHelper } from "../helper/data-helper";
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from "class-validator";
const moment = require('moment-timezone');


@ValidatorConstraint({ async: true })
export class ContainsValidTimeZoneConstraint implements ValidatorConstraintInterface {
    validate(text: any, args: ValidationArguments) {

        if (text != null && typeof text === "string") {
            if (!DataHelper.stringNotNullTrimEmpty(text)) {
                return false;
            }

            if (text.startsWith(" ")) {
                return false;
            }

            if (text.endsWith(" ")) {
                return false;
            }

            const timezone = moment.tz.zone(text);
            if (timezone == null) {
                return false
            }

            return true;
        }
        return false;
    }

    defaultMessage(args: ValidationArguments) {
        const name = "Value"
        if (args.value == null) {
            return `${name} is required`;
        }
        if (typeof args.value !== "string") {
            return `${name} is invalid`;
        }

        if (args.value.trim() == "") {
            return `${name} is required`;
        }

        if (args.value.startsWith(" ")) {
            return `${name} can not start with space`;
        }

        if (args.value.endsWith(" ")) {
            return `${name} can not end with space`;
        }

        const timezone = moment().tz(args.value);
        if (timezone == null) {
            return `${name} is not valid timezone`;
        }

        return `Invalid timezone`;
    }
}

export function ContainsValidTimezone(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            constraints: [],
            validator: ContainsValidTimeZoneConstraint
        });
    };
}