import { dto, apiPaths } from "shared";
import { injectable } from "inversify";
import { IClientCredentialClient } from "./i-client-credential-client";
import { BaseClient } from "../../base/base-client"

@injectable()
export class ClientCredentialClient extends BaseClient implements IClientCredentialClient {

    async getClientCredentials(data: dto.GetClientCredentialsRequest): Promise<dto.PagingResponse<dto.ClientCredential>> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.clientCredential.base + apiPaths.clientCredential.clientCredentials), params: data });
            return response.data as dto.PagingResponse<dto.ClientCredential>;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async getClientCredential(clientcredentialId: string): Promise<dto.ClientCredential> {
        try {
            const response = await this.protectedAxios({ method: 'get', url: (apiPaths.clientCredential.base + apiPaths.clientCredential.clientCredential.replace(":clientcredentialid", clientcredentialId)) });
            return response.data as dto.ClientCredential;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async createClientCredential(data: dto.AddClientCredentialRequest): Promise<dto.ClientCredential> {
        try {
            const response = await this.protectedAxios({ method: 'post', url: (apiPaths.clientCredential.base + apiPaths.clientCredential.clientCredentials), data: data });
            return response.data as dto.ClientCredential;
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }

    async deleteClientCredential(clientcredentialId: string): Promise<void> {
        try {
            await this.protectedAxios({ method: 'delete', url: (apiPaths.clientCredential.base + apiPaths.clientCredential.clientCredential.replace(":clientcredentialid", clientcredentialId)) });
        }
        catch (err) {
            const _err = err;
            throw err;
        }
    }
}