import { CompanyStatus } from "./company-status";
import { IsEmail, IsEnum, MaxLength, MinLength } from "class-validator";
import { ContainsValidString } from "../../../validators/contains-valid-string";
import { ContainsValidTimezone } from "../../../validators/contains-valid-timezone";

export class AddCompanyRequest {
    @ContainsValidString()
    @MinLength(3)
    @MaxLength(50)
    name: string

    @IsEmail()
    email: string

    @ContainsValidString()
    @MinLength(3)
    @MaxLength(100)
    address: string

    @IsEnum(CompanyStatus)
    status: CompanyStatus

    @ContainsValidTimezone()
    timezone: string
}